import { COMMONTYPES } from "TYPES/event.types";
import { LogoutIcon, RightArrowIcon } from "assets/Icons";
import { BUSINESS_NAVS, NAVIGATION_LINKS } from "navigation/AccountRoutes/navs";
import { useRecoilValue } from "recoil";
import { userRoleAtom } from "store/atoms/userAtom";
import { HeaderCON } from "../styles";
import { LogoutCon, RouteLink } from "./styles";
import useLogout from "hooks/logout";

const MobileAccount = () => {
  const userRole = useRecoilValue(userRoleAtom);
  const logout = useLogout();

  const _logout = async (e: COMMONTYPES["divClick"]) => {
    e.preventDefault();
    logout();
  };
  return (
    <div>
      <HeaderCON>Account</HeaderCON>
      {(userRole === "BUSINESS" ? BUSINESS_NAVS : NAVIGATION_LINKS).map(
        ({ to, label, Icon }) => (
          <RouteLink to={`/account/${to}` ?? "/"}>
            <span>
              {Icon && <Icon />}
              {label}
            </span>{" "}
            <RightArrowIcon />
          </RouteLink>
        ),
      )}
      <LogoutCon onClick={_logout}>
        <LogoutIcon />
        Logout
      </LogoutCon>
    </div>
  );
};

export default MobileAccount;
