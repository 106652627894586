import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { Skeleton, useMantineTheme } from '@mantine/core';
import { LogoIcon } from 'assets/Icons';
import 'react-multi-carousel/lib/styles.css';
import { VendorCard } from '../Vendors/styles';
import { ReviewCon, WrapperCon } from './style';
import QUERY_KEYS from 'network/config/queryKeys';
import { Reveal } from '../../atoms/Container/Reveal';
import { getAllVendors } from 'network/services/vendors';
import { CATEGORIES, SUPERMARKET } from 'navigation/routes';
import { userLocationAtom } from 'store/atoms/userLocationAtom';
import SEARCH_PARAMS from 'constants/searchParams';

export const LandingVendors = () => {
  const [userLocation, setUserLocation] = useRecoilState(userLocationAtom);
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.VENDORS, userLocation?.address.state],
    queryFn: () => getAllVendors(userLocation?.address.state),
    onError: error => {
      if ((error as any)?.response?.data?.status === 'NOT_FOUND') {
        setUserLocation(undefined);
      }
    }
  });
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const tablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const vendors = data?.data ? data.data.filter(item => item.category && item.category.length > 0) : [];

  return (
    <WrapperCon>
      <ReviewCon>
        <div className="content">
          <div className="heading">
            <Reveal>
              <p className="text-[#22B872] lg:text-xl text-lg font-semibold">Stores</p>
            </Reveal>
          </div>

          <Reveal>
            <div className="review-items px-3 lg:px-0 w-full">
              {(isLoading || vendors.length > 0) && (
                <Carousel
                  className="carousel"
                  w={'100%'}
                  maw={'95vw'}
                  slidesToScroll={mobile ? 1 : tablet ? 2 : 5}
                  slideSize={mobile ? '90%' : tablet ? '20%' : '20%'}
                  slideGap="lg">
                  {isLoading &&
                    Array(7)
                      .fill(0)
                      .map((_, id) => (
                        <Carousel.Slide key={id}>
                          <Skeleton height={200} width={400} />
                        </Carousel.Slide>
                      ))}
                  {vendors.length > 0 &&
                    vendors.map((vendor, id) => (
                      <Carousel.Slide key={id}>
                        <Link
                          className="h-[200px] w-[400px]"
                          to={`${CATEGORIES}${SUPERMARKET}?${SEARCH_PARAMS.VENDOR_ID}=${vendor.vendorId}&${SEARCH_PARAMS.BG_ID}=${id}&profileImage=${encodeURIComponent(vendor.profilePicture ?? '')}`}>
                          <VendorCard>
                            <div className="img">
                              {vendor.profilePicture ? (
                                <img className="vendor-img" src={vendor.profilePicture} alt="vendor" />
                              ) : (
                                <LogoIcon className="icon" />
                              )}
                            </div>
                            <span className="text-center text-sm font-medium">{vendor.business_name}</span>
                          </VendorCard>
                        </Link>
                      </Carousel.Slide>
                    ))}
                </Carousel>
              )}
              {vendors.length === 0 && !isLoading && (
                <div className="py-10 w-full">
                  <div className="font-normal w-full text-black/50 text-sm">No Vendors found near your location</div>
                </div>
              )}
            </div>
          </Reveal>
        </div>
      </ReviewCon>
    </WrapperCon>
  );
};
