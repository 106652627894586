export const JOIN_WAITING_LIST = '/api/v1/auth/customer-email';
// Auth
export const LOGIN = '/api/v1/customer/auth/login';
export const REGISTER = '/api/v1/customer/auth/register';
export const BUSINESS_REGISTER = '/api/v1/customer/business/auth/register';
export const UPLOAD_BUSINESS_UTILITY_BILL = '/api/v1/customer/business/upload-utility';
export const UPLOAD_BUSINESS_GOVERNMENT_ID = '/api/v1/customer/business/upload-government-id';
export const UPDATE_BUSINESS_KYB = '/api/v1/customer/update_kyb';
export const VERIFY_OTP = '/api/v1/customer/auth/verify';
export const REQUEST_OTP = `/api/v1/customer/auth/resend-otp`;
export const RESET_PASSWORD = '/api/v1/customer/auth/reset-password';
export const NEW_PASSWORD = '/api/v1/customer/auth/new-password';
export const LOGOUT = '/api/v1/auth/logout';
export const GOOGLE_LOGIN = '/api/v1/customer/auth/google-callback';
// Profile
export const GET_CUSTOMER = '/api/v1/customer/get_customer';
export const UPDATE_CUSTOMER = '/api/v1/customer/update';
export const DELETE_CUSTOMER = '/api/v1/customer/1';
export const CHANGE_PASSWORD = '/api/v1/customer/change_password';
export const UPDATE_PIC = '/api/v1/customer/update_profile_picture';
export const DELETE_ACCOUNT = '/api/v1/customer/delete-account';
// Address
export const GET_ALL_ADDRESSES = '/api/v1/customer/addresses';
export const ADD_ADDRESS = '/api/v1/customer/add_address';
export const DELETE_ADDRESS = '/api/v1/customer/address/';
export const GET_SINGLE_ADDRESS = '/api/v1/customer/address/';
export const UPDATE_ADDRESS = '/api/v1/customer/address/update/';
export const SET_DEFAULT_ADDRESS = '/api/v1/customer/address/set_default_address/';
// product or goods
export const GET_ALL_PRODUCT = '/api/v1/products';
export const GET_PRODUCTS = '/api/v1/products/';
export const GET_PRODUCTS_BY_SUBCATEGORY = 'api/v1/products/subcategory';
export const GET_PRODUCTS_BY_VENDOR = '/api/v1/products/get-products-by-vendor';
export const GET_PRODUCTS_BY_VENDOR_AND_CATEGORY = '/api/v1/products/get-products-by-vendor-and-category';
export const GET_PRODUCTS_BY_SEARCH = '/api/v1/products/search_products';
export const LIKE_PRODUCT = '/api/v1/products/';
export const GET_FAVOURITE_PRODUCTS = '/api/v1/products/favourite-products';
export const GET_PRODUCT_DETAIL = '/api/v1/products/details';
export const UPDATE_CART_ITEM_QUANTITY = '/api/v1/customer/cart/';

// categories
export const GET_ALL_CATEGORIES = '/api/v1/categories';
export const GET_SUB_CATEGORIES = '/api/v1/categories/subcategories';

// orders
export const GET_ALL_ORDERS = '/api/v1/customer/cart/view_order/all';
export const GET_PENDING_ORDERS = '/api/v1/customer/cart/view_order/pending';
export const GET_ONGOING_ORDERS = '/api/v1/customer/cart/view_order/ongoing';
export const GET_COMPLETED_ORDERS = '/api/v1/customer/cart/view_order/completed';
export const GET_CANCELLED_ORDERS = '/api/v1/customer/cart/view_order/cancelled';
export const GET_SINGLE_ORDER = 'api/v1/customer/cart/view_single_order';
export const GET_ORDER_TRACKING = '/api/v1/customer/cart/view_single_tracking_order';
export const CANCEL_ORDER = '/api/v1/customer/cart/cancel_order';
export const SUBMIT_ORDER = '/api/v1/customer/cart/submit_cart';
export const BUSINESS_SUBMIT_CART = '/api/v1/business/cart/checkout';

// carts
export const REMOVE_FROM_CART = '/api/v1/customer/cart/remove_cart_item/';
export const BUSINESS_REMOVE_FROM_CART = '/api/v1/business/cart/remove_cart_item/';
export const BUSINESS_ADD_TO_CART = '/api/v1/business/cart/add/';
export const ADD_TO_CART = '/api/v1/customer/cart/add';
export const BUSINESS_VIEW_CART = '/api/v1/business/cart/view_cart';
export const VIEW_CART = '/api/v1/customer/cart/view_cart';
export const CLEAR_CART = '/api/v1/customer/cart/clear-cart';
//Wallet
export const GET_WALLET_HISTORIES = 'api/v1/customer/wallet/histories';
export const POINT_NAIRA_EQUIVALENCE = '/api/v1/customer/wallet/point-naira-equivalent?point=';
export const FUND_MADINA_WALLET = '/api/v1/customer/wallet/fund_wallet';
export const CONVERT_POINTS_TO_NAIRA = '/api/v1/customer/wallet/convert_point';
export const GET_POINTS_NAIRA_EQUIVALENT = '/api/v1/customer/wallet/point-naira-equivalent?point=';
export const GET_WALLET_BALANCE = 'api/v1/customer/wallet/balance';
export const GET_POINTS_EXCHANGE_RATE = '/api/v1/customer/wallet/exchange_rate';
//Review
export const SUBMIT_REVIEW = '/api/v1/customer/review/submit';
// inbox
export const GET_INBOX_MESSAGES = '/api/v1/customer/inbox';

//Logistics
export const BOOK_LOGISTICS_SHIPMENT = '/api/v1/logistics/book-logistic-shipment';
export const ASSIGN_LOGISTICS_SHIPMENT = '/api/v1/logistics/assign-shipment';
export const CREATE_CHOWDECK_DELIVERY = '/api/v1/logistics/create-delivery?orderRef=';

export const GET_DELIVERY_AGENTS = '/api/v1/customer/delivery_agent/all';
export const GET_AVAILABLE_CARRIERS = '/api/v1/logistics/logistic-rates';
export const VALIDATE_ADDRESS = '/api/v1/logistics/validate-addresss?address=';

// Checkout
export const SUBMIT_NIN = '/api/v1/customer/update_bvn_nin/1';
export const CHECK_CREDIT_SCORE = '/api/v1/customer/mono/check-credit-score-with-product-price';
export const CHECKOUT_WITH_BNPL = '/api/v1/customer/bnpl/submit';
export const CHECK_BNPL_LOAN_STATUS = '/api/v1/customer/bnpl/check-customer-bnpl-loan-status';
export const CHECK_LOAN_INSURANCE = '/api/v1/customer/insurance/total_benefit_coverage';
export const CHECKOUT_WITH_WALLET = '/api/v1/customer/wallet/debit_wallet';
export const POST_PURCHASE_PAYMENTS = '/api/v1/business/post-purchase-payments';

// Verify Identity
export const INITIATE_BVN_LOOKUP = '/api/v1/customer/mono/initiate_bvn_lookup?bvn=';
export const INITIAITE_BVN = 'api/v1/customer/business/verify-bvn?bvn=';
export const VERIFY_BVN = '/api/v1/customer/mono/verify_bvn_otp';
export const FETCH_BVN = '/api/v1/customer/mono/fetch_bvn_details';

// Vendors
export const GET_ALL_VENDORS = '/api/v1/unauthorized/sellers';
export const VENDOR = '/api/v1/vendor/';
export const GET_VENDOR_BY_ID_FRAGMENT = '/get-vendor-by-id';
export const GET_VENDOR_BY_LOCATION = '/api/v1/unauthorized/vendors-by-location';

// Individual Loan Management
export const GET_ALL_LOANS = '/api/v1/customer/loan/get_loans/all';

export const GET_LOAN_HISTORY = '/api/v1/customer/loan/get_loan_history/';

export const GET_LOAN_TENURES = '/api/v1/customer/loan_tenure/all';

export const REPAY_LOAN = '/api/v1/customer/loan/loan_payment';

export const GET_LOAN_SETTINGS = '/api/v1/customer/get-loans-settings';

// Subscriptions
export const SUBSCRIPTION_PACKAGES = '/api/v1/madina-plus/subscriptions-package';
export const SUBSCRIBE_TO_PACKAGE = '/api/v1/madina-plus/subscribe';
export const SUBSCRIPTION_HISTORY = '/api/v1/madina-plus/subscriptions';
export const CANCEL_SUBSCRIPTION = '/api/v1/madina-plus/';
export const UPDATE_SUBSCRIPTION = '/api/v1/madina-plus/';

// Referals
export const GET_REFERRAL_LINKS = '/api/v1/customer/get-referral-code';
export const GET_REFERRAL_REWARDS = '/api/v1/customer/get-referral-awards';
export const GET_REFERRALS_TOTAL = '/api/v1/customer/get-referrals';

// AI Assistant
export const INITIATE_CHAT = '/api/v1/openai/initiate-chat';
export const SEND_MESSAGE = '/api/v1/openai/interact?message_content=';
export const CANCEL_RUN = '/api/v1/openai/cancel-run';
//verify Bvn
export const CHECK_BVN_VERIFIED = '/api/v1/customer/mono/check-bvn-verification-status';
export const RESUBMIT_BNPL = '/api/v1/customer/bnpl/';
export const CANCEL_LOAN = '/api/v1/customer/loan/';
export const REPAYLOAN = '/api/v1/customer/loan/repayment';

// usercards
export const GET_MY_CARDS = '/api/v1/customer/get-card';
export const ADD_NEW_CARD = '/api/v1/customer/add-card';
