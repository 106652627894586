import { useQuery } from 'react-query';
import { useRef, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import ProfilePic from './ProfilePic';
import { COMMONTYPES } from 'TYPES/event.types';
import QUERY_KEYS from 'network/config/queryKeys';
import { checkFormData, validators } from 'utils/validator';
import { Button, InputField, Spacer } from 'components/atoms';
import { useMutation } from 'network/mutations/useMutationHook';
import { getCustomer, updateCustomer } from 'network/services/profile';

const INITIALSTATE = {
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  businessName: ''
};

export const ProfileDetails = () => {
  const [error, setError] = useState(INITIALSTATE);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const businessNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const [profilePic, setProfilePic] = useState<string>();
  const { data: profile } = useQuery({
    queryFn: getCustomer,
    queryKey: QUERY_KEYS.CUSTOMER_PROFILE,
    onSuccess: data => {
      if (data.data.customer_type === 'BUSINESS') {
        businessNameRef.current!.value = data.data.business_name ?? '';
      } else {
        lastNameRef.current!.value = data.data.lastname;
        firstNameRef.current!.value = data.data.firstname;
      }
      emailRef.current!.value = data.data.email;
      phoneNumberRef.current!.value = data.data.phoneNumber;
      setProfilePic(data.data.profilePicture);
    }
  });

  const handleChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    const err = validators[name](value);

    setError(error => ({ ...error, [name]: err }));
  };

  const { mutateAsync, isLoading } = useMutation(updateCustomer, 'UPDATE_PROFILE');

  const _submit = async (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();
    const details: Record<string, string> = {
      phoneNumber: phoneNumberRef.current!.value
    };
    if (profile?.data.customer_type === 'BUSINESS') {
      details['businessName'] = businessNameRef.current?.value ?? '';
    } else {
      details['firstname'] = firstNameRef.current!.value;
      details['lastname'] = lastNameRef.current!.value;
    }
    const isValid = checkFormData(details, setError);
    if (!isValid) return;

    try {
      const { data } = await mutateAsync(details);
      enqueueSnackbar({ message: data?.description, variant: 'success' });
    } catch (error) {
      enqueueSnackbar({ message: 'Profile Update failed', variant: 'success' });
    }
  };

  return (
    <>
      <h2>Personal Information</h2>
      <ProfilePic setProfilePic={setProfilePic} profilePic={profilePic} />
      {profile?.data.customer_type === 'BUSINESS' ? (
        <InputField
          required
          name="businessName"
          label="Business Name"
          inputRef={businessNameRef}
          error={error.businessName}
          onChange={handleChange}
          placeholder="Enter business name"
          defaultValue={profile?.data.business_name ?? ''}
        />
      ) : (
        <div className="name-div">
          <InputField
            required
            name="firstname"
            label="First Name"
            inputRef={firstNameRef}
            error={error.firstname}
            onChange={handleChange}
            placeholder="Enter first name"
            defaultValue={profile?.data.firstname ?? ''}
          />
          <InputField
            required
            name="lastname"
            label="Last Name"
            error={error.lastname}
            inputRef={lastNameRef}
            onChange={handleChange}
            placeholder="Enter last name"
            defaultValue={profile?.data.lastname ?? ''}
          />
        </div>
      )}
      <Spacer height="32px" />
      <InputField
        required
        name="email"
        type="email"
        error={error.email}
        inputRef={emailRef}
        label="Email Address"
        placeholder="Email Address"
        onChange={handleChange}
        defaultValue={profile?.data.email}
      />
      <Spacer height="32px" />
      <InputField
        required
        type="number"
        name="phoneNumber"
        label="Phone Number"
        onChange={handleChange}
        placeholder="Phone Number"
        error={error.phoneNumber}
        inputRef={phoneNumberRef}
        defaultValue={profile?.data.phoneNumber}
      />
      <Spacer height="49px" />
      <Button isLoading={isLoading} onClick={_submit} disabled={isLoading}>
        Change
      </Button>
    </>
  );
};
