import { CHECKOUTACTION } from './action-type';

export const INTIALSTATE = {
  isPointModal: false,
  isScheduleModal: false,
  isPaymentModal: false,
  isPayWithMadinaModal: false,
  isConfirmPaymentModal: false,
  isRejectPaymentModal: false,
  addPoint: 1,
  deliveryMethod: 'Home Delivery',
  deliveryTime: '6:00pm',
  deliveryDay: new Date().toDateString(),
  scheduleType: 'Immediately',
  logistics: {
    id: 1,
    value: 'Madina'
  },
  note: '',
  addressId: 0
};

export const checkoutReducer = (state: typeof INTIALSTATE, action: Record<string, any>) => {
  const { type, payload } = action;

  switch (type) {
    case CHECKOUTACTION.SINGLE:
      return { ...state, ...payload };
    default:
      break;
  }
};
