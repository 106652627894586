import { Scrollable } from 'components/atoms';
import QUERY_KEYS from 'network/config/queryKeys';
import { getAllProducts } from 'network/services/goods';
import { ProductsGrid } from 'pages/Products';
import React from 'react';
import { useInfiniteQuery } from 'react-query';

interface Props {
  onLoad?: () => void;
}

const LIMIT = 20;
const AllProducts: React.FC<Props> = ({ onLoad }) => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage, isFetching } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.ALL_PRODUCTS],
    queryFn: ({ pageParam = 0 }) => getAllProducts({ page: pageParam, limit: LIMIT }),
    onSuccess: onLoad,
    getNextPageParam: (lastPage, allPages) => {
      return (lastPage ?? []).length === LIMIT ? allPages.length : undefined;
    }
  });

  return (
    <Scrollable onScrollBottom={fetchNextPage} callWhen={hasNextPage}>
      <ProductsGrid
        emptyMessage="No products were found"
        skeletonCount={LIMIT}
        products={data?.pages ? data.pages.flat() : []}
        isLoading={isFetching || isFetchingNextPage || isLoading}
      />
    </Scrollable>
  );
};

export default AllProducts;
