import { Box, Collapse, ListItem } from "@mui/material";
import { HamBurger } from "assets/Icons";
import useLogout from "hooks/logout";
import React from "react";
import { useRecoilValue } from "recoil";
import { userIsLoggedIn } from "store/atoms/userAtom";
import { NavLinks, Spacer } from "../../atoms";
import { NavLinkProps } from "../../atoms/NavLinks/NavLinks";
import { NavCon } from "./style";

type SideNavType = {
  navs: readonly NavLinkProps[];
  title: string;
  signout?: boolean;
  disable?: boolean;
};

export const SideNav = ({ navs, title, signout, disable }: SideNavType) => {
  const [checked, setChecked] = React.useState(true);
  const isLogged = useRecoilValue(userIsLoggedIn);
  const logout = useLogout();

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const _logout = async () => {
    await logout();
  };

  return (
    <NavCon>
      <Collapse
        orientation="horizontal"
        in={checked}
        timeout="auto"
        collapsedSize={62}
        sx={{
          "& .MuiCollapse-wrapper": { minWidth: "100%" },
          "& .MuiCollapse-wrapperInner": { minWidth: "100%" },
        }}
      >
        <Box sx={{ minWidth: "max-content" }}>
          <div className="menu">
            {!disable && (
              <>
                <HamBurger onClick={handleChange} />
                <Spacer width="1.3rem" />
              </>
            )}
            <h2>{title}</h2>
          </div>
          {navs.map((nav, i) => (
            <ListItem key={i} disablePadding>
              <NavLinks {...nav} minimize={false} />
            </ListItem>
          ))}
          {signout && isLogged && (
            <ListItem disablePadding>
              <button className="logout" onClick={_logout}>
                Log Out
              </button>
            </ListItem>
          )}
        </Box>
      </Collapse>
    </NavCon>
  );
};
