import { Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { BackArrow } from 'assets/Icons';
import { Button, Divider, Spacer } from 'components/atoms';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { buyNowPayLaterStepsAtom } from 'store/atoms/buyNowPayLaterAtom';
import { checkoutDetailsAtom, loanSettingsAtom, loanTenuresAtom } from 'store/atoms/checkoutAtom';
import { formatCurrency } from 'utils/currencyFormater';
import { PaymentModal } from '../../PaymentModal';
import { LoanTenureType, calcPercentage, useLoanRequest } from './hooks';
import { LoanAgreement, LoanSummaryCon, LoanSummaryDetailsCon, LoanTenure, StyledFormLabel } from './style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const LoanSummary = () => {
  const [paymentOpen, setPaymentOpen] = React.useState(false);
  const [isLoanPolicyAccepted, setIsLoanPolicyAccepted] = useState(false);
  const setBuyNowPayLaterStep = useSetRecoilState(buyNowPayLaterStepsAtom);

  const {
    isPending,
    selectedLoanTenure,
    setSelectedLoanTenure,
    performLoanRequest,
    calculatedPartPayment,
    calculatedLoanInsurance,
    calculatedLoanAmountWithInterest
  } = useLoanRequest();
  const [checkoutDetails] = useRecoilState(checkoutDetailsAtom);
  const loanTenures = useRecoilValue(loanTenuresAtom);
  const loanSettings = useRecoilValue(loanSettingsAtom);

  // totalProductAmount === grandTotal
  const handleLoanTenure = (e: SelectChangeEvent<string | null>) => {
    const loanTenure = loanTenures.find((currentTenure: LoanTenureType) => currentTenure.tenure === e.target.value);
    setSelectedLoanTenure(loanTenure);
  };

  const initiatePayment = () => {
    if (selectedLoanTenure === null) {
      enqueueSnackbar('Please select a loan tenure', { variant: 'warning' });
      return;
    }
    setPaymentOpen(true);
  };

  return (
    <LoanSummaryCon>
      <div className="summary-content">
        <button className="icon-wrap" onClick={() => setBuyNowPayLaterStep(0)}>
          <BackArrow />
        </button>

        <h3>Buy Now Pay Later (BNPL)</h3>

        <Spacer height="49px" />

        <LoanSummaryDetailsCon>
          <p className="title">BNPL Loan Details</p>

          <Divider />

          <p className="fees">Fees </p>

          <div className="item">
            <p>Loan Amount</p>
            <p>{formatCurrency(checkoutDetails.grandTotal ?? 0)}</p>
          </div>

          <div className="item">
            <p>Loan Insurance</p>
            <p>{formatCurrency(calculatedLoanInsurance)}</p>
          </div>

          <LoanTenure>
            <p className="title">Loan Tenure</p>

            <FormControl sx={{ m: 1, width: 400 }} className="dropdown-container">
              <InputLabel id="demo-multiple-name-label">Select loan tenure</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={selectedLoanTenure ? selectedLoanTenure.tenure : ''}
                onChange={e => handleLoanTenure(e)}
                input={<OutlinedInput label="Select loan tenure" />}
                MenuProps={MenuProps}>
                {loanTenures.map((loanTenure: LoanTenureType, index: number) => (
                  <MenuItem key={loanTenure.tenure + ' ' + index} value={loanTenure.tenure}>
                    {loanTenure.tenure}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </LoanTenure>

          <LoanAgreement>
            <p className="title">BNPL Loan Agreement</p>
            <Divider />
            <p>
              For you to be granted the BNPL loan, you agree to pay {loanSettings.part_payment_percentage * 100}% (
              {formatCurrency(
                calcPercentage((loanSettings.part_payment_percentage as number) * 100, calculatedLoanAmountWithInterest)
              )}
              ) of the purchase price up front and the remaining {loanSettings.balance_percentage * 100}% (
              {formatCurrency(
                calcPercentage(Number(loanSettings.balance_percentage) * 100, calculatedLoanAmountWithInterest)
              )}
              ) over your selected loan tenure
            </p>
          </LoanAgreement>

          <div className="item">
            <p>Interest Rate</p>
            <p>{selectedLoanTenure?.percent}%</p>
          </div>

          <StyledFormLabel
            onChange={() => setIsLoanPolicyAccepted(prev => !prev)}
            value={isLoanPolicyAccepted}
            control={<Checkbox />}
            label="I agree with the BNPL loan Agreement"
            checked={isLoanPolicyAccepted}
          />
          <Button
            isLoading={isPending}
            onClick={initiatePayment}
            disabled={isLoanPolicyAccepted === false || isPending}>
            Request BNPL loan for this purchase
          </Button>
        </LoanSummaryDetailsCon>
      </div>
      {paymentOpen && (
        <PaymentModal
          onSuccess={performLoanRequest}
          onClose={() => setPaymentOpen(false)}
          amount={calculatedPartPayment}
          exclude={['bnpl', 'wallet']}
        />
      )}
    </LoanSummaryCon>
  );
};
