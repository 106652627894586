import React from 'react';
import { Modal } from '@mantine/core';
import { FaAngleRight } from 'react-icons/fa';
import { useSetRecoilState } from 'recoil';
import { checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import { CustomDateCalender } from 'pages/Checkout/component/style';
import { Dayjs } from 'dayjs';
import { Button } from 'components/atoms';

interface Props {
  opened: boolean;
  onClose: () => void;
  initiatePayment: () => void;
  initiateBusinessPostPayment: () => void;
}

const BusinessPaymentModal: React.FC<Props> = ({ opened, onClose, initiatePayment, initiateBusinessPostPayment }) => {
  const setCheckoutDetails = useSetRecoilState(checkoutDetailsAtom);
  const [paymentDatePickerOpen, setPaymentDatePickerOpen] = React.useState(false);
  const [dateValue, setDateValue] = React.useState<Dayjs>();

  const handleNowPayment = () => {
    setCheckoutDetails(c => ({
      ...c,
      postPurchasePayment: false,
      postPurchasePaymentDate: undefined
    }));
    initiatePayment();
  };

  const handlePostPayment = () => {
    setCheckoutDetails(c => ({
      ...c,
      postPurchasePayment: true,
      postPurchasePaymentDate: dateValue?.toISOString()
    }));
    setPaymentDatePickerOpen(false);
    onClose();
    initiateBusinessPostPayment();
  };

  return (
    <>
      <Modal classNames={{ content: 'p-8 rounded-xl' }} size="lg" centered opened={opened} onClose={onClose}>
        <div className="w-full flex gap-10 flex-col items-center justify-center">
          <h2 className="text-2xl font-bold">When would you like to Pay</h2>
          <div className="w-full flex flex-col gap-4">
            <button
              className="flex w-full py-3 font-bold hover:bg-black/5 rounded-t-md px-1 transition-all text-lg items-center justify-between border-b"
              onClick={handleNowPayment}>
              <span>Pay Now</span>
              <FaAngleRight />
            </button>
            <button
              className="flex w-full py-3 font-bold hover:bg-black/5 rounded-t-md px-1 transition-all text-lg items-center justify-between border-b"
              onClick={() => setPaymentDatePickerOpen(true)}>
              <span>Pay Later</span>
              <FaAngleRight />
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        classNames={{ content: 'p-8 rounded-xl' }}
        size="lg"
        centered
        opened={paymentDatePickerOpen}
        onClose={() => setPaymentDatePickerOpen(false)}>
        <div>
          <div>
            <h3 className="text-2xl font-bold">Select Payment Date</h3>
            <p className="font-light text-black/60">You have to pay within the next 14 days.</p>
          </div>
          <div className="mt-8">
            <CustomDateCalender value={dateValue} onChange={v => setDateValue(v)} />
          </div>
          <Button onClick={handlePostPayment} type="button" className="mt-12">
            Confirm Date
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default BusinessPaymentModal;
