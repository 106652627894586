import { CoinIcon, DiscountIcon, Profile2UserIcon, StarIcon2 } from 'assets/Icons';
import QUERY_KEYS from 'network/config/queryKeys';
import { getReferralCount, getReferralRewards } from 'network/services/referals';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

const RefferalsInfo = () => {
  const { data: referralData } = useQuery({ queryFn: getReferralRewards, queryKey: [QUERY_KEYS.REFFERAL_REWARD] });
  const { data: referralTotal } = useQuery({ queryFn: getReferralCount, queryKey: [QUERY_KEYS.REFFERAL_TOTAL] });

  return (
    <div className="p-4 py-6 rounded-md flex md:items-center flex-col md:flex-row w-full justify-between bg-white">
      <div className="flex items-center w-full justify-between">
        <div className="flex gap-3 flex-col">
          <h3>Total Reward Earned</h3>
          <span>
            &#8358; {(referralTotal?.data?.total_reward_earns ?? 0).toFixed(2)}
          </span>                    
          <Link className="text-primary underline" to="/account/wallet">
            View wallet balance
          </Link>
        </div>
        <StarIcon2 />
      </div>
      <div className="h-px w-full md:min-h-[160px] md:w-px my-6 md:my-0 md:mx-6 bg-black/10" />
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center gap-2">
            <CoinIcon />
            <span>Cashback</span>
          </div>
          <span className="font-semibold text-lg">&#8358; {(referralData?.data.total_cashback ?? 0).toFixed(2)}</span>
        </div>
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center gap-2">
            <Profile2UserIcon />
            <span>Referrals</span>
          </div>
          <span className="font-semibold text-lg">{referralTotal?.data.total_referrals}</span>
        </div>
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center gap-2">
            <DiscountIcon />
            <span>Discount</span>
          </div>
          <span className="font-semibold text-lg">{referralData?.data.total_discount ?? 0}%</span>
        </div>
      </div>
    </div>
  );
};

export default RefferalsInfo;
