import { ChangeEvent, useState } from 'react';
import { Modal } from '..';
import { useMutation } from '../../../network/mutations/useMutationHook';
import { submitReview } from '../../../network/services/review';
import { Button } from '../../atoms';
import { CenterModalContentStyle, CenterModalStyle } from '../Modal/style';
import FeedBackStars from './FeedBackStars';
import { RateModalCon } from './styles';

type Iprop = {
  id: number;
  setId: React.Dispatch<React.SetStateAction<number>>;
};

export const RateModal = ({ setId, id }: Iprop) => {
  const [stars, setStars] = useState([0, 0, 0, 0, 0]);
  const [formData, setFormData] = useState({ product_id: 0, comment: '', rating: 0 });

  const setModalClose = () => {
    setId(0);
  };

  const onDataChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    const rating = stars.reduce((a, b) => a + b);
    setFormData(formData => ({ ...formData, comment: value, rating, product_id: id }));
  };

  const { mutateAsync, isLoading } = useMutation(submitReview, 'CREATE_REVIEW');

  const _send = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const { data } = await mutateAsync(formData);
      if (data.status === 'success') {
        setModalClose();
      }
    } catch (error: any) {}
  };

  const disibled = formData.product_id === 0 || !formData.comment || formData.rating === 0;

  return (
    <Modal
      onModalClose={setModalClose}
      extraModalContentStyle={{
        ...CenterModalContentStyle,
        height: 'max-content',
        minHeight: '616px',
        maxWidth: '527px'
      }}
      extraModalStyle={CenterModalStyle}>
      <RateModalCon>
        <div className="header">
          {/* <img src={item.url} alt="rate product" /> */}
          <div className="header-content">
            <h3>Rate your experience</h3>
            <p>How satisfied are you with the service?</p>
            <div className="star-con">
              <FeedBackStars stars={stars} setStars={setStars} />
            </div>
          </div>
        </div>
        <label htmlFor="feddback">Any additional feedback</label>
        <textarea onChange={onDataChange} name="comment" id="feddback"></textarea>
        <Button disabled={disibled} isLoading={isLoading} onClick={_send}>
          Submit Feedback
        </Button>
      </RateModalCon>
    </Modal>
  );
};
