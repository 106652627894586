import featureImg1 from './images/home-images/features/img-1.png';
import featureImg2 from './images/home-images/features/img-2.png';
import featureImg3 from './images/home-images/features/img-3.png';
import featureImg4 from './images/home-images/features/img-4.png';
import featureImg5 from './images/home-images/features/img-5.png';
import featureImg6 from './images/home-images/features/img-6.png';
import heroBg from './images/home-images/hero-bg.png';
import BgImg1 from './images/home-images/img-1.png';
import BestPriceImg from './images/home-images/img-3.png';
import ReviewUser from './images/home-images/img-4.png';
import MobileShop from './images/home-images/img-5.png';
import BgImg2 from './images/home-images/img-6.png';
import MobileheroBg from './images/home-images/mobile-hero-bg.png';
import MobileBgImg1 from './images/home-images/mobile-img-1.png';
import MobileBgImg2 from './images/home-images/mobile-img-2.png';
import MobileBestPriceImg from './images/home-images/mobile-img-3.png';
import medplusVendor from './images/vendors/medplus.png';
import pepVendor from './images/vendors/pep.png';
import shopriteVendor from './images/vendors/shoprite.png';

export const Png = {
  BgImg1,
  MobileBgImg1,
  MobileBgImg2,
  MobileBestPriceImg,
  BgImg2,
  ReviewUser,
  MobileShop,
  MobileheroBg,
  BestPriceImg,
  featureImg1,
  featureImg2,
  featureImg3,
  featureImg4,
  featureImg5,
  heroBg,
  featureImg6,
  shopriteVendor,
  pepVendor,
  medplusVendor
};
