import { Theme, useMediaQuery } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { Spacer } from '../../components/atoms';
import { Footer } from '../../components/molecules';
import { AboutUs, FrequentlyAskedQuestions, LandingPage, PrivacyPolicy, TermsAndConditions } from '../../pages';
import { ABOUT_US, FREQUENTLY_ASKED_QUESTIONS, HOME, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../routes';

const HomeRoutes = () => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <>
      <Routes>
        <Route path={HOME} element={<LandingPage />} />
        <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
        <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={FREQUENTLY_ASKED_QUESTIONS} element={<FrequentlyAskedQuestions />} />
        <Route path={ABOUT_US} element={<AboutUs />} />
      </Routes>

      <Spacer height={md ? 20 : 80} />

      <Footer />
    </>
  );
};

export default HomeRoutes;
