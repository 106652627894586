import { enqueueSnackbar } from 'notistack';

const MESSAGE: Record<string, { success: string; error: string }> = {
  LOGIN: {
    success: 'Login successful',
    error: 'Failed, Try Again.'
  },
  LOGOUT: {
    success: 'Logout successful',
    error: ''
  },
  SIGNUP: {
    success: 'Account created',
    error: 'Failed, Try Again.'
  },
  BUSINESS_SIGNUP: {
    success: 'Business Account Created',
    error: 'Failed, Try Again.'
  },
  OTP: {
    success: 'Email is verified',
    error: 'OTP Not Found or Expired'
  },
  UPDATE_PROFILE: {
    success: 'Profile updated',
    error: 'Failed, Try Again.'
  },
  CHANGE_PASSWORD: {
    success: 'Password changed successfully',
    error: 'Failed, Try Again.'
  },
  NEW_ADDRESS: {
    success: 'Address added',
    error: 'Failed, Try Again.'
  },
  CREATE_REVIEW: {
    success: 'Review submitted successfully',
    error: 'Failed, Try Again.'
  },
  UPDATE_ADDRESS: {
    success: 'Address updated',
    error: 'Failed, Try Again.'
  },
  PLACE_ORDER: {
    success: 'Order Placed',
    error: 'Failed to place order, Try Again.'
  },
  CANCEL_ORDER: {
    success: 'Order Cancelled',
    error: 'Failed, Try Again.'
  },
  ADD_ITEM_TO_CART: {
    success: 'Item added to cart',
    error: 'Failed to add item to cart, Try Again.'
  },
  REMOVE_ITEM_FROM_CART: {
    success: 'Item removed from cart',
    error: 'Failed to remove item from cart, Try Again.'
  }
};

export type MessageProp = keyof typeof MESSAGE;

export const apiResponse = (data: Record<string, any> | null, type: 'success' | 'error', operation?: MessageProp) => {
  const result = {
    success: data?.data?.description || data?.description,
    error: data?.response?.data?.description || data?.response?.data?.data?.description
  };

  const message = result[type] || (operation && MESSAGE[operation][type]);
  // const message = operation && MESSAGE[operation][data?.data?.status === 'failed' ? 'error' : type];

  if (!message) return;

  enqueueSnackbar(message, {
    variant: data?.data?.status === 'failed' ? 'error' : type,
    autoHideDuration: type === 'error' ? 2500 : 2000
  });
};
