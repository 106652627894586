import { LoanData } from 'TYPES/api.body';
import { RightArrowIcon } from 'assets/Icons';
import QUERY_KEYS from 'network/config/queryKeys';
import { getAllLoans } from 'network/services/loanManagement';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from 'utils/currencyFormater';
import { formatDate } from 'utils/dateFormater';
import {
  BNPLCon,
  ContentCon,
  IconBtn,
  RowStatus,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableRow,
  TableTab,
  TableTabs
} from './styles';

const tabs = [
  { label: 'Requests', value: 'requests' },
  { label: 'Approved', value: 'approved' },
  { label: 'Paid', value: 'paid' }
];

export const BuyNowPayLater = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState('requests');
  const [loanDisplayData, setLoanDisplayData] = useState<LoanData[]>([]);
  const { data, isLoading } = useQuery({
    queryFn: getAllLoans,
    queryKey: [QUERY_KEYS.ALL_LOANS],
    onSuccess: data => {
      setLoanDisplayData(data.data ?? []);
    }
  });

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);

    if (newValue === 'approved' || newValue === 'paid') {
      const filteredLoans = (data?.data ?? []).filter(loan => loan.status === newValue);
      return setLoanDisplayData(filteredLoans);
    }

    setLoanDisplayData(data?.data ?? []);
  };

  const handleDetails = (id: number, data: LoanData) => {
    navigate(`/account/bnpl/${id}`, { state: data });
  };

  return (
    <BNPLCon>
      <div className="header">BNPL Loans</div>

      <ContentCon>
        <TableTabs spacing={0} direction="row">
          {tabs.map(tab => (
            <TableTab
              key={tab.label}
              className={tabValue === tab.value ? 'active' : ''}
              onClick={() => handleTabChange(tab.value)}>
              {tab.label}
            </TableTab>
          ))}
        </TableTabs>

        <Table>
          <TableHead>
            <TableRow>
              <TableData>BNPL Amount</TableData>
              <TableData>Payback Amount</TableData>
              <TableData>Date of Request</TableData>
              <TableData>Payback Date</TableData>              
              <TableData>Status</TableData>
              <TableData></TableData>
            </TableRow>
          </TableHead>
          <TableBody>
            {loanDisplayData.length < 1 ? (
              <TableRow>
                <TableData colSpan={6} className="empty-state">
                  {isLoading
                    ? 'Loading...'
                    : `You don't have any ${tabValue === 'approved' ? 'approved' : tabValue === 'paid' ? 'paid' : ''
                    } loans at the moment.`}
                </TableData>
              </TableRow>
            ) : (
              loanDisplayData.map(loan => {
                return (
                  <TableRow key={loan.id}>
                    <TableData>{formatCurrency(loan?.loanAmount)}</TableData>
                    <TableData>{formatCurrency(loan?.totalAmountWithInterest)}</TableData>
                    <TableData>{formatDate(loan?.dateRequested)}</TableData>
                    <TableData>{formatDate(loan?.payBackDate)}</TableData>                    
                    <TableData>
                      <RowStatus className={`status-${loan?.status.toLowerCase()}`}>
                        {/* <div className={`bullet bullet-${loan?.status.toLowerCase()}`}></div> */}
                        <p className={`py-1 px-2 rounded-md ${loan.status === 'CANCELLED' ? 'bg-red-200 text-red-600' : 'bg-yellow-200 text-700'}`}>{loan.status}</p>
                      </RowStatus>
                    </TableData>
                    <TableData>
                      <IconBtn onClick={() => handleDetails(loan?.id, loan)}>
                        <RightArrowIcon className="icon" />
                      </IconBtn>
                    </TableData>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </ContentCon>
    </BNPLCon>
  );
};
