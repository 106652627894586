import { IRepayLoan, LoanResponse, REPAY_LOANBNPL } from 'TYPES/api.body';
import {
  CANCEL_LOAN,
  GET_ALL_LOANS,
  GET_LOAN_HISTORY,
  GET_LOAN_SETTINGS,
  GET_LOAN_TENURES,
  REPAYLOAN,
  REPAY_LOAN,
  RESUBMIT_BNPL
} from 'network/config/endpoints';
import api from 'network/config/setAuthHeader';

// Individual Loan Management
export const getAllLoans = async (): Promise<LoanResponse> => {
  const { data } = await api.get(GET_ALL_LOANS);
  return data;
};

export const getLoanHistory = async ({ queryKey }: any) => {
  const [, id] = queryKey;
  const { data } = await api.get(`${GET_LOAN_HISTORY}${id}`);
  return data?.data[0];
};
export const repayLoan = async (formData: IRepayLoan) => {
  const { data } = await api.post(REPAY_LOAN, formData);
  return data;
};
export const resubmitBNPL = async(bnplId: number) => {
  const {data} = await api.post(`${RESUBMIT_BNPL}${bnplId}/resubmit`);
  return data;
}

export const getLoanTenures = async () => {
  const { data } = await api.get(GET_LOAN_TENURES);
  return data;
};
export const getLoanSettings = async () => {
  const { data } = await api.get(GET_LOAN_SETTINGS);
  return data;
};
export const repayloanDebt = async(formdata: REPAY_LOANBNPL) => {
  const {data} = await api.post(REPAYLOAN);
  return data;
}
export const CancelLoanRequest = async(loanId: number) => {
  const {data} = await api.post(`${CANCEL_LOAN}${loanId}/cancel-request`);
  return data;
}