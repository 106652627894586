import { CircularProgress, TextField } from "@mui/material";
import { CheckedIcon } from "assets/Icons";
import { Divider, EmptyCon, OrderButton, Spacer } from "components/atoms";
import { Modal } from "components/molecules";
import SEARCH_PARAMS from "constants/searchParams";
import moment from "moment";
import QUERY_KEYS from "network/config/queryKeys";
import { cancelOrder, getOrderTracking } from "network/services/order";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { HeaderCON } from "../styles";
import { TimeLine, TrackHistoryCon } from "./styles";

const PICKUP_ORDER_STATUSES = [
  "Order Placed",
  "Order Confirmed",
  "Order Processed",
  "Order Out",
  "Order Delivered",
] as const;

const DELIVERY_ORDER_STATUSES = [
  "Order Placed",
  "Order Confirmed",
  "Order Processed",
  "Order Out for Delivery",
  "Order Delivered",
];

const ORDER_RESPONSE_STATES = [
  "cancelled",
  "completed",
  "ongoing",
  "rejected",
  "approved",
  "order_processed",
] as const;

export const TrackHistory = () => {
  const { type, id } = useParams();
  const [searchParams] = useSearchParams();
  const [reason, setReason] = React.useState("");

  const isPickup = searchParams.get(SEARCH_PARAMS.DELIVERY_METHOD) === "pickup";

  const [isDecline, setDecline] = useState<boolean>(false);

  const { data, isFetchedAfterMount } = useQuery({
    queryKey: [QUERY_KEYS.ORDER_TRACKING, id],
    queryFn: () => getOrderTracking(id ?? ""),
  });

  const { mutateAsync: cancel } = useMutation({
    mutationFn: cancelOrder,
    onSuccess: () => {
      enqueueSnackbar(`Order ${id} cancelled successfully.`, {
        variant: "success",
      });
      setDecline(false);
    },
    onError: () => {
      enqueueSnackbar(`Failed to cancel order #${id}`, { variant: "error" });
      setDecline(false);
    },
  });

  const handleCancel = () => {
    cancel({ id: Number(id), reason: reason });
  };

  const orderStatusses =
    data?.flatMap((item) =>
      item.orderTracking.map((inner) => ({
        status: inner.orderStatus,
        statusDate: inner.statusDate,
      })),
    ) ?? [];
  const possibleOrderStatuses = isPickup
    ? PICKUP_ORDER_STATUSES
    : DELIVERY_ORDER_STATUSES;

  const pendingStates = (possibleOrderStatuses as string[]).filter(
    (item) => !orderStatusses.map((item) => item.status).includes(item),
  );

  const step = type === "delivered" ? 5 : (2 as 5 | 4 | 3 | 2 | 1);
  return (
    <>
      <HeaderCON>Delivery updates</HeaderCON>

      {!isFetchedAfterMount && (
        <EmptyCon>
          <CircularProgress size={30} />
        </EmptyCon>
      )}
      {isFetchedAfterMount && (
        <TrackHistoryCon>
          <div className="cancel">
            Order ID: {data && data[0].orderTracking[0].orderId}
            {step < 4 &&
              !ORDER_RESPONSE_STATES.includes(type ?? ("" as any)) && (
                <OrderButton
                  onClick={() => setDecline(true)}
                  outlined
                  color="#F04438"
                >
                  Cancel Order
                </OrderButton>
              )}
            {step === 4 && <p className="delivered">OUT FOR DELIVERY</p>}
            {step === 5 && <p className="out-for-delivery">DELIVERED</p>}
          </div>
          <Divider />
          <Spacer height={32} />
          <>
            {orderStatusses.map((item) => (
              <ul className="flex flex-col gap-4">
                <TimeLine key={item.status} done={true}>
                  <CheckedIcon />
                  <Spacer width={18} />
                  <div>
                    <div className="label">{item.status}</div>
                    <div className="time">
                      {moment(item.statusDate).format("dddd Do MMMM, h:mma")}
                    </div>
                  </div>
                </TimeLine>
              </ul>
            ))}
            {pendingStates.map((item) => (
              <ul className="flex flex-col mt-4">
                <TimeLine key={item} done={false}>
                  <CheckedIcon />
                  <Spacer width={18} />
                  <div>
                    <div className="label">{item}</div>
                  </div>
                </TimeLine>
              </ul>
            ))}
          </>
          <Spacer height={76} />

          {isDecline && (
            <Modal maxWidth="400px" onModalClose={() => setDecline(false)}>
              <div className="flex items-center w-full flex-col gap-5">
                <p>Are you sure you want to cancel your order?</p>
                <TextField
                  rows={5}
                  multiline
                  fullWidth
                  value={reason}
                  onChange={(e) => setReason(e.currentTarget.value)}
                  placeholder="Why do you want to cancel your order"
                />
                <div className="flex items-center gap-2 ">
                  <OrderButton
                    onClick={() => setDecline(false)}
                    outlined
                    color="#808080"
                  >
                    No Go Back!
                  </OrderButton>
                  <OrderButton
                    className="disabled:opacity-30 disabled:cursor-not-allowed disabled:hover:opacity-30"
                    onClick={handleCancel}
                    outlined
                    color="#F04438"
                    disabled={reason.length < 3}
                  >
                    Yes Cancel!
                  </OrderButton>
                </div>
              </div>
            </Modal>
          )}
        </TrackHistoryCon>
      )}
    </>
  );
};
