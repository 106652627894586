import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  PickerChangeHandlerContext,
  UseDateFieldProps
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import React from 'react';
import { formatDate } from 'utils/dateFormater';
import { IoCalendarOutline } from 'react-icons/io5';

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
  BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
}
const ButtonField = ({ label, title, InputProps: { ref } = {}, ...rest }: ButtonFieldProps) => {
  return (
    <button
      className="bg-white group flex px-4 pl-6 flex-wrap items-center justify-between w-full"
      onClick={() => rest.setOpen?.(curr => !curr)}
      ref={ref}>
      {title && <span className="font-normal transition-all">{title}</span>}
      <div className="flex items-center gap-4">
        <span className="font-normal group-hover:text-primary">{label ? label : 'Pick a date'}</span>
        <IoCalendarOutline size={20} className="group-hover:text-primary" />
      </div>
    </button>
  );
};

interface ButtonDatePickerProps extends Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> {
  title?: string;
}

const ButtonDatePicker = ({ title, ...props }: ButtonDatePickerProps) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<Dayjs | null>(props.defaultValue ?? null);

  const handleChange = (val: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    setValue(val);
    if (props.onChange) {
      props.onChange(val, context);
    }
  };
  return (
    <DatePicker
      value={value}
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{
        field: { setOpen, title } as any,
        mobilePaper: {
          sx: { background: 'white' }
        },
        desktopPaper: {
          sx: { background: 'white' }
        },
        textField: {
          fullWidth: true,
          variant: 'standard'
        }
      }}
      {...props}
      label={value !== null ? formatDate(value.toDate().toISOString()) : null}
      onChange={handleChange}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
};

export default ButtonDatePicker;
