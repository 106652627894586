import { IFetchBVN, IInitiateBVNLookup, InitiateBNVLookupResponse, IVerifyBVN } from 'TYPES/api.body';
import {
  FETCH_BVN,
  INITIAITE_BVN,
  UPDATE_BUSINESS_KYB,
  UPLOAD_BUSINESS_GOVERNMENT_ID,
  UPLOAD_BUSINESS_UTILITY_BILL,
  VERIFY_BVN
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const initiateBVNLookup = async (formData: IInitiateBVNLookup): Promise<InitiateBNVLookupResponse> => {
  const { data } = await api.post(`${INITIAITE_BVN}${formData.bvn}`);
  return data;
};

export const verifyBVN = async (formData: IVerifyBVN) => {
  return await api.post(VERIFY_BVN, formData);
};

export const fetchBVN = async (formData: IFetchBVN) => {
  return await api.post(FETCH_BVN, formData);
};

export const updateBusinessKYB = async (formData: FormData) => {
  return await api.put(UPDATE_BUSINESS_KYB, formData);
};
export const updateBusinessUtilityBill = async (formData: FormData) => {
  return await api.post(UPLOAD_BUSINESS_UTILITY_BILL, formData);
};
export const updateBusinessGovernmentID = async (formData: FormData) => {
  return await api.post(UPLOAD_BUSINESS_GOVERNMENT_ID, formData);
};
