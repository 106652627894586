import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const FeaturesCon = styled('div')`
  display: inline-flex;
  padding: 76px 20px;
  background: #fff;
  gap: 48px;
  @media (max-width: ${breakpoints.md}px) {
    display: block;
    gap: 10px;
    padding: 76px 11px;
  }
`;

export const LeftCon = styled('div')<{ bg: any }>`
  padding: 30px 20px;
  border-radius: 10px;
  width: 578px;
  flex: 1.2;
  background: ${COLORS.MainBlue};
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  color: white;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    height: 410px;
  }
  .title {
    font-family: Outfit;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    padding-top: 12px;
    line-height: 72px;
    @media (max-width: ${breakpoints.sm}px) {
      padding: 14px 0px;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .desc {
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    padding-top: 12px;
    font-weight: 400;
    line-height: 38px;
    @media (max-width: ${breakpoints.sm}px) {
      line-height: 24px;
      color: #e6e6e6;
    }
  }
  .sign-up {
    border-radius: 12px;
    background: #fff;
    height: 68px;
    width: fit-content;
    padding: 15px 40px;
    color: #2f4dc4;
    text-align: center;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    @media (max-width: ${breakpoints.sm}px) {
      padding: 15px 56px;
    }
  }
`;

export const Tag = styled('div')<{ color: string }>`
  display: flex;
  height: 36px;
  border-radius: 20px;
  width: fit-content !important;
  background: ${props => props.color};
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  p {
    color: ${COLORS.White};
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 12px;
    }
  }
`;

export const RightCon = styled('div')`
  background: transparent;
  width: 100%;
  flex: 2;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  .react-multi-carousel-track {
    gap: 32px !important;

    @media (max-width: ${breakpoints.md}px) {
      gap: 5px !important;
    }
  }
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const RightItem = styled('div')<{ bg: any; position: string; color: string }>`
  background: ${props => props.color};
  width: 100%;
  border-radius: '16px';
  background-image: url(${props => props.bg});
  background-position: ${props => props.position};
  display: flex;
  flex-direction: column;
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
  padding: 23px 23px;
  border-radius: 10px;
  @media (max-width: ${breakpoints.md}px) {
    height: 300px;
    width: 350px;
    position: right;
  }

  .title {
    color: #333;
    font-family: Outfit;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    @media (max-width: ${breakpoints.md}px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (min-width: ${breakpoints.lg}px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .desc {
    color: #757575;

    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    @media (max-width: ${breakpoints.md}px) {
      font-size: 14px;
      line-height: 24px;
    }

    @media (max-width: ${breakpoints.lg}px) {
      font-size: 15px;
      line-height: 24px;
    }
  }
`;
