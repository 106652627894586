import { Box, Collapse, ListItem, Skeleton } from "@mui/material";
import useLogout from "hooks/logout";
import React from "react";
import { To } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { HamBurger } from "../../../assets/Icons";
import { userIsLoggedIn } from "../../../store/atoms/userAtom";
import { FrontNavLinks, Spacer } from "../../atoms";
import { NavCon, SkeletonCon } from "./style";

type FrontSideNavType = {
  navs: {
    to: To;
    id?: number;
    name: string;
    logo?: any;
    Icon?: any;
    minimize?: boolean;
  }[];
  isLoading?: boolean;
  title: string;
  signout?: boolean;
  disable?: boolean;
};

export const FrontSideNav = ({
  navs,
  isLoading,
  title,
  signout,
  disable,
}: FrontSideNavType) => {
  const [checked, setChecked] = React.useState(true);
  const isLogged = useRecoilValue(userIsLoggedIn);
  const logout = useLogout();

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const _logout = async () => {
    logout();
  };

  return (
    <NavCon>
      <Collapse
        orientation="horizontal"
        in={checked}
        timeout="auto"
        collapsedSize={62}
        sx={{
          "& .MuiCollapse-wrapper": { minWidth: "100%" },
          "& .MuiCollapse-wrapperInner": { minWidth: "100%" },
        }}
      >
        <Box sx={{ minWidth: "max-content" }}>
          <div className="menu">
            {!disable && (
              <>
                <HamBurger onClick={handleChange} />
                <Spacer width="1.3rem" />
              </>
            )}
            <h2>{title}</h2>
          </div>
          {!isLoading
            ? navs != null &&
            navs.length > 0 &&
            navs.map(({ to, name, logo }, i) => (
              <ListItem key={i} disablePadding>
                <FrontNavLinks
                  img={logo}
                  label={name}
                  to={to}
                  minimize={false}
                />
              </ListItem>
            ))
            : Array(10)
              .fill(0)
              .map((_, i) => (
                <SkeletonCon key={i}>
                  <Skeleton width="280px" height="60px" />
                </SkeletonCon>
              ))}
          {signout && isLogged && (
            <ListItem disablePadding>
              <div className="logout" onClick={_logout}>
                Logout
              </div>
            </ListItem>
          )}
        </Box>
      </Collapse>
    </NavCon>
  );
};
