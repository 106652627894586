import { CircularProgress } from '@mui/material';
import { COMMONTYPES } from 'TYPES/event.types';
import { Button, EmptyCon } from 'components/atoms';
import { AddressCard, EditAddressModal } from 'components/molecules';
import { getAllAddress } from 'network/services/address';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { contactState } from 'store/atoms/contactAtom';
import { DeliveryCON } from './styles';

export const DeliveryAddress = () => {
  const [contact, setcontactInfo] = useRecoilState(contactState);
  const { data, isFetchedAfterMount, refetch } = useQuery('addresses', getAllAddress);
  const addresses = data?.data ? data.data : [];

  const onAddNew = (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();
    setcontactInfo(oldCred => ({ ...oldCred, operation: 'add', isEditAddressModal: true }));
  };

  return (
    <DeliveryCON>
      <div className="header">Delivery Address</div>
      <div className="address-map">
        {isFetchedAfterMount && addresses.map(address => <AddressCard key={address.id} address={address} />)}
      </div>
      {isFetchedAfterMount && !addresses.length && <EmptyCon>No addresses yet.</EmptyCon>}
      {!isFetchedAfterMount && (
        <EmptyCon>
          <CircularProgress size={30} />
        </EmptyCon>
      )}
      <div className="button">
        <Button onClick={onAddNew}>Add New Address</Button>
      </div>
      {contact.isEditAddressModal && <EditAddressModal refetch={refetch}/>}
    </DeliveryCON>
  );
};
