import { ResponseSuccess, Vendor } from "../../TYPES/api.body";
import { GET_ALL_VENDORS, GET_VENDOR_BY_LOCATION } from "../config/endpoints";
import api from "../config/setAuthHeader";

const state = "State";
export const getAllVendors = async (
  location?: string,
): Promise<ResponseSuccess<Vendor[]>> => {
  if (location !== undefined) {
    const { data } = await api.get(
      `${GET_VENDOR_BY_LOCATION}?location=${location} ${state}`,
    );
    return data;
  }
  const data = await api.get(`${GET_ALL_VENDORS}`);
  return data.data;
};
