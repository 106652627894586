import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'components/molecules';
import { getCustomer } from 'network/services/profile';
import { Button, InputField, Spacer } from 'components/atoms';
import { BusinessKYBCon, ButtonExtraStyles, InputCon, ModalContentCon } from './styles';
import { useQuery } from 'react-query';
import QUERY_KEYS from 'network/config/queryKeys';

export const BusinessKYB = () => {
  const [seeUtilityBill, setSeeUtility] = useState(false);
  const [utilityBill, setUtilityBill] = useState('');
  const utilityBillRef = useRef<HTMLInputElement>(null);
  const cacRef = useRef<HTMLInputElement>(null);
  const ninRef = useRef<HTMLInputElement>(null);
  const bvnRef = useRef<HTMLInputElement>(null);
  const [seeIssuedId, setSeeIssuedId] = useState(false);
  const [issuedId, setIssuedId] = useState('');
  useQuery({
    queryFn: getCustomer,
    queryKey: QUERY_KEYS.CUSTOMER_PROFILE,
    onSuccess: data => {
      cacRef.current!.value = data.data.cac ?? '';
      ninRef.current!.value = data.data.nin ?? '';
      bvnRef.current!.value = data.data.bvn ?? '';
    }
  });

  const fetchDetails = React.useCallback(async () => {
    try {
      const { data } = await getCustomer();
      const profileData = data;

      if (profileData) {
        const { cac, utility_bill, government_id, nin, bvn } = profileData;
        setUtilityBill(utility_bill ?? '');
        setIssuedId(government_id ?? '');
        utilityBillRef.current!.value = utilityBill || '';
        cacRef.current!.value = cac || '';
        ninRef.current!.value = nin || '';
        bvnRef.current!.value = bvn || '';
      }
    } catch (error) {
      enqueueSnackbar(`Something went wrong: ${(error as any).message}`, {
        variant: 'error'
      });
      return null;
    }
  }, [utilityBill]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  return (
    <BusinessKYBCon>
      <div className="header">KYB Documents</div>
      <div className="kyb-item">
        <span>Utility Bill</span>
        <InputCon>
          <InputField inputRef={utilityBillRef} name="utility_bill" disabled={true} />
          <Button onClick={() => setSeeUtility(true)} extraStyles={ButtonExtraStyles}>
            View
          </Button>
        </InputCon>
      </div>
      <Spacer height="1.2rem" />

      <div className="kyb-item">
        <span>Government Issued ID</span>
        <InputCon>
          <InputField disabled name="government_id" />
          <Button onClick={() => setSeeIssuedId(true)} extraStyles={ButtonExtraStyles}>
            View
          </Button>
        </InputCon>
      </div>
      <Spacer height="1.2rem" />

      <div className="kyb-item">
        <span>CAC</span>
        <InputCon>
          <InputField inputRef={cacRef} name="cac" />
        </InputCon>
      </div>
      <Spacer height="1.2rem" />

      <div className="kyb-item">
        <span>BVN</span>
        <InputCon>
          <InputField inputRef={bvnRef} name="bvn" />
        </InputCon>
      </div>

      <Spacer height="1.2rem" />

      <div className="kyb-item">
        <span>NIN</span>
        <InputCon>
          <InputField inputRef={ninRef} name="nin" />
        </InputCon>
      </div>

      <Spacer height={'4rem'} />

      <div className="button-con">
        <Button>Save Changes</Button>
      </div>

      {seeUtilityBill && (
        <Modal maxWidth="500px" onModalClose={() => setSeeUtility(false)}>
          <ModalContentCon>
            <p>Utility Bill</p>
            <Spacer height="3rem" />
            <div className="image-con">
              <img src={utilityBill} alt="Utility Bill" />
            </div>
            <Spacer height="3rem" />

            <Button>Change Document</Button>
          </ModalContentCon>
        </Modal>
      )}

      {seeIssuedId && (
        <Modal maxWidth="500px" onModalClose={() => setSeeIssuedId(false)}>
          <ModalContentCon>
            <p>Government Issued ID</p>
            <Spacer height="3rem" />
            <div className="image-con">
              <img src={issuedId} alt="Government Issued Id" />
            </div>
            <Spacer height="3rem" />

            <Button>Change Document</Button>
          </ModalContentCon>
        </Modal>
      )}
    </BusinessKYBCon>
  );
};
