import { Spacer } from '../../../components/atoms';
import { LegalDocsContainer } from './styles';

export const PrivacyPolicy = () => {
  return (
    <LegalDocsContainer>
      <h1>Privacy Policy</h1>
      <Spacer height={20} />
      <div className="space-columns-4">
        <div>
          <h3>Madina Lifestyle Supermarket: Privacy Policy</h3>
          <p>
            Welcome to Madina Lifestyle Supermarket's Privacy Policy. This document outlines the information we collect,
            how we use it, and the measures we take to secure it. We prioritize the confidentiality and security of your
            data, and it's crucial that you carefully read and understand this Privacy Policy before accessing or using
            the Madina Lifestyle Supermarket website and its features and services.
          </p>
        </div>

        <div>
          <h3>Information We Collect</h3>
          <p>
            When you register for an account on the Madina Lifestyle Supermarket website, we may collect personal
            identifiable information, including but not limited to your name, age, gender, phone number, email address,
            postal address, and debit/credit card details. Some information may be retained on our website for future
            use, while others are only visible during each transaction.
          </p>
        </div>

        <div>
          <h3>How We Use Information We Collect</h3>
          <p>
            Madina Lifestyle Supermarket collects and uses your personal information to provide the services advertised
            on our website. This information serves various purposes, such as notifying you about order status,
            resolving complaints/problems, assessing user interests, communicating promotions/offers, customizing your
            shopping experience, minimizing fraud, and other relevant reasons associated with your use of Madina
            Lifestyle Supermarket, its services, and its website. You can request to stop receiving certain information
            at any time, granted that it doesn't affect our ability to fulfil your orders effectively. To enhance our
            service quality, we collect and analyze data on user activity. Periodically, we may request your
            participation in surveys to assess our service level, customize your website experience, and gather insights
            on how to improve our offerings.
          </p>
        </div>

        <div>
          <h3>Security of Information We Collect</h3>
          <p>
            Madina Lifestyle Supermarket makes every effort to secure your personal information from unauthorized
            access, use, or disclosure. Confidential information, such as debit/credit card details, is handled securely
            and protected through Secure Sockets Layer (SSL) encryption.
          </p>
        </div>

        <div>
          <h3>Cookies</h3>
          <p>
            Cookies are pieces of data sent from a website to a user's computer while browsing. They save information
            inputted by a user on web pages, facilitating the website's recall of details like postal address and phone
            number on future visits. Cookies enhance user experience by eliminating the need to re-enter information.
            Users can accept or decline cookies; most browsers automatically accept them, but preferences can be updated
            in your browser settings. Opting to decline cookies may restrict users from enjoying the full breadth of
            Madina Lifestyle Supermarket's website and its associated services and features. By using Madina Lifestyle
            Supermarket's website, you agree to the terms outlined in this Privacy Policy.
          </p>
        </div>
      </div>
    </LegalDocsContainer>
  );
};
