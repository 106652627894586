import { Modal } from '@mantine/core';
import { CircularProgress } from '@mui/material';
import { InfoIcon } from 'assets/Icons';
import { Button, InputField, Spacer } from 'components/atoms';
import { fetchBVN } from 'network/services/verifyIdentity';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import bvnVerificationAtom from 'store/atoms/bvnVerificationAtom';
import { DEFAULT_BVN_LOOKUP, bvnLookupAtom } from 'store/atoms/verifyIdentityAtom';
import { validators } from 'utils/validator';
import { useResendOTP } from './hooks';
import { DetailsModalCon, InputCon } from './styles';

const INITIALSTATE = {
  bvn: ''
};

const truncate = (val: string) => {
  if (val.length < 11) {
    return '';
  }
  return `${val.slice(0, 4)}****${val.slice(val.length - 5, val.length - 1)}`;
};

export const VerifyBVNModal = () => {
  const [timeLeft, setTimeLeft] = React.useState(120);
  const { resend, resending } = useResendOTP(() => setTimeLeft(120));
  const [bvnLookup, setBVNLookup] = useRecoilState(bvnLookupAtom);
  const [formData, setFormData] = useState({ bvn: bvnLookup.bvn, otp: '' });
  const [formError, setFormError] = useState(INITIALSTATE);
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: fetchBVN,
    onSuccess: () => {
      enqueueSnackbar({
        message: 'BVN verification success',
        variant: 'success'
      });
      setBVNState(undefined);
      setBVNLookup(DEFAULT_BVN_LOOKUP);
    },
    onError: err => {
      enqueueSnackbar({ message: 'BVN verification failed', variant: 'error' });
      console.log(err);
    }
  });
  const [bvnVerification, setBVNState] = useRecoilState(bvnVerificationAtom);

  const handleUpdateFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);

    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError(formError => ({ ...formError, [name]: error }));
  };

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    mutateAsync({ session_id: bvnLookup.session_id, otp: formData.otp });
  };

  React.useEffect(() => {
    setTimeLeft(120);
  }, []);

  React.useEffect(() => {
    if (timeLeft === 0) {
      return;
    }

    const intervalId = setInterval(() => setTimeLeft(c => c - 1), 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <Modal
      size={500}
      centered
      radius="lg"
      opened={bvnVerification?.step === 'verify'}
      onClose={() => setBVNState(undefined)}>
      <DetailsModalCon className="p-5">
        <div className="header">
          <h3>Connect BVN</h3>
          <Spacer height="15px" />
        </div>

        <div>
          <InputCon>
            <span>BVN</span>
            <InputField
              name="bvn"
              placeholder="Enter your BVN"
              onChange={handleUpdateFormData}
              value={formData.bvn}
              required={true}
              error={formError.bvn}
            />
          </InputCon>

          <InputCon>
            <span>Verification Code</span>
            <InputField
              name="otp"
              placeholder="Enter code"
              onChange={handleUpdateFormData}
              value={formData.otp}
              required={true}
            />
          </InputCon>

          <div className="mt-1 flex items-center justify-between">
            <div className="flex items-start gap-1">
              <span className="text-sm text-black/70">expires in:</span>
              <span className="text-sm text-primary">
                {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}
              </span>
            </div>
            <div>
              <button
                className="text-sm w-full disabled:opacity-60 disabled:pointer-events-none bg-primary/10 hover:bg-primary/40 transition-all px-2 rounded text-primary"
                onClick={resend}
                type="button"
                disabled={resending || timeLeft !== 0}>
                {resending ? <CircularProgress size={10} color="primary" /> : 'Resend OTP'}
              </button>
            </div>
          </div>
          <Spacer height="20px" />

          <div className="info">
            <InfoIcon className="icon" />
            <p className="text">Please enter the OTP sent to {truncate(bvnLookup?.phone ?? '')} before it expires</p>
          </div>

          <Spacer height="30px" />

          <Button type="submit" isLoading={isLoading} onClick={handleSave} disabled={isLoading}>
            Verify BVN
          </Button>
          <Spacer height="20px" />
        </div>
      </DetailsModalCon>
    </Modal>
  );
};
