import { styled } from '@mui/material';

export const TopVendorSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  gap: '12px',
  alignItems: 'center',
  margin: '22px',
  marginBottom: '4rem',
  width: '100%',
  maxWidth: '100%',
  '.content': {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    width: '100%'
  },
  '.top': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    span: {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'baseline'
    }
  },
  [theme.breakpoints.up('md')]: {
    alignItems: 'start',
    gap: '28px',
    padding: '0 5rem',
    '.content': {
      gap: '24px'
    }
  }
}));

export const VendorCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  borderRadius: '30px',
  '.img': {
    width: '100%',
    height: '100%',
    borderRadius: '30px',
    overflow: 'hidden',
    display: 'flex',
    maxWidth: '300px',
    maxHeight: '200px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.icon': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    '.vendor-img': {
      height: '100%',
      maxHeight: '230px',
      objectFit: 'cover'
    }
  }
});
