import { GET_REFERRALS_TOTAL, GET_REFERRAL_LINKS, GET_REFERRAL_REWARDS } from 'network/config/endpoints';
import api from 'network/config/setAuthHeader';
import { ReferralCodeResponse, ReferralCountResponse, RefferralRewardsResponse } from 'TYPES/api.body';

export const getReferralLink = async (): Promise<ReferralCodeResponse> => {
  const { data } = await api.get(GET_REFERRAL_LINKS);
  return data;
};

export const getReferralRewards = async (): Promise<RefferralRewardsResponse> => {
  const { data } = await api.get(GET_REFERRAL_REWARDS);
  return data;
};

export const getReferralCount = async (): Promise<ReferralCountResponse> => {
  const { data } = await api.get(GET_REFERRALS_TOTAL);
  return data;
};
