import { Theme, useMediaQuery } from '@mui/material';
import { OrderDetailsResponse } from 'TYPES/api.body';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/dateFormater';
import { OrderCardCon } from './styles';

interface Props {
  item: OrderDetailsResponse;
  productimg?: string;
}

export const OrderCard: React.FC<Props> = ({ item, productimg }) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));  


  return (
    <OrderCardCon>
      <div className="left">
        <img
          className="h-full object-cover"
          src={
            Array.isArray(item?.carts) ? item?.carts[0].product?.productImage1 : '/graphics/madina.svg'
          }
          alt="order product"
        />
        <div className="text">
          <div className="text-upper">
            <div className="title">{(item.carts && item.carts.length > 0) ? item?.carts[0].product?.productName : 'Empty Order'}</div>
            {item.orderRef && <div className="id">Order ID: {item?.orderRef}</div>}
          </div>
          <div className="delivered">{formatDate(item?.orderDate)}</div>
        </div>
      </div>
      <Link className="right" to={`${item.status}/${item.id}`}>
        {!sm && 'View'} details
      </Link>
    </OrderCardCon>
  );
};
