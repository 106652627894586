import React from 'react';
import { IoClose } from 'react-icons/io5';
import { FaArrowUp } from 'react-icons/fa6';
import { MadinaIcon } from 'assets/Icons';
import { ThreeDots } from 'react-loader-spinner';
import ChatBubble from '../../atoms/a-chat-bubble';
import EmptyChat from '../../atoms/a-empty-chat';
import { MessageResponse } from './types';

interface Props {
  userId: string;
  loading?: boolean;
  disabled?: boolean;
  onClose: () => void;
  userLoading?: boolean;
  action?: React.ReactNode;
  loadingMessgage?: string;
  receiverLoading?: boolean;
  messages: MessageResponse[];
  onSend: (message: string) => void;
}

const ChatView: React.FC<Props> = ({
  action,
  userId,
  onSend,
  loading,
  onClose,
  disabled,
  messages,
  userLoading,
  receiverLoading,
  loadingMessgage
}) => {
  const [message, setMessage] = React.useState('');

  const chatRef = React.useRef<HTMLUListElement>(null);
  const handleSendMessage = () => {
    onSend(message);
    setMessage('');
  };
  const handleInputChange: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };
  React.useEffect(() => {
    if (chatRef.current) {
      console.log('Scrolling');
      chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
    }
  }, [messages]);

  return (
    <div className="bg-white scroll-smooth px-7 pb-4 py-2 max-h-[95vh] rounded-md relative w-full">
      <button
        onClick={onClose}
        type="button"
        className="absolute rounded-full hover:bg-black/5 transition-all p-1 top-5 right-6">
        <IoClose size={24} />
      </button>
      <div className="flex items-center gap-4 mt-10">
        <MadinaIcon />
        <div className="flex flex-col w-full items-start gap-1">
          <div>
            <h2 className="font-bold text-2xl">Madina</h2>
            <p>Support</p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div id="chat-view" className="scroll-smooth mt-6 h-screen px-2 overflow-y-auto max-h-[50vh]">
            {messages.length === 0 && (
              <EmptyChat>
                <p>How can we help?</p>
                <p>Send a message to get started</p>
              </EmptyChat>
            )}
            <ul ref={chatRef} className="h-full overflow-y-auto flex scroll-smooth flex-col gap-3">
              {messages.map((message, id) => (
                <ChatBubble
                  timestamp={message.timestamp}
                  key={id}
                  kind={message.senderId === userId ? 'sender' : 'receiver'}>
                  {message.content}
                </ChatBubble>
              ))}
              {userLoading && (
                <ChatBubble kind="sender">
                  <ThreeDots color="white" width={40} height={20} />
                </ChatBubble>
              )}
              {receiverLoading && (
                <ChatBubble kind="receiver">
                  <ThreeDots color="gray" width={40} height={20} />
                </ChatBubble>
              )}
              <div id="chat-end" />
            </ul>
          </div>
          {action}
          <div className="flex w-full items-center gap-2.5">
            <div className="bg-black/10 pl-4 rounded-full p-0.5 px-0 focus-within:border-primary border w-full flex items-center">
              <input
                value={message}
                className="p-2 outline-none bg-transparent rounded-2xl w-full"
                disabled={disabled}
                placeholder={
                  loading
                    ? loadingMessgage ?? 'Loading...'
                    : !disabled
                      ? 'Send a message'
                      : 'Login to begin conversation'
                }
                onKeyDown={handleInputChange}
                onChange={e => setMessage(e.currentTarget.value)}
              />
              <button
                onClick={handleSendMessage}
                disabled={disabled}
                className="mr-2 text-white cursor-pointer bg-[#2F4DC4] p-2 rounded-full"
                type="button">
                <FaArrowUp size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatView;
