import React from 'react';
import { ChatConversationIcon } from '../../../assets/Icons';

interface Props {
  children?: React.ReactNode;
}

const EmptyChat: React.FC<Props> = p => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <ChatConversationIcon />
      <div className="text-black/50 flex flex-col items-center mt-3">{p.children}</div>
    </div>
  );
};

export default EmptyChat;
