import { styled } from '@mui/material';
import { breakpoints } from '../../../theme/theme';
import AboutUsHeaderBgImage from '../../../assets/images/madina-desktop-grab.png';

export const LegalDocsContainer = styled('div')`
  padding: 80px;
  margin: 80px;
  border-radius: 0.375rem;
  background-color: #ffffff;

  p {
    font-size: 18px;
    text-align: justify;
  }

  h1 {
    font-family: Outfit;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
  }

  .space-columns-4 > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media (max-width: ${breakpoints.md}px) {
    padding: 40px;
    margin: 40px;

    h1 {
      font-size: 28px;
    }

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const FAQsContainer = styled('div')`
  padding: 80px;
  margin: 80px;

  > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  .space-columns-4 > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  h1 {
    font-family: Outfit;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
  }
  @media (max-width: ${breakpoints.md}px) {
    padding: 20px;
    margin: 20px;

    h1 {
      font-size: 22px;
      text-align: center;
    }
  }
`;

export const FAQsHeader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  @media (max-width: ${breakpoints.md}px) {
    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const FAQsGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.5rem;

  @media (max-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const FAQsCard = styled('div')`
  padding: 2rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
  }

  p {
    font-size: 17px;
    text-align: justify;
  }

  @media (max-width: ${breakpoints.md}px) {
    padding: 1.5rem;

    h3 {
      font-size: 17px;
    }

    p {
      font-size: 15px;
    }
  }
`;

export const AboutUsContainer = styled('div')`
  padding: 80px;
  margin: 80px;

  > *:not(:last-child) {
    margin-bottom: 3rem;
  }

  h1 {
    font-family: Outfit;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
  }

  @media (max-width: ${breakpoints.md}px) {
    padding: 20px;
    margin: 20px;

    h1 {
      font-size: 22px;
      text-align: center;
    }
  }
`;

export const AboutUsHeader = styled('div')`
  background-image: url(${AboutUsHeaderBgImage});
  background-size: cover; // to ensure the image covers the entire div
  background-position: center; // to center the image

  padding: 80px;
  width: 100%;
  height: 452px;
  border-radius: 1rem;

  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
  }

  p {
    font-size: 20px;
    text-align: center;
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}px) {
    padding: 30px;
    height: auto;
    p {
      width: 100%;
      font-size: 14px;
    }

    h3 {
      font-size: 23px;
    }
  }
`;

export const AboutUsMission = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
  }

  p {
    font-size: 18px;
    text-align: center;
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}px) {
    p {
      width: 100%;
      font-size: 16px;
    }

    h3 {
      font-size: 23px;
    }
  }
`;

export const AboutUsDetails = styled('div')`
  background-color: #ffffff;
  padding: 60px;
  border-radius: 0.375rem;

  h3 {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
  }

  @media (max-width: ${breakpoints.md}px) {
    padding: 40px;

    h3 {
      font-size: 23px;
    }
  }
`;

export const AboutUsDetailsGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 4.5rem;

  @media (max-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2.5rem;
  }
`;

export const AboutUsDetailsInfoItem = styled('div')`
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */

  h4 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
  }

  p {
    font-size: 17px;
    text-align: left;
  }

  @media (max-width: ${breakpoints.md}px) {
    h4 {
      font-size: 18px;
    }

    p {
      font-size: 15px;
      text-align: justify;
    }
  }
`;
