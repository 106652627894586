import { OrderDetailsResponse } from 'TYPES/api.body';
import React from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { COLORS } from '../../../theme/color';
import { OrderButton, Spacer } from '../../atoms';
import { RateCON } from './styles';

type Iprop = {
  item: OrderDetailsResponse;
  setId: React.Dispatch<React.SetStateAction<number>>;
};
export const RateBox = ({ item, setId }: Iprop) => {
  const handleClick = (e: COMMONTYPES['buttonClick']) => {
    e.stopPropagation();
    e.preventDefault();
    setId(parseInt(e.currentTarget.value));
  };
  console.log(item);
  return (
    <div className='mt-2'>
      {item.carts && item.carts.length > 0 &&
        item.carts.map(({ product, ...rest }) => (
          <RateCON>
            <div className="rate-product">
              <img
                className="w-full object-cover"
                src={product?.productImage1 ?? '/graphics/madina.svg'}
                alt="product img"
              />
              <Spacer width={20} />
              <div>
                <div className="title">{product?.productName}</div>
                <div className="id">Order ID {item.orderRef}</div>
                <div className="status">{item.status}</div>
              </div>
            </div>
            <OrderButton value={product.id} onClick={handleClick} outlined color={COLORS.MainBlue}>
              Rate Product
            </OrderButton>
          </RateCON>
        ))}
    </div>
  );
};
