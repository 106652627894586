import { atom } from 'recoil';
import { BusinessRegisterRequest } from 'TYPES/api.body';
import { STORE_KEYS } from '../keys';

export const businessRegDetails = atom<{ request: BusinessRegisterRequest; step: number }>({
  key: STORE_KEYS.BUSINESS_KYB,
  default: {
    request: {
      bvn: '',
      nin: '',
      email: '',
      cac: '',
      location: '',
      password: '',
      phoneNumber: '',
      businessName: '',
      businessAddress: ''
    },
    step: 1
  }
});
