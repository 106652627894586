import { Theme, useMediaQuery } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import Refferals from 'pages/Refer';
import { useRecoilValue } from 'recoil';
import { Spacer } from 'components/atoms';
import { SideNav } from 'components/molecules';
import DeleteAccount from 'pages/DeleteAccount';
import { userRoleAtom } from 'store/atoms/userAtom';
import MobileAccount from 'pages/Account/MobileAccount';
import Subscriptions from 'pages/Account/Subscriptions';
import { BuyNowPayLater } from 'pages/Account/BNPL/BNPL';
import { VerifyIdentity } from 'pages/Account/VerifyIdentity/VerifyIdentity';
import { BuyNowPayLaterDetails } from 'pages/Account/BNPLDetails/BNLPDetails';
import {
  BusinessKYB,
  DeliveryAddress,
  Favourite,
  Inbox,
  OrderDetails,
  PendingPayments,
  Profile,
  Reviews,
  Support,
  TrackHistory,
  UserOrders,
  UserWallet
} from 'pages';
import {
  ADDRESS,
  BNPL,
  BUSINESS_KYB,
  DELETE_ACCOUNT,
  FAVOURITE,
  INBOX,
  ORDERS,
  PENDING_PAYMENTS,
  PROFILE,
  REFER,
  REVIEW,
  SUBSCRIPTIONS,
  SUPPORT,
  VERIFY_IDENTITY,
  WALLET
} from '../routes';
import { AccountLeft, Container, MainGridCon, Right } from '../styles';
import { BUSINESS_NAVS, NAVIGATION_LINKS } from './navs';

const AccountRoutes = () => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const userRole = useRecoilValue(userRoleAtom);

  return (
    <Container>
      <MainGridCon>
        {!md && (
          <AccountLeft>
            <SideNav
              title="Account"
              navs={userRole === 'BUSINESS' ? BUSINESS_NAVS : NAVIGATION_LINKS}
              signout
              disable
            />
          </AccountLeft>
        )}
        <Right>
          <Routes>
            <Route index element={md ? <MobileAccount /> : <Navigate to={PROFILE} replace />} />
            <Route path={FAVOURITE} element={<Favourite />} />
            <Route path={ADDRESS} element={<DeliveryAddress />} />
            <Route path={PROFILE} element={<Profile />} />
            <Route path={REVIEW} element={<Reviews />} />
            <Route path={INBOX} element={<Inbox />} />
            <Route path={WALLET} element={<UserWallet />} />
            <Route path={SUPPORT} element={<Support />} />
            <Route path={BUSINESS_KYB} element={<BusinessKYB />} />
            <Route path={ORDERS} element={<UserOrders />} />
            <Route path={ORDERS + '/:type/:id'} element={<OrderDetails />} />
            <Route path={ORDERS + '/:type/:id/track'} element={<TrackHistory />} />
            <Route path={BNPL} element={<BuyNowPayLater />} />
            <Route path={BNPL + '/:id'} element={<BuyNowPayLaterDetails />} />
            <Route path={VERIFY_IDENTITY} element={<VerifyIdentity />} />
            <Route path={SUBSCRIPTIONS} element={<Subscriptions />} />
            <Route path={REFER} element={<Refferals />} />
            <Route path={PENDING_PAYMENTS} element={<PendingPayments />} />
            <Route path={DELETE_ACCOUNT} element={<DeleteAccount />} />
          </Routes>
        </Right>
      </MainGridCon>
      <Spacer height={100} />
    </Container>
  );
};

export default AccountRoutes;
