export function replaceItemAtIndex(arr: any, index: number, newValue: any) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export function removeItemAtIndex<T>(arr: T[], index: number): T[] {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export const addToCartHelper = (cartItems: any[], productToAdd: Record<string, any>, quantity: number) => {
  const existingCartItem = cartItems.find(cartItem => cartItem.name === productToAdd.name);

  if (existingCartItem) {
    return cartItems.map(cartItem =>
      cartItem.name === productToAdd.name ? { ...cartItem, quantity: cartItem.quantity + quantity } : cartItem
    );
  }

  return [...cartItems, { ...productToAdd, quantity: quantity }];
};

export const addToAddressHelper = (items: any[], productToAdd: Record<string, any>) => {
  const existingCartItem = items.find(cartItem => cartItem.id === productToAdd.id);

  if (existingCartItem) {
    return items.map(cartItem => (cartItem.id === productToAdd.id ? productToAdd : cartItem));
  }

  return [...items, productToAdd];
};
