import React from "react";
import { StarIcon } from "../../../assets/Icons";

interface StarProps {
  stars: number;
}

export const GoldStars: React.FunctionComponent<StarProps> = ({ stars }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {Array.from(
        { length: stars },
        (_, i) => <StarIcon fill="#F79009" key={i} />,
      )}
      {Array.from(
        { length: 5 - stars },
        (_, i) => <StarIcon fill="#D3D3D3" key={i} />,
      )}
    </div>
  );
};
