import { walletHistoryResponse } from 'TYPES/api.body';
import { Debitwallet, Wallethistory } from 'assets/Icons';
import { Dispatch, SetStateAction } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
interface props {
  data: walletHistoryResponse[];
  setvalue: Dispatch<SetStateAction<number>>;
  value: number;
  totalpages: number;
}
export const WalletHistoryMessage: React.FC<props> = ({ data, setvalue, value, totalpages }) => {
  const queryClient = useQueryClient();

  const goto = useNavigate();
  console.log(value);

  return (
    <div className="list-con">
      {data.map((mes, index) => (
        <div key={index} className="flex w-full md:flex-row flex-col justify-between mb-4">
          <div className="flex items-center gap-2">
            {mes.transactionType === 'Debit' ? <Wallethistory /> : <Debitwallet />}
            <div className="flex flex-col">
              <span className="text-md font-bold">&#8358;{mes.balance}</span>
              <span className="">{mes.description}</span>
            </div>
          </div>
          <span className="text-sm hidden md:block">{mes.transactionDate}</span>
        </div>
      ))}
      <div className="w-full flex justify-between">
        <button
          onClick={() => {
            setvalue(value - 1);
            goto(`/account/wallet?page=${Number(value) - 1}`);
            queryClient.invalidateQueries('wallet_histories');
          }}
          data-hidden={value === 0}
          className={
            'data-[hidden=true]:opacity-0 data-[hidden=true]:pointer-events-none underline text-blue-800 cursor-pointer'
          }>
          Previous
        </button>
        <button
          onClick={() => {
            setvalue(value + 1);
          }}
          data-hidden={Number(value) === totalpages - 1}
          className={`data-[hidden=true]:opacity-0 data-[hidden=true]:pointer-events-none underline text-blue-800 cursor-pointer`}>
          Next
        </button>
      </div>
    </div>
  );
};
