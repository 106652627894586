import { CheckoutProductCon } from './style';
import { useRecoilState } from 'recoil';
import { DeleteCartProduct } from 'assets/Icons';
import { formatCurrency } from 'utils/currencyFormater';
import { CartItem, cartListAtom } from 'store/atoms/cartAtom';
import { removeItemAtIndex } from 'utils/arrayHelpers';
import { performRemoveProductFromCart } from 'components/molecules/Goods/utils';
import { useMutation, useQueryClient } from 'react-query';
import { removeProductFromCart } from 'network/mutations/products';

type CheckoutProductProp = {
  item: CartItem & { index: number };
};

export const CheckoutProduct = ({ item: { amount, photo, name, quantity, index } }: CheckoutProductProp) => {
  const queryClient = useQueryClient();
  const { mutateAsync: removeFn, isLoading: deleting } = useMutation({ mutationFn: removeProductFromCart });
  const [cartList, setCartList] = useRecoilState(cartListAtom);

  const deleteItem = () => {
    performRemoveProductFromCart(cartList, index, removeFn, setCartList, queryClient);
    setCartList(oldCartList => removeItemAtIndex(oldCartList, index));
  };
  return (
    <div>
      <CheckoutProductCon>
        <div className="imageCon">
          <img src={photo !== undefined ? photo : '/graphics/madina.svg'} alt="productImage" />
        </div>
        <div className="productDetailsCon">
          <div className="top flex items-start justify-between">
            <div className="desc">
              <p>{name}</p>
              <p>QTY: {quantity}</p>
            </div>
            <button disabled={deleting} onClick={deleteItem} className="pt-2">
              <DeleteCartProduct />
            </button>
          </div>
          <div className="price">{formatCurrency(amount)}</div>
        </div>
      </CheckoutProductCon>
    </div>
  );
};
