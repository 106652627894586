import React from 'react';
import { Drawer } from '@mui/material';
import { DrawerHeader } from './styles';
import { CancelIcon } from 'assets/Icons';
import { Logo, Spacer } from '../../atoms';
import MobileAccount from 'pages/Account/MobileAccount';

type SideBarProp = {
  side: boolean;
  setSide: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SideBar = ({ side, setSide }: SideBarProp) => {
  const toggleDrawer = (_: any) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setSide(!side);
  };

  return (
    <Drawer
      anchor="left"
      open={side}
      onClose={toggleDrawer(false)}
      sx={{ '.MuiDrawer-paper': { backgroundColor: '#fff' } }}>
      <DrawerHeader>
        <Logo />
        <Spacer width={50} />
        <CancelIcon onClick={() => setSide(false)} />
      </DrawerHeader>
      <Spacer height={30} />

      <div className="p-5 py-10">
        <MobileAccount />
      </div>
    </Drawer>
  );
};
