import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { STORE_KEYS } from '../keys';
const { persistAtom } = recoilPersist();

export type CartItem = {
  id: number;
  name: string;
  photo?: string;
  amount: number;
  cartId?: number;
  quantity: number;
  shortDSC?: string;
  category?: string;
  productImage1?: string;
  quantityAvailable: number;
};

export const isCartOpen = atom<{ onCartOpen: boolean }>({
  key: STORE_KEYS.IS_CART_OPEN,
  default: {
    onCartOpen: false
  }
});

export const cartListAtom = atom<CartItem[]>({
  key: STORE_KEYS.CART_LIST,
  default: [],
  effects_UNSTABLE: [persistAtom]
});
