import { Flex, Modal, Radio } from '@mantine/core';
import { CircularProgress } from '@mui/material';
import { SubscriptionPackage } from 'TYPES/api.body';
import ActiveSubscription from 'components/molecules/m-active-subscription';
import { parseInt } from 'lodash';
import QUERY_KEYS from 'network/config/queryKeys';
import { cancelSubscription, getSubscriptionHistory, getSubscriptionPackages } from 'network/services/subscriptions';
import { enqueueSnackbar } from 'notistack';
import { PaymentModal } from 'pages/Checkout/component';
import React from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useMutation, useQuery } from 'react-query';
import SubscriptionCard from '../../../components/atoms/a-subscription-card';
import { SubAction, usePaystackSubscribe, useUpdateSubscription } from './hooks';

const Subscriptions = () => {
  const [subAction, setSubAction] = React.useState<SubAction | undefined>(undefined);
  const [sub, setSub] = React.useState<SubscriptionPackage>();
  const [upgradeSub, setUpgradeSub] = React.useState<SubscriptionPackage>();
  const { onSuccess, onClose } = usePaystackSubscribe(setSub, sub);
  const { onSuccess: onUpgradeSuccess, onClose: onUpgradeClose } = useUpdateSubscription(
    setSubAction,
    subAction,
    upgradeSub
  );
  const { data: subscriptions, isLoading } = useQuery({
    queryFn: getSubscriptionPackages,
    queryKey: QUERY_KEYS.SUBSCRIPTION_PACKAGES
  });

  const { mutateAsync: cancelSub } = useMutation({
    mutationFn: cancelSubscription,
    onSuccess: data => {
      enqueueSnackbar({ message: 'Subscription canceled successfully', variant: 'success' });
      console.log(data);
    },
    onError: err => {
      console.log(err);
      enqueueSnackbar({ message: 'Failed to cancel subscription', variant: 'error' });
    }
  });

  const { data: subsHistory } = useQuery({
    queryFn: getSubscriptionHistory,
    queryKey: [QUERY_KEYS.SUBSCRIPTION_HISTORY]
  });

  const handleUpgrade = () => {
    if (upgradeSub !== undefined) {
      setSub(upgradeSub);
    }
  };

  return (
    <>
      <div className="bg-white p-10 rounded-lg">
        {isLoading ? (
          <div className="w-full justify-center flex p-10 items-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <h1 className="font-semibold text-2xl">Madina Subscriptions</h1>
            <div className="w-full h-px bg-black/10 my-7" />
            {subscriptions?.data === undefined && (
              <div className="w-full flex items-center justify-center p-8 opacity-70">
                <p>{subscriptions?.message}</p>
              </div>
            )}
            {subsHistory?.data && (subsHistory?.data.filter(item => item.status === 'ACTIVE') ?? []).length > 0 ? (
              <div className="flex flex-col gap-8 w-full">
                {subsHistory?.data.map(item => (
                  <ActiveSubscription setSubAction={setSubAction} cancelSub={cancelSub} sub={item} />
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                {(subscriptions?.data ?? []).map(item => (
                  <SubscriptionCard onSelect={setSub} key={item.id} subscription={item} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {sub && (
        <PaymentModal
          exclude={['bnpl', 'wallet']}
          onSuccess={subAction !== undefined ? onUpgradeSuccess : onSuccess}
          onClose={subAction !== undefined ? onUpgradeClose : onClose}
          amount={sub?.amount}
        />
      )}
      <Modal radius="md" opened={subAction !== undefined} onClose={() => setSubAction(undefined)}>
        <div className="px-5 pb-5">
          <div className="flex items-center my-2 mb-8 gap-4">
            <IoArrowBack />
            <h3 className="font-bold text-2xl">Upgrade Plan</h3>
          </div>
          <div>
            <Radio.Group
              value={upgradeSub ? upgradeSub.id.toString() : undefined}
              onChange={id => setUpgradeSub((subscriptions?.data ?? []).filter(item => item.id === parseInt(id))[0])}>
              <Flex direction="column" w="100%" gap="sm">
                {(subscriptions?.data ?? []).map(sub => (
                  <Radio
                    value={sub.id.toString()}
                    width="100%"
                    labelPosition="left"
                    classNames={{ body: 'justify-between bg-primary/10 text-primary font-bold text-lg p-5 rounded-md' }}
                    variant="outline"
                    label={
                      <span>
                        &#8358;{sub.amount} {sub.name}
                      </span>
                    }
                  />
                ))}
                <button className="bg-primary text-white font-bold text-lg p-3 rounded-md mt-6" onClick={handleUpgrade}>
                  Proceed To Payment
                </button>
              </Flex>
            </Radio.Group>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Subscriptions;
