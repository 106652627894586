import { Box, FormControlLabel, styled } from '@mui/material';
import { COLORS } from '../../../../theme/color';
import { maxWidth } from '../../../../theme/theme';

export const DetailsModalCon = styled('div')`
  .header {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }

  .info {
    padding: 12px;
    border: 1px solid #dadada;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 8px;
      height: 40px;
    }

    .text {
      color: #999999;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .confirm-bvn-text {
    color: #999999;
    font-weight: 400;
    text-align: center;
  }

  .buttonsCon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const InputCon = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0.5rem 0;
  }
`;

export const AuthorizeRequestCon = styled('div')`
  .header {
    text-align: center;
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin: 20px 0 19px 0;
    }

    p {
      p {
        font-size: 16px;
        font-size: 400;
        text-align: center;
        line-height: 32px;
      }
    }
  }
`;

export const StyledFormLabel = styled(FormControlLabel)(({ checked }) => ({
  '.MuiFormControlLabel-label': {
    color: '#483D3D'
  },
  '.MuiSvgIcon-root': checked
    ? {
      color: COLORS.MainBlue
    }
    : {
      color: '#6F6F6F'
    }
}));

export const LoanSummaryCon = styled(Box)(({ theme }) => ({
  maxWidth: `${maxWidth}px`,
  padding: '0 1rem',

  '.summary-content': {
    // padding: '10px 20px',
    width: '100%',
    borderRadius: '16px',
    display: 'flex',
    background: '#fff',
    flexDirection: 'column',
    button: {
      fontWeight: '700'
    },

    h3: {
      fontSize: '24px',
      lineHeight: '32px',
      fontFamily: '700',
      textAlign: 'center'
    },

    [theme.breakpoints.up('lg')]: {
      padding: '80px 400px'
    }
  }
}));

export const cancelConfirmBtn = {
  width: '20%',
  margin: '0 20px',
  backgroundColor: 'transparent',
  color: '#A8A8A8',
  fontWeight: '600'
};

export const proceedConfirmBtn = {
  width: '20%',
  margin: '0 20px',
  backgroundColor: 'transparent',
  color: '#2F4DC4',
  fontWeight: '600'
};
