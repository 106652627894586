import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COMMONTYPES } from '../../TYPES/event.types';
import { Button, Spacer } from '../../components/atoms';
import { InputField } from '../../components/atoms/InputField/InputField';
import { resetPassword } from '../../network/services/auth';
import { validators } from '../../utils/validator';
import { AuthContainer, InputCon, extraStylesInput } from './styles';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from 'react-query';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e: COMMONTYPES['inputChange']) => {
    setEmail(e.target.value);
    const error = validators.email(e.target.value) || '';
    setError(error);
  };

  const { mutateAsync, isLoading } = useMutation({ mutationFn: resetPassword });

  const navigate = useNavigate();

  const _requestOtp = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const { data } = await mutateAsync({ email });
      if (!data.description.includes('Not')) {
        navigate(`/otp/forgot-password/${email}`);
        enqueueSnackbar(data.description, { variant: 'success' });
      } else {
        enqueueSnackbar(data.description, { variant: 'error' });
      }
    } catch (error) {
      console.log({ error });
      enqueueSnackbar(`Something went wrong: ${(error as any).message}`, { variant: 'error' });
    }
  };

  const disabled = !email || !!validators.email(email);

  return (
    <AuthContainer>
      <h1>Please Enter your email address</h1>
      <p className="option">an otp verification will be sent to your email</p>
      <form>
        <InputCon>
          <span>Email Address</span>
          <InputField
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder="example@mail.com"
            extraStyles={extraStylesInput}
            required={true}
            error={error}
          />
        </InputCon>
        <Spacer height={'3.5rem'} />
        <div className="buttonContainer">
          <Button
            // extraStyles={bottonStyle}
            isLoading={isLoading}
            type="submit"
            onClick={_requestOtp}
            disabled={disabled}>
            Reset
          </Button>
        </div>
      </form>
    </AuthContainer>
  );
};

export default ForgotPassword;
