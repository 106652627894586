import React from "react";
import { EmptyProductsDiv } from "./styles";

interface Props {
  message: string;
}

const Empty: React.FC<Props> = ({ message }) => {
  return (
    <EmptyProductsDiv>
      <h3>{message}</h3>
    </EmptyProductsDiv>
  );
};

export default Empty;
