import { FormControlLabel, Grid, styled } from '@mui/material';
import { COLORS } from '../../theme/color';
import { maxWidth } from '../../theme/theme';

export const Container = styled('div')`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f8f7f7;
  position: relative;
`;

export const MainGridCon = styled(Grid)`
  width: 100%;
  max-width: ${maxWidth}px;
  margin: auto;
  padding: 0 1rem;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`;

export const CheckoutContainer = styled('div')`
  width: 100%;
  min-height: 400px;
  background: #fff;
  padding: 40px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
  }

  h2 {
    font-weight: 600;
    font-size: 32px;
    margin: 24px 0 8px 0;
  }

  p {
    line-height: 24px;
  }

  p.top {
    margin-bottom: 35px;
  }

  p,
  label {
    color: #483d3d;
    font-size: 14px;
  }

  .sub-head,
  .name,
  .edit {
    font-weight: 700;
    color: ${COLORS.MainBlack};
  }

  .sub-head {
    font-size: 16px;
    margin: 16px 0;
    &:nth-of-type(2),
    &:nth-of-type(4) {
      margin: 0;
    }
  }

  .contact-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .edit {
      height: max-content;
      display: flex;
      align-items: center;
      p {
        margin-left: 10px;
      }
      &:hover,
      &:hover p {
        color: ${COLORS.MainBlue};
      }
      cursor: pointer;
    }
    .name {
      display: flex;
      word-spacing: 3px;
      gap: 10px;
    }
  }
  .note {
    margin-top: 24px;
    p {
      margin-bottom: 8px;
      font-weight: 500;
    }
    textarea {
      width: 100%;
      min-height: 80px;
      outline: none;
      font-size: 14px;
      padding: 16px;
      margin-bottom: 35px;
      border: 1px solid #00000040;
      &:focus {
        outline: none;
      }
    }
  }

  button {
    font-weight: 700;
    word-spacing: 5px;
  }
`;

export const CheckoutItems = styled('div')`
  width: 100%;
  min-height: 300px;
  background: #fff;
  padding: 32px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: ${COLORS.MainBlack};
  }
`;

export const PriceSummary = styled('div')`
  width: 100%;
  background: #fff;
  padding-bottom: 32px;
  div {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${COLORS.MainBlack};
    p:nth-of-type(2) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .subtotal {
    border-top: 0.05rem solid #e8e8e8;
    border-bottom: 0.05rem solid #e8e8e8;
  }
  .delivery {
    border-bottom: 0.05rem solid #e8e8e8;
  }
  .total {
    p:nth-of-type(2) {
      font-weight: 600;
    }
  }
  .wallet {
    p:nth-of-type(2) {
      font-size: 16px;
      line-height: 20px;
      color: ${COLORS.MainBlue};
    }
    .wallet-point {
      display: flex;
      ailgn-items: center;
      cursor: pointer;
      &:hover {
        color: ${COLORS.MainBlue};
      }
    }
    .wallet-text {
      margin-left: 10px;
    }
  }
  .grandtotal {
    border-top: 0.05rem dashed #e8e8e8;
    p {
      font-weight: 600;
    }
  }
`;

export const LogisticsCon = styled('div')`
  padding: 20px 40px;
`;

export const StyledLogisticFormLabel = styled(FormControlLabel)(({ checked }) => ({
  '.MuiFormControlLabel-label': checked
    ? {
      fontWeight: 500
    }
    : {
      color: '#6F6F6F',
      fontSize: '14px'
    },
  '.MuiSvgIcon-root': checked
    ? {
      color: COLORS.MainBlue
    }
    : {
      color: '#6F6F6F'
    }
}));
