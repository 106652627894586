import { useCallback, useEffect } from 'react';
import { CircularProgress, Theme, useMediaQuery } from '@mui/material';
import { PaymentModalCon } from './style';
import { EmptyCon } from 'components/atoms';
import { Modal } from 'components/molecules/Modal/Modal';
import { checkoutWithWallet } from 'network/services/checkout';
import { CenterModalContentStyle, CenterModalStyle } from 'components/molecules/Modal/style';
import { useSetRecoilState } from 'recoil';
import modalAtom from 'store/atoms/modals';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';

type PaymentModalProp = {
  onSucces?: () => void;
  amount: number;
};

export const PayWithMadinaModal = ({ amount, onSucces }: PaymentModalProp) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const setModalState = useSetRecoilState(modalAtom);

  const { mutateAsync, isLoading } = useMutation({ mutationFn: checkoutWithWallet });

  const setModalClose = () => {
    setModalState(c => ({ ...c, payWithMandinaModalOpen: false, paymentModalOpen: false }));
  };

  const withdrawFromWallet = useCallback(async () => {
    try {
      await mutateAsync({ amount });
      onSucces && onSucces();
    } catch (error) {
      if ((error as any)?.response?.data?.status === 'success') {
        onSucces && onSucces();
      } else if ((error as any)?.response?.data?.status === 'failed') {
        enqueueSnackbar((error as any)?.response?.data?.description, { variant: 'error' });
        setModalState(c => ({
          ...c,
          paymentFailureModalOpen: true,
          paymentModalOpen: false,
          payWithMandinaModalOpen: false
        }));
      }
    }
  }, [amount, setModalState, mutateAsync, onSucces]);

  useEffect(() => {
    withdrawFromWallet();
  }, [withdrawFromWallet]);

  return (
    <Modal
      onModalClose={setModalClose}
      extraModalContentStyle={{ ...CenterModalContentStyle, width: sm ? '500px' : '' }}
      extraModalStyle={CenterModalStyle}>
      <PaymentModalCon>
        {isLoading && (
          <EmptyCon>
            <CircularProgress size={30} />
          </EmptyCon>
        )}
      </PaymentModalCon>
    </Modal>
  );
};
