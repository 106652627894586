import { Tab, Tabs, styled } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const CustomTab = styled(Tab)(({ theme }) => ({

  '&.MuiTab-labelIcon': {
    color: '#180101',
    textTransform: 'capitalize',
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '64px',
    height: '64px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    justifyContent: 'start',
    // maxWidth: '300px',
    background: '#ffffff',
    borderRadius: ' 8px',
    fontWeight: 400,
    fontSize: '16px',
    paddingLeft: '6.7%',
    marginBottom: '20px',
    '&.Mui-selected': {
      border: '1px solid #2F4DC4'
    },
    '&.MuiTab-labelIcon': {
      paddingLeft: '6.7%',
      svg: {
        margin: 0
      }
    }
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
    padding:' 0 12px',
    marginRight: '20px',
    '&:last-of-type': {
      marginRight: 0
    },
    '&.MuiTab-labelIcon': {
      minHeight: 'max-content',
      marginBottom: '6px',
    }
  }
}));

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  width: '100%',
  maxWidth: '300px',
  minHeight: 'max-content',
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
    '& .MuiTabs-indicator': {
      display: 'unset'
    }
  }
}));
