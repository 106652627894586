import { Spacer } from '../../../components/atoms';
import { LegalDocsContainer } from './styles';

export const TermsAndConditions = () => {
  return (
    <LegalDocsContainer>
      <h1>Terms & Conditions</h1>
      <Spacer height={20} />
      <div className="space-columns-4">
        <div>
          <h3>Madina Lifestyle Supermarket: Terms & Conditions</h3>
          <p>
            Welcome to Madina Lifestyle Supermarket! These Terms and Conditions govern your use of our services through
            the Madina Lifestyle Supermarket website (the "Website"). Please take the time to carefully read and
            understand these Terms and Conditions, as well as our Privacy Policy, before using our services or accessing
            our website.
          </p>
        </div>

        <div>
          <h3>Acceptance of Terms and Conditions</h3>
          <p>
            Your use of Madina Lifestyle Supermarket's services or website signifies your acceptance and agreement to
            comply with all the terms and conditions outlined herein and in our Privacy Policy. By using our services,
            you confirm that you are either at least eighteen (18) years old or under the supervision of a parent or
            legal guardian who meets the age requirement.
          </p>
        </div>

        <div>
          <h3>Use of the Website</h3>
          <p>
            When using Madina Lifestyle Supermarket's website, you affirm that you are either at least 18 years old or
            under the supervision of a parent or legal guardian who is at least eighteen (18) years old. We grant you a
            non-transferable and revocable license to use the website for the purpose of shopping for items sold on it.
            Any violation of these Terms and Conditions will result in the immediate revocation of the granted license
            without prior notice. Certain services and features may require registration or subscription. By choosing to
            register or subscribe, you agree to provide accurate and current information and update it promptly.
          </p>
        </div>

        <div>
          <h3>User Account</h3>
          <p>
            You are the sole authorized user of any account you create with Madina Lifestyle Supermarket and are
            entirely responsible for all activities under such account. Maintain the confidentiality of your account
            name and password. Notify us immediately of any suspected or actual unauthorized use. By providing your
            contact information, you agree to receive relevant information from Madina Lifestyle Supermarket. You can
            request to discontinue such communication at any time. Inform us promptly of any changes to your
            registration information. Madina Lifestyle Supermarket may suspend or cancel your account for various
            reasons, including a breach of these Terms and Conditions, and shall not be liable for any resulting loss or
            damage.
          </p>
        </div>

        <div>
          <h3>User Submissions</h3>
          <p>
            The website may allow user submissions such as comments, reviews, and photos. These submissions are
            non-confidential and may be used by Madina Lifestyle Supermarket as deemed fit. Users agree not to submit
            inappropriate material, and Madina Lifestyle Supermarket reserves the right to remove any submission at its
            discretion.
          </p>
        </div>

        <div>
          <h3>Website Content and Pricing</h3>
          <p>
            While efforts are made to ensure accuracy, Madina Lifestyle Supermarket is not liable for errors in content.
            Prices of weighed items may be approximated, and any difference will be credited/debited accordingly. Madina
            Lifestyle Supermarket reserves the right to refuse or cancel orders with mispriced or incorrect content.
          </p>
        </div>

        <div>
          <h3>Order Acceptance and Cancellation</h3>
          <p>
            Madina Lifestyle Supermarket has the sole right to accept, process, or cancel orders at its discretion.
            Cancellation will be communicated appropriately.
          </p>
        </div>

        <div>
          <h3>Orders Including Alcoholic Drinks</h3>
          <p>
            Alcoholic drinks can only be purchased by individuals at least eighteen (18) years old, with legitimate
            identification required upon delivery.
          </p>
        </div>

        <div>
          <h3>Third-Party Websites</h3>
          <p>Madina Lifestyle Supermarket is not responsible for third-party websites linked on its platform.</p>
        </div>

        <div>
          <h3>Use of Material</h3>
          <p>
            All website materials are proprietary to Madina Lifestyle Supermarket. Users may use them for personal,
            non-commercial purposes only.
          </p>
        </div>

        <div>
          <h3>Trademarks and Copyrights</h3>
          <p>
            Materials on the website are protected by copyrights and trademarks owned by Madina Lifestyle Supermarket.
            Unauthorized use is prohibited.
          </p>
        </div>

        <div>
          <h3>Territorial Use</h3>
          <p>
            Madina Lifestyle Supermarket does not guarantee suitability of items outside Nigeria. Users are responsible
            for compliance with local laws.
          </p>
        </div>

        <div>
          <h3>Applicable Law</h3>
          <p>These Terms and Conditions are governed by Nigerian law, with disputes decided by Nigerian courts.</p>
        </div>

        <div>
          <h3>Limitation of Liability</h3>
          <p>
            Madina Lifestyle Supermarket is not liable for any damages resulting from the use of its services, with
            total liability not exceeding the amount paid.
          </p>
        </div>

        <div>
          <h3>Indemnity</h3>
          <p>
            Users indemnify Madina Lifestyle Supermarket against any losses, claims, or expenses arising from a breach
            of these Terms and Conditions.
          </p>
        </div>

        <div>
          <h3>Termination</h3>
          <p>Madina Lifestyle Supermarket may suspend or terminate access at its discretion, with immediate effect.</p>
        </div>

        <div>
          <h3>Delivery Windows</h3>
          <p>
            Delivery windows are a guide, and while efforts are made to adhere, no specific time requests are accepted
            beyond 24 hours of the selected window.
          </p>
        </div>

        <div>
          <h3>Communicating During Order Processing</h3>
          <p>
            Customers must respond promptly to communications during order processing. Preferred communication methods
            apply only before dispatch.
          </p>
        </div>

        <div>
          <h3>Access to Your Delivery Location</h3>
          <p>Customers must notify and meet drivers at a more accessible location if delivery access is restricted.</p>
        </div>

        <div>
          <h3>Access Within Your Building</h3>
          <p>Delivery access within buildings is limited by factors like elevators and item weight.</p>
        </div>

        <div>
          <h3>Meeting Our Delivery Driver</h3>
          <p>Drivers wait for 5 minutes; failure to receive the order may incur additional costs.</p>
        </div>

        <div>
          <h3>Cancellations</h3>
          <p>Orders cannot be canceled or refunded once paid, except for incorrect or damaged items.</p>
        </div>

        <div>
          <h3>Returns</h3>
          <p>Returns are only accepted for damaged items reported at the point of delivery.</p>
        </div>

        <div>
          <h3>Refunds</h3>
          <p>Refunds for unavailable items are credited to the Supermart eWallet.</p>
        </div>

        <div>
          <h3>Treatment of Our Employees</h3>
          <p>
            Customers must treat employees with courtesy and respect. Abuse may result in order refusal or cancellation.
          </p>
        </div>
      </div>
      <Spacer height={20} />
      <b>Thank you for choosing Madina Lifestyle Supermarket!</b>
    </LegalDocsContainer>
  );
};
