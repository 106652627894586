import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import QUERY_KEYS from 'network/config/queryKeys';
import { getAllVendors } from 'network/services/vendors';
import AllProducts from 'components/molecules/m-all-products';
import { userLocationAtom } from 'store/atoms/userLocationAtom';
import SupermarketVendors from 'components/molecules/m-supermarket-vendors';

const Supermarket = () => {
  const userLocation = useRecoilValue(userLocationAtom);
  const { data, isLoading } = useQuery([QUERY_KEYS.VENDORS], () => getAllVendors(userLocation?.address.state));

  return (
    <div className="bg-white">
      {(data?.data ?? []).length > 0 ? (
        <SupermarketVendors vendors={data?.data ?? []} isLoading={isLoading} />
      ) : (
        <div className="p-4 px-8">
          <AllProducts />
        </div>
      )}
    </div>
  );
};

export default Supermarket;
