import React from 'react';
import { useRecoilState } from 'recoil';
import { Radio, RadioGroup } from '@mui/material';
import { EditIcon } from 'assets/Icons';
import { Spacer } from 'components/atoms';
import { formatDateTime } from 'utils/dateFormater';
import { checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import { StyledFormLabel } from 'components/atoms/InputField/style';
import { ScheduleModal } from 'pages/Checkout/component';

const LogisticsDetails = () => {
  const [checkoutDetails, setCheckoutDetails] = useRecoilState(checkoutDetailsAtom);
  const [scheduleOpen, setScheduleOpen] = React.useState(false);
  return (
    <>
      {checkoutDetails.deliveryMethod === 'homedelivery' && (
        <>
          <div className="note">
            <p>Note to Logistics</p>
            <textarea
              name="note"
              placeholder="Give instructions"
              value={checkoutDetails.driverNote}
              onChange={e =>
                setCheckoutDetails(prev => ({
                  ...prev,
                  driverNote: e.currentTarget.value
                }))
              }
            />
          </div>
          <p className="sub-head">Delivery Schedule</p>

          <RadioGroup
            row
            value={checkoutDetails.deliverySchedule}
            onChange={e =>
              setCheckoutDetails(curr => ({
                ...curr,
                deliverySchedule: e.currentTarget.value as any
              }))
            }>
            <StyledFormLabel
              value="Immediately"
              control={<Radio />}
              label="Immediately"
              checked={checkoutDetails.deliverySchedule === 'Immediately'}
            />

            <Spacer width={34} />

            <StyledFormLabel
              value="Schedule"
              control={<Radio />}
              label="Schedule"
              checked={checkoutDetails.deliverySchedule === 'Schedule'}
            />
          </RadioGroup>
        </>
      )}

      {checkoutDetails.deliveryMethod === 'homedelivery' && checkoutDetails.deliverySchedule === 'Schedule' && (
        <>
          <p className="sub-head">Details</p>
          <div className="contact-info">
            <p className="name">
              <span>
                {checkoutDetails.scheduleDate
                  ? formatDateTime(checkoutDetails.scheduleDate)
                  : 'Please schedule a delivery date'}
              </span>
            </p>
            <div className="edit" onClick={() => setScheduleOpen(true)}>
              <EditIcon width={16} /> <p>Edit</p>
            </div>
          </div>
        </>
      )}
      <Spacer height={70} />
      {scheduleOpen && <ScheduleModal onOpenChange={setScheduleOpen} />}
    </>
  );
};

export default LogisticsDetails;
