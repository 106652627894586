import { Cart2Icon, CopyIcon, Gift2Icon, UserCrackedIcon } from 'assets/Icons';
import QUERY_KEYS from 'network/config/queryKeys';
import { getReferralLink } from 'network/services/referals';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { createAbsoluteURL } from 'utils/urls';

interface Step {
  step: string;
  description: React.ReactNode;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

const STEPS: readonly Step[] = [
  {
    step: 'Step 1',
    description: 'Copy and share your referral link',
    icon: CopyIcon
  },
  {
    step: 'Step 2',
    description: 'Your friends get 10% off their first order when they complete the sign up',
    icon: UserCrackedIcon
  },
  {
    step: 'Step 3',
    description: (
      <div>
        You get <strong>N500</strong> when they sign up
      </div>
    ),
    icon: Cart2Icon
  }
];

const RefferAndEarn = () => {
  const { data, isLoading } = useQuery({
    queryFn: getReferralLink,
    queryKey: [QUERY_KEYS.REFFERAL_LINK]
  });
  const referralLink = createAbsoluteURL(`/sign-up?referralCode=${data?.message}`);

  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    enqueueSnackbar({ message: 'Refferal link copied', variant: 'info' });
  };

  return (
    <div className="bg-white mt-4 p-4 py-5 rounded-xl">
      <div className="flex items-center gap-2">
        <Gift2Icon />
        <h2 className="font-semibold text-lg md:text-2xl">Refer and Earn</h2>
      </div>
      <div className="flex flex-col gap-6 mt-8">
        {STEPS.map(({ icon: Icon, ...step }) => (
          <div key={step.step} className="flex flex-col">
            <h4 className="font-bold text-lg">{step.step}</h4>
            <div className="flex items-center w-full justify-between">
              <p>{step.description}</p>
              <div className="bg-black/5 w-fit p-2 rounded-full">
                <Icon />
              </div>
            </div>
          </div>
        ))}
        <p className="self-center text-lg text-black/50 text-center">
          A bonus is added to your account once your friends complete their sign up process
        </p>
        <div className="flex flex-col gap-2">
          <h3 className="text-black/60">Share link</h3>
          <div className="flex items-center gap-2">
            <input
              value={isLoading ? 'Loading...' : referralLink}
              className="border opacity-70 w-full p-1.5 rounded"
              disabled
            />
            <button
              onClick={copyReferralLink}
              className="bg-primary whitespace-nowrap text-white hover:bg-primary/80 transition-all p-2 px-6 rounded">
              Copy Link
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefferAndEarn;
