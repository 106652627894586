export const formatDate = (date: string, format: 'full' | 'long' | 'short' = 'short') => {
  const parsedDate = Date.parse(date);

  // Check if the parsedDate is NaN, which indicates an invalid date.
  if (isNaN(parsedDate)) {
    return date; // Return the input date as is.
  }

  return new Intl.DateTimeFormat(undefined, { dateStyle: format }).format(parsedDate);
};

export function convertTimeStringToDate(timeString: string, baseDate?: Date): Date | null {
  const [time, period] = timeString.split(/([a-zA-Z]+)/).filter(Boolean);

  // Check if the time and period are present
  if (time && period) {
    // Parse hours and minutes
    const [hours, minutes] = time.split(':').map(Number);

    // Create a new Date object and set hours and minutes
    const date = baseDate ?? new Date();
    date.setHours(hours, minutes);

    // Adjust for am/pm
    if (period.toLowerCase() === 'pm' && hours < 12) {
      date.setHours(hours + 12);
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      date.setHours(0);
    }

    return date;
  }

  return null; // Return null if the time string is not in the expected format
}

export const formatDateTime = (datetime: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };

  const date = new Date(datetime);

  return date.toLocaleString('en-US', options);
};

