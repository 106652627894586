import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';

export interface UserLocation {
  place_id: string;
  licence?: string;
  osm_type?: string;
  osm_id?: number;
  lat: string;
  lon: string;
  class?: string;
  type?: string;
  place_rank?: number;
  importance?: number;
  addresstype?: string;
  name: string;
  display_name: string;
  address: {
    suburb?: string;
    city: string;
    county?: string;
    state: string;
    ISO3166_2_lvl4?: string;
    postcode: string;
    country: string;
    country_code?: string;
  };
  boundingbox?: string[];
}

export const userLocationAtom = atom<UserLocation | undefined>({
  key: STORE_KEYS.USER_LOCATION,
  default: undefined
});
