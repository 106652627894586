interface TextData {
  text: string;
  linkData?: {
    title: string;
    href: string;
  };
}

export const extractLink = (text: string): TextData => {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/;

  const matches = text.match(regex);

  if (matches) {
    const productName = matches[1];
    const productLink = matches[2];

    const newText = text.replace(regex, '').replace('【67†source】', '');
    return {
      text: newText,
      linkData: {
        title: productName,
        href: productLink
      }
    };
  } else {
    return {
      text
    };
  }
};
