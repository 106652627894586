import { NewAddressArgs, UpdateAddressArgs } from 'TYPES/api.body';
import type { AddressResponse, ResponseSuccess } from 'TYPES/api.body';
import {
  ADD_ADDRESS,
  DELETE_ADDRESS,
  GET_ALL_ADDRESSES,
  GET_SINGLE_ADDRESS,
  SET_DEFAULT_ADDRESS,
  UPDATE_ADDRESS
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getAllAddress = async (): Promise<ResponseSuccess<AddressResponse[]>> => {
  const { data } = await api.get(GET_ALL_ADDRESSES);
  return data;
};

export const getSingleAddress = async (id: string) => {
  return await api.get(GET_SINGLE_ADDRESS + id);
};

export const addAddress = async (args: NewAddressArgs) => {
  return await api.post(ADD_ADDRESS, args);
};

export const updateAddress = async (opts: UpdateAddressArgs) => {
  return await api.put(UPDATE_ADDRESS + opts.id, opts);
};

export const setDefaultAddress = async (id: string) => {
  const body = { status: true };
  return await api.put(SET_DEFAULT_ADDRESS + id, body);
};

export const deleteAddress = async (id: string) => {
  return await api.delete(DELETE_ADDRESS + id);
};
