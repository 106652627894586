import { Button } from 'components/atoms';
import UploadBusinessKYB from 'components/molecules/m-upload-business-kyb';
import QUERY_KEYS from 'network/config/queryKeys';
import { v1GetVerifiedBVN } from 'network/services/checkout';
import { getCustomer } from 'network/services/profile';
import React from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import bvnVerificationAtom from 'store/atoms/bvnVerificationAtom';
import { ConfirmBVNModal } from './components/ConfirmBVNModal';
import { ConnectBVNModal } from './components/ConnectBVNModal';
import { VerifyBVNModal } from './components/VerifyBVNModal';
import { BtnExtraStyles, ContentCon, VerifyIdentityCon } from './styles';

export const VerifyIdentity = () => {
  const [idModalOpen, setIdModalOpen] = React.useState(false);
  const [utilityModalOpen, setUtilityModalOpen] = React.useState(false);
  const setBVNVerification = useSetRecoilState(bvnVerificationAtom);
  const { data: profile } = useQuery({
    queryFn: getCustomer,
    queryKey: QUERY_KEYS.CUSTOMER_PROFILE
  });
  const { data } = useQuery({
    queryFn: v1GetVerifiedBVN,
    queryKey: [QUERY_KEYS.VERIFIED_BVN]
  });

  return (
    <VerifyIdentityCon>
      <div className="header">Verify your Identity</div>
      <ContentCon>
        <Button
          disabled={data?.data.data}
          extraStyles={BtnExtraStyles}
          onClick={() => setBVNVerification({ step: 'lookup' })}>
          <span className="text-primary mr-2">
            {data?.data.data ? <IoIosCheckmarkCircle size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
          </span>
          Verify your BVN
        </Button>
        {profile?.data.customer_type === 'BUSINESS' && (
          <>
            <Button disabled={data?.data.data} extraStyles={BtnExtraStyles} onClick={() => setIdModalOpen(true)}>
              <span className="text-primary mr-2">
                {!!profile.data.government_id ? (
                  <IoIosCheckmarkCircle size={20} />
                ) : (
                  <MdOutlineRadioButtonUnchecked size={20} />
                )}
              </span>
              Upload your ID
            </Button>
            <Button disabled={data?.data.data} extraStyles={BtnExtraStyles} onClick={() => setUtilityModalOpen(true)}>
              <span className="text-primary mr-2">
                {!!profile.data.utility_bill ? (
                  <IoIosCheckmarkCircle size={20} />
                ) : (
                  <MdOutlineRadioButtonUnchecked size={20} />
                )}
              </span>
              Upload Utility Bill
            </Button>
          </>
        )}
      </ContentCon>
      <UploadBusinessKYB kybType="GOVERNMENT_ID" open={idModalOpen} onClose={() => setIdModalOpen(false)} />
      <UploadBusinessKYB kybType="UTILITY_BILL" open={utilityModalOpen} onClose={() => setUtilityModalOpen(false)} />
      <ConnectBVNModal />
      <ConfirmBVNModal />
      <VerifyBVNModal />
    </VerifyIdentityCon>
  );
};
