import { useGoogleLogin } from "@react-oauth/google";
import { AuthSuccess } from "TYPES/api.body";
import { HidePassword, ShowPassword } from "assets/Icons";
import { Button, Spacer } from "components/atoms";
import { InputField } from "components/atoms/InputField/InputField";
import SEARCH_PARAMS from "constants/searchParams";
import { FORGOT_PASSWORD, SIGN_UP } from "navigation/routes";
import { useMutation } from "network/mutations/useMutationHook";
import { googleLoginCallback, login, requestOtp } from "network/services/auth";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { ImFacebook2 } from "react-icons/im";
import "react-phone-input-2/lib/style.css";
import { useMutation as rUseMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import { useSetRecoilState } from "recoil";
import { userIsLoggedIn, userRoleAtom } from "store/atoms/userAtom";
import { validators } from "utils/validator";
import {
  AuthContainer,
  InputCon,
  TogglePassword,
  extraStylesInput,
} from "./styles";

const Login = () => {
  const [searchParams] = useSearchParams();
  const setUserRole = useSetRecoilState(userRoleAtom);
  const setUserStatus = useSetRecoilState(userIsLoggedIn);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formError, setFormError] = useState({
    email: "",
    password: "",
  });
  const { mutateAsync: googleLoginMutate } = rUseMutation({
    mutationFn: googleLoginCallback,
  });
  const googleLogin = useGoogleLogin({
    onSuccess: (res) => {
      googleLoginMutate(res.access_token)
        .then((res) => {
          if (res.data.status === "success") {
            handleAuthSuccess(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Google Auth Failed", { variant: "error" });
        });
    },
  });

  const handleAuthSuccess = (data: AuthSuccess) => {
    sessionStorage.setItem("access_token", data.access_token);
    sessionStorage.setItem("refresh_token", data.refresh_token);
    sessionStorage.setItem("is_login", "true");
    sessionStorage.setItem(
      "newuser",
      String(data.data.referredCustomerAndNoPurchase),
    );
    setUserRole(data?.data.customer_type);
    setUserStatus(true);
    const reroute = searchParams.get(SEARCH_PARAMS.LOGIN_SUCCESS_REROUTE);

    if (reroute !== null) {
      navigate(`${reroute}?refresh=True`);
    } else {
      navigate("/");
    }
  };

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData((formData) => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error,
    });
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<"text" | "password">(
    "password",
  );

  const onTogglePassword = () => {
    if (showPassword === "text") return setShowPassword("password");
    setShowPassword("text");
  };

  const { mutateAsync, isLoading } = useMutation(login, "LOGIN");

  const _login = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const { data } = await mutateAsync(formData);
      if (data.description === "Account not verified") {
        navigate(`/otp/${formData.email}`);
        await requestOtp({ email: formData.email });
      }

      if (data.status === "success") {
        handleAuthSuccess(data);
      }
    } catch (error: any) {
      if (error?.response?.data?.description === "Account not verified") {
        navigate(`/otp/${formData.email}`);
        const { data } = await requestOtp({ email: formData.email });
        enqueueSnackbar(`Login Error: ${data.message}`, { variant: "error" });
      }
    }
  };

  const disabled =
    !formData.email || !formData.password || !!validators.email(formData.email);

  return (
    <AuthContainer>
      <h1>Login to your account</h1>
      <p className="option">
        Don't have an account?{" "}
        <span onClick={() => navigate(SIGN_UP)}>Create an Account</span>
      </p>
      <div className="flex w-full flex-row items-center gap-4">
        <LoginSocialFacebook
          onReject={() => { }}
          onResolve={() => { }}
          appId={String(process.env.REACT_APP_FACEBOOK_APP_ID)}
        >
          <button className="disabled:opacity-60 disabled:cursor-not-allowed flex items-center gap-3 p-4 flex-1 justify-center px-6 bg-[#3B5998] rounded-xl md:rounded-md text-white font-medium">
            <ImFacebook2 />
            <span>
              <span className="hidden md:inline">Continue With</span> Facebook
            </span>
          </button>
        </LoginSocialFacebook>
        <button
          type="button"
          onClick={() => googleLogin()}
          className="flex border items-center gap-3 flex-1 justify-center p-4 px-6 rounded-xl md:rounded-md font-medium"
        >
          <FcGoogle />
          <span>
            <span className="hidden md:inline">Continue With</span> Google
          </span>
        </button>
      </div>

      <form className="sign-up">
        <InputCon>
          <span>Email Address</span>
          <InputField
            type="email"
            name="email"
            value={formData.email}
            onChange={onUpdateFormData}
            placeholder="example@mail.com"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.email}
            autoComplete="username"
          />
        </InputCon>
        <Spacer height="1.5rem" />
        <InputCon>
          <span>Password</span>
          <InputField
            type={showPassword}
            name="password"
            value={formData.password}
            onChange={onUpdateFormData}
            placeholder="enter your password "
            extraStyles={extraStylesInput}
            action={
              <TogglePassword onClick={onTogglePassword}>
                {showPassword !== "text" ? <ShowPassword /> : <HidePassword />}
              </TogglePassword>
            }
            autoComplete="current-password"
          />
        </InputCon>

        <div className="forgot">
          <p className="option">
            <span onClick={() => navigate(FORGOT_PASSWORD)}>
              Forgot Password ?
            </span>
          </p>
        </div>

        <div className="buttonContainer">
          <Button
            isLoading={isLoading}
            type="submit"
            onClick={_login}
            disabled={isLoading || disabled}
          >
            Log In
          </Button>
        </div>
      </form>
    </AuthContainer>
  );
};

export default Login;
