import { atom } from 'recoil';
import { STORE_KEYS } from 'store/keys';

export const pointsAtom = atom({
  key: STORE_KEYS.POINTS,
  default: {
    open: false,
    value: 0
  }
});
