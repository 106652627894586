import React from 'react';
import { useQuery } from 'react-query';
import { usePaystackPayment } from 'react-paystack';
import { PaymentButton } from '../PaymentButton';
import { getCustomer } from 'network/services/profile';

export type PaymentChannels =
  | 'bank'
  | 'card'
  | 'qr'
  | 'ussd'
  | 'mobile_money'
  | 'eft'
  | 'bank_transfer'
  | 'payattitude';
type KlumpType = {
  amount: number;
  onSuccess?: () => void;
  onClose?: () => void;
  title?: string;
  channels?: PaymentChannels[];
  children?: React.ReactNode;
  desc?: string;
};

export const Paystack: React.FC<KlumpType> = ({ amount, onSuccess, onClose, channels, title, desc }) => {
  const { data } = useQuery('profile', getCustomer);
  const profile = data?.data || null;

  const amountToPay = Math.round(amount * 100);
  const initializePayment = usePaystackPayment({
    reference: new Date().getTime().toString(),
    email: profile?.email ?? 'none',
    amount: amountToPay,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY ?? '',
    channels
  });

  const handleClick = () => {
    if (profile?.email !== undefined) {
      initializePayment(onSuccess, onClose);
    } else {
      return;
    }
  };

  return <PaymentButton title={title ?? 'Pay with PayStack'} desc={desc} onClick={handleClick} />;
};
