export const stateinNigeria = [
  'Abia',
  'Adamawa',
  'Akwa Ibom',
  'Anambra',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Edo',
  'Ekiti',
  'Enugu',
  'FCT - Abuja',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara'
];

export const Defaultaddresses = [
  {
    id: '1',
    lastname: 'Sharma',
    firstname: 'Jay',
    address: '10, Ladipo, StreetLagos State, Nigeria',
    phoneNumber: '+234-81234567890',
    state: 'Lagos',
    country: 'Nigeria',
    asDefault: true
  },
  {
    id: '2',
    lastname: 'John',
    firstname: 'Okafor',
    address: '10, Ladipo, StreetLagos State, Nigeria',
    phoneNumber: '+234-81234567890',
    state: 'Lagos',
    country: 'Nigeria',
    asDefault: false
  }
];

export const faqData = [
  {
    label: 'What is Madina?',
    content:
      ' Madina is an exclusive network of marketing agents across Nigeria. Our agents solve the marketing challenges faced by businesses and ensure each business experience massive growth and profitability.'
  },
  {
    label: 'How do i create an order on Madina ?',
    content:
      ' Madina is an exclusive network of marketing agents across Nigeria. Our agents solve the marketing challenges faced by businesses and ensure each business experience massive growth and profitability.'
  },
  {
    label: 'Having a delayed order. What do i do ?',
    content:
      ' Madina is an exclusive network of marketing agents across Nigeria. Our agents solve the marketing challenges faced by businesses and ensure each business experience massive growth and profitability.'
  },
  {
    label: 'I missed my delivery. What happens now ?',
    content:
      ' Madina is an exclusive network of marketing agents across Nigeria. Our agents solve the marketing challenges faced by businesses and ensure each business experience massive growth and profitability.'
  },
  {
    label: 'How to change delivery information ?',
    content:
      ' Madina is an exclusive network of marketing agents across Nigeria. Our agents solve the marketing challenges faced by businesses and ensure each business experience massive growth and profitability.'
  },
  {
    label: 'Having troubles adding products to cart ?',
    content:
      ' Madina is an exclusive network of marketing agents across Nigeria. Our agents solve the marketing challenges faced by businesses and ensure each business experience massive growth and profitability.'
  }
];

export const orderProduct = [
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#23467990',
    delivered: '07/08/2022',
    type: 'pending'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#23467340',
    delivered: '07/08/2022',
    type: 'pending'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#236673440',
    delivered: '07/08/2022',
    type: 'pending'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#236675540',
    delivered: '07/08/2022',
    type: 'pending'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#23464490',
    delivered: '07/08/2022',
    type: 'delivered'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#234612340',
    delivered: '07/08/2022',
    type: 'delivered'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#236451440',
    delivered: '07/08/2022',
    type: 'delivered'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#236612340',
    delivered: '07/08/2022',
    type: 'delivered'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#23400090',
    delivered: '07/08/2022',
    type: 'cancelled'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#23411140',
    delivered: '07/08/2022',
    type: 'cancelled'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#239999440',
    delivered: '07/08/2022',
    type: 'cancelled'
  },
  {
    url: 'https://images.unsplash.com/photo-1679599673055-62de1177335d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
    title: 'Mama’s Pride',
    id: '#2364566740',
    delivered: '07/08/2022',
    type: 'cancelled'
  }
];
