import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const DownloadAppCon = styled('div')<{ bg: any }>`
  padding: 91px 132px;
  display: flex;
  align-items: start;
  border-radius: 11.5px;
  margin: 0px 20px;
  justify-content: stretch;
  background: ${COLORS.MainBlue};
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  overflow-x: hidden;
  @media (max-width: ${breakpoints.md}px) {
    background-position: bottom;
    padding: 20px 11px 0px 11px;
    margin: 11px;
  }
`;

export const LeftCon = styled('div')`
  width: 626.869px;
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }

  .mobile-shop{
    padding-top: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      
      width: 261.808px;
    }
  }

  .heading {
    color: #fff;
    font-family: Outfit;
    font-size: 46.869px;
    font-weight: 700;
    line-height: 92px;
    letter-spacing: 0.216px;
    text-transform: capitalize;
    @media (max-width: ${breakpoints.md}px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .desc {
    color: #fff;
    font-family: Outfit;
    font-size: 20.505px;
    font-weight: 400;
    line-height: 32.222px; 
    letter-spacing: 0.205px;
    @media (max-width: ${breakpoints.md}px) {
      padding: 12px 0px;
      font-size: 16px;
      line-height: 24px;
    }

  }

  .btns {
    display: flex;
    gap: 18px;
    align-items: center;
  }
`;
