import { styled } from "@mui/material";
import { COLORS } from "../../../theme/color";
import { breakpoints } from "../../../theme/theme";

export const ReviewCon = styled("div")(({ theme }) => (
  {
    marginBlock: "30px",
    width: "70%",
    overflowX: "hidden",
    ".content": {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      ".heading": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ".review": {
          fontSize: "1rem",
          color: COLORS.MainGreen,
        },
        ".title": {
          fontSize: "1.2rem",
          color: COLORS.DarkBlue,
        },
      },
    },
    ".review-items": {
      marginInline: "19px",
    },
    [theme.breakpoints.down("md")]: {
      marginInline: "19px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  }
));

export const Review = styled("div")({
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "24px",
  padding: "32px 24px",
  height: "100%",
  width: "100%",
  borderRadius: "10px",
  alignItems: "center",
  ".review-heading": {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    alignItems: "center",
    ".review-author": {
      fontSize: "1.4rem",
      fontWeight: 500,
    },
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    textAlign: "center",
    fontWeight: 300,
    fontSize: "1.2rem",
    ".title": {
      fontWeight: 400,
    },
  },
});

export const WrapperCon = styled("div")`
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
  
`;

export const ReviewOld = styled("div")`
  padding: 52px 35px;
  width: 477px !important;

  height: 100%;
  border-radius: 12px;
  border: 1px solid #e2e2e2;
  background: #fff;
  box-shadow: 0px 0px 100px 12px rgba(0, 0, 0, 0.04);

  @media (min-width: ${breakpoints.lg}px) {
    width: 100% !important;
    padding: 20px 15px;
  }
  @media (max-width: ${breakpoints.md}px) {
    width: 100% !important;
    padding: 20px 15px;
  }

  .review-heading {
    display: flex;
    align-items: center;
    gap: 22px;
    .img-con {
      width: 57px;
      height: 57px;
      border-radius: 57px;
      @media (max-width: ${breakpoints.md}px) {
        width: 37px;
        height: 37px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    p {
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      @media (max-width: ${breakpoints.md}px) {
        font-size: 16px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 26px;
    align-items: center;
    justify-content: center;
    @media (max-width: ${breakpoints.md}px) {
      gap: 10px;
    }
    .title {
      color: #000;
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 20px;
      @media (max-width: ${breakpoints.md}px) {
        font-size: 16px;
        padding-top: 10px;
      }
    }

    .desc {
      color: #000;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 400;
      @media (max-width: ${breakpoints.md}px) {
        font-size: 14px;
      }
    }
  }
`;
