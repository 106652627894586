import React from 'react';
import { ProfilPicCon } from './styles';
import { COMMONTYPES } from 'TYPES/event.types';
import ProfilePicture from 'assets/images/profile-pic.svg';
import { updateProfilePic } from 'network/services/profile';
import { useMutation } from 'network/mutations/useMutationHook';
import { ReactComponent as EditPic } from 'assets/images/picEdit.svg';

interface Props {
  setProfilePic: React.Dispatch<React.SetStateAction<string | undefined>>;
  profilePic?: string;
}

const ProfilePic: React.FC<Props> = ({ setProfilePic, profilePic }) => {
  const { mutateAsync: mutateUploadProfilePicAsync, isLoading: isUploadProfilePicLoading } =
    useMutation(updateProfilePic);

  const handleUploadProfilePhoto = async (e: COMMONTYPES['inputChange']) => {
    const file = e.target.files?.[0];

    try {
      if (file) {
        const formData = new FormData();
        formData.append('profile_picture', file);
        const { data } = await mutateUploadProfilePicAsync(formData);
        setProfilePic(data.data);
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
  };

  return (
    <ProfilPicCon>
      <div className="img">
        <img src={profilePic || ProfilePicture} alt="profile-pic" />
        <label htmlFor="profile">
          <EditPic className="edit" />
        </label>
      </div>
      <input
        type="file"
        name="profile"
        id="profile"
        onChange={handleUploadProfilePhoto}
        disabled={isUploadProfilePicLoading}
      />
      <p>Change profile photo</p>
    </ProfilPicCon>
  );
};

export default ProfilePic;
