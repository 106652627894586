import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { App } from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { themes } from './theme/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GoogleOAuthProvider } from '@react-oauth/google';

const theme = responsiveFontSizes(themes);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const anchorOrigin = { vertical: 'top', horizontal: 'right' } as const;

const ensureEnv = [
  'REACT_APP_FACEBOOK_APP_ID',
  'REACT_APP_GOOGLE_OAUTH_CLIENT_ID',
  'REACT_APP_MONO_PUBLIC_KEY',
  'REACT_APP_PAYSTACK_PUBLIC_KEY',
  'REACT_APP_GOOGLE_API_KEY',
  'REACT_APP_BASE_URL'
];
for (const item of ensureEnv) {
  if (!process.env[item]) {
    throw new Error(`Missing environment variable ${item}`);
  }
}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID)}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot key="Recoil">
          <ThemeProvider theme={theme}>
            <MantineProvider>
              <CssBaseline />
              <SnackbarProvider maxSnack={3} preventDuplicate anchorOrigin={anchorOrigin}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <App />
                </LocalizationProvider>
              </SnackbarProvider>
            </MantineProvider>
          </ThemeProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
