// import { getPostPurchasePayments } from 'network/services/checkout';
// import React from 'react';
// import { useQuery } from 'react-query';

const PendingPayments = () => {
  // const PAGE_SIZE = 20;
  // const [page, setPage] = React.useState(0);
  // const { data } = useQuery({
  //   queryFn: () => getPostPurchasePayments({ page, size: PAGE_SIZE })
  // });

  return (
    <main className="bg-white rounded-xl">
      <header className="p-4 lg:p-8">
        <h1 className="text-lg font-bold">Pending Payments</h1>
      </header>
    </main>
  );
};

export default PendingPayments;
