import React from 'react';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { IoClose, IoReload } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { extractLink } from './utils';
import ChatView from 'components/molecules/m-chat-view';
import { GoodsBySearch } from 'pages/GoodsByCategories/GoodsBySearch';
import { MessageResponse } from 'components/molecules/m-chat-view/types';
import { cancelMessageRun, initiateChat, sendAiMessage } from 'network/services/assistedShopping';

const CHAT = 'aiChat';
const USER_ID = 'User';

const AssistedShopping = () => {
  const goto = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [messages, setMessages] = React.useState<MessageResponse[]>([]);
  const { mutateAsync: cancelMessageSend, isLoading: cancelingMessageSend } = useMutation({
    mutationFn: cancelMessageRun
  });
  const { mutateAsync: createNewChat, isLoading: creatingChat } = useMutation({
    mutationFn: initiateChat
  });
  const { mutateAsync: sendAIChat, isLoading: aiResponseLoading } = useMutation({
    mutationFn: sendAiMessage,
    onSuccess: data => {
      const textData = extractLink(data);
      if (textData.linkData !== undefined) {
        const search = new URL(textData.linkData.href).searchParams;
        const query = search.get('query');
        if (query !== null) {
          setSearchParams({ query });
        }
      }
      if (textData.text === 'No response from AI') {
        enqueueSnackbar('No response from AI', { variant: 'info' });
      } else {
        setMessages(curr => [
          ...curr,
          {
            senderId: 'Madin AI',
            id: curr.length + 1,
            content: textData.text,
            timestamp: new Date().toISOString(),
            recipientId: USER_ID
          }
        ]);
        localStorage.setItem(CHAT, JSON.stringify(messages));
      }
    }
  });

  React.useEffect(() => {
    const chatStr = localStorage.getItem(CHAT);
    if (chatStr !== null) {
      setMessages(JSON.parse(chatStr));
    } else {
      setMessages([
        {
          senderId: 'Madina AI',
          id: 1,
          content: '👋 Welcome to Madina!',
          timestamp: new Date().toISOString(),
          recipientId: USER_ID
        },
        {
          senderId: 'Madina AI',
          id: 2,
          content: 'What would you need help with today?',
          timestamp: new Date().toISOString(),
          recipientId: USER_ID
        }
      ]);
    }
  }, []);

  const handleSendMessage = async (message: string) => {
    if (message.length > 0) {
      if (messages.length < 1) {
        const res = await createNewChat();
        if (res !== 'Chat initiated successfully') {
          enqueueSnackbar('Failed to initiate chat');
          return;
        }
      }
      setMessages(curr => [
        ...curr,
        {
          senderId: USER_ID,
          id: curr.length + 1,
          content: message,
          timestamp: new Date().toISOString(),
          recipientId: 'Madina AI'
        }
      ]);
      sendAIChat(message);
    }
  };

  const header = (
    <div>
      <h3 className="text-2xl">Top Results</h3>
    </div>
  );

  const resendDisabled =
    messages.length < 1 ||
    messages[messages.length - 1].senderId !== USER_ID ||
    cancelingMessageSend ||
    aiResponseLoading;

  const action = (
    <div className="flex items-center justify-center gap-3 p-2 py-4">
      <button
        disabled={cancelingMessageSend || !aiResponseLoading}
        onClick={() => cancelMessageSend()}
        className="flex items-center text-red-600 gap-1 bg-red-600/20 p-0.5 px-4 rounded-2xl disabled:bg-gray-600/10 disabled:text-gray-600/50">
        <IoClose />
        <span>Cancel</span>
      </button>
      <button
        disabled={resendDisabled}
        onClick={() => sendAIChat(messages[messages.length - 1].content)}
        className="flex items-center text-primary gap-1 bg-primary/20 p-0.5 px-4 rounded-2xl disabled:bg-gray-600/10 disabled:text-gray-600/50">
        <IoReload />
        <span>Resend</span>
      </button>
    </div>
  );
  return (
    <div className="flex flex-col-reverse p-4 gap-8 md:p-10 md:px-14 lg:flex-row">
      <div className="flex-1 lg:max-w-[70%]">
        <GoodsBySearch header={header} />
      </div>
      <div className="flex-[0.5]">
        <div className="lg:fixed w-full max-h-[90vh] lg:max-w-[30%]">
          <ChatView
            action={action}
            // disabled={!profile}
            messages={messages}
            loading={creatingChat}
            onClose={() => goto('/')}
            userLoading={creatingChat}
            onSend={handleSendMessage}
            receiverLoading={aiResponseLoading}
            userId={USER_ID}
            loadingMessgage="Creating new chat..."
          />
        </div>
      </div>
    </div>
  );
};

export default AssistedShopping;
