import React from 'react';
import { DropDownIcon } from '../../../assets/Icons';
import replacer from '../../../utils/replacer';
import { Spacer } from '../../atoms';
import { DropDown } from '../DropDown/DropDown';
import { DropButton, List } from './style';

type SortProp = {
  sort: string | null;
  setSort: React.Dispatch<React.SetStateAction<string>>;
};

export const SortBy = ({ sort, setSort }: SortProp) => {
  const parsed_sort = sort && replacer(/-/g, ' ', sort);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSort(e.currentTarget.value);
  };

  return (
    <div>
      <DropDown
        x="right"
        label={
          <DropButton>
            Sort: {parsed_sort || 'Product Rating'} <Spacer width={5} /> <DropDownIcon />
          </DropButton>
        }>
        <>
          <List onClick={handleClick} value="product-rating">
            Product Rating
          </List>
          <List onClick={handleClick} value="low-to-high">
            Price: Low to High
          </List>
          <List onClick={handleClick} value="high-to-low">
            Price: Hign to Low
          </List>
        </>
      </DropDown>
    </div>
  );
};
