import { styled } from '@mui/material';
import { BasicBox } from '../styles';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const BusinessKYBCon = styled(BasicBox)`
  .address-map {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
  .button button {
    max-width: 100%;
    width: 472px;
    margin: 100px auto 0;
    font-weight: 700;
  }
  @media (max-width: 1100px) {
    .address-map {
      grid-template-columns: 1fr;
    }
  }

  .kyb-item {
    display: flex;
    width: 100%;
    flex-direction: column;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #483d3d;
      line-height: 20px;
      margin: 0.5rem 0;
    }
  }

  .button-con {
    padding: 0px 200px;
    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px 10px;
    }
  }
`;

export const InputCon = styled('div')`
  display: flex;
  align-items: center;
  gap: 60px;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
`;

export const ButtonExtraStyles = {
  background: ' #EFF1FB',
  width: '102px',
  color: `${COLORS.MainBlue}`,
  borderRadius: '12px',
  height: '31px',
  padding: '10px'
};

export const ModalContentCon = styled('div')`
  p {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
  .image-con{
    width: 100%;
    height: 300px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;
