import { Modal } from '@mantine/core';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useMutation } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import bvnVerificationAtom from 'store/atoms/bvnVerificationAtom';
import { profileAtom } from 'store/atoms/profileAtom';
import { Button, InputField, Spacer } from '../../../../components/atoms';
import { verifyBVN } from '../../../../network/services/verifyIdentity';
import { bvnLookupAtom } from '../../../../store/atoms/verifyIdentityAtom';
import { DetailsModalCon, cancelConfirmBtn, proceedConfirmBtn, InputCon } from './styles';

export const ConfirmBVNModal: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = React.useState<string>();
  const [bvnLookup, setBVNLookup] = useRecoilState(bvnLookupAtom);
  const profile = useRecoilValue(profileAtom);
  const [bvnVerification, setBVNState] = useRecoilState(bvnVerificationAtom);
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: verifyBVN,
    onSuccess: () => {
      setBVNLookup(c => ({ ...c, phone: phoneNumber }));
      setBVNState({ step: 'verify' });
    },
    onError: error => {
      enqueueSnackbar({ message: 'BVN verification failed ', variant: 'error' });
      console.log('Verify BVN Error:', error);
    }
  });

  const handleProceed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const { session_id, methods } = bvnLookup;
    if (phoneNumber === undefined || phoneNumber.length < 10) {
      enqueueSnackbar({ message: 'Please enter the phone number attached to this BVN' });
      return;
    }
    if (profile !== undefined) {
      mutateAsync({
        session_id,
        method: methods.filter(method => method.method === 'phone')[0]?.method,
        phone_number: phoneNumber
      });
    } else {
      enqueueSnackbar({ message: 'You need to be logged in to continue', variant: 'error' });
      return;
    }
  };

  return (
    <Modal
      size={500}
      centered
      radius="lg"
      opened={bvnVerification?.step === 'confirm'}
      onClose={() => setBVNState(undefined)}>
      <DetailsModalCon className="p-2 px-5">
        <div className="header">
          <h3>Confirm BVN</h3>
        </div>

        <div>
          <Spacer height="35px" />

          <p className="confirm-bvn-text">
            To confirm that this is your BVN, please enter the phone number linked to this BVN
          </p>

          <Spacer height="45px" />
          <InputCon>
            <span>Phone number</span>
            <InputField onChange={e => setPhoneNumber(e.currentTarget.value)} name="BVN" placeholder="1234567890" />
          </InputCon>

          <div className="buttonsCon">
            <Button type="submit" extraStyles={cancelConfirmBtn} onClick={() => setBVNState(undefined)}>
              Cancel
            </Button>

            <Button
              type="submit"
              extraStyles={proceedConfirmBtn}
              isLoading={isLoading}
              onClick={handleProceed}
              disabled={isLoading || !phoneNumber || phoneNumber?.length < 10}>
              Proceed
            </Button>
          </div>

          <Spacer height="20px" />
        </div>
      </DetailsModalCon>
    </Modal>
  );
};
