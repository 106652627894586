import { styled } from '@mui/material';
import { breakpoints } from '../../../theme/theme';
import { COLORS } from '../../../theme/color';

export const OrderDetailsCon = styled('div')`
  max-width: 854px;
  padding: 24px 32px;
  background-color: #fff;
  color: ${COLORS.MainBlack};
  .id {
    margin-bottom: 11.5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .text1 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    color: ${COLORS.SecBlack};
  }

  .div1 {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
    font-size: 16px;
  }

  .cancel {
    margin-top: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    padding: 24px 5%;
  }
`;

export const OrderSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1dbdb;
  padding: 20px 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    img {
      width: 108px;
      height: 108px;
      border-radius: 12px;
      object-fit: center;
      margin-right: 16px;
      flex-shrink: 0;
    }
    .text {
      width: calc(100% - 128px);
      min-height: 108px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .title {
        color: #1e223e;
        margin-bottom: 11px;
      }
      .qty {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: ${COLORS.SecBlack};
      }
      .amount {
        color: ${COLORS.SecBlack};
        font-weight: 500;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
    .item {
      img {
        width: 70px;
        height: 70px;
        border-radius: 6px;
      }
      .text {
        width: calc(100% - 90px);
        min-height: 80px;
        .title {
          margin-bottom: 4px;
          font-size: 14.5px;
        }
        .qty {
          font-size: 12.5px;
        }
        .amount {
          font-size: 14.5px;
        }
      }
    }
  }
`;

export const OrderInfo = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .col {
    border: 1px solid #e1dbdb;
    border-top: none;
    .headings {
      border-bottom: 1px solid #e1dbdb;
      padding: 24px 20px 16px 32px;
      font-weight: 500;
      font-size: 16px;
    }
    .text-con {
      padding: 24px 32px;
      font-weight: 300;

      .method {
        font-weight: 500;
        margin-bottom: 11px;
      }
      div:nth-of-type(2) {
        margin-bottom: 24px;
        color: ${COLORS.SecBlack};
      }
      div:nth-of-type(3) {
        margin-bottom: 12px;
        font-weight: 500;
      }
      div:nth-of-type(4),
      div:nth-of-type(5) {
        margin-bottom: 8px;
        color: ${COLORS.SecBlack};
      }
      div:nth-of-type(6) {
        font-weight: 500;
      }
    }

    &:last-of-type {
      border-left: none;
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
    grid-template-columns: 1fr;
    .col {
      .headings {
        font-size: 15px;
      }
      .text-con {
        font-size: 14px;
      }
      &:last-of-type {
        border-left: 1px solid #e1dbdb;
      }
    }
  }
`;

export const OrderLabel = styled('div')<{ type: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  width: 101px;
  height: 32px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  background: ${props =>
    props.type === 'pending'
      ? '#FEF9E2'
      : props.type === 'delivered'
      ? '#F0F5EA'
      : props.type === 'cancelled'
      ? '#FFEBEE'
      : ''};
  color: ${props =>
    props.type === 'pending'
      ? '#F79009'
      : props.type === 'delivered'
      ? '#669F2A'
      : props.type === 'cancelled'
      ? '#F04438'
      : ''};
`;
