import React from 'react';
import { PaymentButton } from '../PaymentButton';
import { useRecoilState } from 'recoil';
import modalAtom from 'store/atoms/modals';
import { PayWithMadinaModal } from '../PayWithMadinaModal';

interface Props {
  amount: number;
  onSuccess?: () => void;
}

export const PayWithMadinaWallet: React.FC<Props> = ({ onSuccess, amount }) => {
  const [modalState, setModalState] = useRecoilState(modalAtom);

  const handlePayWithWallet = () => {
    setModalState(c => ({ ...c, payWithMandinaModalOpen: true }));
  };

  return (
    <>
      <PaymentButton title="Pay with your madina wallet" desc="" onClick={handlePayWithWallet} />
      {modalState.payWithMandinaModalOpen && <PayWithMadinaModal amount={amount} onSucces={onSuccess} />}
    </>
  );
};
