import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { LOGIN } from '../navigation/routes';
import { userIsLoggedIn } from '../store/atoms/userAtom';
import PageLayout from '../components/layout/PageLayout';
import AuthLayout from '../components/layout/AuthLayout';

const ProtectedRoute: React.FC<{ authRequired?: boolean }> = ({ authRequired }) => {
  const isLogged = useRecoilValue(userIsLoggedIn);

  if (authRequired) {
    return isLogged ? (
      <PageLayout>
        <Outlet />
      </PageLayout>
    ) : (
      <Navigate to={LOGIN} />
    );
  }

  return !isLogged ? (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ) : null;
};

export default ProtectedRoute;
