import React from 'react';
import { ProductSkeleton } from '../../components/molecules/Goods/ProductSkeleton';

interface Props {
  count: number;
}

const ProductSkeletons: React.FC<Props> = ({ count }) => {
  return (
    <>
      {Array(count)
        .fill(0)
        .map((_, i) => (
          <div className="w-full h-full" key={i}>
            <ProductSkeleton />
          </div>
        ))}
    </>
  );
};

export default ProductSkeletons;
