import { CircularProgress } from '@mui/material';
import { walletHistoryResponse } from 'TYPES/api.body';
import { COMMONTYPES } from 'TYPES/event.types';
import { CashIcon, ConversionIcon, WalletSmallIcon } from 'assets/Icons';
import { EmptyCon } from 'components/atoms';
import { ConvertPointModal, WalletCard, WalletHistoryMessage } from 'components/molecules';
import FundMadinaWallet from 'components/molecules/m-fund-madina-wallet';
import QUERY_KEYS from 'network/config/queryKeys';
import { getWalletBalance, getWalletHistories } from 'network/services/wallet';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { UserWalletCon } from './styles';

export const UserWallet = () => {
  const { data } = useQuery(QUERY_KEYS.WALLET_BALANCE, getWalletBalance);
  const walletBalance = data?.data?.data || null;
  const [curr, setcurr] = useState<number>(0);
  const { data: walletHistoryData, isFetchedAfterMount } = useQuery({
    queryFn: () => getWalletHistories(curr),
    queryKey: ['wallet_histories', { curr }]
  });
  const walletData: walletHistoryResponse[] = walletHistoryData?.data?.data || [];

  const [isPoint, setIsPoint] = useState(false);
  const showModal = (e: COMMONTYPES['divClick']) => {
    e.stopPropagation();
    setIsPoint(true);
  };
  return (
    <UserWalletCon>
      <div className="flex pb-4 flex-col border-b mb-6 gap-2 lg:flex-row justify-between items-center">
        <h2 className="text-2xl font-bold">Wallet</h2>
        <FundMadinaWallet />
      </div>
      <div className="wallet-stat-con">
        <WalletCard
          label="Wallet Balance"
          Icon={WalletSmallIcon}
          amount={`N ${isFetchedAfterMount && walletBalance != null ? walletBalance.balance.toFixed(2) : 0}`}
        />
        <WalletCard
          label="Available points"
          Icon={CashIcon}
          amount={`${isFetchedAfterMount && walletBalance != null ? walletBalance.points : 0}`}
          // amount={"400"}
        />
        <WalletCard label="Convert points to cash" Icon={ConversionIcon} onClick={showModal} />
      </div>
      <div className="subhead">Wallet History</div>
      {!isFetchedAfterMount && (
        <EmptyCon>
          <CircularProgress size={30} />
        </EmptyCon>
      )}
      {isFetchedAfterMount && walletData.length ? (
        <WalletHistoryMessage
          data={walletData}
          setvalue={setcurr}
          value={curr}
          totalpages={walletHistoryData?.data.totalPages ?? 0}
        />
      ) : (
        isFetchedAfterMount && !walletData.length && <EmptyCon>No History</EmptyCon>
      )}
      {isPoint && <ConvertPointModal setIsPoint={setIsPoint} />}
    </UserWalletCon>
  );
};
