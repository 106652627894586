import { useRecoilValue } from 'recoil';
import { liveChatAtom } from 'store/atoms/liveChatAtom';
import CustomerSupportChat from '../o-madina-support-chat';
import LiveChatStep1 from 'components/molecules/m-live-chat-step-1';
import LiveChatStep2OrderRelated from 'components/molecules/m-live-chat-step-2-order-related';
import CustomerSupportWelcomeView from 'components/molecules/m-customer-support-welcome-view';
import LiveChatSetp2NonOrderRelated from 'components/molecules/m-live-chat-setp-2-non-order-related';

const CustomerSupport = () => {
  const liveChat = useRecoilValue(liveChatAtom);
  return (
    <>
      {liveChat.step === 0 && <CustomerSupportWelcomeView />}
      {liveChat.step === 1 && <LiveChatStep1 />}
      {liveChat.step === 2 && liveChat.issueType === 'orderRelated' && <LiveChatStep2OrderRelated />}
      {liveChat.step === 2 && liveChat.issueType === 'other' && <LiveChatSetp2NonOrderRelated />}
      {liveChat.step === 3 && <CustomerSupportChat />}
    </>
  );
};

export default CustomerSupport;
