import { styled } from "@mui/material";
import { COLORS } from "../../../theme/color";
import { breakpoints } from "../../../theme/theme";

export const SeamlessShopingCon = styled("div")(({ theme }) => ({
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "4rem",
  justifyContent: "center",
  margin: "40px",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: "126px",
  },
}));

export const RightCon = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  [theme.breakpoints.up("md")]: {
    maxWidth: "600px",
  },
  [theme.breakpoints.down("lg")]: {
    flex: 1,
  },

  ".cart-logo": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "65px",
    height: "65px",
    background: COLORS.MainBlue,
    borderRadius: "50px",
    [theme.breakpoints.down("md")]: {
      width: "39px",
      height: "39px",
      svg: {
        width: "18px",
        height: "18px",
        color: COLORS.White,
      },
    },
  },
  h3: {
    color: "#272d4e",
    fontFamily: "Outfit",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",

    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      color: "#272D4E",
      fontWeight: 400,
    },
  },

  p: {
    color: "#94A2B3",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const LeftCon = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    flex: 1,
  },
  ".img-con": {
    height: "100%",
    background: "rgba(169, 169, 169, 0.1)",
    aspectRatio: "1/1",

    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "100%",
    },

    "img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

export const Title = styled("p")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "700",

  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
}));

export const RightTab = styled("div")<{ index: number }>`
  border-radius: 12px;
  font-weight: 500;
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  /* @media  (max-width: ${breakpoints.md}px) {
    background: ${(props) => (props.index === 0 ? COLORS.MainBlue : "#E8F1FF")};
    color: ${(props) => (props.index === 0 ? COLORS.White : "#777777")};
    font-weight: 700;
    height: 58px;
 
  } */

  P {
    color: #667085;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
