import React from "react";
import { BackArrow } from "assets/Icons";
import { extraStylesInput, InputCon, KYBCon } from "./styles";
import { ChangeEvent, useState } from "react";
import { useRecoilState } from "recoil";
import { InputField } from "components/atoms";
import { Button, Spacer } from "components/atoms";
import { checkFormData, validators } from "utils/validator";
import { businessRegDetails } from "store/atoms/businessKYB";
import { registerBusiness } from "network/services/auth";
import { Link, useNavigate } from "react-router-dom";
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "navigation/routes";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { Autocomplete, TextField } from "@mui/material";
import { stateinNigeria } from "assets/mockData";

const INITIALSTATE = {
  businessName: "",
  businessAddress: "",
  cac: "",
  location: "",
  bvn: "",
  nin: "",
};

export default function BusinessKYB() {
  const [formError, setFormError] = useState(INITIALSTATE);
  const [agreement, setAgreement] = useState(false);
  const [businessFormData, setBusinessFormData] =
    useRecoilState(businessRegDetails);

  const navigate = useNavigate();

  const onToggleAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
  };

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: registerBusiness,
  });

  const setFormInfo = (name: string, value: unknown) => {
    const error = validators[name](value);
    setBusinessFormData((curr) => ({
      ...curr,
      request: { ...curr.request, [name]: value },
    }));
    setFormError({
      ...formError,
      [name]: error,
    });
  }

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormInfo(name, value)
  };

  const _handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const isValid = checkFormData(businessFormData.request, setFormError);
    if (!isValid) {
      enqueueSnackbar("Please Fill out the necessary fields", {
        variant: "info",
      });
      return;
    }
    try {
      const { data } = await mutateAsync({
        ...businessFormData.request,
        phoneNumber:
          businessFormData.request.phoneNumber.split("234").pop() ?? "",
      });
      if (data != null) {
        navigate(`/otp/${businessFormData.request.email}`);
      }
    } catch (error) {
      const errMessage = (error as any)?.response?.data?.message;
      if (errMessage) {
        enqueueSnackbar(errMessage, { variant: "error" });
      }
      console.log(error);
    }
  };


  const disabled =
    !businessFormData.request.businessAddress ||
    !businessFormData.request.businessName ||
    !businessFormData.request.cac ||
    !agreement;

  return (
    <KYBCon>
      <BackArrow
        style={{ cursor: "pointer" }}
        onClick={() => setBusinessFormData((curr) => ({ ...curr, step: 1 }))}
      />
      <Spacer height="24px" />
      <h2>KYB</h2>
      <Spacer height="16px" />
      <p>Let’s get to know you better</p>
      <div>
        <form>
          <InputCon>
            <span>Business Name</span>
            <InputField
              type="text"
              name="businessName"
              onChange={onUpdateFormData}
              placeholder="Bilsome Naz"
              extraStyles={extraStylesInput}
              required={true}
              error={formError.businessName}
              autoComplete="username"
            />
          </InputCon>
          <Spacer height="1.5rem" />

          <InputCon>
            <span>Business Address</span>
            <InputField
              type="text"
              name="businessAddress"
              onChange={onUpdateFormData}
              placeholder="123 maginator Nazi, Ikoyi Lagos"
              extraStyles={extraStylesInput}
              required={true}
              error={formError.businessAddress}
            />
          </InputCon>
          <Spacer height="1.5rem" />
          <InputCon>
            <span>State</span>
            <Autocomplete
              options={stateinNigeria}
              onChange={(_, v) => setFormInfo("location", v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  name="location"
                  helperText={formError.location}
                  error={!!formError.location}
                  onChange={onUpdateFormData}
                  placeholder="Abuja"
                  required={true}
                />
              )}
            />
          </InputCon>

          <Spacer height="1.5rem" />

          <InputCon>
            <span>CAC Registration Number</span>
            <InputField
              type="text"
              name="cac"
              onChange={onUpdateFormData}
              placeholder="892123AHj"
              required={true}
              error={formError.cac}
            />
          </InputCon>

          <Spacer height="1.5rem" />

          <InputCon>
            <span>BVN</span>
            <InputField
              type="text"
              name="bvn"
              onChange={onUpdateFormData}
              placeholder="18201932840"
              required={true}
              error={formError.bvn}
            />
          </InputCon>
          <Spacer height="1.5rem" />

          <InputCon>
            <span>NIN</span>
            <InputField
              type="text"
              name="nin"
              onChange={onUpdateFormData}
              placeholder="1820193284073"
              required={true}
              error={formError.nin}
            />
          </InputCon>
          <Spacer height="1.5rem" />

          <div className="policy">
            <input
              type="checkbox"
              name="policy"
              id="policy"
              onChange={onToggleAgreement}
            />
            <p className="option">
              I have read, understood and I agree to Madina’s{" "}
              <Link className="text-primary font-medium" to={PRIVACY_POLICY}>
                Privacy Policy
              </Link>
              , and{"  "}
              <Link
                className="text-primary font-medium"
                to={TERMS_AND_CONDITIONS}
              >
                Terms and conditions
              </Link>
              .
            </p>
          </div>

          <div className="buttonContainer">
            <Button
              isLoading={isLoading}
              disabled={disabled}
              type="submit"
              onClick={_handleSubmit}
            >
              Register
            </Button>
          </div>
        </form>
      </div>
    </KYBCon>
  );
}
