import { ResponseSuccess, WalletResponse, walletHistoryResponse } from 'TYPES/api.body';
import { ResponseContent } from 'TYPES/response.types';
import {
  CONVERT_POINTS_TO_NAIRA,
  FUND_MADINA_WALLET,
  GET_WALLET_BALANCE,
  GET_WALLET_HISTORIES,
  POINT_NAIRA_EQUIVALENCE
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getWalletBalance = async (): Promise<ResponseContent<ResponseSuccess<WalletResponse>>> => {
  return await api.get(GET_WALLET_BALANCE);
};

export const getWalletHistories = async (page: number):Promise<ResponseContent<ResponseSuccess<walletHistoryResponse[]>>> => {
  return await api.get(`${GET_WALLET_HISTORIES}?page=${page}`);
};
export const v1postfundwallet = async (points: {
  amount: string;
}): Promise<
  ResponseContent<
    ResponseSuccess<{
      status: string;
      description: string;
    }>
  >
> => {
  return await api.post(FUND_MADINA_WALLET, points);
};

export const convertPointsToNaira = async (points: number) => {
  return await api.post(CONVERT_POINTS_TO_NAIRA, { point: points });
};

export const getPointNairaEquivalent = async (points: number) => {
  return api.get(POINT_NAIRA_EQUIVALENCE.concat(points.toString()));
};
