import LOCALSTORAGE_KEYS from 'constants/localStorage';
import QUERY_KEYS from 'network/config/queryKeys';
import { addProductToCart, removeProductFromCart, updateCartItemQty } from 'network/mutations/products';
import { viewCart } from 'network/services/goods';
import { getCustomer } from 'network/services/profile';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CartItem, cartListAtom } from 'store/atoms/cartAtom';
import { userRoleAtom } from 'store/atoms/userAtom';
import { syncLocalAndServerCart } from '../Goods/utils';

export const useUpdatedCart = () => {
  const userRole = useRecoilValue(userRoleAtom);
  const { mutateAsync: addMutate } = useMutation({
    mutationFn: addProductToCart
  });
  const { mutateAsync: updateMutate } = useMutation({
    mutationFn: updateCartItemQty
  });
  const { mutateAsync: removeMutate } = useMutation({
    mutationFn: removeProductFromCart
  });
  const { data: profileData } = useQuery({
    queryFn: getCustomer,
    queryKey: [QUERY_KEYS.CUSTOMER_PROFILE]
  });
  const setCartState = useSetRecoilState(cartListAtom);

  const cartQuery = useQuery({
    queryKey: [QUERY_KEYS.CART, userRole],
    queryFn: () => viewCart(userRole === 'BUSINESS' ? 'BUSINESS' : 'REGULAR'),
    onSuccess: data => {
      const serverCart: CartItem[] = [];
      const localCartStr = localStorage.getItem(LOCALSTORAGE_KEYS.CART);
      const localCart: CartItem[] | undefined = localCartStr !== null ? JSON.parse(localCartStr) : undefined;
      if (data.data) {
        serverCart.push(
          ...(data.data ?? []).map(item => {
            return {
              cartId: item.id,
              id: item.product.id,
              name: item.product.productName,
              amount: item.product.price,
              quantity: item.quantity,
              photo: item.product.productImage1,
              quantityAvailable: item.product.quantityAvailable
            };
          })
        );
        if (profileData !== undefined && localStorage.getItem(LOCALSTORAGE_KEYS.CART) !== null) {
          syncLocalAndServerCart(
            serverCart,
            addMutate,
            updateMutate,
            removeMutate,
            setCartState,
            userRole === 'BUSINESS' ? 'BUSINESS' : 'REGULAR'
          );
        } else {
          setCartState(serverCart);
        }
      } else {
        setCartState(localCart ?? []);
      }
    }
  });

  return cartQuery;
};
