import { SlOptions } from 'react-icons/sl';
import { Popover } from '@mantine/core';
import { SubscriptionHistory } from 'TYPES/api.body';
import React from 'react';
import { UseMutateAsyncFunction } from 'react-query';

interface Props {
  sub: SubscriptionHistory;
  setSubAction: React.Dispatch<
    React.SetStateAction<
      | {
        action: 'upgrade' | 'downgrade';
        sub: SubscriptionHistory;
      }
      | undefined
    >
  >;
  cancelSub: UseMutateAsyncFunction<any, unknown, string, unknown>;
}

const ActiveSubscription: React.FC<Props> = ({ sub, setSubAction, cancelSub }) => {
  const [open, setOpen] = React.useState(false);

  const setSubActionFactory = (action: 'upgrade' | 'downgrade') => () => {
    setSubAction({ action, sub });
    setOpen(false);
  };

  return (
    <div key={sub.subscriptionRef} className="flex flex-col gap-2">
      <div className="flex items-center flex-wrap justify-between">
        <Popover opened={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
          <Popover.Target>
            <button type="button" onClick={() => setOpen(true)} className="flex items-center cursor-pointer gap-2">
              <h3 className="font-semibold text-primary text-xl">Madina Plus: {sub.packageName}</h3>
              <SlOptions />
            </button>
          </Popover.Target>
          <Popover.Dropdown>
            <div className="flex flex-col gap-2 items-start">
              <button onClick={setSubActionFactory('upgrade')} type="button">
                Upgrade
              </button>
              <button onClick={setSubActionFactory('downgrade')} type="button">
                Downgrade
              </button>
              <button
                onClick={() => {
                  cancelSub(sub.subscriptionRef);
                  setOpen(false);
                }}
                type="button"
                className="text-red-500">
                Cancel subscription
              </button>
            </div>
          </Popover.Dropdown>
        </Popover>
        <span className="font-semibold">&#8358;{sub.subscriptionAmount}</span>
      </div>
      <div className="flex font-light items-center text-sm gap-1 sm:gap-6 flex-wrap">
        <div>
          Last Billing: <span className="font-normal">{sub.lastBilling}</span>
        </div>
        <div>
          Next Billing: <span className="font-normal">{sub.nextBilling}</span>
        </div>
      </div>
    </div>
  );
};

export default ActiveSubscription;
