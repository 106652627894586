import { atom, selector } from 'recoil';
import { cartListAtom } from '../atoms/cartAtom';
import { STORE_KEYS } from '../keys';

export const cartItemLength = selector<number>({
  key: STORE_KEYS.CART_LENGTH,
  get: ({ get }) => {
    const list = get(cartListAtom);
    const length = list.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
    return length;
  }
});

export const cartTotalAmount = selector<number>({
  key: STORE_KEYS.CART_AMOUNT_TOTAL,  
  get: ({ get }) => {
    const list = get(cartListAtom);
    const total = list.reduce((total, { quantity, amount }) => {
      return (total = total + quantity * amount);
    }, 0);
    return total;
  }
});
export const WasAttemptingCheckout = atom<boolean>({
  key: STORE_KEYS.WASATTEMPTINGCHECKOUT,
  default: false
});

