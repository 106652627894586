import { styled } from '@mui/material';
import { NavLink as Link } from 'react-router-dom';
import { COLORS } from '../../../theme/color';

export const NavLinkCon = styled(Link)`
  width: 100%;
  background-color: ${COLORS.White};
  padding: 0.5rem 2rem 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
  color: ${COLORS.MainBlack};
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  text-transform: capitalize;

  .navIcon {
    background-color: #f8f7f7;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    border-radius: 1rem;
    overflow: hidden;
    margin-right: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  /* svg {
    margin-right: 1rem;
  } */

  &:hover {
    background-color: ${COLORS.SecBlue};
  }
`;

// export const active = {
//   backgroundColor: `${COLORS.MainBlue}`,
//   color: '#fff'
// };
