import { useRecoilState } from 'recoil';
import { LoanApproved } from './components/loanApproved';
import { AuthorizeRequest } from './components/authorize';
import VerifyIdentityModal from './components/VerifyIdentityModal';
import { buyNowPayLaterStepsAtom } from 'store/atoms/buyNowPayLaterAtom';

export const BuyNowPayLater = () => {
  const [step, setCurrentStep] = useRecoilState(buyNowPayLaterStepsAtom);

  return (
    <div>
      {step === 0.5 && <VerifyIdentityModal nextStep={setCurrentStep} setModalIsOpen={setCurrentStep} />}
      {step === 1 && <AuthorizeRequest nextStep={setCurrentStep} setIsModalOpen={setCurrentStep} />}
      {step === 2 && <LoanApproved nextStep={setCurrentStep} setIsModalOpen={setCurrentStep} />}
    </div>
  );
};
