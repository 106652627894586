import QUERY_KEYS from 'network/config/queryKeys';
import { getFavouriteProducts } from 'network/services/goods';
import { ProductsGrid } from 'pages/Products';
import { useQuery } from 'react-query';
import { FavouriteCON } from './styles';

export const Favourite = () => {
  const { data, isLoading } = useQuery({ queryFn: getFavouriteProducts, queryKey: [QUERY_KEYS.FAVOURITE_PRODUCTS] });

  return (
    <FavouriteCON>
      <div className="header">My Favourite</div>
      <ProductsGrid
        emptyMessage="Favourite is empty"
        isLoading={isLoading}
        products={(data?.data ?? []).map(item => ({
          ...item,
          productImage1: item.productImage1 ?? '/graphics/full_logo.svg',
          description: item.description ?? '',
          barCode: item.barCode ?? ''
        }))}
      />
    </FavouriteCON>
  );
};
