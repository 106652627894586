import { RxCopy } from "react-icons/rx";
import { BsReddit } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { enqueueSnackbar } from "notistack";
import { TfiTwitterAlt } from "react-icons/tfi";
import { Modal } from "components/molecules";
import SEARCH_PARAMS from "constants/searchParams";
import { Link, useSearchParams } from "react-router-dom";

const FACEBOOK_BASE = "https://www.facebook.com/sharer/sharer.php?u=";
const TWITTER_BASE = "https://twitter.com/intent/tweet?url=";
const LINKEDIN_BASE = "https://www.linkedin.com/sharing/share-offsite/?url=";
const WHATSAPP_BASE = "https://wa.me/?text=";
const REDDIT_BASE =
	"https://www.reddit.com/submit?title=Shared+From+Madina.ng&url=";
const ShareModal = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const shareUrl = searchParams.get(SEARCH_PARAMS.SHARE_URL);
	const url = decodeURIComponent(shareUrl ?? "");

	const handleCopyFactory =
		(type: "Facebook" | "Twitter" | "Instagram" | "Regular") => () => {
			if (type === "Regular") {
				navigator.clipboard.writeText(url);
			}
			enqueueSnackbar("Link Coppied to Clipboard", { variant: "info" });
		};
	return (
		<Modal
			className="!max-w-[500px]"
			onModalClose={() =>
				setSearchParams((c) => {
					c.delete(SEARCH_PARAMS.SHARE_URL);
					return c;
				})
			}
		>
			<h1 className="font-semibold text-2xl pb-4">Share</h1>
			<div className="flex items-center gap-2 mb-4">
				<Link
					to={FACEBOOK_BASE.concat(encodeURIComponent(url))}
					target="_blank"
					className="text-blue-900 text-3xl border border-black/30 p-1 rounded-full aspect-square"
					title="Share to Facebook"
				>
					<BsFacebook />
				</Link>
				<Link
					to={TWITTER_BASE.concat(encodeURIComponent(url))}
					target="_blank"
					className="text-blue-400 text-xl border border-black/30 p-2 rounded-full aspect-square"
					title="Share to Twitter"
				>
					<TfiTwitterAlt />
				</Link>
				<Link
					to={LINKEDIN_BASE.concat(encodeURIComponent(url))}
					target="_blank"
					className="text-blue-800 text-xl border border-black/30 p-2 rounded-full aspect-square"
					title="Share to Linkedin"
				>
					<BsLinkedin />
				</Link>
				<Link
					to={WHATSAPP_BASE.concat(encodeURIComponent(url))}
					target="_blank"
					className="text-green-700 text-xl border border-black/30 p-2 rounded-full aspect-square"
					title="Share to WhatsApp"
				>
					<BsWhatsapp />
				</Link>
				<Link
					to={REDDIT_BASE.concat(encodeURIComponent(url))}
					target="_blank"
					className="text-orange-700 text-3xl border border-black/30 p-1 rounded-full aspect-square"
					title="Share to Redit"
				>
					<BsReddit />
				</Link>
			</div>
			<div className="flex items-center border-2 p-1 overflow-hidden border-black/60 rounded-3xl justify-between">
				<span className="whitespace-nowrap max-w-[80%] px-2 overflow-x-auto">
					{url}
				</span>
				<button
					type="button"
					title="Copy to Clipboard"
					onClick={handleCopyFactory("Regular")}
					className="bg-primary flex items-center text-sm gap-2 p-1.5 px-3 rounded-full text-white"
				>
					<span>Copy</span>
					<RxCopy />
				</button>
			</div>
		</Modal>
	);
};

export default ShareModal;
