import { MenuItem, Radio, RadioGroup, SelectChangeEvent } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { COMMONTYPES } from "TYPES/event.types";
import {
  Button,
  CustomSelect,
  Spacer,
  StyledFormLabel,
} from "components/atoms";
import { Modal } from "components/molecules/Modal/Modal";
import {
  CenterModalContentStyle,
  CenterModalStyle,
} from "components/molecules/Modal/style";
import dayjs, { Dayjs } from "dayjs";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useSetRecoilState } from "recoil";
import { checkoutDetailsAtom } from "store/atoms/checkoutAtom";
import { convertTimeStringToDate } from "utils/dateFormater";
import { CustomDateCalender, ScheduleModalCon } from "./style";

type ScheduleModalProp = {
  onOpenChange: (open: boolean) => void;
};
const LaterTimeArray = [
  "08:00 am",
  "09:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 noon",
  "13:00 pm",
  "14:00 pm",
  "15:00 pm",
  "16:00 pm",
  "17:00 pm",
  "18:00 pm",
  "19:00 pm",
  "20:00 pm",
];

const getTimes = (): string[] => {
  const currHour = new Date().getHours();
  const hours = Array.from({ length: 24 })
    .map((_, i) => i + 1)
    .filter((i) => i + 1 > 5 && i + 1 < 22 && i + 1 > currHour + 1);
  return hours.map((i) =>
    i < 12 ? `${i}:00 am` : i === 12 ? `${12}:00 noon` : `${i - 12}:00 pm`,
  );
};

export const ScheduleModal = ({ onOpenChange }: ScheduleModalProp) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const [schedule, setSchedule] = React.useState<"Today" | "Later">("Today");
  const [date, setDate] = React.useState<Dayjs>(dayjs(tomorrow));
  const setCheckoutDetails = useSetRecoilState(checkoutDetailsAtom);
  const timeArray = React.useMemo(getTimes, []);
  const [time, setTime] = React.useState(() =>
    timeArray.length > 0 ? timeArray[0] : "",
  );

  const setModalClose = () => {
    onOpenChange(false);
  };

  const changeSchedule = (e: COMMONTYPES["buttonClick"]) => {
    e.preventDefault();
    if (schedule === "Today") {
      const deliveryTime = convertTimeStringToDate(time);
      setCheckoutDetails((curr) => ({
        ...curr,
        scheduleDate: deliveryTime?.toISOString() ?? null,
      }));
    } else {
      const deliveryDateTime = convertTimeStringToDate(time, date.toDate());
      if (deliveryDateTime === null) {
        enqueueSnackbar(`Failed to schedule delivery`, { variant: "error" });
      } else {
        setCheckoutDetails((curr) => ({
          ...curr,
          scheduleDate: deliveryDateTime.toISOString(),
        }));
      }
    }
    onOpenChange(false);
  };

  const onTimeSelect = (e: SelectChangeEvent<unknown>) => {
    setTime(e.target.value as string);
  };

  const onSelectDate = (value: unknown) => {
    const date = value as Dayjs;
    if (date.toDate() <= today) {
      enqueueSnackbar("You cannot set a date less that tommorow", {
        variant: "warning",
      });
      return;
    }
    setDate(date);
  };

  return (
    <Modal
      onModalClose={setModalClose}
      extraModalContentStyle={CenterModalContentStyle}
      extraModalStyle={CenterModalStyle}
    >
      <ScheduleModalCon>
        <div>
          <h3>Schedule My Order</h3>
          <RadioGroup
            row
            value={schedule}
            onChange={(e) => setSchedule(e.currentTarget.value as any)}
          >
            <StyledFormLabel
              value={"Today"}
              control={<Radio />}
              label="Today"
              checked={schedule === "Today"}
            />
            <Spacer width={20} />
            <StyledFormLabel
              value={"Later"}
              control={<Radio />}
              label="Later"
              checked={schedule === "Later"}
            />
          </RadioGroup>
          {schedule === "Later" && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDateCalender
                value={date}
                onChange={onSelectDate}
                disablePast
              />
            </LocalizationProvider>
          )}
          <Spacer height={32} />
          <p className="select-time">Select time</p>
          <CustomSelect
            value={time}
            onChange={onTimeSelect}
            onClick={(e) => e.stopPropagation()}
          >
            {schedule === "Today" &&
              timeArray.map((time, index) => (
                <MenuItem value={time} key={index}>
                  {time}
                </MenuItem>
              ))}
            {schedule !== "Today" &&
              LaterTimeArray.map((time, index) => (
                <MenuItem value={time} key={index}>
                  {time}
                </MenuItem>
              ))}
          </CustomSelect>
          <Spacer height={32} />
        </div>
        <Button onClick={changeSchedule}>
          Deliver at {time.toUpperCase()}
        </Button>
      </ScheduleModalCon>
    </Modal>
  );
};
