import { styled } from '@mui/material';
import { BasicBox } from '../styles';

export const VerifyIdentityCon = styled(BasicBox)`
  max-width: 100%;
`;

export const ContentCon = styled('div')`
  margin: 0 20px;
  min-height: 200px;

  .indicator {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 1px solid #2f4dc4;
  }
`;

export const BtnExtraStyles = {
  marginBottom: '30px',
  padding: '24px 16px',
  // width: '152px',
  height: '48px',
  backgroundColor: 'transparent',
  textAlign: 'left',
  color: '#180101',
  fontSize: '14px',
  fontWeight: '400',
  borderRadius: '4px',
  border: '1px solid #DADADA',
  justifyContent: 'flex-start'
};
