export const STORE_KEYS = {
  ORDER: '@order',
  POINTS: '@points',
  MODALS: '@modals',
  VENDOR: '@vendor',
  LIVE_CHAT: '@liveChat',
  USER_ROLE: '@userRole',
  WISH_LIST: '@wishList',
  USER_ATOM: '@userAtom',
  CART_LIST: '@cartList',
  BVN_LOOKUP: '@bvnLookup',
  VERIFY_BVN: '@verifyBVN',
  CART_LENGTH: '@cartLength',
  IS_CART_OPEN: '@IsCartOpen',
  IS_LOGGED_IN: '@IsLoggedIn',
  GOOD_DETAIL: '@goodsDetails',
  USER_PROFILE: '@userProfile',
  LOAN_TENURES: '@loanTenures',
  CONTACT_INFO: '@contactInfo',
  BUSINESS_KYB: '@businessKYB',
  SEARCH_QUERY: '@searchQuery',
  USER_ADDRESS: '@userAddress',
  LOAN_SETTINGS: '@loanSettings',
  USER_LOCATION: '@userLocation',
  LOAN_INSURANCE: '@loanInsurance',
  USER_TOKEN_ATOM: '@userTokenAtom',
  CHECKOUT_DETAILS: '@checkoutDetails',
  WASATTEMPTINGCHECKOUT : '@checkoutattempt',
  CART_AMOUNT_TOTAL: '@cartAmountTotal',
  SELECTED_CARRIERS: '@selectedCarriers',
  RECURRING_DELIVERY: '@recurringDelivery',
  CHECKOUT_VARIABLES: '@checkoutVariables',
  IS_CHECKOUT_LOADING: '@IsCheckoutLoading',
  IS_GOOD_OPEN_DETAIL: '@IsgoodsDetailsOpen',
  REOCCURING_DELIVERY: '@reoccurringDelivery',
  VERIFY_IDENTITY_STEPS: '@verifyIdentitySteps',
  UPDATE_BUSINESS_AUTH_STEP: '@businessAuthStep',
  BUY_NOW_PAY_LATER_STEPS: '@buyNowPayLaterSteps',
  EMPTY_CATEGORY_PRODUCTS: '@emptyCategoryProducts'
  // USER_TOKEN_SELECTOR:'@userTokenSelector',
} as const;
