import { Box, styled, Tab, Tabs } from '@mui/material';
import { COLORS } from '../../theme/color';
import { breakpoints } from '../../theme/theme';

export const AuthContainer = styled('div')`
  max-width: 40rem;
  margin: 3rem auto 0;
  padding: 0 2rem;

  h1 {
    line-height: 54px;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
  }
  .forgot {
    margin-bottom: 2rem;
    display: flex;
    justify-content: end;
  }
  .option {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #483d3d;
    margin: 1rem 0;
    span {
      font-weight: 500;
      color: ${COLORS.MainBlue};
      cursor: pointer;
    }
  }
  .resend {
    color: ${COLORS.Disabled};
    pointer-events: none;
  }
  form {
    padding: 2rem 0 5rem;
  }
  .policy {
    margin-bottom: 2rem;
    display: flex;
    justify-content: start;

    input {
      margin-right: 1rem;
    }
  }
  .otp-form {
    padding: 0;
  }
  .buttonContainer button {
    font-size: 18px;
    font-weight: 500;
  }
  .name-grid {
    display: grid;
  }
  @media (max-width: ${breakpoints.sm}px) {
    h1 {
      font-size: 32px;
    }
    .option {
      font-size: 16px;
    }
    .buttonContainer button {
      font-size: 16px;
    }
  }
`;
export const AltSignUp = styled(Box)`
  display: flex;
  button {
    font-style: normal;
    font-weight: 700;
    font-size: 15.4483px;
    line-height: 26px;
    text-align: center;
    color: #fff;
    svg {
      margin-right: 2rem;
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const LineBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
`;
export const Line = styled(Box)`
  height: 0;
  border: 0.5px solid #e9e5e5;
  width: 100%;
  margin: 0 1rem;
`;
export const InputCon = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0.5rem 0;
  }
`;
export const TogglePassword = styled(Box)`
  width: 1.5rem;
  cursor: pointer;
  height: 1.5rem;
  display: flex;
  align-items: center;
`;
export const OtpContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  input {
    border-radius: 0.5rem;
    border: none;
    width: 80%;
    height: 5rem;
    text-align: center;
    margin: 0 0.4rem;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 41px;
    color: #666666;
    outline: 2px solid ${COLORS.MainGray};
  }
  @media (max-width: ${breakpoints.sm}px) {
    input {
      margin: 0 0.3rem;
      max-height: 12vw;
    }
  }
`;
export const otpInput = {
  borderRadius: '0.5rem',
  border: `2px solid ${COLORS.MainGray}`,
  width: '80%',
  height: '4rem',
  padding: '1rem',
  textAlign: 'center'
};
export const extraStylesBtn = {
  color: '#180101',
  background: '#fff',
  border: '2px solid #f2f2f6'
};

export const extraStylesInput = {
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  width: '100%',
  height: '56px',
  background: '#F1F1F1',
  opacity: '0.8',
  border: '1px solid #CCCCCC'
};

export const extraStylesInputPhone = {
  ...extraStylesInput,
  paddingLeft: '3.5rem'
};

export const CustomAuthTab = styled(Tab)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '26.483px',
  textTransform: 'capitalize',
  color: '#777777',

  fontStyle: 'normal',
  '&.Mui-selected': {
    color: '#000',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'capitalize',
    backgroundColor: `${COLORS.White}`,
    borderRadius: '23px',
    width: '162px',
    [theme.breakpoints.down('sm')]: {
      width: '110px'
    },
    marginTop: '5px',
    height: '36px',
    minHeight: '36px',
    boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.08)'
  }
}));

export const CustomAuthTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: `${COLORS.MildestGray}`,
  padding: '0px 50px 0px 50px',
  height: '46px',
  borderRadius: '23px',
  minHeight: 'max-content',
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  [theme.breakpoints.down('sm')]: {}
}));
