import { Icon } from '@iconify/react';
import { Theme, useMediaQuery } from '@mui/material';
import { Button } from 'components/atoms';
import { Modal } from 'components/molecules/Modal/Modal';
import { CenterModalContentStyle, CenterModalStyle } from 'components/molecules/Modal/style';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import modalAtom from 'store/atoms/modals';
import { FeedbackModalCon } from './style';

export const RejectPaymentModal = () => {
  const navigate = useNavigate();
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const setModalState = useSetRecoilState(modalAtom);

  const setModalClose = () => {
    setModalState(c => ({
      ...c,
      paymentFailureModalOpen: false,
      paymentSuccessModalOpen: false
    }));
  };

  const handleReturnToPayment = () => {
    navigate('/checkout');
    setModalClose();
  };

  return (
    <Modal
      onModalClose={setModalClose}
      extraModalContentStyle={{
        ...CenterModalContentStyle,
        width: sm ? '500px' : ''
      }}
      extraModalStyle={CenterModalStyle}>
      <FeedbackModalCon>
        <div className="icon-wrap reject text-8xl text-red-700 flex items-center justify-center">
          <Icon icon="line-md:close-small" />
        </div>
        <h3 className="font-bold text-2xl mt-8">Order Placement Failed</h3>
        <p className="text-black/50 font-light my-4 text-sm">Perhaps try a different payment method</p>
        <Button onClick={handleReturnToPayment}>Return to Payment</Button>
      </FeedbackModalCon>
    </Modal>
  );
};
