import ContactDetails from './ContactDetails';
import DeliveryDetails from './DeliveryDetails';
import CheckoutSummary from './CheckoutSummary';
import LogisticsDetails from './LogisticsDetails';
import BusinessPaymentModal from './BusinessPaymentModal';

const CheckoutDetails = {
  Contact: ContactDetails,
  Summary: CheckoutSummary,
  Delivery: DeliveryDetails,
  Logistics: LogisticsDetails,
  BusinessPaymentModal: BusinessPaymentModal
};

export default CheckoutDetails;
