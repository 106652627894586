import { useQuery } from 'react-query';
import { MessageList } from '../../../components/molecules';
import { getInboxMessages } from '../../../network/services/inbox';
import { EmptyCON } from '../styles';
import { InboxCon } from './styles';

export const Inbox = () => {
  const { data } = useQuery('inbox', getInboxMessages, { staleTime: Infinity, retry: false });

  const inboxData: any[] = data?.data?.data || [];

  return (
    <InboxCon>
      {inboxData.length ? (
        <>
          <div className="header">Inbox</div>
          {inboxData.map((data, index) => {
            return <MessageList data={data} key={index} />;
          })}
        </>
      ) : (
        <EmptyCON>Empty Inbox</EmptyCON>
      )}
    </InboxCon>
  );
};
