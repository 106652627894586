import { BNPLDetails, LoanTenureDuration } from 'TYPES/api.body';
import { useMutation } from 'network/mutations/useMutationHook';
import { checkoutWithBNPL } from 'network/services/checkout';
import { enqueueSnackbar } from 'notistack';
import usePayment from 'pages/Checkout/hooks';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { buyNowPayLaterStepsAtom } from 'store/atoms/buyNowPayLaterAtom';
import { checkoutDetailsAtom, loanInsuranceAtom, loanSettingsAtom } from 'store/atoms/checkoutAtom';
import modalAtom from 'store/atoms/modals';

export type LoanTenureType = {
  id: number;
  tenure: LoanTenureDuration;
  percent: number;
};

export const calcPercentage = (percentage: number, amount: number) => (amount * percentage) / 100;

export const useLoanRequest = () => {
  const loanSettings = useRecoilValue(loanSettingsAtom);
  const loanInsurance = useRecoilValue(loanInsuranceAtom);
  const { handleOrderSuccess, handleOrderFailure } = usePayment();
  const { mutateAsync, isLoading } = useMutation(checkoutWithBNPL);
  const setBuyNowPayLaterStep = useSetRecoilState(buyNowPayLaterStepsAtom);
  const [checkoutDetails, setCheckoutDetails] = useRecoilState(checkoutDetailsAtom);
  const [selectedLoanTenure, setSelectedLoanTenure] = React.useState<LoanTenureType | null>(null);
  const setModalState = useSetRecoilState(modalAtom);

  const calculatedLoanInsurance = React.useMemo(
    () => calcPercentage(loanInsurance.total, checkoutDetails.grandTotal),
    [checkoutDetails.grandTotal, loanInsurance.total]
  );

  const calculatedLoanAmountWithInterest = React.useMemo(
    () => checkoutDetails.grandTotal + calculatedLoanInsurance,
    [calculatedLoanInsurance, checkoutDetails.grandTotal]
  );

  const calculatedPartPayment = React.useMemo(
    () => calcPercentage(parseFloat(loanSettings.part_payment_percentage) * 100, calculatedLoanAmountWithInterest),
    [calculatedLoanAmountWithInterest, loanSettings.part_payment_percentage]
  );

  /** balance_payment_% of totalProductAmount + loanInsuranceFee */
  const calculatedBalancePayment = React.useMemo(
    () => calcPercentage((loanSettings.balance_percentage as number) * 100, calculatedLoanAmountWithInterest),
    [calculatedLoanAmountWithInterest, loanSettings.balance_percentage]
  );

  const performLoanRequest = () => {
    setCheckoutDetails(curr => ({
      ...curr,
      paymentType: 'BNPL',
      paymentStatus: 'pending'
    }));
    const formData: BNPLDetails = {
      // totalAmount = totalProductAmount + shippingFee + loanInsuranceFee
      totalAmount: checkoutDetails.grandTotal + calculatedLoanInsurance,
      totalProductAmount: checkoutDetails.grandTotal,
      loanInsuranceFee: calculatedLoanInsurance,
      loanTenure: selectedLoanTenure!.tenure,
      partPayment: calculatedPartPayment,
      balancePayment: calculatedBalancePayment,
      orderRequest: {
        ...checkoutDetails,
        paymentType: 'BNPL',
        paymentStatus: 'pending',
        paymentTrxRef: `trx${Date.now()}`
      }
    };

    setModalState(c => ({
      ...c,
      paymentModalOpen: false,
      paymentFailureModalOpen: false,
      paymentSuccessModalOpen: false,
      transactionInProgressModalOpen: true
    }));
    mutateAsync(formData)
      .then((data: any) => {
        if (data.data.status === 'failed') {
          enqueueSnackbar('BNPL Loan Request Failed', { variant: 'error' });
        } else {
          setBuyNowPayLaterStep(0);
          handleOrderSuccess();
        }
      })
      .catch(error => {
        handleOrderFailure(error);
      });
  };

  return {
    performLoanRequest,
    selectedLoanTenure,
    isPending: isLoading,
    calculatedPartPayment,
    setSelectedLoanTenure,
    calculatedLoanInsurance,
    calculatedBalancePayment,
    calculatedLoanAmountWithInterest
  };
};
