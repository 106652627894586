import { styled } from '@mui/material';
import { breakpoints } from '../../theme/theme';

export const HomePageCon = styled('div')`
  width: 100vw;
  display: flex;
  background: #f8f7f7;
  flex-direction: column;
  overflow-x: hidden;
  @media (max-width: ${breakpoints.sm}px) {
    padding: 0px 0px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 0px 0px;
  }
`;

export const HeroCon = styled('div')<{ bg: any }>(({ theme, bg }) => ({
  background: `url(${bg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  padding: '0.5rem',
  height: '45rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    height: '60rem'
  },
  '.hero-content': {
    h3: {
      fontWeight: '600',
      fontSize: '3rem',
      lineHeight: '3.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '4rem'
      }
    },
    p: {
      fontWeight: '400',
      fontSize: '1.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem'
      }
    },
    '.location': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '700px',
      alignSelf: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        gap: '25px'
      },
      '.input': {
        width: '50%',
        [theme.breakpoints.up('md')]: {
          width: '100%'
        }
      }
    }
  }
}));

export const HeroRightCon = styled('div')`
  width: 100%;
  text-align: center;
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
  h3 {
    color: #fff;
    font-family: 'Outfit';
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 30px;
      font-weight: 600;
      line-height: 32px;
    }
  }

  .location {
    width: 418px;
    padding-top: 60px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: ${breakpoints.md}px) {
      padding-top: 32px;
      width: 100%;
    }
    @media (max-width: ${breakpoints.sm}px) {
      padding-top: 18px;
      width: 100%;
    }
  }

  .MuiInput-root input {
    min-width: 100%;
    border: 1px solid blue;
    padding: 0px;
    padding-left: 24px;
    background: #fff;
    border-radius: 12px 0px 0px 12px;
    border: 1px solid #fff;
    height: 60px;
    @media (max-width: ${breakpoints.sm}px) {
      min-width: fit-content;
    }
  }

  p {
    color: #fff;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const HeroLeftCon = styled('div')`
  width: 100%;
  height: 700px !important;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: ${breakpoints.md}px) {
    padding-top: 32px;
  }
`;

export const ProductsCon = styled('div')`
  height: auto;
  width: 100%;
  padding: 61px 0px;

  .sub-title {
    margin-top: 20px;
    color: #1ab66e;
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 32px;
    letter-spacing: 0.12px;
  }
  h3 {
    color: #272d4e;
    font-family: Outfit;
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    @media (max-width: ${breakpoints.md}px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const MadinaDiffrenceCon = styled('div')`
  padding: 106px 40px 60px 40px;
  .card-con {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  h3 {
    color: #272d4e;
    text-align: center;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const MadinaDiffrenceCard = styled('div')<{ index: number }>`
  width: 305px;
  height: 216px;
  background: ${props => (props.index === 0 ? '#0095FF' : '#1AB66E')};
  display: flex;
  justify-content: center;
  padding: 19px;
  border-radius: 12px;
  flex-direction: column;
  .title {
    color: #fff;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .desc {
    color: #fff;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const UseLocationExtraStyles = {
  borderRadius: '0px 12px 12px 0px',
  gap: '10px',
  width: '200px',
  background: '#1AB66E',
  height: '61px'
};

export const SubHero = styled('div')(({ theme }) => ({
  padding: '32px',
  '.content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [theme.breakpoints.up('md')]: {
      alginItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      gap: '2.5rem'
    }
  }
}));

export const SubHeroCard = styled('div')(({ theme, color }) => ({
  display: 'flex',
  flexDirection: 'column',
  alginItems: 'start',
  '.card-icon': {
    marginLeft: '10px',
    width: '37px',
    height: '37px',
    padding: '7px 6px 6px 7px',
    borderRadius: '4px',
    background: color,
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  '.title': {
    paddingTop: '21px',
    fontfamily: 'Inter',
    fontSize: '1.1rem',
    color: '#101828',
    fontWeight: '500',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center'
    }
  },
  '.desc': {
    fontfamily: 'Inter',
    fontSize: '1.2rem',
    color: '#667085',
    lineHeight: '24px',
    fontWeight: '400',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center'
    }
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '370px'
  }
}));

export const SupportSection = styled('div')(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '4rem',
  margin: '24px',
  '.content': {
    alignItems: 'center',
    placeItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: '32px',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '20px'
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      margin: '30px'
    }
  },
  [theme.breakpoints.down('md')]: {
    margin: '19px',
    marginBottom: '5rem'
  }
}));

export const SupportSectionCard = styled('div')(({ theme, color }) => ({
  '.card-icon': {
    width: '50px',
    height: '50px',
    display: 'flex',
    padding: '7px 6px 6px 7px',
    borderRadius: '4px',
    background: color,
    alignItems: 'center',
    justifyContent: 'center'
  },
  '.title': {
    paddingTop: '21px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    fontfamily: 'Inter',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '500'
  },

  '.desc': {
    fontfamily: 'Inter',
    fontSize: '16px',
    color: '#667085',
    lineHeight: '24px',
    fontWeight: '400'
  },
  borderRadius: '10px',
  border: '1px solid #27AE60',
  padding: '1rem',
  height: '100%',
  maxHeight: '190px',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '500px'
  },

  [theme.breakpoints.down('md')]: {
    padding: '24px 16px'
  }
}));
