import { CircularProgress, Skeleton } from "@mui/material";
import { Product } from "TYPES/api.body";
import { LikeIcon, LogoIcon } from "assets/Icons";
import { Button, ProductQtyCounter, Spacer } from "components/atoms";
import SEARCH_PARAMS from "constants/searchParams";
import {
	addProductToCart,
	removeProductFromCart,
	updateCartItemQty,
} from "network/mutations/products";
import { getProductDetail } from "network/services/goods";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { HiOutlineShare } from "react-icons/hi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { CartItem, cartListAtom } from "store/atoms/cartAtom";
import { wishListState } from "store/atoms/productDetail";
import { profileAtom } from "store/atoms/profileAtom";
import { userRoleAtom } from "store/atoms/userAtom";
import { formatCurrency } from "utils/currencyFormater";
import { Modal } from "../Modal/Modal";
import {
	AddToCart,
	GoodSDetails,
	ProductDsc,
	btn,
	extraModalContentStyle,
} from "./style";
import { localAddProductToCart, performAddProductToCart } from "./utils";

export const GoodsDetailModal = () => {
	const route = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [productDetail, setProductDetail] = React.useState<Product | null>(
		location.state,
	);
	const profile = useRecoilValue(profileAtom);
	const [cartList, setCartList] = useRecoilState(cartListAtom);
	const [wishList, setWishList] = useRecoilState(wishListState);
	const userRole = useRecoilValue(userRoleAtom);
	const [mainImg, setMainImg] = useState("");
	const [quantity, setQuantity] = useState(1);
	const wishitemIndex = wishList.findIndex(
		(item) => item.id === productDetail?.productId,
	);
	const isItemInWishlist = wishitemIndex >= 0;
	const queryClient = useQueryClient();
	const { isLoading: detailLoading } = useQuery({
		queryFn: () =>
			getProductDetail(parseInt(searchParams.get("productId") ?? "1")),
		onSuccess: (data) => {
			if (productDetail === null) {
				setProductDetail(data.data ?? null);
			}
		},
		onError: (err) => {
			enqueueSnackbar({ message: (err as any).message });
		},
	});

	const {
		mutateAsync: addMutate,
		error: addToCartError,
		isLoading,
	} = useMutation({ mutationFn: addProductToCart });
	const { mutateAsync: updateMutate } = useMutation({
		mutationFn: updateCartItemQty,
	});
	const { mutateAsync: removeMutate } = useMutation({
		mutationFn: removeProductFromCart,
	});

	const productPrice =
		(userRole === "BUSINESS"
			? productDetail?.bulkOrderPrice
			: productDetail?.price) ?? 0;

	const cartItem: CartItem = {
		name: productDetail?.productName ?? "",
		quantityAvailable: productDetail?.quantityAvailable ?? 0,
		amount: productPrice,
		id: productDetail?.productId ?? 0,
		quantity,
		photo: productDetail?.productImage1 ?? "",
		shortDSC: productDetail?.description ?? "",
	};

	if (addToCartError) {
		enqueueSnackbar((addToCartError as any).message, { variant: "error" });
	}

	const closeModal = () => {
		const search = new URLSearchParams(location.search);
		search.delete("productId");
		const path =
			Array.from(search.entries()).length > 0
				? location.pathname.concat("?", search.toString())
				: location.pathname;
		route(path, { state: undefined });
	};

	const toggleWishList = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.stopPropagation();
		if (isItemInWishlist) {
			const newArray = wishList.filter(
				(item) => item.id !== productDetail?.productId,
			);
			setWishList(newArray);
		} else {
			setWishList((oldWishList) => [
				...oldWishList,
				{
					id: productDetail?.productId ?? 0,
					atrs: [],
					imgs: [productDetail?.productImage1 ?? ""],
					shortDSC: productDetail?.description ?? "",
					name: productDetail?.productName ?? "",
					img: productDetail?.productImage1 ?? "",
					amount: productDetail?.price ?? 0,
					isLiked: false,
				}!,
			]);
		}
	};

	const addToCartItem = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		e.stopPropagation();
		if (profile === undefined) {
      console.log("Local Adding")
			localAddProductToCart(quantity, cartList, cartItem, setCartList);
		} else {
			performAddProductToCart(
				cartList,
				[cartItem],
				addMutate,
				updateMutate,
				removeMutate,
				setCartList,
				queryClient,
			);
		}
		closeModal();
	};

  console.log({profile})

	const addToCartItemForBusiness = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		e.stopPropagation();
		if (profile === undefined) {
      console.log("Local Business Adding")
			localAddProductToCart(quantity, cartList, cartItem, setCartList);
		} else {
			performAddProductToCart(
				cartList,
				[cartItem],
				addMutate,
				updateMutate,
				removeMutate,
				setCartList,
				queryClient,
				"BUSINESS",
			);
		}
		closeModal();
	};

	useEffect(() => {
		if (productDetail) {
			setMainImg(productDetail.productImage1);
		}
	}, [productDetail]);

	const disabled =
		(quantity < (productDetail?.minimumOrderQuantity ?? 0) &&
			userRole === "BUSINESS") ||
		(productDetail === null && detailLoading);

	return (
		<Modal
			onModalClose={closeModal}
			extraModalContentStyle={extraModalContentStyle}
		>
			<GoodSDetails>
				<div className="right">
					<div className="imageCon">
						{mainImg ? <img src={mainImg} alt="productImage" /> : <LogoIcon />}
					</div>
					<Spacer height={20} />
					{/* productDetail.productImage1 */}
					<div className="flex  gap-3 items-center">
						{productDetail?.productImage2 && (
							<img
								src={productDetail?.productImage2}
								width={90}
								height={90}
								alt="productImage"
								className="rounded-2xl"
							/>
						)}
						{productDetail?.productImage3 && (
							<img
								src={productDetail?.productImage3}
								width={90}
								height={90}
								alt="productImage"
								className="rounded-2xl"
							/>
						)}
						{productDetail?.productImage4 && (
							<img
								src={productDetail?.productImage4}
								width={90}
								height={90}
								alt="productImage"
								className="rounded-2xl"
							/>
						)}
					</div>
				</div>
				<div className="left">
					<h3>{productDetail?.category?.name ?? ""}</h3>
					<Spacer height={"1.2rem"} />

					{!productDetail && detailLoading ? (
						<Skeleton height={50} width={200} />
					) : (
						<h1>{productDetail?.productName}</h1>
					)}
					<Spacer height={"1.2rem"} />

					{!productDetail && detailLoading ? (
						<Skeleton height={50} width={200} />
					) : (
						<h4>{formatCurrency(productDetail?.price ?? 0)}</h4>
					)}
					<Spacer height={"1.2rem"} />
					<div>
						<p className="label">
							Quantity: <span>{productDetail?.quantityAvailable ?? 0}</span>
						</p>
						<Spacer height={"0.5rem"} />
						{userRole === "BUSINESS" && (
							<div>
								<p className="label">
									Minimum Order Quantity:{" "}
									<span>{productDetail?.minimumOrderQuantity ?? 0}</span>
								</p>
								<Spacer height={"0.5rem"} />

								<p className="label">
									Bulk Order Price:{" "}
									<span>
										{formatCurrency(productDetail?.bulkOrderPrice ?? 0)}
									</span>
								</p>
								<Spacer height={"0.5rem"} />
							</div>
						)}

						<Spacer height={"1rem"} />
						<ProductQtyCounter
							max={productDetail?.quantityAvailable}
							customerType={userRole as any}
							value={quantity}
							setValue={setQuantity}
						/>
					</div>
					<Spacer height={"2rem"} />

					<AddToCart $isLiked={isItemInWishlist}>
						<Button
							disabled={disabled}
							extraStyles={btn}
							onClick={
								userRole === "BUSINESS"
									? addToCartItemForBusiness
									: addToCartItem
							}
						>
							{isLoading ? (
								<CircularProgress size={24} color="inherit" />
							) : (
								"Add to cart"
							)}
						</Button>
						<Spacer width={"1rem"} />
						<div className="likeCon">
							<LikeIcon onClick={toggleWishList} />
						</div>
						<button
							title={`Share ${productDetail?.productName}`}
							type="button"
							onClick={() =>
								setSearchParams((c) => {
									c.append(SEARCH_PARAMS.SHARE_URL, window.location.href);
									return c;
								})
							}
							className="text-lg ml-2 p-2 rounded-full bg-black/10 hover:bg-black/20 border border-black/5 hover:border-black/20 transition-all"
						>
							<HiOutlineShare />
						</button>
					</AddToCart>
				</div>
			</GoodSDetails>
			<Spacer height={40} />
			{productDetail?.description && productDetail.description.length > 0 && (
				<ProductDsc>
					<h3>Product description :</h3>
					<Spacer height={10} />
					<div
						dangerouslySetInnerHTML={{ __html: productDetail?.description }}
					/>
				</ProductDsc>
			)}
			<Spacer height={40} />
		</Modal>
	);
};
