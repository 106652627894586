import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const RateCON = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 50px;
  &:last-of-type {
    margin-bottom: 0;
  }

  .rate-product {
    display: flex;
    align-items: center;
    max-width: 426px;
    img {
      width: 120px;
      height: 120px;
      object-fit: center;
      border-radius: 12px;
    }
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 12px;
    }
    .id {
      font-weight: 300;
      font-size: 11px;
      line-height: 14px;
      margin-bottom: 24px;
    }
    .status {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-transform: capitalize;
    }
  }
  @media (max-width: ${breakpoints.lg}px) {
    button {
      max-width: 150px;
    }
    .rate-product {
      img {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        flex-shrink: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.md}px) {
    button {
      max-width: 100%;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    align-items: center;
    .rate-product {
      img {
        width: 70px;
        height: 70px;
        border-radius: 6px;
      }
      .title {
        font-size: 15.5px;
        margin-bottom: 8px;
      }
      .id {
        font-size: 10.5px;
        margin-bottom: 18px;
      }
      .delivered {
        font-size: 11.5px;
      }
    }
  }
`;

export const RateModalCon = styled('div')`
  .header {
    display: flex;
    align-items: center;
    margin: 10px 0 56px;
    img {
      width: 120px;
      height: 120px;
      object-fit: center;
      border-radius: 12px;
      margin-right: 32px;
    }
    .header-content {
      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: ${COLORS.MainBlack};
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: ${COLORS.SecBlack};
        margin: 16px 0 24px 0;
      }
      .start-con {
        display: flex;
        svg {
          margin-right: 4.5px;
        }
      }
    }
  }
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: block;
  }
  textarea {
    width: 442px;
    max-width: 100%;
    height: 147px;
    border: 1px solid #e1dbdb;
    border-radius: 4px;
    margin: 16px 0 56px;
    padding: 10px;
    outline: none;
  }
  button {
    font-weight: 700;
    font-size: 16px;
  }
  @media (max-width: ${breakpoints.sm}px) {
    .header {
      flex-direction: column;
      img {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        margin-right: 0;
      }
      .header-content {
        text-align: center;
        h3 {
          margin-top: 20px;
        }
      }
    }
  }
`;

export const StarButton = styled('button')`
  border: none;
  outline: none;
  background: none;
  &:active,
  &:hover,
  &:disabled {
    border: none;
    outline: none;
    background: none;
  }
  svg {
    cursor: pointer;
  }
`;
