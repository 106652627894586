import { CircularProgress } from '@mui/material';
import { LoanData } from 'TYPES/api.body';
import { BackArrow } from 'assets/Icons';
import { Button, EmptyCon } from 'components/atoms';
import { CancelLoanRequest, repayloanDebt, resubmitBNPL } from 'network/services/loanManagement';
import { enqueueSnackbar } from 'notistack';
import { PaymentModal } from 'pages/Checkout/component';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatCurrency } from 'utils/currencyFormater';
import {
  BNPLDetailsCon,
  BtnExtraStyles,
  CancelBtnStyle,
  ContentBody,
  ContentCon,
  ContentHeader,
  ListItem
} from './styles';

export const BuyNowPayLaterDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const locationState: LoanData = location.state;

  const [paymentOpen, setPaymentOpen] = React.useState(false);

  const { mutateAsync: resubmitBNPLMutate, isLoading: isReSubmitting } = useMutation({
    mutationFn: resubmitBNPL,
    onSuccess: () => {
      enqueueSnackbar('You loan has been resent', { variant: 'success' });
    }
  });
  const { mutateAsync: RepayLoanBNPL } = useMutation(repayloanDebt);
  const { mutateAsync: CancelBNPLlOANL, isLoading: isCanceling } = useMutation({
    mutationFn: CancelLoanRequest,
    onSuccess: () => {
      enqueueSnackbar('Loan cancelled successfully', { variant: 'success' });
    }
  });

  useEffect(() => {
    if (!locationState) {
      navigate(-1); // /account/bnpl
    }
  }, [locationState, navigate]);

  const handleBack = () => {
    navigate(-1);
  };
  const handleTerminate = (id: number) => {
    CancelBNPLlOANL(id);
  };

  const handleRepayLoan = (channel?: string) => {
    if (id) {
      RepayLoanBNPL({
        paymentChannel: channel ?? 'PayStack',
        loanAmount: +locationState.loanAmount,
        loanId: locationState.id
      }).then(data => {
        console.log(data);
      });
    }
  };

  return (
    <>
      <BNPLDetailsCon>
        <div className="header">
          <button className="icon-wrap" onClick={handleBack}>
            <BackArrow />
          </button>{' '}
          BNPL Loan Details
        </div>

        {!locationState && (
          <EmptyCon>
            <CircularProgress size={30} />
          </EmptyCon>
        )}

        {locationState && (
          <ContentCon>
            <ContentHeader>
              <p className="label">First Due Date</p>
              <p className="value">{new Date(locationState.loanPaymentDates[0].paymentDate).toDateString()}</p>
            </ContentHeader>

            <ContentBody>
              <h3 className="heading">Fees</h3>

              <ListItem>
                <p className="label">Loan Amount</p>
                <p className="value">{formatCurrency(locationState.loanAmount)}</p>
              </ListItem>
              <ListItem>
                <p className="label">Interest Rate</p>
                <p className="value">{locationState.loanInterest}%</p>
              </ListItem>
              <ListItem>
                <p className="label">Repayment Amount</p>
                <p className="value">{formatCurrency(locationState.totalAmountWithInterest)}</p>
              </ListItem>
              <ListItem>
                <p className="label">Loan Duration</p>
                <p className="value">{locationState.loanTenure}</p>
              </ListItem>
              <div className="flex lg:flex-row flex-col lg:gap-2 gap-0">
                <Button
                  extraStyles={BtnExtraStyles}
                  onClick={() => {
                    if (locationState.status === 'APPROVED') {
                      resubmitBNPLMutate(locationState.id);
                    }
                    if (locationState.status === 'REDEMMED') {
                      setPaymentOpen(true);
                    }
                  }}
                  isLoading={isReSubmitting}>
                  {locationState.status && locationState.status === 'PENDING'
                    ? 'Resend Request'
                    : locationState.status === 'APPROVED'
                      ? 'Re-Submit'
                      : locationState.status === 'REDEMMED'
                        ? 'Pay Loan'
                        : 'View'}
                </Button>
                {locationState.status !== 'CANCELLED' && (
                  <Button
                    className="bg-red-500"
                    extraStyles={CancelBtnStyle}
                    onClick={() => handleTerminate(locationState.id)}
                    isLoading={isCanceling}>
                    Cancel
                  </Button>
                )}
              </div>
            </ContentBody>
          </ContentCon>
        )}
      </BNPLDetailsCon>
      {paymentOpen && (
        <PaymentModal
          exclude={['bnpl']}
          amount={locationState.loanAmount}
          onSuccess={handleRepayLoan}
          onWalletSuccess={() => handleRepayLoan('Wallet')}
          onClose={() => setPaymentOpen(false)}
        />
      )}
    </>
  );
};
