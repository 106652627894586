import { styled } from '@mui/material';

export const StyledModalContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1210;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled('div')`
  width: 95vw;
  max-width: ${({ $maxWidth }: { $maxWidth?: string }) => ($maxWidth ? $maxWidth : '930px')};
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 90vh;
  padding-top: 0 !important;

  .modalClose {
    background-color: transparent;
    position: sticky;
    top: 0%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    cursor: pointer;
  }
`;

export const ModalContentContainer = styled('div')`
  position: relative;
`;

export const SideModalContentStyle = {
  height: '100vh',
  maxHeight: '100vh',
  maxWidth: '426px',
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px'
};

export const SideModalStyle = {
  alignItems: 'flex-end',
  justifyContent: 'flex-end'
};

export const CenterModalContentStyle = {
  minHeight: '300px',
  maxHeight: '90vh',
  maxWidth: '600px',
  overflowY: 'scroll'
};

export const CenterModalStyle = {
  alignItems: 'center',
  justifyContent: 'center'
};
