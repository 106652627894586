import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const CartCon = styled('div')`
  h3 {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
  }
`;

export const SubTotal = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.2rem 0;

  h2 {
    font-weight: 400;
    font-size: 1rem;
  }

  h4 {
    font-weight: 600;
    font-size: 1.125rem;
  }
`;

export const CheckoutBottom = styled('div')`
  position: sticky;
  left: 0;
  bottom: -33px;
  width: 100%;
  padding: 0 0 20px 0;
  background: #fff;
`;

export const CartProductCon = styled('div')({
  display: 'flex',
  marginBottom: '50px',
  '&:last-of-type': {
    marginBottom: '20px'
  },
  '.imageCon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '7.5rem',
    height: '7.5rem',
    overflow: 'hidden',
    borderRadius: '5px',
    backgroundColor: COLORS.MildGray,
    img: {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    },
    padding: '10px 0'
  },
  '.productDetailsCon': {
    marginLeft: '1.2rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '.top': {
      display: 'flex',
      justifyContent: 'space-between',
      h1: {
        fontWeight: 500,
        fontSize: '1rem',
        color: COLORS.MainBlack
      },
      h2: {
        fontWeight: 300,
        fontSize: '0.6875rem',
        color: COLORS.SecBlack
      },
      h4: {
        fontWeight: 400,
        fontSize: '1rem'
      }
    },
    '.bottom': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      svg: {
        cursor: 'pointer',
        transition: '0.2s ease-in-out',
        // transition: '-webkit-transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)'
        }
      }
    }
  }
});
