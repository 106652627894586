import { Product as ProductType } from 'TYPES/api.body';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductCard } from './ProductCard';

interface Props {
  product: ProductType;
}

export const Product: React.FC<Props> = ({ product }) => {
  
  const goto = useNavigate();
  const location = useLocation();

  const navigateProductDetail = () => {
    const search = new URLSearchParams(location.search);
    search.delete('productId');
    search.append('productId', product.productId.toString());
    goto(location.pathname.concat('?', search.toString()), { state: product });
  };

  return (
    <>
      <ProductCard {...product} setOnSeeGoodsDetails={navigateProductDetail} />
    </>
  );
};
