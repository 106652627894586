import { Theme, useMediaQuery } from '@mui/material';
import { getBGImage } from 'constants/images';
import SEARCH_PARAMS from 'constants/searchParams';
import { useQuery } from 'react-query';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { Spacer } from '../../components/atoms';
import { Footer, FrontSideNav } from '../../components/molecules';
import { getAllCategories, getVendorById } from '../../network/services/goods';
import { GoodsByCategories, GoodsBySearch } from '../../pages';
import { ProductsBySubCategory } from '../../pages/Products';
import { CATEGORIES, SEARCH, SUPERMARKET } from '../routes';
import { CategoriesLeft, Container, MainGridCon, Right } from '../styles';

const CategoriesRoutes = () => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [searchParam] = useSearchParams();
  const vendorParam = searchParam.get(SEARCH_PARAMS.VENDOR_ID);
  const bgId = searchParam.get(SEARCH_PARAMS.BG_ID);
  const profileImage = searchParam.get(SEARCH_PARAMS.PROFILE_IMAGE);
  const vendorId = vendorParam ? parseInt(vendorParam) : undefined;
  const { data, isLoading } = useQuery({
    queryKey: ['categories', vendorId],
    queryFn: () => getAllCategories(vendorId)
  });
  const { data: seller } = useQuery({
    queryKey: ['vendorname', vendorId],
    queryFn: () => getVendorById(vendorId ?? 1)
  });

  const categories = data
    ? data?.map(c => ({
        to: {
          pathname: `${CATEGORIES}${SUPERMARKET}/${c.name.replace(/\s/g, '-')}`.toLowerCase(),
          search: `category=${c.id}${vendorId ? `&vendorId=${vendorId}` : ''}`
        },
        name: c.name,
        id: c.id,
        logo: c.logo
      }))
    : [];

  const { pathname } = useLocation();
  const isSearchPage = pathname.endsWith(SEARCH);
  return (
    <>
      {vendorId && bgId && (
        <div className="relative w-screen h-fit">
          <img
            src={getBGImage(Number(bgId))}
            alt="vendor-logo"
            className="w-screen max-h-[200px] md:max-h-[457px] object-cover"
          />
          <div className="h-full w-full absolute top-0 bg-black/60" />
          <span className="text-white z-[2] absolute top-4 lg:px-24 px-2 w-full text-xl font-bold capitalize">
            {seller?.data.business_name}
          </span>
          <img
            className="absolute z-[1] left-5 shadow -bottom-10 w-16 h-16 rounded bg-[#E7E6E6]"
            src={profileImage && profileImage !== '' ? decodeURIComponent(profileImage) : '/graphics/madina.svg'}
            alt="vendor-profile"
          />
        </div>
      )}
      <Container>
        <MainGridCon>
          {!md && !isSearchPage && (
            <CategoriesLeft>
              <FrontSideNav isLoading={isLoading} title="Categories" navs={categories} />
            </CategoriesLeft>
          )}
          <Right>
            <Routes>
              <Route path={`${SUPERMARKET}`} element={<GoodsByCategories />} />
              <Route path={SEARCH} element={<GoodsBySearch />} />
              <Route path={`${SUPERMARKET}/:category`} element={<GoodsByCategories />} />
              <Route path={`${SUPERMARKET}/:category/:sub_category`} element={<ProductsBySubCategory />} />
            </Routes>
          </Right>
        </MainGridCon>
      </Container>

      <Spacer height={md ? 20 : 80} />

      <Footer />
    </>
  );
};

export default CategoriesRoutes;
