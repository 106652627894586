import RefferalsInfo from 'components/molecules/m-referals-info';
import RefferAndEarn from 'components/molecules/m-reffer-and-earn';

const Refferals = () => {
  return (
    <div>
      <h2 className="font-semibold text-lg mb-4">Refer a Friend</h2>
      <RefferalsInfo />
      <RefferAndEarn />
    </div>
  );
};

export default Refferals;
