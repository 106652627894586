import { ApiSuccessResponse } from "network/config/interface";
import {
  AuthSuccess,
  BusinessRegisterRequest,
  ILogin,
  IRegister,
  RequestOtp,
  ResetPassword,
  ResetPasswordResponse,
  ResponseSuccess,
  updatePassword,
  VerifyOtp,
} from "TYPES/api.body";
import {
  BUSINESS_REGISTER,
  GOOGLE_LOGIN,
  LOGIN,
  LOGOUT,
  NEW_PASSWORD,
  REGISTER,
  REQUEST_OTP,
  RESET_PASSWORD,
  VERIFY_OTP,
} from "../config/endpoints";
import api from "../config/setAuthHeader";

export const login = async (formData: ILogin) => {
  return await api.post(LOGIN, formData);
};

export const logout = async () => {
  return await api.post(LOGOUT);
};

export const register = async (formData: IRegister) => {
  return await api.post(REGISTER, formData);
};

export const resetPassword = async (
  formData: ResetPassword,
): Promise<ResponseSuccess<ResetPasswordResponse>> => {
  return await api.post(RESET_PASSWORD, formData);
};

export const newPassword = async (formData: updatePassword) => {
  return await api.post(NEW_PASSWORD, formData);
};

export const verifyOtp = async (payload: VerifyOtp) => {
  return await api.post(VERIFY_OTP, { ...payload });
};

export const requestOtp = async (payload: RequestOtp) => {
  return await api.post(REQUEST_OTP, payload);
};
export const registerBusiness = async (input: BusinessRegisterRequest) => {
  return await api.post(BUSINESS_REGISTER, input, {
    headers: { "Content-Type": "application/json" },
  });
};

export const googleLoginCallback = async (
  accessToken: string,
): Promise<ApiSuccessResponse<AuthSuccess>> => {
  return await api.get(GOOGLE_LOGIN.concat(`?access_token=${accessToken}`));
};
