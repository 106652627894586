import { initiateBVNLookup, verifyBVN } from 'network/services/verifyIdentity';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { bvnLookupAtom } from 'store/atoms/verifyIdentityAtom';

// Todo: Check bvn verified

export const useResendOTP = (onResend: () => void) => {
  const navigate = useNavigate();
  const [bvnLookup, setBVNLookup] = useRecoilState(bvnLookupAtom);
  const { mutateAsync: confirm, isLoading: confirming } = useMutation({
    mutationFn: verifyBVN,
    onSuccess: () => {
      onResend();
      enqueueSnackbar({ message: 'OTP Resent', variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar({ message: 'Failed to resend OTP', variant: 'error' });
    }
  });
  const { mutateAsync: initiate, isLoading: initiating } = useMutation({
    mutationFn: initiateBVNLookup,
    onSuccess: data => {
      if (bvnLookup.phone !== undefined) {
        confirm({ method: 'phone', session_id: data.data.session_id, phone_number: bvnLookup.phone });
      } else {
        enqueueSnackbar({ message: 'Phone number not found. Please restart the process', variant: 'error' });
        setBVNLookup({
          bvn: '',
          session_id: '',
          methods: [{ method: '', hint: '' }],
          phone: undefined
        });
        navigate('/account/verify-identity');
      }
    },
    onError: () => {
      enqueueSnackbar({ message: 'Failed to resend OTP', variant: 'error' });
    }
  });

  const resend = () => {
    initiate({ bvn: bvnLookup.bvn });
  };

  const resending = initiating || confirming;

  return { resend, resending };
};
