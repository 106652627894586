import React from 'react';
import { useSetRecoilState } from 'recoil';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { DropDown } from '..';
import { MoreIcon } from 'assets/Icons';
import { AddressCON, DropCON } from './styles';
import { AddressResponse } from 'TYPES/api.body';
import { contactState } from 'store/atoms/contactAtom';
import { apiResponse } from 'network/config/message.constants';
import { deleteAddress, setDefaultAddress } from 'network/services/address';

interface Props {
  address: AddressResponse;
}

export const AddressCard: React.FC<Props> = ({ address }) => {
  const setContactInfo = useSetRecoilState(contactState);
  const { lastname, firstname, street, phoneNumber, status } = address;
  const queryClient = useQueryClient();

  const _delete = async () => {
    try {
      const { data } = await deleteAddress(`${address.id}`);
      apiResponse(data, 'success');
      queryClient.invalidateQueries('addresses');
    } catch (error) {
      enqueueSnackbar(`Error deleting address: ${(error as any).message}`, { variant: 'error' });
    }
  };

  const _edit = () => {
    setContactInfo({ isEditAddressModal: true, ...address, id: `${address.id}`, city: address.city ?? '' });
  };

  const saveAsDefault = async () => {
    if (status) return;
    try {
      const { data } = await setDefaultAddress(`${address.id}`);
      apiResponse(data, 'success');
      queryClient.invalidateQueries('addresses');
    } catch (error) {
      enqueueSnackbar(`Error setting default address: ${(error as any).message}`, { variant: 'error' });
    }
  };

  return (
    <AddressCON>
      <div className="name">
        <span>
          {lastname} {firstname}
        </span>
        <DropDown x="right" label={<MoreIcon />}>
          <DropCON>
            <div onClick={_edit}>Edit Address</div>
            <div onClick={saveAsDefault}>Set As Default</div>
            <div onClick={_delete}>Delete Address</div>
          </DropCON>
        </DropDown>
      </div>
      <div className="address">{street}</div>
      <div>{phoneNumber}</div>
      {status && <div className="default">Default</div>}
    </AddressCON>
  );
};
