import React from 'react';
import Stomp from 'stompjs';
import SocksJs from 'sockjs-client';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { enqueueSnackbar } from 'notistack';
import QUERY_KEYS from 'network/config/queryKeys';
import { liveChatAtom } from 'store/atoms/liveChatAtom';
import { getChatHistory } from 'network/services/customerSupport';
import { MessageResponse } from 'components/molecules/m-chat-view/types';

interface ChatArgs {
  nickname: string;
  fullName: string;
  userType: 'ADMIN' | 'CUSTOMER';
}

export const useChatSocket = () => {
  const liveChat = useRecoilValue(liveChatAtom);
  const [client, setClient] = React.useState<Stomp.Client>();
  const [messages, setMessages] = React.useState<MessageResponse[]>([]);
  const [error, setError] = React.useState<Stomp.Frame | string>();
  const [connected, setConnected] = React.useState(false);
  const [chatArgs, setChatArgs] = React.useState<ChatArgs>();
  const { refetch } = useQuery({
    queryFn: () =>
      getChatHistory({
        nickname: chatArgs?.nickname ?? '',
        userId: process.env.REACT_APP_MADINA_ADMIN_EMAIL ?? ''
      }),
    queryKey: [QUERY_KEYS.CHAT_HISTORY, process.env.REACT_APP_MADINA_ADMIN_EMAIL, chatArgs?.nickname],
    onSuccess: data => {
      if (liveChat.issueType === 'orderRelated') {
        setMessages(data.data.filter(item => item.orderRef === liveChat.orderId));
      } else {
        setMessages(data.data.filter(item => item.orderRef === 'Non-order related chat'));
      }
    }
  });

  const handleMessage = React.useCallback((payload: Stomp.Message) => {
    const message = JSON.parse(payload.body);
    if (message.content) {
      setMessages(c => [...c, { ...message, timestamp: new Date().toISOString() }]);
    }
  }, []);

  const handleConnect = React.useCallback(
    (args: ChatArgs) => {
      if (client !== undefined) {
        setConnected(true);
        client.subscribe(`/user/${args.nickname}/queue/order-messages`, handleMessage);
        client.subscribe('/user/public', handleMessage);
        client.send('/app/user.addUser', {}, JSON.stringify({ ...args, status: 'ONLINE' }));
      }
    },
    [client, handleMessage]
  );

  const handleError = React.useCallback((error: Stomp.Frame | string) => {
    setError(error);
  }, []);

  const connect = React.useCallback(
    (args: ChatArgs) => {
      setChatArgs(args);
      if (client === undefined) {
        const socket = new SocksJs(process.env.REACT_APP_BASE_URL.concat('/ws'));
        setClient(Stomp.over(socket));
      } else {
        client.connect({}, () => handleConnect(args), handleError);
      }
      refetch();
    },
    [client, handleConnect, handleError, refetch]
  );

  const sendMessage = React.useCallback(
    (args: { senderId: string; message: string }) => {
      if (liveChat.issueType === 'other') {
        if (connected && client !== undefined) {
          client.send(
            `/app/order-chat/Non-order related chat`,
            {},
            JSON.stringify({
              senderId: args.senderId,
              recipientId: process.env.REACT_APP_MADINA_ADMIN_EMAIL,
              content: args.message,
              timestamp: new Date()
            })
          );
        }
      } else if (liveChat.issueType === 'orderRelated' && liveChat.orderId === undefined) {
        enqueueSnackbar({
          message: 'Please select an order for order related chat',
          variant: 'error'
        });
        return;
      } else {
        if (connected && client !== undefined) {
          client.send(
            `/app/order-chat/${liveChat.orderId}`,
            {},
            JSON.stringify({
              senderId: args.senderId,
              recipientId: process.env.REACT_APP_MADINA_ADMIN_EMAIL,
              content: args.message,
              timestamp: new Date()
            })
          );
        }
      }
    },
    [client, connected, liveChat.issueType, liveChat.orderId]
  );

  return { messages, error, sendMessage, connect, connected, setMessages };
};
