import { atom } from 'recoil';
import { STORE_KEYS } from 'store/keys';
import { AvailableCarrierResponse, WalletResponse } from 'TYPES/api.body';

interface CheckoutVariables {
  checkoutLoading: boolean;
  deliveryOccurrence?: string;
  pointsUsed: Omit<WalletResponse, 'balance' | 'id'>;
  selectedCarrier?: AvailableCarrierResponse;
}

const checkoutVariablesAtom = atom<CheckoutVariables>({
  key: STORE_KEYS.CHECKOUT_VARIABLES,
  default: {
    checkoutLoading: false,
    deliveryOccurrence: 'None',
    pointsUsed: { pointNairaEquivalent: 0, points: 0 }
  }
});

export default checkoutVariablesAtom;
