import { FormControlLabel, styled } from "@mui/material";
import { COLORS } from "../../../../theme/color";

export const PointsModalCon = styled('div')`
  height: 80vh;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .first {
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 24px;
    }
    .info {
      display: flex;
      align-items: flex-start;
      padding: 16px 12px;
      gap: 16px;
      height: 79px;
      background: #ebeefa;
      border-radius: 8px;
      margin-bottom: 40px;
      color: ${COLORS.MainBlue};

      font-size: 14px;
      line-height: 18px;
      p:first-of-type {
        font-weight: 400;
        margin-bottom: 10px;
      }
      p:last-of-type {
        font-weight: 500;
      }
    }
    label {
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
      margin-bottom: 8px;
    }
  }
`;


export const StyledFormLabel = styled(FormControlLabel)(({ checked }) => ({
  justifyContent: 'space-between',
  alignContent:'start',
  '.MuiFormControlLabel-label': {
    fontWeight: '400',
    fontFamily: 'Outfit',
    lineHeight: '40px',
    color: '#483D3D',
    fontSize: '18px'
  },
  '.MuiSvgIcon-root': checked
    ? {
        color: COLORS.MainBlue
      }
    : {
        color: '#66F6F'
      }
}));