import React from 'react';
import { Modal } from '@mantine/core';
import { Button } from 'components/atoms';
import { TextInput } from '@mantine/core';
import { IoAddOutline } from 'react-icons/io5';
import { useRecoilState } from 'recoil';
import modalAtom from 'store/atoms/modals';
import { PaymentModal } from 'pages/Checkout/component';
import { useMutation, useQueryClient } from 'react-query';
import { v1postfundwallet } from 'network/services/wallet';
import { enqueueSnackbar } from 'notistack';
import QUERY_KEYS from 'network/config/queryKeys';
import TransactionInProgressModal from 'components/atoms/a-transaction-in-progress-modal';

const FundMadinaWallet = () => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLInputElement>(null);
  const [modalState, setModalState] = useRecoilState(modalAtom);
  const { mutateAsync } = useMutation({ mutationFn: v1postfundwallet });
  const queryClient = useQueryClient();

  const commenceFund: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    setModalState(c => ({ ...c, paymentModalOpen: true }));
  };

  const closeAll = () => {
    setOpen(false);
    setModalState(c => ({ ...c, paymentModalOpen: false, transactionInProgressModalOpen: false }));
  };

  const handleFundWallet = () => {
    setModalState(c => ({ ...c, paymentModalOpen: false, transactionInProgressModalOpen: true }));
    mutateAsync({ amount: ref.current?.value ?? '0' })
      .then(() => {
        queryClient.invalidateQueries(QUERY_KEYS.WALLET_BALANCE);
        enqueueSnackbar(`Fund wallet success: N${ref.current?.value}`, { variant: 'success' });
        ref.current && (ref.current.value = '');
        closeAll();
      })
      .catch(() => {
        enqueueSnackbar('Failed to fund wallet', { variant: 'error' });
        closeAll();
      });
  };

  return (
    <>
      <button onClick={() => setOpen(c => !c)} type="button" className="flex items-center gap-2 font-medium">
        <span>Fund Wallet</span>
        <span className="bg-primary p-2 rounded-full text-white">
          <IoAddOutline />
        </span>
      </button>
      <Modal opened={open} onClose={() => setOpen(false)} centered radius="md">
        <form onSubmit={commenceFund}>
          <TextInput
            ref={ref}
            required
            label="Fund amount"
            title="Fund amount"
            placeholder="0.00"
            type="number"
            size="lg"
          />
          <Button type="submit" className="mt-6">
            Fund Wallet
          </Button>
        </form>
        {modalState.paymentModalOpen && (
          <PaymentModal
            exclude={['bnpl', 'wallet']}
            onSuccess={handleFundWallet}
            onClose={closeAll}
            amount={Number(ref.current?.value)}
          />
        )}
        <TransactionInProgressModal />
      </Modal>
    </>
  );
};

export default FundMadinaWallet;
