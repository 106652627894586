import { Spacer } from '../../../components/atoms';
import {
  AboutUsContainer,
  AboutUsDetails,
  AboutUsDetailsGrid,
  AboutUsDetailsInfoItem,
  AboutUsHeader,
  AboutUsMission
} from './styles';

export const AboutUs = () => {
  return (
    <AboutUsContainer>
      <h1>About Us</h1>
      <AboutUsHeader>
        <h3>Madina Lifestyle Supermarket</h3>
        <p>
          Welcome to Madina Lifestyle Supermarket, your ultimate one-stop destination for all your shopping needs.
          Founded with the vision of providing unparalleled convenience, we've crafted an online platform that brings
          the supermarket experience directly to your fingertips.
        </p>
      </AboutUsHeader>
      <AboutUsMission>
        <h3>Our Mission</h3>
        <p>
          At Madina Lifestyle Supermarket, our mission is to redefine the way you shop by offering a seamless online
          experience. We believe in providing quality products, unmatched convenience, and a shopping journey that
          aligns with the fast-paced lifestyle of our customers.
        </p>
      </AboutUsMission>
      <AboutUsDetails>
        <h3>Why Choose Madina</h3>
        <Spacer height={20} />
        <AboutUsDetailsGrid>
          <AboutUsDetailsInfoItem>
            <h4>Diverse Product Selection</h4>
            <p>
              Elevate your shopping experience with our extensive range of products, carefully curated to cater to every
              aspect of your life. From fresh groceries to essential home supplies, Madina Lifestyle Supermarket is your
              comprehensive solution for all your needs.
            </p>
          </AboutUsDetailsInfoItem>
          <AboutUsDetailsInfoItem>
            <h4>Unmatched Convenience</h4>
            <p>
              Say goodbye to the hassles of traditional shopping. With Madina Lifestyle Supermarket, you can
              effortlessly browse and purchase from the comfort of your home. Choose between doorstep delivery or the
              convenience of in-store pickup - it's shopping on your terms.
            </p>
          </AboutUsDetailsInfoItem>
          <AboutUsDetailsInfoItem>
            <h4>Madina Plus</h4>
            <p>
              Be part of something bigger by joining Madina Plus, our innovative marketplace within a marketplace. This
              unique feature not only provides a platform for third-party vendors to showcase their products but also
              fosters a sense of community among shoppers and sellers. By renting shop spaces, vendors contribute to a
              dynamic marketplace, creating a synergy that goes beyond a typical shopping experience.
            </p>
          </AboutUsDetailsInfoItem>
          <AboutUsDetailsInfoItem>
            <h4>Unrivaled Quality Service</h4>
            <p>
              At Madina Lifestyle Supermarket, our commitment to excellence is more than just a promise; it's our
              standard. From the moment you start browsing to the final delivery or pickup, expect top-notch service at
              every step. We pride ourselves on creating a seamless and enjoyable shopping journey for our valued
              customers.
            </p>
          </AboutUsDetailsInfoItem>
        </AboutUsDetailsGrid>
      </AboutUsDetails>
        <p>
          Choose Madina Lifestyle Supermarket - Where Diversity Meets Convenience, Community Thrives, and Excellence
          Prevails.
        </p>
    </AboutUsContainer>
  );
};
