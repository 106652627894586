import { Image } from '@mantine/core';
import { RightPointedArrow, SupportCartIcon, SupportHelpIcon } from 'assets/Icons';
import BlobFlex from 'components/molecules/BlobFlex';
import BlobGrid from 'components/molecules/BlobGrid';
import { LandingVendors } from 'components/molecules/LandingVendors';
import { Reviews } from 'components/molecules/Landingreviews';
import {
  Applelogo,
  HelpIcon,
  Imager,
  Iphone13,
  Iphone13b,
  Iphone13c,
  Playlogo,
  asistedshoping,
  mockup
} from 'components/molecules/blobs';
import LandingPageLocationInput from 'components/molecules/m-landing-page-location-input';
import { OFFERS } from 'constants/landingPage/offers';
import { ASSISTED_SHOPPING } from 'navigation/routes';
import QUERY_KEYS from 'network/config/queryKeys';
import { getAllCategories } from 'network/services/goods';
import { SupportSection, SupportSectionCard } from 'pages/Home/style';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { data: categories } = useQuery([QUERY_KEYS.CATEGORIES], () => getAllCategories());

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="w-screen overflow-hidden">
      <section className="py-4 px-7 pt-12 lg:pt-36 text-white text-center font-semibold text-[20px] bg-gradient-to-b to-[#2F4DC4] from-[#2F4DC4] lg:w-full lg:min-h-80 lg:flex lg:items-center lg:flex-col relative lg:pb-20 lg:px-20">
        <h1 className=" lg:text-white lg:text-5xl lg:font-bold lg:text-center text-3x1">
          The One-Stop Super Market Place for all your needs
        </h1>
        <p className="lg:text-white lg:text-3xl lg:mt-2 font-normal">The Premier family heaven</p>
        <LandingPageLocationInput />
        {windowWidth >= 768 ? <BlobFlex categories={categories ?? []} /> : <BlobGrid categories={categories ?? []} />}
      </section>
      <img src="/wave.svg" alt="wave" className="w-screen min-w-[1000px]" />
      <section className="w-full lg:flex items-center lg:flex-col lg:items-center lg:w-full lg:text-3xl lg:font-semibold lg:mt-0 lg:px-[0%]">
        {/* <h1 className="lg:text-[30px] lg:font-semibold w-full mx-auto text-center text-[18px] font-bold mb-6">
          Find and Shop our Products
        </h1> */}
        {/* <div className="flex-col lg:w-full lg:flex lg:flex-row lg:justify-evenly lg:my-6 lg:gap-6">
          <section className="blobicon mb-4 lg:mb-0">
            <div className="rounded-t-[42%] rounded-r-[58%] rounded-b-[14%] rounded-l-[86%] rounded-tl-[67%] rounded-tr-[73%] rounded-br-[27%] rounded-bl-[33%]  bg-[#E4E6F2] lg:p-9 p-6 lg:w-[110px] lg:h-[110px] w-[30] h-[30]">
              <Image src={verifiedIcon} />
            </div>
            <span className="font-semibold text-[20px]">Fast Delivery</span>
            <p className="lg:text-lg font-normal lg:text-gray-500 lg:text-center">
              share your referral code and earn amazing rewards
            </p>
          </section>

          <section className="blobicon mb-4 lg:mb-0">
            <div className="rounded-t-[75%] rounded-r-[25%] rounded-b-[71%] rounded-l-[29%] rounded-tl-[43%] rounded-tr-[18%] rounded-br-[82%] rounded-bl-[57%]  bg-[#E4E6F2] lg:p-9 p-8 lg:w-[110px] lg:h-[110px]  w-[30] h-[30] grid place-content-center">
              <Image src={zapped} alt="zap" />
            </div>
            <span className="font-semibold text-[20px]">24/7 Customer Support</span>
            <p className="lg:text-lg lg:font-normal lg:text-gray-500 lg:text-center">
              share your referral code and earn amazing rewards
            </p>
          </section>
          <section className="blobicon mb-4 lg:mb-0">
            <div className="rounded-t-[42%] rounded-r-[58%] rounded-b-[14%] rounded-l-[86%] rounded-tl-[67%] rounded-tr-[73%] rounded-br-[27%] rounded-bl-[33%] bg-[#E4E6F2] lg:p-9 p-6 lg:w-[110px] lg:h-[110px] w-[30] h-[30]">
              <Image src={featured} />
            </div>
            <span className="font-semibold text-[20px]">14-Day's return policy</span>
            <p className="lg:text-lg font-normal text-gray-500 text-center">
              share your referral code and earn amazing rewards
            </p>
          </section>
        </div> */}
        {/* <div className="w-full flex justify-center">
          <Link
            to="/supermarket"
            className="text-[16px] text-center mx-auto text-white rounded-[40px] bg-blue-700 px-[7rem] py-2 mt-3">
            Explore stores 
          </Link>
        </div> */}
        <LandingVendors />
      </section>
      {/* <img src="/wave2.svg" alt="wave" className="w-screen min-w-[1000px]" /> */}
      <section className="w-full bg-[#2f4dc4] flex flex-col items-center pt-10 mt-6 relative border-t border-none">
        <h1 className="m-auto text-white text-[25px] font-semibold mb-8">What we offer</h1>
        <div className="flex lg:flex-row flex-col justify-between w-full px-[15%] gap-8 mb-[200px]">
          {OFFERS.map(offer => (
            <Link to={offer.href} key={offer.title} className="">
              <div className="flex flex-col gap-2 items-center">
                <div className="w-[120px] h-[120px] bg-white rounded-[50%] p-4 grid place-content-center">
                  <img src={offer.img} alt="bnbl" />
                </div>
                <span className="text-md text-white font-semibold mb-3">{offer.title}</span>
              </div>
              <p className="text-center  text-white px-[20%]">{offer.description}</p>
            </Link>
          ))}
        </div>
        <div className="custom-shape-divider-bottom-1707333217">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"></path>
          </svg>
        </div>
      </section>
      <section className="border-none outline-none">
        <Reviews />
      </section>
      {/* <BestPrice /> */}
      <section className="bg-[#F04438] min-h-[200px] lg:min-h-[600px] lg:pl-[10%] flex px-4 lg:pr-0 py-4 md:py-0 rounded-sm">
        <div className=" flex-1 lg:flex-1 lg:my-auto flex items-center gap-2 ">
          <div className="h-[100%] flex flex-col gap-3 px-1 justify-center">
            <h1 className="text-white text-[30px] lg:text-[60px] font-bold">
              Enjoy the best prices and deals on madina plus
            </h1>
            <span className="text-white lg:text-[25px]">Get up to 10% discount on your orders</span>
            <div className="flex flex-col w-full justify-start gap-3 mt-4 md:flex-row">
              <img src={Applelogo} alt="applelogo" className="w-[70%] md:w-48" />
              <img src={Playlogo} alt="playlogo" className="w-[70%] md:w-48" />
              {/* <Image src={Playlogo} /> */}
            </div>
          </div>
        </div>
        <div className="lg:flex-[25%] flex relative overflow-hidden">
          <>
            <Imager src={Iphone13} width={450} height={450} className="absolute top-0 left-[20%] lg:block hidden" />
            <Imager
              src={Iphone13c}
              width={400}
              height={450}
              className="absolute bottom-[-20%] right-[0%] lg:block hidden"
            />
            <Imager src={Iphone13b} width={450} height={400} className="absolute top-0 left-[53%] lg:block hidden" />
          </>
          <Imager src={mockup} className="lg:hidden block" />
        </div>
      </section>
      <SupportSection>
        <div className="content">
          <SupportSectionCard>
            <div className="card-icon">
              <SupportCartIcon />
            </div>
            <p className="title">
              <span>How to shop</span>
              <RightPointedArrow />
            </p>
            <p className="desc">Your guide to shopping and placing orders.</p>
          </SupportSectionCard>
          <SupportSectionCard>
            <div className="card-icon">
              <SupportHelpIcon />
            </div>
            <p className="title">
              <span>FAQ</span>
              <RightPointedArrow />
            </p>
            <p className="desc">Your questions answered.</p>
          </SupportSectionCard>
          <SupportSectionCard>
            <div className="card-icon">
              {/* <SupportCartIcon /> */}
              <Image src={HelpIcon} />
            </div>
            <p className="title">
              <span>Need help?</span>
              <RightPointedArrow />
            </p>
            <p className="desc">Contact our customer support team.</p>
          </SupportSectionCard>
        </div>
      </SupportSection>
      <div className="fixed bottom-10 right-4 z-[1000]">
        <Link
          to={ASSISTED_SHOPPING}
          title="Shop with Madina-AI"
          className=" bg-white px-4 text-black items-center rounded-3xl flex gap-1 lg:gap-2 p-2 text-sm">
          <Image src={asistedshoping} /> Assisted Shopping
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
