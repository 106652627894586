import React from 'react';
import { Modal } from '@mantine/core';
import { LuUpload } from 'react-icons/lu';
import { Button } from 'components/atoms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { updateBusinessGovernmentID, updateBusinessUtilityBill } from 'network/services/verifyIdentity';
import { enqueueSnackbar } from 'notistack';
import { CiEdit } from 'react-icons/ci';
import { getCustomer } from 'network/services/profile';
import QUERY_KEYS from 'network/config/queryKeys';

interface Props {
  open: boolean;
  onClose: () => void;
  kybType: 'UTILITY_BILL' | 'GOVERNMENT_ID';
}

const UploadBusinessKYB: React.FC<Props> = ({ open, onClose, kybType }) => {
  const queryClient = useQueryClient();
  const [file, setFile] = React.useState<File>();
  const { data: profile } = useQuery({
    queryFn: getCustomer,
    queryKey: QUERY_KEYS.CUSTOMER_PROFILE
  });
  const [current, setCurrent] = React.useState<string>();

  const imageSrc = React.useMemo(() => (file ? URL.createObjectURL(file) : current), [current, file]);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: kybType === 'UTILITY_BILL' ? updateBusinessUtilityBill : updateBusinessGovernmentID,
    onSuccess: data => {
      console.log(data);
      enqueueSnackbar(`${kybType} updated`, { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar(`Failed to update ${kybType}`, { variant: 'error' });
      onClose();
    }
  });

  React.useEffect(() => {
    setCurrent(kybType === 'GOVERNMENT_ID' ? profile?.data.government_id : profile?.data.utility_bill);
  }, [profile?.data, kybType]);

  const updateId = () => {
    if (!file) {
      enqueueSnackbar(`Please select a ${kybType}`, { variant: 'error' });
      return;
    }
    const formData = new FormData();
    if (kybType === 'GOVERNMENT_ID') {
      formData.append('government_id', new Blob([file], { type: 'multipart/form-data' }));
    } else {
      formData.append('utility', new Blob([file], { type: 'multipart/form-data' }));
    }
    try {
      mutateAsync(formData);
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_PROFILE]);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const clearCurrent = () => {
    setFile(undefined);
    setCurrent(undefined);
  };

  return (
    <Modal.Root size={500} centered radius="lg" opened={open} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content radius="lg" p="md">
        <Modal.Header>
          <Modal.Title className="font-semibold text-2xl my-4">
            {kybType === 'GOVERNMENT_ID' ? 'Government issued ID' : 'Utility Bill'}
          </Modal.Title>
          <Modal.CloseButton className="absolute top-3 right-3" />
        </Modal.Header>
        <Modal.Body className="mt-10">
          {imageSrc ? (
            <div className="relative">
              <img src={imageSrc} alt="Government issued ID" className="aspect-[16/8] object-cover rounded-md" />
              <button
                type="button"
                title="Change Image"
                onClick={clearCurrent}
                className="hover:border-black/30 transition-all absolute right-0 -top-8 border border-black/10 rounded-full p-1">
                <CiEdit />
              </button>
            </div>
          ) : (
            <label
              htmlFor={kybType}
              className="border justify-center gap-4 text-primary hover:bg-primary/10 transition p-4 rounded cursor-pointer border-primary w-full flex items-center">
              <span>Upload a {kybType === 'GOVERNMENT_ID' ? 'Government Issued ID' : 'Utility Bill'}</span>
              <LuUpload />
              <input
                className="hidden"
                type="file"
                accept="image/*"
                onChange={e => e.target.files && setFile(e.target.files[0])}
                id={kybType}
              />
            </label>
          )}
          <Button onClick={updateId} disabled={isLoading} isLoading={isLoading} className="mt-6">
            Save Document
          </Button>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default UploadBusinessKYB;
