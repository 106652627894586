import { useRecoilState } from 'recoil';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { PointModalCon } from './style';
import { Divider } from 'components/atoms';
import { COMMONTYPES } from 'TYPES/event.types';
import { pointsAtom } from 'store/atoms/points';
import { Modal } from 'components/molecules/Modal/Modal';
import { SideModalContentStyle, SideModalStyle } from 'components/molecules/Modal/style';

const initial = {
  x: '100%',
  height: 'auto'
};
const animate = {
  x: '0'
};

export const PointModal = () => {
  const [points, setPoints] = useRecoilState(pointsAtom);
  const setModalClose = () => {
    setPoints(c => ({ ...c, open: false }));
  };

  const onPointChoice = (e: COMMONTYPES['inputChange']) => {
    setPoints(c => ({ ...c, value: parseInt(e.currentTarget.value) }));
  };

  return (
    <Modal
      onModalClose={setModalClose}
      extraModalStyle={SideModalStyle}
      extraModalContentStyle={SideModalContentStyle}
      initial={initial}
      animate={animate}>
      <PointModalCon>
        <h3>Madina Points</h3>
        <RadioGroup value={points.value} onChange={onPointChoice}>
          <FormControlLabel value={1} control={<Radio />} label="Use Cash from Wallet" labelPlacement="start" />
          <Divider />
          <FormControlLabel value={0} control={<Radio />} label="Save for later" labelPlacement="start" />
        </RadioGroup>
      </PointModalCon>
    </Modal>
  );
};
