import { useMediaQuery } from '@mantine/hooks';
import { NavLinkCon } from './style';

type LinkProps = {
  to: string;
  label: string;
  img?: any;
  Icon?: any;
  minimize?: boolean;
};

export type NavLinkProps = Omit<LinkProps, 'minimize'>;

export const NavLinks = ({ to, label, img, Icon }: LinkProps) => {
  const isLarge = useMediaQuery('(min-width: 900px)');
  return (
    <NavLinkCon to={to}>
      {img && (
        <div className="navIcon">
          <img alt="icon" src={img} />
        </div>
      )}
      {Icon && !isLarge && <Icon width={24} height={24} />}
      {label}
    </NavLinkCon>
  );
};
