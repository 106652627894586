import React from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  children?: React.ReactNode;
  callWhen?: boolean;
  onScrollBottom?: () => void;
}

const Scrollable: React.FC<Props> = ({ children, onScrollBottom, callWhen }) => {
  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (callWhen !== undefined) {
      if (inView && callWhen && onScrollBottom) {
        onScrollBottom();
      }
    } else {
      if (inView && onScrollBottom) {
        onScrollBottom();
      }
    }
  }, [callWhen, inView, onScrollBottom]);

  return (
    <div>
      {React.Children.map(children, (child, index) => {
        if (React.Children.count(children) === index + 1) {
          return <div ref={ref}>{child}</div>;
        } else {
          return child;
        }
      })}
    </div>
  );
};

export default Scrollable;
