import { Grid, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';

import { DebitCardIcon, LockIcon, ProfileIcon } from 'assets/Icons';
import {
  ChangePassword,
  CustomTab,
  CustomTabs,
  ProfileDetails,
  TabPanel,
  UserCards,
  a11yProps
} from 'components/molecules';
import { HeaderCON } from '../styles';
import { ProfileRight } from './styles';

export const Profile = () => {
  const [currentRoute, setCurrentRoute] = useState(0);
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: any) => {
    setCurrentRoute(value);
  };
  return (
    <>
      {!lg && <HeaderCON>Profile</HeaderCON>}
      <Grid container gap={'33px'}>
        <Grid xs={12} lg={3.5} item>
          <CustomTabs
            value={currentRoute}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            orientation={lg ? 'horizontal' : 'vertical'}>
            <CustomTab
              label="Personal Details"
              {...a11yProps(0)}
              disableRipple
              icon={<ProfileIcon />}
              iconPosition="start"
            />
            <CustomTab
              label="Card Management"
              {...a11yProps(2)}
              disableRipple
              icon={<DebitCardIcon />}
              iconPosition="start"
            />
            <CustomTab label="Password" {...a11yProps(1)} disableRipple icon={<LockIcon />} iconPosition="start" />
          </CustomTabs>
        </Grid>

        <Grid xs={12} lg={7.7} item>
          <ProfileRight>
            <TabPanel value={currentRoute} index={0}>
              <ProfileDetails />
            </TabPanel>
            <TabPanel value={currentRoute} index={2}>
              <ChangePassword />
            </TabPanel>
            <TabPanel value={currentRoute} index={1}>
              <UserCards />
            </TabPanel>
          </ProfileRight>
        </Grid>
      </Grid>
    </>
  );
};
