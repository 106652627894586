import {
  BookLogisticsShipmentArgs,
  BookLogisticsShipmentResponse,
  ChowdeckDeliveryArgs,
  ResponseSuccess
} from '../../TYPES/api.body';
import { ASSIGN_LOGISTICS_SHIPMENT, CREATE_CHOWDECK_DELIVERY } from '../config/endpoints';
import api from '../config/setAuthHeader';

export const bookLogisticsShipment = async (
  args: BookLogisticsShipmentArgs,
  // orderId: string
): Promise<ResponseSuccess<BookLogisticsShipmentResponse>> => {
  const { data } = await api.post(`api/v1/logistics/${args.orderId}/book-logistic-shipment`, args);
  return data;
};

export const assignLogisticsShipment = async (shipmentId: number) => {
  const { data } = await api.post(ASSIGN_LOGISTICS_SHIPMENT, {
    shipment_id: shipmentId
  });
  return data;
};

export const createChowDeckDelivery = async (args: ChowdeckDeliveryArgs) => {
  const { data } = await api.post(CREATE_CHOWDECK_DELIVERY.concat(encodeURIComponent(args.reference)), args);
  return data;
};
