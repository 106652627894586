import styled from '@emotion/styled';

export const Top = styled.div`
  padding: 2rem;
`;

export const RouteContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background-color: white;
  overflow-y: scroll;
`;