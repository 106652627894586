import React from 'react';
import moment from 'moment';

interface Props {
  kind: 'sender' | 'receiver';
  children?: React.ReactNode;
  timestamp?: string;
}

const ChatBubble: React.FC<Props> = ({ kind, children, timestamp }) => {
  return (
    <div
      className={`font-light min-w-[100px] text-base p-3 relative flex flex-col max-w-[50%] pb-5 rounded-2xl ${kind === 'sender'
          ? 'rounded-br-none bg-[#1AB66E] self-end text-end text-white'
          : 'rounded-bl-none bg-[#E0E4F6] self-start text-start items-end text-black'
        }`}>
      {children}
      {timestamp && (
        <time className="text-[10px] bottom-0 right-3 max-w-full whitespace-nowrap overflow-hidden text-ellipsis absolute">
          {moment(timestamp).fromNow()}
        </time>
      )}
    </div>
  );
};

export default ChatBubble;
