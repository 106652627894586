import { Breadcrumbs } from '@mui/material';
import AllProducts from 'components/molecules/m-all-products';
import ProductCategories from 'components/molecules/m-product-categories';
import QUERY_KEYS from 'network/config/queryKeys';
import { getAllCategories } from 'network/services/goods';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CrumbIcon } from '../../assets/Icons';
import { Spacer } from '../../components/atoms';
import { SortBy } from '../../components/molecules';
import replacer from '../../utils/replacer';
import { ProductsByVendor, ProductSkeletons } from '../Products';
import { HeaderCon, HeaderLink, SortWrapper } from './style';

export const GoodsByCategories = () => {
  const [sort, setSort] = useState('product-rating');
  const [searchParams] = useSearchParams();
  const vendorParam = searchParams.get('vendorId');
  const vendorId = vendorParam ? parseInt(vendorParam) : undefined;
  const navigate = useNavigate();
  const { category, sub_category } = useParams();
  const parsed_category = category ? replacer(/-/g, ' ', category) : 'Supermarket';
  const parsed_sub_category = replacer(/-/g, ' ', sub_category);

  const { data: categories, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.PRODUCT_CATEGORIES, vendorId],
    queryFn: () => getAllCategories(vendorId)
  });

  return (
    <div>
      <HeaderCon sub={!!sub_category}>
        {sub_category ? (
          <>
            <Breadcrumbs aria-label="breadcrumb" separator={<CrumbIcon />}>
              <HeaderLink onClick={() => navigate(`/${category}`)}>{parsed_category}</HeaderLink>
              <HeaderLink>{parsed_sub_category}</HeaderLink>
            </Breadcrumbs>
            <SortWrapper>
              <SortBy sort={sort} setSort={setSort} />
            </SortWrapper>
          </>
        ) : (
          !vendorId && <HeaderLink>{parsed_category}</HeaderLink>
        )}
      </HeaderCon>
      {(categories ?? []).length > 0 ? (
        <>{vendorId ? <ProductsByVendor vendorId={vendorId} /> : <ProductCategories />}</>
      ) : (
        <div className="bg-white p-4 rounded-md">
          <AllProducts />
        </div>
      )}
      {isLoading && (
        <div className="grid bg-white rounded-md grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          <ProductSkeletons count={20} />
        </div>
      )}
      <Spacer height={'4rem'} />
    </div>
  );
};
