import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { COLORS } from '../../../theme/color';

export const CheckoutProductCon = styled('div')`
  display: flex;
  .imageCon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 10px;
    background-color: ${COLORS.MildGray};
    img {
      object-fit: cover;
      width: 100%;
      border-radius: 12px;
      height: 100%;
    }
  }

  .productDetailsCon {
    margin-left: 1.2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .desc {
        margin-right: 16px;
      }

      .desc p:first-of-type {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }

      .desc p:nth-of-type(2) {
        font-weight: 300;
        font-size: 11px;
        line-height: 14px;
      }
    }

    .price {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const PointModalCon = styled('div')`
  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 59px;
  }
  label {
    padding: 25px 0;
    justify-content: space-between;
    &:first-of-type {
      padding-top: 0;
    }
    span {
      font-size: 18px;
    }
  }
`;

export const ScheduleModalCon = styled('div')`
  padding: 0 10px;
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  display: flex;
  min-height: 480px;
  flex-direction: column;
  justify-content: space-between;
  .select-time {
    margin-bottom: 5px;
    font-size: 14px;
  }
`;

export const CustomDateCalender = styled(DateCalendar)({
  width: '100%',
  '.css-1kczsr3-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
    color: '#fff'
  },
  '.css-dzuhm9-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
    color: '#fff'
  },
  '.css-nk89i7-MuiPickersCalendarHeader-root': {
    padding: 0
  },
  '.css-i5q14k-MuiDayCalendar-header': {
    justifyContent: 'space-around',
    background: '#F8F7F7',
    borderRadius: '3px',
    margin: '10px 0'
  },
  '.css-flbe84-MuiDayCalendar-weekContainer': {
    justifyContent: 'space-around'
  }
});

export const PaymentModalCon = styled('div')`
  padding: 0 10px;
  h3 {
    font-weight: 700;
    font-size: 24px;
    margin: 20px 0 19px 0;
  }
  .total-amount {
    width: 100%;
    height: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: ${COLORS.MainBlue};
    border-radius: 8px;
    margin-bottom: 12px;

    .amount {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const PaymentMethod = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    .text {
      margin-left: 16px;
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
      p:nth-of-type(2) {
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  padding: 30px 0 20px;
  border-bottom: 1px solid #e6e6e6;
  &:last-of-type {
    border: none;
  }
  cursor: pointer;
`;

export const RecurringDeliveryCon = styled('div')`
  margin: 20px 0px;
`;

export const CustomAccordion = styled(Accordion)({
  boxShadow: 'none'
});
export const CustomAccordionSummary = styled(AccordionSummary)({
  background: '#fff',
  boxShadow: 'none',

  p: {
    color: '#483D3D',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '24px',
    fontFamily: 'Outfit'
  }
});
export const CustomAccordionDetails = styled(AccordionDetails)({
  background: '#fff'
});

export const StyledFormLabel = styled(FormControlLabel)(({ checked }) => ({
  justifyContent: 'space-between',
  alignContent: 'start',
  '.MuiFormControlLabel-label': {
    fontWeight: '500',
    fontFamily: 'Outfit',
    color: '#444',
    fontSize: '12px'
  },
  '.MuiSvgIcon-root': checked
    ? {
      color: COLORS.MainBlue
    }
    : {
      color: '#66F6F'
    }
}));

export const PromoCon = styled('div')`
  display: flex;
`;

export const FeedbackModalCon = styled('div')`
  padding: 0 10px;
  text-align: center;

  .icon-wrap {
    margin: auto;
    width: 100px;
    height: 100px;
    background-color: #1ab66e29;
    border-radius: 50%;

    &.reject {
      background-color: #d9042929;
    }
  }

  .heading {
    margin: 20px 0 30px 0;
    color: #180101;
    font-size: 24px;
    font-weight: 700;
  }

  .message {
    margin: auto;
    margin-bottom: 25px;
    max-width: 267px;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }

  .actions {
    button {
      width: 100%;
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #e6e6e6;
      cursor: pointer;
    }

    span {
      display: block;
    }

    .reward {
      color: #2f4dc4;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
  }
`;
