import {
  BUSINESS_VIEW_CART,
  CLEAR_CART,
  GET_ALL_CATEGORIES,
  GET_ALL_PRODUCT,
  GET_FAVOURITE_PRODUCTS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCTS,
  GET_PRODUCTS_BY_SEARCH,
  GET_PRODUCTS_BY_SUBCATEGORY,
  GET_PRODUCTS_BY_VENDOR,
  GET_PRODUCTS_BY_VENDOR_AND_CATEGORY,
  GET_SUB_CATEGORIES,
  GET_VENDOR_BY_ID_FRAGMENT,
  VENDOR,
  VIEW_CART
} from 'network/config/endpoints';
import api from 'network/config/setAuthHeader';
import type {
  CartResponseItem,
  Category,
  CustomerType,
  FavouriteProductsResponse,
  Product,
  ResponseSuccess,
  Vendor
} from 'TYPES/api.body';

export const getFavouriteProducts = async (): Promise<FavouriteProductsResponse> => {
  const { data } = await api.get(GET_FAVOURITE_PRODUCTS);
  return data;
};

export const getAllProducts = async (args: { page: number; limit: number }): Promise<Product[]> => {
  const { data } = await api.get(`${GET_ALL_PRODUCT}?page=${args.page}&size=${args.limit}`);
  return data.data;
};

interface ProductByCategoryOpts {
  categoryId: string | number;
  page?: number;
  size?: number;
}

export const getProductByCategory = async (opts?: ProductByCategoryOpts): Promise<Product[]> => {
  const { data } = await api.get(
    `${GET_PRODUCTS}category?categoryId=${opts?.categoryId}&page=${opts?.page ?? 0}&size=${opts?.size ?? 6}`
  );

  return data.data;
};

export const getAllCategories = async (vendorId?: number) => {
  if (vendorId) {
    const vendorData = await getVendorById(vendorId);
    return vendorData.data.category ?? [];
  } else {
    const data = await allCategories();
    return data.data;
  }
};

export const getSubcategories = async ({ queryKey }: any) => {
  const [, { categoryId }] = queryKey;
  const { data } = await api.get(`${GET_SUB_CATEGORIES}?categoryId=${categoryId}`);
  return data.data;
};

export interface ProductsByCategoryOpts {
  subCategoryId: number;
  page?: number;
  size?: number;
  vendorId?: number;
}

export const getProductsBySubCategory = async (opts: ProductsByCategoryOpts): Promise<ResponseSuccess<Product[]>> => {
  const { data } = await api.get(
    `${GET_PRODUCTS_BY_SUBCATEGORY}?subCategoryId=${opts.subCategoryId}&page=${opts.page ?? 0}&size=${opts.size ?? 6}`
  );
  return data;
};

export interface ProductsBySearchOpts {
  search: string;
  page?: number;
  size?: number;
}

export const getProductsBySearch = async (opts: ProductsBySearchOpts): Promise<ResponseSuccess<Product[]>> => {
  const { data } = await api.get(
    `${GET_PRODUCTS_BY_SEARCH}?searchQuery=${opts.search}&page=${opts.page ?? 0}&pageSize=${opts.size ?? 6}`
  );
  return data;
};

const allCategories = async (): Promise<ResponseSuccess<Category[]>> => {
  const { data } = await api.get(GET_ALL_CATEGORIES);
  return data;
};

export const getVendorById = async (vendorId: number): Promise<ResponseSuccess<Vendor>> => {
  const { data } = await api.get(`${VENDOR}${vendorId}${GET_VENDOR_BY_ID_FRAGMENT}`);

  return data;
};

export interface ProductsByVendorOpts {
  vendorId: number;
  categoryId?: number;
  page?: number;
  size?: number;
}

export const getProductsByVendor = async (opts: ProductsByVendorOpts): Promise<ResponseSuccess<Product[]>> => {
  let url = '';
  if (opts.categoryId !== undefined) {
    url = `${GET_PRODUCTS_BY_VENDOR_AND_CATEGORY}?vendorId=${opts.vendorId}&categoryId=${opts.categoryId}`;
  } else {
    url = `${GET_PRODUCTS_BY_VENDOR}?vendorId=${opts.vendorId}`;
  }
  const { data } = await api.get(`${url}&page=${opts.page ?? 0}&pageSize=${opts.size ?? 6}`);

  return data;
};

export const viewCart = async (customerType?: CustomerType): Promise<ResponseSuccess<CartResponseItem[]>> => {
  if (customerType === 'BUSINESS') {
    const { data } = await api.get(BUSINESS_VIEW_CART);
    return data;
  }
  const { data } = await api.get(VIEW_CART);
  return data;
};

export const clearCart = async () => {
  const { data } = await api.delete(CLEAR_CART);
  return data;
};

export const getProductDetail = async (id: number): Promise<ResponseSuccess<Product | undefined>> => {
  const { data } = await api.get(`${GET_PRODUCT_DETAIL}?productId=${id}`);
  return data;
};
