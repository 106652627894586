import { FAQsCard, FAQsContainer, FAQsGrid, FAQsHeader } from './styles';

type FAQItem = {
  id: number;
  question: string;
  answer: string;
};

const FAQs: FAQItem[] = [
  {
    id: 1,
    question: 'How Does Madina Lifestyle Supermarket Work?',
    answer:
      'Customers can explore our extensive product range, covering everything from fruits, vegetables, and meat to toiletries, medicines, office supplies, snacks, drinks, and more. Adding items to your basket is easy, and you have the flexibility to choose your preferred delivery window.'
  },
  {
    id: 2,
    question: 'Will You Deliver To My Area?',
    answer:
      'Madina Lifestyle Supermarket currently serves most parts of Lagos , including central areas such as (please insert locations). For Abuja: (please insert locations). For areas outside our current coverage, alternative centrally located delivery addresses are welcomed.'
  },
  {
    id: 3,
    question: 'What About Refunds at Madina Lifestyle Supermarket?',
    answer:
      "If an item you've ordered is temporarily unavailable, our dedicated team will persistently check its stock status. In the event that the item remains out of stock after thorough checks, any credit owed to you will be promptly added to your Madina Lifestyle Supermarket wallet or your bank account, providing you the flexibility to choose your preferred mode of refund."
  },
  {
    id: 4,
    question: 'How Much Will It Cost?',
    answer:
      'Experience the convenience of having a Personal Shopper handpick and deliver your items for as low as (please insert price).'
  },
  {
    id: 5,
    question: 'How Do I Pay For My Order?',
    answer:
      'Madina Lifestyle Supermarket accepts online payments from major Naira-denominated debit and credit cards, as well as international Mastercard and Visa cards. Bank transfers are also an option, though payment on delivery is currently not available.'
  },
  {
    id: 6,
    question: 'Do Product Images Reflect Exactly What I Will Receive?',
    answer:
      'While most items showcase accurate images, some variations may occur due to packaging updates, promotional items, or different versions. Images may also be for illustration purposes only, particularly for fresh produce like fruits and vegetables.'
  },
  {
    id: 7,
    question: 'How Are Weighed Items Priced?',
    answer:
      'Prices of weighed items, such as fruits and vegetables, may be approximated for buying decisions. However, you will only be billed for the actual weight purchased, with any difference credited or debited to your Madina Lifestyle Supermarket wallet.'
  },
  {
    id: 8,
    question: 'What If An Item Is Unavailable?',
    answer:
      'Our dedicated Personal Shoppers make every effort to find all items. If an item remains unavailable after thorough checks, suitable replacements are suggested, and you have the choice to accept or decline.'
  },
  {
    id: 9,
    question: 'What Do You Consider A Suitable Replacement?',
    answer:
      'Replacements may include different flavors, alternative brands of similar quality, or items with similar functions. Your satisfaction is our priority, and we seek your approval before finalizing any replacements.'
  },
  {
    id: 10,
    question: 'What Is A Madina Lifestyle Supermarket Wallet?',
    answer:
      'Your online account, the Madina Lifestyle Supermarket wallet, holds monetary value for various purposes, including refunds, promotions, discounts, and rebates.'
  },
  {
    id: 11,
    question: 'Do I Pay For Delivery If An Item Is Unavailable?',
    answer:
      "Customers are not obligated to pay for unavailable items. If a suggested replacement is not satisfactory, a refund of the item's monetary value is credited to your Madina Lifestyle Supermarket wallet."
  },
  {
    id: 12,
    question: 'What About Refunds at Madina Lifestyle Supermarket?',
    answer:
      "If an item you've ordered is temporarily unavailable, our dedicated team will persistently check its stock status. In the event that the item remains out of stock after thorough checks, any credit owed to you will be promptly added to your Madina Lifestyle Supermarket wallet or your bank account, providing you the flexibility to choose your preferred mode of refund."
  },
  {
    id: 13,
    question: 'Returns and Replacements',
    answer:
      "In the rare instance of receiving an incorrect or damaged item, Madina Lifestyle Supermarket is committed to promptly replacing the item at no additional cost to you. Please notify our delivery driver of any discrepancies at the point of delivery. Kindly note that once you've taken ownership of your order, returns will not be accepted, and the refund process will be unavailable."
  },
  {
    id: 14,
    question: 'Order Cancellation Policy',
    answer:
      'Madina Lifestyle Supermarket strives for immediate order processing to meet your needs efficiently. As we source products from partner supermarkets upon order placement, and due to the no-return policy of these retailers, we regret to inform you that cancellations cannot be accepted once an order has been placed and paid for. We appreciate your understanding in this matter.'
  },
  {
    id: 15,
    question: 'Contact Us',
    answer:
      "Your satisfaction is our priority. If you have any queries or concerns not addressed in our FAQ section, feel free to reach out to us at <a class='text-primary' href='mailto:support@madinasupermarket.com'>support@madinasupermarket.com</a> We're here to assist you."
  }
];

export const FrequentlyAskedQuestions = () => {
  return (
    <FAQsContainer>
      <FAQsHeader>
        <h1>Frequently asked questions</h1>
        <p>Have questions? We're here to help.</p>
      </FAQsHeader>
      <FAQsGrid>
        {FAQs.map(({ id, question, answer }) => (
          <FAQsCard key={id}>
            <h3>{question}</h3>
            <p dangerouslySetInnerHTML={{ __html: answer }} />
          </FAQsCard>
        ))}
      </FAQsGrid>
      {/* </div> */}
    </FAQsContainer>
  );
};
