import { atom } from 'recoil';
import { STORE_KEYS } from 'store/keys';
import { getCacheAddress } from 'utils/address';

const userAddressAtom = atom({
  key: STORE_KEYS.USER_ADDRESS,
  default: getCacheAddress() ?? ''
});

export default userAddressAtom;
