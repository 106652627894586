import QUERY_KEYS from 'network/config/queryKeys';
import { getWalletBalance } from 'network/services/wallet';
import React from 'react';
import { BsDashLg } from 'react-icons/bs';
import { ThreeDots } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import { WalletResponse } from 'TYPES/api.body';

interface Props {
  onChange?: (pointsData: Omit<WalletResponse, 'balance' | 'id'>) => void;
}

function getNairaPointsEquivalent(totalPoints: number, totalNairaEquivalent: number, points: number): number {
  return (points * totalNairaEquivalent) / totalPoints;
}

const MadinaPointsInput: React.FC<Props> = ({ onChange }) => {
  const [totalPoints, setTotalPoinst] = React.useState(0);
  const [pointsLeft, setPointsLeft] = React.useState(0);
  const [pointsToUse, setPointsToUse] = React.useState(0);
  const { data, isLoading } = useQuery({
    queryFn: getWalletBalance,
    queryKey: QUERY_KEYS.WALLET_BALANCE,
    onSuccess: data => {
      setPointsLeft(data.data.data.points);
      setTotalPoinst(data.data.data.points);
    }
  });

  React.useEffect(() => {
    setPointsLeft(totalPoints - pointsToUse);
  }, [setPointsLeft, totalPoints, pointsToUse]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (parseFloat(e.currentTarget.value) > totalPoints) {
      e.preventDefault();
      return;
    } else {
      setPointsToUse(parseFloat(e.currentTarget.value));
      const points = parseFloat(e.currentTarget.value);
      if (onChange)
        onChange({
          points,
          pointNairaEquivalent: getNairaPointsEquivalent(totalPoints, data?.data.data.pointNairaEquivalent ?? 0, points)
        });
    }
  };

  return (
    <label className="font-semibold">
      Madina Points
      <div className="flex items-center mt-1 border-2 rounded pr-3">
        <input
          value={pointsToUse}
          onChange={handleChange}
          className="w-full p-3 outline-none remove-arrow"
          placeholder="0.00"
          type="number"
        />
        <div className="flex items-center gap-1">
          <span>
            {isLoading ? (
              <ThreeDots width={24} height={24} color="#2f4dc4" />
            ) : isNaN(pointsLeft) ? (
              totalPoints.toFixed(2)
            ) : (
              pointsLeft.toFixed(2)
            )}
          </span>
          <BsDashLg />
          <button onClick={() => setPointsToUse(totalPoints)} className="text-primary">
            MAX
          </button>
        </div>
      </div>
      <span className="font-light text-sm pl-4">Points will be converted and deducted from the grand total</span>
    </label>
  );
};

export default MadinaPointsInput;
