import { Theme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { Divider, Spacer } from '../../atoms';
import { FooterSocial } from './FooterSocial';
import { FooterCon } from './style';

const footerLinks = [
  {
    header: 'COMPANY',
    link: [
      {
        title: 'About Us',
        to: '/about-us'
      },
      {
        title: 'Terms & Conditions',
        to: '/terms-and-conditions'
      },
      {
        title: 'Privacy Policies',
        to: '/privacy-policy'
      }
    ]
  },
  {
    header: 'SUPPORT',
    link: [
      {
        title: 'FAQs',
        to: '/faqs'
      }
    ]
  },
  {
    header: 'CONTACT US',
    link: [
      {
        title: '(+234) 916 860 4446',
        to: 'tel:+2349168604446'
      },
      {
        title: 'support@madinasupermarket.com',
        to: 'mailto:support@madinasupermarket.com'
      }
    ]
  },
  {
    header: 'LOCATIONS',
    link: [
      {
        title: 'Lekki, Lagos',
        to: '/'
      },
      {
        title: 'Maitama, Abuja',
        to: '/'
      }
    ]
  }
];

export const Footer = () => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <FooterCon>
      <div id="footer" className="container">
        {!md && (
          <>
            <section className="footerSocial">
              <FooterSocial />
            </section>
            <Spacer width={250} />
          </>
        )}
        <section className="footerLinks">
          {footerLinks.map((link, i) => (
            <div className="gridContainer" key={i}>
              <div className="gridItem">
                <h3>{link.header}</h3>
                <Spacer height={20} />
                <ul>
                  {link.link.map((link, i) => (
                    <li key={i}>
                      <Link key={i} to={link.to}>
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </section>
      </div>
      {md && (
        <>
          <section className="footerSocial">
            <FooterSocial />
          </section>
          <Spacer height={45} />
        </>
      )}
      <Divider />

      <Spacer height={45} />
      <p>© 2023 Madina. All Rights Reserved.</p>
      <Spacer height={45} />
    </FooterCon>
  );
};
