import React from 'react';

const ShoppintBagIcon: React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
> = props => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 7C4 7.55228 4.44772 8 5 8C5.55228 8 6 7.55228 6 7V6H10V7C10 7.55229 10.4477 8 11 8C11.5523 8 12 7.55228 12 7V6H12.2563L13.3798 12.1789L13.7109 14H2.29293L2.62023 12.1789L2.62059 12.1769L3.74367 6H4V7ZM6 4L10 4C10 2.89543 9.10457 2 8 2C6.89543 2 6 2.89543 6 4ZM12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4L2.90909 4C2.4258 4 2.01167 4.34562 1.92522 4.82111L0.652494 11.8211L0.652134 11.8231L0.0157702 15.3638C0.00527751 15.4222 0 15.4814 0 15.5407V19C0 19.5523 0.447715 20 1 20H15C15.5523 20 16 19.5523 16 19V15.5C16 15.44 15.9946 15.3801 15.9839 15.3211L15.3475 11.8211L14.0748 4.82111C13.9883 4.34562 13.5742 4 13.0909 4L12 4ZM2 18V16H14V18H2Z"
        fill={props.fill || 'black'}
      />
    </svg>
  );
};

export default ShoppintBagIcon;
