import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { STORE_KEYS } from '../keys';
const { persistAtom } = recoilPersist();

const credentials: string = '';

export const userAtom = atom({
  key: STORE_KEYS.USER_ATOM,
  default: credentials,
  effects_UNSTABLE: [persistAtom]
});

export const userRoleAtom = atom<string>({
  key: STORE_KEYS.USER_ROLE,
  default: 'NORMAl',
  effects_UNSTABLE: [persistAtom]
});

const defaultToken = {
  access_token: sessionStorage.getItem('access_token') || '',
  refresh_token: sessionStorage.getItem('refresh_token') || ''
};

export const userTokenAtom = atom<typeof defaultToken>({
  key: STORE_KEYS.USER_TOKEN_ATOM,
  default: defaultToken
  // effects_UNSTABLE: [persistAtom]
});

export const userIsLoggedIn = atom<boolean>({
  key: STORE_KEYS.IS_LOGGED_IN,
  default: !!sessionStorage.getItem('is_login')
  // effects_UNSTABLE: [persistAtom]
});
