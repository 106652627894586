import { Logo, Spacer } from '../../atoms';
import { Facebook, Instagram, Twitter } from 'assets/Icons';

const socialIcon = [
  {
    Icon: Facebook,
    href: 'https://web.facebook.com/profile.php?id=100089994672845&mibextid=ZbWKwL&_rdc=1&_rdr'
  },
  {
    Icon: Twitter,
    href: 'https://twitter.com/madina_lekki'
  },
  {
    Icon: Instagram,
    href: 'https://www.instagram.com/madina.africa/'
  }
];

export const FooterSocial = () => {
  return (
    <>
      <Logo noFill={true} />
      <Spacer height={30} />
      <div className="socialLogos">
        {socialIcon.map((Icon, i) => (
          <a key={i} href={Icon.href} target="_blank" rel="noreferrer">
            <div className="socialIcon">
              <Icon.Icon className="svg" />
            </div>
          </a>
        ))}
      </div>
    </>
  );
};
