import { IoArrowBackOutline } from 'react-icons/io5';
import { useSetRecoilState } from 'recoil';
import { liveChatAtom } from 'store/atoms/liveChatAtom';
import { PaymentButton } from 'pages/Checkout/component/PaymentButton';

const LiveChatStep1 = () => {
  const setLiveChat = useSetRecoilState(liveChatAtom);
  return (
    <div className="bg-white p-7 relative rounded-lg">
      <button
        onClick={() => setLiveChat(curr => ({ ...curr, step: 0 }))}
        type="button"
        className="absolute rounded-full hover:bg-black/5 transition-all p-1 top-5 left-6">
        <IoArrowBackOutline size={24} />
      </button>

      <div className="mt-12">
        <h2 className="font-bold text-2xl">How can we help you</h2>
        <div className="mt-3">
          <PaymentButton
            onClick={() => setLiveChat(curr => ({ ...curr, issueType: 'orderRelated', step: 2 }))}
            title="Help With Orders"
            desc="Chat with us about your order"
          />
          <PaymentButton
            onClick={() => setLiveChat(curr => ({ ...curr, issueType: 'other', step: 2 }))}
            title="Non Order Related Help"
            desc="Let us know where you need help"
          />
          <hr />
        </div>
      </div>
    </div>
  );
};

export default LiveChatStep1;
