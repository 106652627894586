import { IoArrowBackOutline } from 'react-icons/io5';
import { useSetRecoilState } from 'recoil';
import { liveChatAtom } from '../../../store/atoms/liveChatAtom';
import { FaAngleRight } from 'react-icons/fa6';
import { useQuery } from 'react-query';
import { getOrderHistories } from '../../../network/services/order';
import QUERY_KEYS from '../../../network/config/queryKeys';
import React from 'react';
import { Logo, Scrollable } from '../../atoms';
import { OrderDetailsResponse } from '../../../TYPES/api.body';
import { Loader } from '@mantine/core';
import { formatDate } from 'utils/dateFormater';

const LiveChatStep2OrderRelated = () => {
  const setLiveChat = useSetRecoilState(liveChatAtom);
  const [nextPage, setNextPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);
  const [orders, setOrders] = React.useState<OrderDetailsResponse[]>([]);
  const { refetch, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.ALL_ORDERS, nextPage, 10],
    queryFn: () => getOrderHistories({ size: 10, page: nextPage }),
    onSuccess: data => {
      setHasMore(data.length === 10);
      setNextPage(c => c + 1);
      setOrders(c => [...c, ...data]);
    }
  });

  const fetchMore = () => {
    if (hasMore) {
      refetch();
    }
  };

  return (
    <div className="bg-white p-7 relative rounded-lg">
      <button
        onClick={() => setLiveChat(curr => ({ ...curr, step: 1 }))}
        type="button"
        className="absolute rounded-full hover:bg-black/5 transition-all p-1 top-5 left-6">
        <IoArrowBackOutline size={24} />
      </button>

      <div className="mt-12">
        <h2 className="font-bold text-2xl">Select which order</h2>
        <Scrollable onScrollBottom={fetchMore}>
          <ul className="flex flex-col gap-6 mt-10">
            {orders
              .filter(order => order.carts.length > 0)
              .map(order => (
                <button
                  key={order.id}
                  onClick={() => setLiveChat(curr => ({ ...curr, orderId: order.orderRef, step: 3 }))}
                  type="button"
                  className="hover:bg-black/10 transition-all rounded-md pr-5 flex items-center gap-10 w-full">
                  {order.carts[0].productImage ? (
                    <img
                      className="aspect-square max-w-[93px] rounded-xl w-full h-full object-cover"
                      src={order.carts[0].productImage}
                      alt={order.carts[0].product.productName}
                    />
                  ) : (
                    <Logo />
                  )}
                  <div className="flex items-start max-w-[500px] justify-between w-full">
                    <div className="flex flex-col items-start">
                      <h3 className="text-[#1E223E] font-medium text-lg max-w-[300px] whitespace-nowrap overflow-hidden text-ellipsis">
                        {order.carts[0].product.productName}
                      </h3>
                      <p className="font-light">Order #{order.id}</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p>{formatDate(order.orderDate, 'short')}</p>
                      <FaAngleRight size={24} className="self-center" />
                    </div>
                  </div>
                </button>
              ))}
          </ul>
        </Scrollable>
        {isLoading && (
          <div className="flex items-center justify-center w-full">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveChatStep2OrderRelated;
