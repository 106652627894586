import styled from '@emotion/styled';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

type GoodsProps = {
  $isLiked?: boolean;
};
type IAtr = {
  $selected: boolean;
};

export const GoodsCon = styled('div')<GoodsProps>`
  background-color: #f8f8f8;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  .likeCon {
    width: 24px;
    height: 24px;
    background: rgba(13, 0, 0, 0.2);
    backdrop-filter: blur(3.75px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    transition: 0.4s ease-in-out;
    transition: -webkit-transform 0.4s ease-in-out;
    cursor: pointer;

    svg {
      fill: ${props => (props.$isLiked ? COLORS.MainRed : 'none')};
      path {
        stroke: ${props => (props.$isLiked ? COLORS.MainRed : COLORS.White)};
      }
    }

    &:hover {
      background: rgba(13, 0, 0, 0.7);
      transform: scale(1.1);
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
    max-width: 100%;
  }

  .imageCon {
    width: 100%;
    height: 14rem;
    overflow: hidden;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .goodsDetails {
    padding: 1rem;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .addScale {
        transform: scale(1.25);
      }
    }

    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      color: ${COLORS.MainBlack};
      margin-right: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    h4 {
      font-weight: 600;
      font-size: 1.125rem;
      color: ${COLORS.MainGreen};
      white-space: nowrap;
    }

    h5 {
      font-weight: 400;
      font-size: 1rem;
    }
  }

  &:hover {
    transform: scale(1.01);
  }
`;

export const GoodsGridGoodsGridCon = styled('div')({
  '.top': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  h4: {
    fontWeight: 700,
    fontSize: '1.25rem'
  },
  p: {
    fontWeight: 300,
    fontSize: '1.1rem',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: COLORS.MainBlue
  },
  a: {
    color: COLORS.MainBlue
  }
});

export const GoodSDetails = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;

  @media (max-width: ${breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }

  .right {
    .imageCon {
      width: 100%;
      height: 18rem;
      overflow: hidden;
      background-color: ${COLORS.MainGray};
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .imageGal {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;

      .imageConGal {
        width: 4.6rem;
        height: 4.6rem;
        overflow: hidden;
        border-radius: 1rem;
        background-color: ${COLORS.MildGray};
        cursor: pointer;
        transition: 0.4s ease-in-out;
        transition: -webkit-transform 0.4s ease-in-out;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .left {
    .label {
      font-size: 1rem;
      color: #000000;
      font-weight: 400;

      span {
        font-weight: 500;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 1.5rem;
      color: ${COLORS.MainGreen};
    }

    h1 {
      font-weight: 600;
      font-size: 2rem;
      color: ${COLORS.MainBlack};
    }

    h3 {
      font-weight: 400;
      font-size: 1.125rem;
      color: ${COLORS.SecBlack};
    }
  }
`;

export const AttributeCon = styled('div')`
  p {
    font-size: 1rem;
    color: #000000;
    font-weight: 400;
  }

  .innerCon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const Atr = styled('div')<IAtr>`
  .color {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 2rem;
    cursor: pointer;
    margin-right: 0.8rem;
    position: relative;

    &:after {
      content: "'";
      display: ${props => (props.$selected ? 'block' : 'none')};
      color: transparent;
      width: 1.9rem;
      height: 1.9rem;
      background-color: transparent;
      border: 0.1rem solid ${COLORS.SecBlack};
      border-radius: 2rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const btn = {
  maxWidth: '170px',
  padding: '0.7rem'
};

export const AddToCart = styled('div')<GoodsProps>`
  display: flex;
  align-items: center;
  .likeCon {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background: rgba(13, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    transition: -webkit-transform 0.4s ease-in-out;

    svg {
      fill: ${props => (props.$isLiked ? COLORS.MainRed : 'none')};
      path {
        stroke: ${props => (props.$isLiked ? COLORS.MainRed : COLORS.White)};
      }
    }
    &:hover {
      background: rgba(13, 0, 0, 0.7);
      transform: scale(1.05);
    }
  }
`;

export const ProductDsc = styled('div')`
  h3 {
    font-weight: 600;
    font-size: 1.25rem;
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 2.25rem;
    color: ${COLORS.SecBlack};
  }
`;

export const RelatedProducts = styled('div')`
  h3 {
    font-weight: 600;
    font-size: 1.25rem;
  }
`;

export const DropButton = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
  padding: 0.8em 1.3rem;
  border-radius: 33px;
  font-size: 14.5px;
  font-weight: 400;
  text-transform: capitalize;
  background: #efefef;
  @media (max-width: ${breakpoints.sm}px) {
    font-size: 13.8px;
  }
`;

export const List = styled('button')`
  display: block;
  padding: 13px 24px 13px 16px;
  background: none;
  &:hover {
    background: ${COLORS.SecBlue};
    color: ${COLORS.MainBlue};
  }
  text-align: left;
  font-size: 14.5px;
  outline: none;
  border: none;
  @media (max-width: ${breakpoints.sm}px) {
    font-size: 13.8px;
  }
`;

export const BoardContainer = styled('div')`
  position: relative;
  height: 40px;

  div {
    font-weight: 700 !important;
  }

  .board {
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 1;
    width: 850px;
    max-width: calc(100vw - 400px);
    min-height: 426px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 0.1);
    padding: 30px 40px;
    display: flex;

    ul {
      list-style: none;
      font-weight: 300 !important;
      margin-right: 120px;
      li {
        font-size: 14px;
        margin-bottom: 16px;
        color: #999999;
        cursor: pointer;
      }
      li:hover {
        color: ${COLORS.MainBlack};
        scale: 1.01;
      }
      li.header {
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 500 !important;
        color: ${COLORS.MainBlack};
      }
    }
    ul:last-child {
      margin-right: 0px;
    }
    @media (max-width: 1050px) {
      ul {
        margin-right: 80px;
      }
    }
  }
`;

export const extraModalContentStyle = {
  borderRadius: '12px'
};

export const ProductSkeletonCon = styled('div')`
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
