import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { EmptyCon } from '../../../components/atoms';
import { RateBox, RateModal } from '../../../components/molecules';
import { getCompletedOrders} from '../../../network/services/order';
import { BasicBox, EmptyCON } from '../styles';

export const Reviews = () => {
  const [searcParams] = useSearchParams();
  const prodId = searcParams.get('page') ?? '0';  

  const [id, setId] = useState(0);
  const { data, isLoading } = useQuery(['reviews', {prodId}], () => getCompletedOrders(), {});

  const rateProduct = data || [];
  console.log(rateProduct)

  return (
    <BasicBox>
      <div className="header">Reviews ({rateProduct.filter(x => x.status === 'completed').length})</div>
      {isLoading && (
        <EmptyCon>
          <CircularProgress size={30} />
        </EmptyCon>
      )}
      {!isLoading &&
        Array.isArray(rateProduct) &&
        rateProduct.length > 0 &&
        rateProduct.filter(x => x.status === 'completed').map(item => {
          return <RateBox item={item} key={item.id} setId={setId} />;
        })}
      {!isLoading && !rateProduct.length && <EmptyCON>No Item to Review</EmptyCON>}

      {id > 0 && <RateModal setId={setId} id={id} />}
    </BasicBox>
  );
};
