import { styled } from '@mui/material';
import { NavLink as Link } from 'react-router-dom';
import { COLORS } from '../../../theme/color';

export const LogoutCon = styled('div')`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.MainRed};
  padding: 0 20px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RouteLink = styled(Link)`
max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #fff;
  text-decoration: none;
  color: ${COLORS.MainBlack};
  padding: 0 12px 0 18px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  border-radius:5px;
  span {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;
