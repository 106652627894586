import { styled } from "@mui/material";

export const EmptyProductsDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "300px",
  h3: {
    fontWeight: 500,
    fontSize: "1.1rem",
    opacity: 0.4,
  },
});
