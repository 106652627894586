import { IAddNewCard, ResponseSuccess, allmycards } from "TYPES/api.body";
import { ADD_NEW_CARD, GET_MY_CARDS } from "network/config/endpoints";
import api from "network/config/setAuthHeader";

export const mycards = async (): Promise<ResponseSuccess<allmycards>> => {
    return api.get(GET_MY_CARDS);
  };

  export const addcard  = async (formData: IAddNewCard) => {
    return await api.post(ADD_NEW_CARD, formData);
  };