import { Product as ProductType } from 'TYPES/api.body';
import { Empty } from 'components/atoms';
import { Product } from 'components/molecules/Goods/Product';
import React from 'react';
import { GridContainer } from 'styles/grids';
import ProductSkeletons from './ProductSkeletons';

interface Props {
  products: ProductType[];
  diva?: any;
  isLoading: boolean;
  skeletonCount?: number;
  emptyMessage: string;
  showEmptyPrompt?: boolean;
}

const ProductsGrid: React.FC<Props> = ({diva, products, isLoading, skeletonCount, showEmptyPrompt = true }) => {
  return (
    <>
      <GridContainer>
        {products.map(product => (
          <div key={product?.productId}>
            <Product product={product} />
          </div>
        ))}
        {isLoading && <ProductSkeletons count={skeletonCount ?? 6} />}
      </GridContainer>
      {!isLoading && showEmptyPrompt && products.length < 1 && (
        <Empty message="Unfortunately, there are no items in this category." />
      )}
    </>
  );
};

export default ProductsGrid;
