import React from 'react';
import { SubscriptionPackage } from 'TYPES/api.body';
import { ShoppingBagIcon } from '../../../assets/Icons';

interface Props {
  subscription: SubscriptionPackage;
  onSelect: (sub: SubscriptionPackage) => void;
}

const SubscriptionCard: React.FC<Props> = ({ subscription, onSelect }) => {
  return (
    <div className="max-w-[400px] ">
      <div className="transition-all bg-primary w-full flex flex-col gap-4 p-4 rounded-xl">
        <div className="flex items-center gap-2 text-sm text-white">
          <ShoppingBagIcon fill="white" />
          <p>Madina Plus</p>
        </div>
        <button
          type="button"
          onClick={() => onSelect(subscription)}
          className="hover:bg-gray-300 transition-all p-1 w-fit px-2 rounded text-primary text-sm bg-white">
          Subscribe - &#8358;{subscription.amount} {subscription.name}
        </button>
      </div>
      <div className="mt-14">
        <h2>Madina Plus {subscription?.name} details</h2>
        <hr />
        <div className="p-2 text-black/70">
          <p className="my-3 mb-4">Unlock savings with our {subscription?.name} Subscription Card:</p>
          <ul className="list-disc px-4">{subscription?.benefits.map(item => <li key={item}>{item}</li>)}</ul>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
