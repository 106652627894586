import React from 'react';
import { PaymentButton } from '../PaymentButton';
import { buyNowPayLaterStepsAtom } from '../../../../store/atoms/buyNowPayLaterAtom';
import { useSetRecoilState } from 'recoil';

export const BuyNowPayLaterButton = ({ setPaymentModalClose }: any) => {
  const setStep = useSetRecoilState(buyNowPayLaterStepsAtom);
  const handleNextStep = () => {
    setPaymentModalClose();
    setStep(1);
  };
  return <PaymentButton onClick={handleNextStep} title="Buy Now, Pay Later (BNPL)" desc="" />;
};
