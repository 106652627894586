import { Loader } from '@mantine/core';
import { Box } from '@mui/material';
import { IAddNewCard, ReducerActionType } from 'TYPES/api.body';
import { InfoIcon, MoreIcon } from 'assets/Icons';
import { Button, InputField, Spacer } from 'components/atoms';
import QUERY_KEYS from 'network/config/queryKeys';
import { addcard, mycards } from 'network/services/card';
import { enqueueSnackbar } from 'notistack';
import { FormEvent, useReducer, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DropCON } from '../Address/styles';
import { DropDown } from '../DropDown/DropDown';

interface stateTypes {
  page: string;
  payload?: IAddNewCard
}

const initialstate = { page: 'view' };
const INITIALFORMSTATE = {
  cardNumber: '',
  expirationDate: '',
  cvv: '',
  cardHolderName: '',
  cardType: ''
};
function handlepages(state: stateTypes, action: ReducerActionType) {
  switch (action.type) {
    case 'view':
      return { ...state, page: 'view' };
    case 'add':
      return { ...state, page: 'add' };
    case 'create':             
        return state;
    default:
        return state;
  }
//   return state;
}
export const UserCards = () => {
  const history = useNavigate();
    const { mutateAsync, isLoading} = useMutation({
        mutationFn: addcard,
        onSuccess: () => {
          enqueueSnackbar('Card Added Successfully', { variant: 'success' });
        },
        onError: () => {
          enqueueSnackbar('Error Processing Requst', {variant: 'error'});
          history('/account/orders');          
        }});        
  const [state, dispatch] = useReducer(handlepages, initialstate);
  const { page } = state;
  const { data: profile } = useQuery({
    queryFn: mycards,
    queryKey: QUERY_KEYS.CUSTOMER_CARDS,
    onSuccess: data => {}
  });
  // const [error, setError] = useState(INITIALSTATE);
  const [formData, setFormData] = useState(INITIALFORMSTATE);
  const [formError, setFormError] = useState(INITIALFORMSTATE);
  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
    // Reset errors when user types
    setFormError(prevErrors => ({
        ...prevErrors,
        [name]: ''
    }));
};
console.log(profile);
//   const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     const error = validators[name](value);
//     setFormData(formData => ({ ...formData, [name]: value }));
//     setFormError({
//       ...formError,
//       [name]: error
//     });
//   };
//   const { cardNumber, expirationDate, cvv } = formData;
  const viewBlock = (
    <>
      <h2>Card Management</h2>
      {Array.isArray(profile?.data) && profile?.data !== undefined ? (      
        profile.data.map(card => {
          return (
            <Box
              key={card.id}
              my={4}
              display="flex"
              alignItems="start"
              justifyContent="space-between"
              p={2}
              sx={{ backgroundColor: '#fff', color: '#2F4DC4', border: '1px solid gray' }}>
              <Box
                display="flex"
                sx={{
                  backgroundColor: '#fff',
                  color: '#000',
                  flexDirection: 'column'
                }}>
                <span className="text-sm font-semibold">
                  {card?.card_type} XXXXXX-{card?.last_4digit}
                </span>
                <span className="text-sm text-black/70">Expiry year {card?.expiry_year}</span>
              </Box>
              <DropDown x="right" label={<MoreIcon />}>
                <DropCON>
                  <div>Set As Default</div>
                  <div>Delete Card</div>
                </DropCON>
              </DropDown>
            </Box>
          );
        })
      ) : (
        <>
          <Box
            my={4}
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            sx={{ backgroundColor: '#EFF1FB', color: '#2F4DC4', borderRadius: '10px' }}>
            <InfoIcon className="text-[#2F4DC4]" />
            <span className="text-[#2F4DC4]">No card associated with this account</span>
          </Box>
          <Spacer height="100px" />
          <Button onClick={() => dispatch({type: 'add'})} disabled>
            Add Card
          </Button>
        </>
      )}
    </>
  );
  const addBlock = (
    <>
      <h2>Add Card</h2>
      <form method='post' onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        mutateAsync(formData)
        }}>
      <InputField
        label="Card Number"
        name="cardNumber"
        value={formData.cardNumber}
        onChange={handleChange}
        placeholder="XXXX-XXXX-XXXX-XXXX"
        error={formError.cardNumber}
        required
      />
      <Spacer height="30px"/>
      <InputField
        label="Card Holder"
        name="cardHolderName"
        value={formData.cardHolderName}
        onChange={handleChange}
        placeholder="Devon Lane"
        error={formError.cardNumber}
        required
      />
      <Spacer height="30px" />
      <InputField
        label="Type"
        name="cardType"
        value={formData.cardType}
        onChange={handleChange}
        placeholder="MasterCard"
        error={formError.cardNumber}
        required
        type='select'
      />
      <Spacer height="32px" />
      <div className="name-div">
        <InputField
          label="CVV"
          name="cvv"
          value={formData.cvv}
          onChange={handleChange}
          placeholder="e.g 132"
          error={formError.cardNumber}
          required
        />
        <InputField
          type="date"
          label="Expiry Date"
          name="expirationDate"
          value={formData.expirationDate}
          onChange={handleChange}
          placeholder="Old Password"
          error={formError.expirationDate}
          required
        />
      </div>
      <Spacer height="50px" />
      <Button type="submit" disabled={isLoading}>
        {isLoading ? <Loader size="sm" color='white'/>: 'Save Card'}
      </Button>
      </form>
    </>
  );
  return <>{page === 'view' ? viewBlock : addBlock}</>;
};
