
export const blob = '/graphics/electronics.png';
export const fashion = '/graphics/fashion.png';
export const supermarket = '/graphics/supermarket.png';
export const home = '/graphics/homeandoffice.png';
export const health = '/graphics/health.png';
export const asistedshoping = '/graphics/assistshopping.png';
export const verifiedIcon = '/graphics/verifiedicon.png';
export const zapped = '/graphics/zap.png';
export const featured = '/graphics/featured.png';

export const BNPL = '/graphics/BNPL.png';
export const Friendrefer = '/graphics/referfriend.png';
export const Madinaplus= '/graphics/madinaplus.png';
export const Applelogo = '/graphics/applelogo.png';
export const Playlogo = '/graphics/playstorelogo.png';

export const Iphone14 = '/graphics/phones/iphone14.png';
export const Iphone13 = '/graphics/phones/iphone13.png';
export const Iphone13c = '/graphics/phones/iphone13b.png';
export const Iphone13b= '/graphics/phones/iphone13c.png';
export const mockup = '/mockup.png';
export const badgePhoto = '/graphics/bagdePhoto.png';
export const HelpIcon = '/graphics/Icon.png';
interface ImageProps {
    src: string;
    width?: number;
    height?: number;
    className?: string;
}

export const Imager: React.FC<ImageProps> = ({src, width, height, className}) => {
    return <img src={src} width={width} height={height} alt="alt" className={`child ${className}`}/>
}

