const BG_IMGS: readonly string[] = [
  '/images/supermarket_fruits_section.jpg',
  '/images/supermarket_kids_section.jpg',
  '/images/supermarket_beverages_section.jpg',
  '/images/supermarket_beverages_section_2.jpg',
  '/images/supermarket_snacks_section.jpg',
  '/images/supermarket_groceries_section.jpg',
  '/images/supermarket_groceries_section_2.jpg'
];

export const getBGImage = (index: number) =>
  BG_IMGS[index > BG_IMGS.length - 1 ? Math.floor(Math.random() * (BG_IMGS.length - 1)) : index];
