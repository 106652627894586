import { CANCEL_RUN, INITIATE_CHAT, SEND_MESSAGE } from 'network/config/endpoints';
import api from 'network/config/setAuthHeader';

export const initiateChat = async (): Promise<string> => {
  const { data } = await api.post(INITIATE_CHAT);
  return data;
};

export const sendAiMessage = async (message: string): Promise<string> => {
  const { data } = await api.post(`${SEND_MESSAGE}${message}`);
  return data;
};

export const cancelMessageRun = async () => {
  const { data } = await api.post(CANCEL_RUN);
  return data;
};
