import { styled } from "@mui/material";
import { breakpoints } from "../../../theme/theme";

export const BestPriceCon = styled("div")<{ bg: any }>`
  padding: 91px 132px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background: #1ab66e;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  @media (max-width: ${breakpoints.md}px) {
    height: 450px;
    align-items: start;
    padding: 36px 21px;
    background-position: bottom;
  }

  

`;
export const LeftCon = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  ".heading": {
    fontWeight: 700,
    fontSize: "3.5rem",
    lineHeight: "4.5rem",
    maxWidth: 500,
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
      lineHeight: "2.5rem",
      maxWidth: 250,
    }
  },
  ".discount": {
    fontWeight: 400,
    fontSize: "1.7rem",
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.2rem",
      maxWidth: 250,
    }
  },
  ".btns": {
    display: "flex",
    gap: 10,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    }
  }
}));
