import { atom } from 'recoil';
import { STORE_KEYS } from 'store/keys';

interface ModalState {
  paymentModalOpen: boolean;
  paymentSuccessModalOpen: boolean;
  paymentFailureModalOpen: boolean;
  transactionInProgressModalOpen: boolean;
  payWithMandinaModalOpen: boolean;
}

const modalAtom = atom<ModalState>({
  key: STORE_KEYS.MODALS,
  default: {
    paymentModalOpen: false,
    paymentSuccessModalOpen: false,
    paymentFailureModalOpen: false,
    transactionInProgressModalOpen: false,
    payWithMandinaModalOpen: false
  }
});

export default modalAtom;
