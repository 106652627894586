import React from "react";
import { useQuery } from "react-query";
import QUERY_KEYS from "network/config/queryKeys";
import { getAllAddress } from "network/services/address";
import { EditIcon } from "assets/Icons";
import { AddressResponse } from "TYPES/api.body";
import { useRecoilState } from "recoil";
import { contactState } from "store/atoms/contactAtom";
import { EditAddressModal } from "components/molecules";

const ContactDetails = () => {
	const [addressInfo, setAddressInfo] = useRecoilState(contactState);
	const { data: addressData, isFetchedAfterMount } = useQuery({
		queryFn: getAllAddress,
		queryKey: QUERY_KEYS.CUSTOMER_ADDRESSES,
	});
	const addresses = React.useMemo(
		() => (addressData?.data ? addressData.data : []),
		[addressData?.data],
	);

	const showAddressModal = (item: AddressResponse) => {
		setAddressInfo({
			isEditAddressModal: true,
			...item,
			id: item.id.toString(),
			city: item.city ?? "",
		});
	};

	return (
		<>
			<div>
				<p className="sub-head">Contact Details</p>
				{isFetchedAfterMount &&
					addresses.length > 0 &&
					addresses.map((item) => (
						<div key={item.id} className="contact-info">
							<div>
								<p className="name">
									{item.firstname} {item.lastname}
								</p>
								<p>{item.street}</p>
								<p>{item.phoneNumber}</p>
							</div>
							<button
								type="button"
								className="edit"
								onClick={() => showAddressModal(item)}
							>
								<EditIcon width={16} /> <p>Edit</p>
							</button>
						</div>
					))}
			</div>

			{addressInfo.isEditAddressModal && <EditAddressModal />}
			{addressInfo.isEditAddressModal && addresses.length === 0 && (
				<EditAddressModal />
			)}
		</>
	);
};

export default ContactDetails;
