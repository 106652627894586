import React from 'react';
import { Spacer } from 'components/atoms';
import { CatergoriesCON, HeaderCon, HeaderLink } from './style';
import { SupermarketGoodsFromSearch } from 'components/molecules/Goods/SupermarketGoodsFromSearch';

interface Props {
  header?: React.ReactNode;
}

export const GoodsBySearch: React.FC<Props> = ({ header }) => {
  return (
    <div>
      <HeaderCon sub={true}>{header ? header : <HeaderLink>Search Results</HeaderLink>}</HeaderCon>
      <CatergoriesCON>
        <SupermarketGoodsFromSearch />
        <Spacer height={'2.5rem'} />
      </CatergoriesCON>
      <Spacer height={'4rem'} />
    </div>
  );
};
