import { To } from "react-router-dom";
import { LogoIcon } from "../../../assets/Icons";
import { NavLinkCon } from "./style";

type LinkProps = {
  to: To;
  label: string;
  img?: any;
  Icon?: any;
  minimize?: boolean;
};

export type navLinkProps = Omit<LinkProps, "minimize">;

export const FrontNavLinks = ({ to, label, img, Icon }: LinkProps) => {
  return (
    <NavLinkCon
      to={to}
    >
      <div className="navIcon">
        {img ? <img alt="icon" src={img} /> : <LogoIcon />}
      </div>
      {Icon && <Icon />}
      {label}
    </NavLinkCon>
  );
};
