import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';
// import { recoilPersist } from 'recoil-persist';
// const { persistAtom } = recoilPersist();

export const ADDRESS_INITIAL = {
  id: '',
  isEditAddressModal: false,
  lastname: '',
  firstname: '',
  street: '',
  phoneNumber: '',
  state: 'Lagos',
  country: 'Nigeria',
  city: '',
  status: false
} as AddressStateProp;

export type AddressStateProp = {
  id?: string;
  isEditAddressModal: boolean;
  lastname: string;
  firstname: string;
  street: string;
  phoneNumber: string;
  state: string;
  country: string;
  city: string;
  status: boolean;
};

export const contactState = atom<AddressStateProp>({
  key: STORE_KEYS.CONTACT_INFO,
  default: ADDRESS_INITIAL
});
