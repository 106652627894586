import { Box, FormControlLabel, styled } from '@mui/material';
import { COLORS } from '../../../../../theme/color';
import { maxWidth } from '../../../../../theme/theme';

export const VerifyIdentityContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 15,
  padding: '10px 20px',
  p: {
    fontSize: 24,
    textAlign: 'center'
  },
  '.link': {
    textDecoration: 'none',
    backgroundColor: COLORS.MainBlue,
    color: '#fff',
    padding: '10px 20px',
    fontSize: 24,
    borderRadius: 5,
    marginTop: 15
  }
});

export const DetailsModalCon = styled('div')`
  .header {
    text-align: center;
  }
  padding: 0 10px;
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 20px 0 19px 0;
  }
  p {
    font-size: 16px;
    font-size: 400;
    text-align: center;
  }
  .total-amount {
    width: 100%;
    height: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: ${COLORS.MainBlue};
    border-radius: 8px;
    margin-bottom: 12px;

    .amount {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .info {
    .info-card {
      width: 100%;
      background: #011b33;
      height: 48px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: white;
        font-weight: 700;
        font-size: 16px;
      }
    }

    .refunds {
      display: flex;
      align-items: center;
      gap: 5px;
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: #999;
      }
    }
  }
`;

export const InputCon = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0.5rem 0;
  }
`;

export const AuthorizeRequestCon = styled('div')`
  .header {
    text-align: center;
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin: 20px 0 19px 0;
    }

    p {
      p {
        font-size: 16px;
        font-size: 400;
        text-align: center;
        line-height: 32px;
      }
    }
  }
`;

export const StyledFormLabel = styled(FormControlLabel)(({ checked }) => ({
  '.MuiFormControlLabel-label': {
    color: '#483D3D'
  },
  '.MuiSvgIcon-root': checked
    ? {
        color: COLORS.MainBlue
      }
    : {
        color: '#6F6F6F'
      }
}));

export const LoanApprovedCon = styled(Box)({
  display: 'flex',
  height: '400px',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '50px',
  button: {
    fontWeight: '700'
  },

  h3: {
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: '700'
  }
});

export const LoanSummaryCon = styled(Box)(({ theme }) => ({
  margin: 'auto',
  maxWidth: `${maxWidth}px`,
  padding: '0 1rem',

  '.summary-content': {
    position: 'relative',
    // padding: '10px 20px',
    width: '100%',
    borderRadius: '16px',
    display: 'flex',
    background: '#fff',
    flexDirection: 'column',

    '.icon-wrap': {
      display: 'inline-block',
      position: 'absolute',
      top: '1.7rem',
      left: '2.7rem',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer'
    },

    button: {
      fontWeight: '700'
    },

    h3: {
      fontSize: '24px',
      lineHeight: '32px',
      fontFamily: '700',
      textAlign: 'center'
    },

    [theme.breakpoints.up('lg')]: {
      padding: '80px 400px'
    }
  }
}));

export const ThumbCon = styled(Box)({
  display: 'flex',
  height: '100px',
  width: '100px',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '50px',
  background: '#e3f6ee'
});

export const LoanSummaryDetailsCon = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  gap: '20px',

  '.title': {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px'
  },

  '.fees': {
    fontSize: '16px',
    fontWeight: '600',
    color: 'rgba(0, 0, 0, 0.45)'
  },

  '.item': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',

    'p:first-of-type': {
      color: 'rgba(0, 0, 0, 0.45)',
      fontSize: '14px',
      fontWeight: '400'
    },
    'p:last-of-type': {
      color: '#180101',
      fontWeight: '700',
      lineHeight: '24px'
    }
  }
});

export const LoanTenure = styled('div')`
  width: 100%;

  .title {
    font-size: 16px;
    color: #656769;
    font-weight: 600;
    line-height: 24px;
  }

  .dropdown-container {
    margin: 8px 0;
    width: 100%;
  }
`;

export const LoanAgreement = styled('div')`
  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  p {
    padding: 10px 0px;
    color: #000;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;
