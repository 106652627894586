import { Carousel } from "@mantine/carousel";
import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";
import "react-multi-carousel/lib/styles.css";
import { Png } from "../../../assets/Png";
import { GoldStars } from "../../atoms";
import { Reveal } from "../../atoms/Container/Reveal";
import { Review, ReviewCon, WrapperCon } from "./style";

type ReviewItemType = {
  id: number;
  author: string;
  title: string;
  desc: string;
  stars: number;
};

const ReviewItems: ReviewItemType[] = [
  {
    id: 1,
    author: "Uchenna Ogbonnaya",
    title: "I’d rate them a 5/5!",
    stars: 5,
    desc:
      "With Madina Lifestyle, I’ve been able to buy simple groceries, or toiletries just with a click. I must say, their service is top-notch, and I will 10/10 be using this app again.",
  },
  {
    id: 2,
    author: "Maria Olawale",
    title: "Great Customer Service, Great Items",
    stars: 4,
    desc:
      "Everything I ordered from this website came intact without any complaints on my end. I enjoyed every step of the process, from breezing the catalogues to ordering and receiving. Their customer service system is great.",
  },
  {
    id: 3,
    author: "Caleb Ogonor",
    title: "I rate this brand 100%",
    stars: 4,
    desc:
      "I’ve done some online shopping in the past, but with this website, nothing lags, and you can track your order in real time. It’s been such a lifesaver for me.",
  },
  {
    id: 4,
    author: "Ekanem Benson",
    title: "Quality Products",
    stars: 4,
    desc:
      "I’ve never received a bad item! All products I have purchased on Madina are 100% original and worth the price. Great delivery service too. ",
  },
  {
    id: 5,
    author: "Ummie Wali",
    title: "I keep coming back",
    stars: 3,
    desc:
      "Madina Lifestyle just works for me. With my schedule, I’m able to just go through the online store, pick all the items I need and pay. Just like that, my delivery gets to me. They make life so much easier.",
  },
];

export const Reviews = () => {
  const { ReviewUser } = Png;
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const autoplay = useRef(Autoplay({ delay: 4000 }));
  return (
    <WrapperCon>
      <ReviewCon>
        <div className="content">
          <div className="heading">
            <Reveal>
              <p className="text-[#22B872] text-[20px] font-semibold">Reviews</p>
            </Reveal>
            <Reveal>
              <p className="title lg:font-semibold lg:text-2xl">We care about your concerns</p>
            </Reveal>
          </div>

          <Reveal>
            <div className="review-items px-3 lg:px-0">
              <Carousel
                plugins={[autoplay.current]}
                onMouseEnter={autoplay.current.stop}
                onMouseLeave={autoplay.current.reset}
                slideGap="md"
                orientation={mobile ? "vertical" : "horizontal"}
                height={mobile ? 500 : "auto"}                
                withControls={false}
                slidesToScroll={ 2}
                align="start"
                slideSize={"50%"}
              >
                {ReviewItems
                  .map((review) => (
                    <Carousel.Slide key={review.id}>
                      <Review key={review.id}>
                        <div className="review-heading">
                          <div className="img-con">
                            <img src={ReviewUser} alt="review-user" />
                          </div>
                          <p className="review-author">{review.author}</p>
                        </div>
                        <div className="content">
                          <p className="title">{review.title}</p>
                          <p className="desc">
                            {review.desc}
                          </p>
                        </div>
                        <GoldStars stars={review.stars} />
                      </Review>
                    </Carousel.Slide>
                  ))}
              </Carousel>
            </div>
          </Reveal>
        </div>
      </ReviewCon>
    </WrapperCon>
  );
};
