import {
  CANCEL_SUBSCRIPTION,
  SUBSCRIBE_TO_PACKAGE,
  SUBSCRIPTION_HISTORY,
  SUBSCRIPTION_PACKAGES,
  UPDATE_SUBSCRIPTION
} from 'network/config/endpoints';
import api from 'network/config/setAuthHeader';
import { SubscriptionHistoryResponse, SubscriptionPackagesResponse } from 'TYPES/api.body';

export const getSubscriptionPackages = async (): Promise<SubscriptionPackagesResponse> => {
  const { data } = await api.get(SUBSCRIPTION_PACKAGES);
  return data;
};

export const getSubscriptionHistory = async (): Promise<SubscriptionHistoryResponse> => {
  const { data } = await api.get(SUBSCRIPTION_HISTORY);
  return data;
};

interface SubscribeArgs {
  subscriptionPackageId: number;
  paymentRef: string;
  paymentStatus: 'SUCCESS' | 'ERROR';
  paymentMethod: 'Paystack';
}

interface SubscriptionSuccess {
  status: string;
  message: string;
}

export const subscribeToPackage = async (args: SubscribeArgs): Promise<SubscriptionSuccess> => {
  const { data } = await api.post(SUBSCRIBE_TO_PACKAGE, args);
  return data;
};

export const cancelSubscription = async (ref: string) => {
  const { data } = await api.post(`${CANCEL_SUBSCRIPTION}${ref.replace('#', '%23')}/cancel`);
  return data;
};

export const updateSubscription = async (args: { currRef: string; toId: number }) => {
  const { data } = await api.post(`${UPDATE_SUBSCRIPTION}${args.currRef}/${args.toId}/upgrade`);
  return data;
};
