import { useState } from 'react';
import { Spacer } from '../../atoms';
import { DisclosureCon } from './styles';
import { faqData } from 'assets/mockData';
import { RightArrowIcon } from 'assets/Icons';
import { COMMONTYPES } from 'TYPES/event.types';

type DisclosureProp = {
  faq: { label: string; content: string };
  current: string;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
};

const Disclosure = ({ faq, current, setCurrent }: DisclosureProp) => {
  const { label, content } = faq;
  const toggleShow = (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();
    e.stopPropagation();
    current === label ? setCurrent('') : setCurrent(label);
  };

  return (
    <DisclosureCon>
      <button onClick={toggleShow}>
        <span>{label}</span> <RightArrowIcon />
      </button>
      {current === label && <div className="content">{content}</div>}
    </DisclosureCon>
  );
};

export const Faqs = () => {
  const [current, setCurrent] = useState('');
  return (
    <div>
      {faqData.map((faq, index) => (
        <div key={index}>
          <Disclosure faq={faq} current={current} setCurrent={setCurrent} />
          <Spacer height={16} />
        </div>
      ))}
    </div>
  );
};
