import React, { useState } from 'react';
import { CustomTab, CustomTabs, TabPanel, a11yProps } from '../../../components/molecules';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { ClipboardIcon, CompletedIcon, OngoingIcon } from '../../../assets/Icons';
import { HeaderCON } from '../styles';
import OrderList from 'components/molecules/m-order-list';

export const UserOrders = () => {
  const [currentRoute, setCurrentRoute] = useState(0);
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: any) => {
    setCurrentRoute(value);
  };

  return (
    <>
      {!lg && <HeaderCON>Orders History</HeaderCON>}
      <Grid container gap={'33px'}>
        <Grid xs={12} lg={3.5} item>
          <CustomTabs
            value={currentRoute}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            orientation={lg ? 'horizontal' : 'vertical'}>
            <CustomTab
              label={lg ? 'All' : 'All Orders'}
              {...a11yProps(0)}
              disableRipple
              icon={<OngoingIcon />}
              iconPosition="start"
            />
            <CustomTab
              label={lg ? 'Ongoing' : 'Ongoing Orders'}
              {...a11yProps(1)}
              disableRipple
              icon={<OngoingIcon />}
              iconPosition="start"
            />
            <CustomTab
              label={lg ? 'Completed' : 'Completed Orders'}
              {...a11yProps(2)}
              disableRipple
              icon={<CompletedIcon />}
              iconPosition="start"
            />
            <CustomTab
              label={lg ? 'Cancelled' : 'Cancelled Orders'}
              {...a11yProps(3)}
              disableRipple
              icon={<ClipboardIcon />}
              iconPosition="start"
            />
          </CustomTabs>
        </Grid>

        <Grid xs={12} lg={7.7} item>
          <TabPanel value={currentRoute} index={0}>
            <OrderList variant="all" noneMessage="No Orders found" />
          </TabPanel>

          <TabPanel value={currentRoute} index={1}>
            <OrderList variant="ongoing" noneMessage="No Ongoing Orders found" />
          </TabPanel>

          <TabPanel value={currentRoute} index={2}>
            <OrderList variant="complete" noneMessage="No Completed Orders found" />
          </TabPanel>

          <TabPanel value={currentRoute} index={3}>
            <OrderList variant="canceled" noneMessage="No Cancelled Orders found" />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};
