import { CircularProgress } from "@mui/material";
import { EmptyCon, Scrollable } from "components/atoms";
import QUERY_KEYS from "network/config/queryKeys";
import {
	getCancelledOrders,
	getCompletedOrders,
	getOngoingOrders,
	getOrderHistories,
} from "network/services/order";
import React from "react";
import { useInfiniteQuery } from "react-query";
import { OrderCard } from "../OrderCard/OrderCard";

interface Props {
	noneMessage?: string;
	variant: "ongoing" | "complete" | "canceled" | "all";
}

const LIMIT = 20;
const OrderList: React.FC<Props> = ({ noneMessage, variant }) => {
	const { data, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: [
				variant === "ongoing"
					? QUERY_KEYS.ONGOING_ORDERS
					: variant === "complete"
						? QUERY_KEYS.COMPLETE_ORDERS
						: variant === "canceled"
							? QUERY_KEYS.CANCELED_ORDERS
							: QUERY_KEYS.ALL_ORDERS,
			],
			queryFn: ({ pageParam = 0 }) =>
				variant === "ongoing"
					? getOngoingOrders({ size: LIMIT, page: pageParam })
					: variant === "complete"
						? getCompletedOrders({ size: LIMIT, page: pageParam })
						: variant === "canceled"
							? getCancelledOrders({ size: LIMIT, page: pageParam })
							: getOrderHistories({ size: LIMIT, page: pageParam }),
			getNextPageParam: (lastPage, allPages) =>
				(lastPage ?? []).length === LIMIT ? allPages.length : undefined,
		});

	const orders = (data?.pages ?? []).flat();

	const fetchMore = () => {
		if (hasNextPage) {
			fetchNextPage();
		}
	};

	return (
		<>
			{isFetching ? (
				<div className="w-full flex justify-center">
					{" "}
					<CircularProgress size={30} />
				</div>
			) : (
				<Scrollable onScrollBottom={fetchMore}>
					{orders.length > 0 &&
						orders.map((order) => {
							return <OrderCard item={order} key={order.id} />;
						})}
				</Scrollable>
			)}

			{orders.length < 1 && !(isFetching || isFetchingNextPage) && (
				<EmptyCon>{noneMessage ?? "No ongoing order items"}</EmptyCon>
			)}
		</>
	);
};

export default OrderList;
