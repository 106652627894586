import { useSetRecoilState } from 'recoil';
import { FaAngleRight } from 'react-icons/fa6';
import { IoArrowBackOutline } from 'react-icons/io5';
import { liveChatAtom } from 'store/atoms/liveChatAtom';

const CHAT_ISSUES = ['Cancel order', 'Place an order', 'Payment option', 'Track order', 'Return & Refund', 'Other'];

const LiveChatSetp2NonOrderRelated = () => {
  const setLiveChat = useSetRecoilState(liveChatAtom);
  return (
    <div className="bg-white p-7 relative rounded-lg">
      <button
        onClick={() => setLiveChat(curr => ({ ...curr, step: 1 }))}
        type="button"
        className="absolute rounded-full hover:bg-black/5 transition-all p-1 top-5 left-6">
        <IoArrowBackOutline size={24} />
      </button>

      <div className="mt-12">
        <h2 className="font-bold text-2xl">Select an issue</h2>
        <ul className="flex flex-col gap-6 mt-10">
          {CHAT_ISSUES.map(issue => (
            <button
              onClick={() => setLiveChat(curr => ({ ...curr, step: 3, chatTopic: issue }))}
              className="flex items-center pb-4 justify-between border-b border-black/10"
              key={issue}
              type="button">
              <h4 className="font-medium text-lg">{issue}</h4>
              <FaAngleRight size={24} />
            </button>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LiveChatSetp2NonOrderRelated;
