import React from 'react';
import { StarIcon } from '../../../assets/Icons';
import { StarButton } from './styles';

type StarModalProp = {
  stars: number[];
  setStars: React.Dispatch<React.SetStateAction<number[]>>;
};

const FeedBackStars = ({ stars, setStars }: StarModalProp) => {
  const handleChange = (indexParam: number) => {
    const newStars = stars.map((star, index) => {
      return index > indexParam ? 0 : index < indexParam ? 1 : star;
    });

    newStars[indexParam] = 1;

    setStars(newStars);
  };

  return (
    <>
      {stars.map((star, index) => {
        return (
          <StarButton key={index} onClick={() => handleChange(index)}>
            <StarIcon fill={star ? '#2F4DC4' : '#D9D9D9'} />
          </StarButton>
        );
      })}
    </>
  );
};

export default FeedBackStars;
