import { Theme, useMediaQuery } from '@mui/material';
import { Vendor } from 'TYPES/api.body';
import { Spacer } from 'components/atoms';
import { Footer } from 'components/molecules';
import { getBGImage } from 'constants/images';
import SEARCH_PARAMS from 'constants/searchParams';
import { CATEGORIES, SUPERMARKET } from 'navigation/routes';
import { ProductSkeletons } from 'pages/Products';
import React from 'react';
import { Link } from 'react-router-dom';

interface SupermarketFeature {
  img: string;
  title: string;
  href: string;
}

const SUP_FEATS: readonly SupermarketFeature[] = [
  {
    title: 'BNPL',
    img: '/graphics/buy_now.svg',
    href: '/account/bnpl'
  },
  {
    title: 'Subscription',
    img: '/graphics/madina.svg',
    href: '/account/subscriptions'
  },
  {
    title: 'Refer a friend',
    img: '/graphics/gift.svg',
    href: '/account/refer'
  },
  {
    title: 'Shop & Earn',
    img: '/graphics/shop.svg',
    href: '/account/wallet'
  }
];

interface Props {
  vendors: Vendor[];
  isLoading?: boolean;
}

const SupermarketVendors: React.FC<Props> = ({ vendors, isLoading }) => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <>
      <div className="p-2 md:px-16 lg:px-28">
        <div className="flex md:gap-6 my-10 w-full justify-evenly md:justify-center">
          {SUP_FEATS.map(feat => (
            <Link to={feat.href} key={feat.href} className="flex flex-col items-center gap-0.5">
              <div className="p-2 w-fit rounded-full md:p-4 bg-white md:bg-red-500/10">
                <img className="w-10 h-10" src={feat.img} alt={feat.title} />
              </div>
              <p className="text-sm text-black/80">{feat.title}</p>
            </Link>
          ))}
        </div>
        <div className="my-2 md:my-6">
          <h1 className="font-bold text-lg md:text-4xl px-4">Supermarket</h1>
        </div>
        {vendors.length < 1 && !isLoading && (
          <div className="w-full p-10 py-20 flex items-center justify-center opacity-70 text-center">
            There are currently no vendors available
          </div>
        )}
        <ul className="grid grid-cols-1 gap-y-10 place-items-center gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {isLoading ? (
            <ProductSkeletons count={16} />
          ) : (
            vendors.map((item, id) => (
              <Link
                to={`${CATEGORIES}${SUPERMARKET}?${SEARCH_PARAMS.VENDOR_ID}=${item.vendorId}&${SEARCH_PARAMS.BG_ID}=${id}&profileImage=${encodeURIComponent(item.profilePicture ?? '')}`}
                className="w-full flex group flex-col items-center gap-2"
                key={item.vendorId}>
                <div className="flex group md:w-full flex-col items-start gap-2">
                  <div className="flex items-center bg-black/5 rounded-lg relative overflow-hidden w-screen sm:w-full h-screen max-h-[270px] max-w-[400px] justify-center">
                    <img
                      className="w-full min-h-full max-h-[252px] object-cover"
                      src={getBGImage(id)}
                      alt={item.business_name}
                    />
                    <div className="absolute p-1 flex w-20 h-20 bottom-1 left-1">
                      {item.profilePicture ? (
                        <img src={item.profilePicture} alt={item.business_name} className="rounded-md" />
                      ) : (
                        <img src="/graphics/madina.svg" alt="madina" className="rounded-md bg-[#E7E6E6]" />
                      )}
                    </div>
                  </div>
                  <p className="font-medium text-lg group-hover:text-primary transition-all">
                    {item.business_name ?? 'Madina'}
                  </p>
                </div>
              </Link>
            ))
          )}
        </ul>
      </div>
      <Spacer height={md ? 20 : 80} />
      <Footer />
    </>
  );
};

export default SupermarketVendors;
