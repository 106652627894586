import { Alert, Radio as MantineRadio, Stack } from "@mantine/core";
import { CircularProgress, Radio, RadioGroup } from "@mui/material";
import { AvailableCarrierResponse } from "TYPES/api.body";
import { Spacer } from "components/atoms";
import { StyledFormLabel } from "components/atoms/InputField/style";
import { useUpdatedCart } from "components/molecules/Cart/hooks";
import MADINA_INFO from "constants/madina";
import { uniqBy } from "lodash";
import QUERY_KEYS from "network/config/queryKeys";
import { getAllAddress } from "network/services/address";
import {
  getAllCarriers,
  validateAddressCallback,
} from "network/services/order";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { IoInformationCircleSharp } from "react-icons/io5";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { checkoutDetailsAtom } from "store/atoms/checkoutAtom";
import checkoutVariablesAtom from "store/atoms/checkoutVariables";
import { profileAtom } from "store/atoms/profileAtom";

const DeliveryDetails = () => {
  const go = useNavigate();
  const { pathname } = useLocation();
  const profile = useRecoilValue(profileAtom);
  const { data: cartData } = useUpdatedCart();
  const [checkoutVariables, setCheckoutVariables] = useRecoilState(
    checkoutVariablesAtom,
  );
  const [checkoutDetails, setCheckoutDetails] =
    useRecoilState(checkoutDetailsAtom);
  const { data: addressData, isLoading: addressLoading } = useQuery({
    queryFn: getAllAddress,
    queryKey: QUERY_KEYS.CUSTOMER_ADDRESSES,
  });
  const {
    mutateAsync: asyncGetAllCarriers,
    data: carriersData,
    isLoading: carriersLoading,
  } = useMutation({ mutationFn: getAllCarriers });
  const carriers: AvailableCarrierResponse[] = uniqBy(
    carriersData !== undefined ? carriersData.data : [],
    (c) => c.carrierId,
  );

  const { mutateAsync: validateAddress, isLoading: validatingAddress } =
    useMutation({
      mutationFn: validateAddressCallback,
    });

  const defaultAddress = React.useMemo(() => {
    const addresses = addressData?.data ? addressData.data : [];
    const defAddr = addresses.find((item) => item.status === true);
    if (!defAddr && addresses.length > 0) {
      return addresses[0];
    }
    return defAddr;
  }, [addressData]);

  const handleGetCarriers = React.useCallback(async () => {
    if (cartData?.data.length === 0) {
      enqueueSnackbar("Please add items to cart", { variant: "error" });
      return;
    }
    if (profile?.email === undefined) {
      enqueueSnackbar("Please login to continue", { variant: "error" });
      go("/login", { state: { from: pathname } });
    } else {
      if (!defaultAddress && !addressLoading) {
        enqueueSnackbar(
          "No default address found: Please set a default delivery address",
          { variant: "error" },
        );
        return;
      }
      setCheckoutDetails((c) => ({ ...c, addressId: defaultAddress?.id }));
      const addrStr = `${defaultAddress?.street}, ${defaultAddress?.state}, ${defaultAddress?.country}`;
      try {
        const madinaAddrPromise = validateAddress(MADINA_INFO.address);
        const userAddrPromise = validateAddress(addrStr);
        const [madinaAddrData, userAddrData] = await Promise.all([
          madinaAddrPromise,
          userAddrPromise,
        ]);
        asyncGetAllCarriers({
          type: "local",
          toAddress: {
            name: defaultAddress?.firstname + " " + defaultAddress?.lastname,
            email: profile?.email,
            address: addrStr,
            phone: profile?.phoneNumber,
            latitude: Number(userAddrData.data.lat),
            longitude: Number(userAddrData.data.lng),
          },
          fromAddress: {
            name: MADINA_INFO.name,
            email: MADINA_INFO.email,
            address: MADINA_INFO.address,
            phone: MADINA_INFO.phone.replace("+234", "0"),
            latitude: Number(madinaAddrData.data.lat),
            longitude: Number(madinaAddrData.data.lng),
          },
          parcels: {
            width: 32.5,
            length: 32.5,
            height: 32.5,
            weight: "2",
          },
          items: cartData?.data
            ? cartData?.data.map((item) => ({
              weight: "20",
              name: item.product.productName,
              amount: `${item.product.price}`,
              category: "Product",
              quantity: `${item.quantity}`,
              description: "Product Desctripion",
            }))
            : [],
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar(`Error getting Carriers`, {
          variant: "error",
        });
      }
    }
  }, [
    go,
    pathname,
    cartData?.data,
    profile?.email,
    defaultAddress,
    addressLoading,
    validateAddress,
    setCheckoutDetails,
    asyncGetAllCarriers,
    profile?.phoneNumber,
  ]);

  React.useEffect(() => {
    if (checkoutDetails.deliveryMethod === "homedelivery") {
      handleGetCarriers();
    }
  }, [checkoutDetails.deliveryMethod, handleGetCarriers]);

  return (
    <>
      <p className="sub-head">Delivery Method</p>

      <RadioGroup
        row
        value={checkoutDetails.deliveryMethod}
        onChange={(e) =>
          setCheckoutDetails((curr) => ({
            ...curr,
            deliveryMethod: e.currentTarget.value as any,
          }))
        }
      >
        <StyledFormLabel
          value="homedelivery"
          control={<Radio />}
          label="Home Delivery"
          checked={checkoutDetails.deliveryMethod === "homedelivery"}
        />

        <Spacer width={34} />

        <StyledFormLabel
          value="pickup"
          control={<Radio />}
          label="Pick up"
          checked={checkoutDetails.deliveryMethod === "pickup"}
        />
      </RadioGroup>

      {checkoutDetails.deliveryMethod === "homedelivery" && (
        <MantineRadio.Group
          value={checkoutVariables.selectedCarrier?.carrierId}
          onChange={(carrierId) =>
            setCheckoutVariables((curr) => ({
              ...curr,
              selectedCarrier: carriers
                .filter((c) => c.carrierId === carrierId)
                .pop(),
            }))
          }
          pl="lg"
          name="preferredCarrier"
          label="Select your preferred carrier"
        >
          {!defaultAddress && (
            <p className="bg-primary/5 p-4 border border-primary/30 mt-2 rounded-md !text-primary">
              Default address not found,{" "}
              <Link
                to="/account/address"
                className="font-semibold hover:underline"
              >
                click here
              </Link>{" "}
              to set a default address.
            </p>
          )}
          <Stack pl="lg" mt="sm">
            {carriersLoading || validatingAddress ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              carriers.map((c, i) => (
                <MantineRadio
                  classNames={{ label: "w-full", labelWrapper: "w-full" }}
                  w="100%"
                  color="#2F4DC4"
                  checked={
                    checkoutVariables.selectedCarrier?.carrierId.toString() ===
                    c.carrierId.toString()
                  }
                  value={c.carrierId}
                  label={
                    <div className="grid font-medium grid-cols-10 content-center w-full">
                      <img
                        src={c.carrierLogo ?? "/images/chowdeck.webp"}
                        alt={c.carrierName}
                        className="rounded-full w-5 h-5"
                      />
                      <span className="col-span-5 whitespace-nowrap text-ellipsis overflow-hidden">
                        {c.carrierName}
                      </span>
                      <span className="col-span-4">
                        ₦ {(c.charge + c.amount).toFixed(2)}
                      </span>
                    </div>
                  }
                  key={i}
                  variant="outline"
                />
              ))
            )}
          </Stack>
        </MantineRadio.Group>
      )}

      {checkoutDetails.deliveryMethod === "homedelivery" &&
        checkoutVariables.selectedCarrier !== undefined && (
          <Alert
            className="my-5 rounded-lg"
            icon={<IoInformationCircleSharp size={24} />}
            color="#2F4DC4"
            title={`${checkoutVariables.selectedCarrier.estimatedDaysOfDelivery ?? ""
              } Shipping Fee: ₦ ${(
                checkoutVariables.selectedCarrier.charge +
                checkoutVariables.selectedCarrier.amount
              ).toFixed(2)}`}
          />
        )}
    </>
  );
};

export default DeliveryDetails;
