/// <reference path="../../../../../../typings/mono-connect.d.ts" />

import MonoConnect from '@mono.co/connect.js';
import { Checkbox, Theme, useMediaQuery } from '@mui/material';
import { Button, Spacer } from 'components/atoms';
import { Modal } from 'components/molecules';
import { CenterModalContentStyle, CenterModalStyle } from 'components/molecules/Modal/style';
import { checkCreditScore } from 'network/services/checkout';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import { AuthorizeRequestCon, StyledFormLabel } from './style';

type AuthorizeProp = {
  setIsModalOpen: (number: number) => void;
  nextStep: (number: number) => void;
};

export const AuthorizeRequest = ({ setIsModalOpen, nextStep }: AuthorizeProp) => {
  const [isAuthorize, setIsAuthorize] = useState(true);

  const checkoutFormData = useRecoilValue(checkoutDetailsAtom);

  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const { mutateAsync } = useMutation({
    mutationFn: checkCreditScore,
    onSuccess: () => {
      nextStep(2);
    },
    onError: error => {
      nextStep(0);
      if (error as any satisfies Error) {
        if ((error as Error).cause === 'unpaid-loan') {
          enqueueSnackbar('You already have pending loan request', {
            variant: 'error'
          });
          return;
        } else if ((error as Error).cause === 'unvefied-bvn') {
          enqueueSnackbar('Your BVN is not verified', {
            variant: 'error'
          });
          return;
        }
      }
      enqueueSnackbar(`Failed to check credit score: ${(error as any).message}`, { variant: 'error' });
    }
  });
  // Todo: check bnpl balance

  const monoConnect = useMemo(() => {
    const monoInstance = new MonoConnect({
      onSuccess: async ({ code }) => {
        await mutateAsync({
          code,
          productPrice: checkoutFormData.grandTotal ?? 0
        });
      },
      key: process.env.REACT_APP_MONO_PUBLIC_KEY ?? ''
    });

    monoInstance.setup();

    return monoInstance;
  }, [checkoutFormData.grandTotal, mutateAsync]);

  const setModalClose = () => {
    setIsModalOpen(0);
  };

  const onSelect = () => {
    setIsAuthorize(prev => !prev);
  };

  const handleContinue = () => {
    nextStep(0);
    monoConnect.open();
  };

  return (
    <Modal
      onModalClose={setModalClose}
      maxWidth="500"
      extraModalContentStyle={{
        ...CenterModalContentStyle,
        width: sm ? '550px' : ''
      }}
      extraModalStyle={CenterModalStyle}>
      <AuthorizeRequestCon>
        <div className="header">
          <h3>Buy Now Pay Later (BNPL)</h3>
          <Spacer height="49px" />
          <p>
            With the BNPL feature, Madina gives you the opportunity to purchase products from the store and pay within a
            period of time
          </p>
        </div>

        <div>
          <Spacer height="98px" />

          <StyledFormLabel
            value={isAuthorize}
            control={<Checkbox onChange={onSelect} />}
            label="Authorize third party service provider to collect and analyze your account statements to see whether you qualify for the BNPL loan or not"
            checked={isAuthorize === true}
          />

          <Spacer height="58px" />

          <Button onClick={handleContinue} disabled={!isAuthorize}>
            Continue
          </Button>
          <Spacer height="20px" />
        </div>
      </AuthorizeRequestCon>
    </Modal>
  );
};
