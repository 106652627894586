import React from 'react';
import { RiEditFill } from 'react-icons/ri';
import { Autocomplete, Button } from '@mantine/core';
import { useLoadScript } from '@react-google-maps/api';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { LocationIcon2 } from 'assets/Icons';
import userAddressAtom from 'store/atoms/address';
import { userLocationAtom } from 'store/atoms/userLocationAtom';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { getUserLocation, parseGoogleGeocodeResult } from 'utils/location';
import { setCacheAddress } from 'utils/address';

const LandingPageLocationInput = () => {
  const setUserLocation = useSetRecoilState(userLocationAtom);
  const [userAddress, setUserAddress] = useRecoilState(userAddressAtom);
  const [showInput, setShowInput] = React.useState(false);

  React.useEffect(() => {
    setShowInput(!userAddress || userAddress.trim() === '');
  }, [userAddress]);

  const { loadError, isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    libraries: ['places']
  });

  const handleGetuserLocation = () => {
    getUserLocation(setUserLocation);
  };

  const {
    value,
    setValue,
    ready,
    init,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'ng' },
      types: ['address']
    }
  });

  const handleSelectAddress = async (address: string) => {
    setValue(address, false);
    setCacheAddress(address);
    setUserAddress(address);
    clearSuggestions();
    const geoCodeRes = await getGeocode({ address });
    setUserLocation(parseGoogleGeocodeResult(geoCodeRes[0]));
  };

  React.useEffect(() => {
    if (!ready && isLoaded) {
      init();
    }
  }, [ready, isLoaded, init]);

  const editLocation = () => {
    setShowInput(true);
  };

  return showInput ? (
    <div className="w-full flex items-center justify-center mt-8 mb-8">
      <div className="flex p-3 items-center justify-center w-full md:max-w-[851px]">
        <Autocomplete
          color="#2F4DC4"
          size="xl"
          classNames={{
            input: 'rounded-l-xl outline-none border-none h-full rounded-r-none'
          }}
          className="flex-1"
          error={!!loadError}
          value={value}
          onOptionSubmit={handleSelectAddress}
          comboboxProps={{
            transitionProps: { transition: 'pop', duration: 300 },
            radius: 'md'
          }}
          onChange={value => setValue(value)}
          disabled={!ready}
          placeholder={!ready ? 'Loading...' : loadError === undefined ? 'Enter your address' : 'Error loading maps'}
          data={status === 'OK' ? data.map(i => i.description) : []}
        />

        <Button
          size="xl"
          rightSection={<LocationIcon2 />}
          onClick={handleGetuserLocation}
          className="bg-[#F04438] rounded-l-none rounded-r-xl"
          color="#1AB66E">
          Use my location
        </Button>
      </div>
    </div>
  ) : (
    <div className="my-[54px] relative text-2xl lg:text-4xl">
      <span>{userAddress}</span>
      <button
        onClick={editLocation}
        className="absolute -top-2 -right-8 text-lg border border-white/20 p-1 hover:scale-125 transition-all rounded-full aspect-square"
        type="button"
        title="Edit address">
        <RiEditFill />
      </button>
    </div>
  );
};

export default LandingPageLocationInput;
