import { Skeleton } from '@mui/material';
import { Spacer } from '../../atoms';

export const ProductSkeleton = () => {
  return (
    <div>
      <div>
        <Skeleton width="100%" height="10rem" variant="rounded" />
      </div>
      <div>
        <Spacer height="1rem" />
        <Skeleton width="100%" height="50px" variant="text" />
        <Spacer height="10px" />

        <div>
          <Skeleton width="100%" height="40px" variant="rounded" />
        </div>
      </div>
    </div>
  );
};
