import { styled } from '@mui/material';
import { breakpoints } from '../../../theme/theme';
import { COLORS } from '../../../theme/color';

export const CategoryCon = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f8f7f7;
  width: 305px;
  padding-bottom: 20px;

  @media (max-width: ${breakpoints.md}px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 170px;
    align-items: center;
  }

  .content {
    border-radius: 12px;

    padding: 18px 28px;
    @media (max-width: ${breakpoints.md}px) {
      width: 175px;
      height: 175px;
    }
  }

  .img-con {
    width: 258.813px;
    height: 181px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: ${breakpoints.md}px) {
      width: 100%;
      height: 59px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    color: #000;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 18px;
    @media (max-width: ${breakpoints.md}px) {
      font-size: 14px;
    }
  }
`;

export const CategoriesGridCon = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 32px 32px;
  background: #fff;
  height: auto;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 700;
      font-size: 24px;

      @media (max-width: ${breakpoints.md}px) {
        font-size: 18px;
      }
    }

    a {
      color: ${COLORS.MainBlue};
      text-decoration: none;
    }
  }
  /* justify-content: space-between; */
  /* padding: 0px 20px; */
  .content {
    display: flex;
    overflow-x: auto;

    gap: 20px;
    padding: 32px 0px;
  }
  @media (max-width: ${breakpoints.md}px) {
    gap: 11px;
  }
`;

export const ViewMoreExtraStyles = {
  width: '211px',
  height: '68px',
  padding: ' 15px 56px',
  fontSiz: '18px',
  fontWeight: '500'
};
