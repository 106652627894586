import { Theme, useMediaQuery } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import React, { useRef } from "react";
import { Close } from "../../../assets/Icons";
import {
	AnimatedContainer,
	AnimatedContainerProps,
} from "../../atoms/Container/AnimatedContainer";
import { Portal } from "./Portal";
import {
	ModalContent,
	ModalContentContainer,
	StyledModalContainer,
} from "./style";

interface ModalProps extends AnimatedContainerProps {
	children: React.ReactNode;
	onModalClose: () => void;
	parentRef?: React.RefObject<HTMLDivElement>;
	maxWidth?: string;
	className?: string;
	extraModalStyle?: Record<string, string>;
	extraModalContentStyle?: Record<string, string>;
}

export const Modal: React.FunctionComponent<ModalProps> = ({
	children,
	onModalClose,
	parentRef,
	maxWidth,
	extraModalStyle,
	className,
	extraModalContentStyle,
	...restProps
}) => {
	const currentRef = useRef<HTMLDivElement>(null);
	const modalContentRef = parentRef ?? currentRef;
	const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const padding = sm ? "1.2em" : "2em";

	return (
		<Portal>
			<StyledModalContainer
				onClick={(e) => {
					e.stopPropagation();
					if (
						modalContentRef.current &&
						!modalContentRef.current.contains(e.target as Node)
					) {
						onModalClose();
					}
				}}
				style={{ ...extraModalStyle }}
			>
				<ModalContentContainer>
					<AnimatePresence>
						<AnimatedContainer {...restProps}>
							<ModalContent
								className={className}
								ref={modalContentRef}
								style={{ padding, ...extraModalContentStyle }}
								$maxWidth={maxWidth}
							>
								<div className="modalClose">
									<Close onClick={onModalClose} />
								</div>

								<div>{children}</div>
							</ModalContent>
						</AnimatedContainer>
					</AnimatePresence>
				</ModalContentContainer>
			</StyledModalContainer>
		</Portal>
	);
};
