import { SearchIcon } from '../../../assets/Icons';
import { Button, InputField, Spacer } from '../../atoms';
import { extraStylesBtn, SearchBar, SearchInput } from './style';

type Isearch = {
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  extraStyles?: Record<string, string>;
  value?: string;
  name: string;
  onSearch?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Search = ({ onSearch, name, disabled, extraStyles, placeholder, value, onChange, onFocus }: Isearch) => {
  return (
    <SearchBar style={extraStyles}>
      <SearchIcon />
      <InputField
        name={name}
        type="search"
        placeholder={placeholder}
        onChange={onChange}
        onFocus = {onFocus}
        disabled={disabled}
        value={value}
        extraStyles={SearchInput}
      />

      <Spacer width={10} />
      <div className="buttonContainer">
        <Button extraStyles={extraStylesBtn} onClick={onSearch}>
          Search
        </Button>
      </div>
    </SearchBar>
  );
};
