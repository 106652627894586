import { Image } from "@mantine/core";
import {
  blob,
  fashion as fashionImg,
  health as healthImg,
  home as homeImg,
  supermarket,
} from "components/molecules/blobs";
import { Link } from "react-router-dom";
import { Category } from "TYPES/api.body";

interface Props {
  categories: Category[];
}

const BlobFlex: React.FC<Props> = ({ categories }) => {
  const fashion = categories
    .filter((item) => item.name.toLowerCase().includes("fashion"))
    .pop();
  const electronics = categories
    .filter((item) => item.name.toLowerCase().includes("electronic"))
    .pop();
  const home = categories
    .filter((item) => item.name.toLowerCase().includes("home"))
    .pop();
  const health = categories
    .filter((item) => item.name.toLowerCase().includes("health"))
    .pop();

  const makeSlug = (name: string) => name.toLowerCase().split(" ").join("-");
  return (
    <div className="md:flex md:justify-center gap-[8%] md:mt-9 md:pb-5 md:mb-8">
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to={
            electronics !== undefined
              ? `/categories/supermarket/${makeSlug(electronics.name)}?category=${electronics?.id}`
              : "#"
          }
        >
          <div className="blob blob1 mb-2">
            <Image src={blob} />
          </div>
          <span className="text-white">Electronics</span>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to={
            fashion !== undefined
              ? `/categories/supermarket/${makeSlug(fashion.name)}?category=${fashion?.id}`
              : "#"
          }
        >
          <div className="blob blob2 mb-2">
            <Image src={fashionImg} />
          </div>
          <span className="text-white">Fashion</span>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to="/categories/supermarket"
        >
          <div className="blob blob3 mb-2">
            <Image src={supermarket} />
          </div>
          <span className="text-white">Supermarket</span>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to={
            home !== undefined
              ? `/categories/supermarket/${makeSlug(home.name)}?category=${home?.id}`
              : "#"
          }
        >
          <div className="blob blob4 mb-2">
            <Image src={homeImg} />
          </div>
          <span className="text-white">Home & Office</span>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to={
            health !== undefined
              ? `/categories/supermarket/${makeSlug(health.name)}?category=${health?.id}`
              : "#"
          }
        >
          <div className="blob blob5 mb-2">
            <Image src={healthImg} />
          </div>
          <span className="text-white">Health & Beauty</span>
        </Link>
      </div>
    </div>
  );
};

export default BlobFlex;
