import { useMutation as rUseMutation } from 'react-query';
import { ResponseContent } from '../../TYPES/response.types';
// import { enqueueSnackbar } from 'notistack';
import { MessageProp, apiResponse } from '../config/message.constants';

export const useMutation = <T extends unknown>(
  request: (payload: T) => Promise<ResponseContent<any>>,
  operation?: MessageProp
) => {
  const mutate = rUseMutation(request, {
    onSuccess: async data => {
      apiResponse(data, 'success', operation);
      return;
    },
    onError: (error: Record<any, any>) => {
      apiResponse(error, 'error', operation);
      return;
    }
  });
  return mutate;
};
