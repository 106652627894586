import Popover from '@mui/material/Popover';
import * as React from 'react';
import { DropCon, DropDownCon, paperProps } from './style';

type IconDropDownProps = {
  children: React.ReactNode;
  label: React.ReactNode;
  x?: number | 'left' | 'center' | 'right';
  margin?: string;
};

export const DropDown = ({ children, label, x = 'left', margin = '6px 0' }: IconDropDownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <DropCon>
      <button aria-describedby={id} onClick={handleClick}>
        {label}
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: x
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: x
        }}
        sx={DropDownCon}
        PaperProps={{ ...paperProps, style: { ...paperProps.style, margin } }}>
        <div onClick={handleClose}>{children}</div>
      </Popover>
    </DropCon>
  );
};
