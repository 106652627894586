import { Button, Stack, styled } from '@mui/material';
import { BasicBox } from '../styles';

export const BNPLCon = styled(BasicBox)`
  max-width: 100%;
`;

export const ContentCon = styled('div')`
  border: solid 1px #e2e2e2;
  border-radius: 12px;
  overflow: hidden;
`;

export const TableTabs = styled(Stack)`
  margin-top: 20px;
  margin-left: 20px;
`;

export const TableTab = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
  text-transform: capitalize;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
  font-weight: 400;
  color: #000000;

  &.active {
    border-bottom: 3px solid #2f4dc4;
  }
`;

export const Table = styled('table')`
  width: 100%;
  border-collapse: collapse;
  
`;

export const TableHead = styled('thead')`
  background-color: #f0f0f0;

  td {
    padding: 30px 30px;
  }
`;

export const TableBody = styled('tbody')`
  tr:not(:last-child) {
    border-bottom: solid 1px #e9e5e5;
    
  }
  overflow-x: scroll;
`;

export const TableRow = styled('tr')`
  td.empty-state {
    text-align: center;
  }
`;

export const TableData = styled('td')`
  padding: 20px 30px;
  font-size: 14px;
  font-weight: 400;
`;

export const IconBtn = styled('button')`
  height: 28px;
  width: 28px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    width: 24px;
  }
`;

export const RowStatus = styled('div')`
  padding: 4px 16px;
  border-radius: 7px;
  display: flex;
  align-items: center;

  &.status-pending {
    background-color: #fff6c7;
  }
  &.status-approved {
    background-color: #1ab66e1f;
  }
  &.status-repay {
    background-color: #eff1fb;
    cursor: pointer;
  }

  .bullet {
    width: 6px;
    height: 6px;
    margin-right: 8px;
    border-radius: 50%;
  }
  .bullet-pending {
    background-color: #f79009;
  }
  .bullet-approved {
    background-color: #1ab66e;
  }
  .bullet-repay {
    background-color: #2f4dc4;
  }

  .text {
    font-size: 14px;
    text-transform: capitalize;
  }
  .text-pending {
    color: #f79009;
  }
  .text-approved {
    color: #1ab66e;
  }
  .text-repay {
    color: #2f4dc4;
  }
`;
