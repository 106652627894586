import { enqueueSnackbar } from 'notistack';
import { geocode, OutputFormat, RequestType } from 'react-geocode';
import { getLatLng } from 'use-places-autocomplete';
import { UserLocation } from '../store/atoms/userLocationAtom';
import type { GeocodeResult } from 'use-places-autocomplete';
import { getEnvValue } from './environment';

export const addressToCoordinates = async (address: string) => {
  return await geocode(RequestType.ADDRESS, address, {
    key: getEnvValue('REACT_APP_GOOGLE_API_KEY'),
    outputFormat: OutputFormat.JSON
  });
};

export const getUserLocation = (callback: (location?: UserLocation) => void, throwErr: boolean = true) => {
  const onSuccess = async (position: GeolocationPosition) => {
    const res = await reverseGeocode(position.coords.latitude, position.coords.longitude);
    callback(res);
  };
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      onSuccess,
      err => {
        if (throwErr) {
          enqueueSnackbar({
            message:
              'You have blocked madina from using your location. To allow madina to use your location, please update your location settings.',
            variant: 'error'
          });
        }
        console.log({ err });
      },
      { enableHighAccuracy: true }
    );
  } else {
    enqueueSnackbar({
      message: 'Geolocation is not supported by this browser.'
    });
  }
};

const reverseGeocode = async (latitude: number, longitude: number): Promise<UserLocation | undefined> => {
  const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;

  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
  } catch (error) {
    enqueueSnackbar({
      message: `Failed to get location: ${(error as any).message}`
    });
  }
};

type AddressComponentTypes =
  | 'postal_code'
  | 'country'
  | 'political'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'locality'
  | 'route';

const getAddressComponentByType = (components: google.maps.GeocoderAddressComponent[], type: AddressComponentTypes) => {
  return components.filter(c => c.types.includes(type)).pop();
};

export const parseGoogleGeocodeResult = (res: GeocodeResult): UserLocation => {
  const { lat, lng } = getLatLng(res);
  let userLoc: UserLocation = {
    place_id: res.place_id,
    display_name: res.formatted_address,
    lat: `${lat}`,
    lon: `${lng}`,
    name: res.formatted_address,
    address: {
      county: getAddressComponentByType(res.address_components, 'country')?.long_name ?? '',
      state: getAddressComponentByType(res.address_components, 'administrative_area_level_1')?.long_name ?? '',
      country: getAddressComponentByType(res.address_components, 'administrative_area_level_2')?.long_name ?? '',
      city: getAddressComponentByType(res.address_components, 'administrative_area_level_3')?.long_name ?? '',
      suburb: getAddressComponentByType(res.address_components, 'locality')?.long_name ?? '',
      country_code: getAddressComponentByType(res.address_components, 'country')?.short_name ?? '',
      postcode: getAddressComponentByType(res.address_components, 'postal_code')?.long_name ?? ''
    }
  };
  return userLoc;
};
