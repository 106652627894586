const SEARCH_PARAMS = {
	BG_ID: "bgId",
	VENDOR_ID: "vendorId",
	PROFILE_IMAGE: "profileImage",
	SHARE_URL: "shareUrl",
	DELIVERY_METHOD: "dm",
	LOGIN_SUCCESS_REROUTE: "ls_reroute",
} as const;

export default SEARCH_PARAMS;
