import React from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { enqueueSnackbar } from 'notistack';
import { useChatSocket } from './hooks';
import QUERY_KEYS from 'network/config/queryKeys';
import { getCustomer } from 'network/services/profile';
import ChatView from 'components/molecules/m-chat-view';
import { liveChatAtom } from 'store/atoms/liveChatAtom';

const CustomerSupportChat = () => {
  const [liveChat, setLiveChat] = useRecoilState(liveChatAtom);
  const { sendMessage, setMessages, error, messages, connect, connected } = useChatSocket();
  const [senderId, setSenderId] = React.useState<string>();
  const { data } = useQuery({
    queryFn: getCustomer,
    queryKey: [QUERY_KEYS.CUSTOMER_PROFILE],
    onSuccess: data => {
      setSenderId(data.data.email);
    }
  });

  React.useEffect(() => {
    if (data?.data !== undefined) {
      connect({
        nickname: data.data.email,
        fullName: `${data.data.firstname} ${data.data.lastname}`,
        userType: 'CUSTOMER'
      });
    }
  }, [connect, data]);

  React.useEffect(() => {
    if (error !== undefined) {
      enqueueSnackbar({ message: JSON.stringify(error), variant: 'error' });
    }
  }, [error]);

  const handleSendMessage = (message: string) => {
    if (message !== '') {
      if (senderId === undefined) {
        enqueueSnackbar({ message: 'You need to login to send a message', variant: 'error' });
        return;
      } else {
        sendMessage({ senderId, message });
        setMessages(c => [
          ...c,
          {
            senderId,
            content: message,
            id: Math.random() * 100,
            recipientId: process.env.REACT_APP_MADINA_ADMIN_EMAIL ?? '',
            orderRef: liveChat.orderId,
            timestamp: new Date().toISOString()
          }
        ]);
      }
    }
  };

  React.useEffect(() => {
    const chatView = document.getElementById('chat-view');
    if (chatView) {
      chatView.scrollTop = chatView.scrollHeight + 20000;
    }
  }, [messages]);
  return (
    <ChatView
      userId={senderId ?? ''}
      disabled={!connected}
      onSend={handleSendMessage}
      messages={messages}
      onClose={() => setLiveChat(curr => ({ ...curr, step: 0 }))}
    />
  );
};

export default CustomerSupportChat;
