import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const DrawerHeader = styled('div')`
  width: 100vw;
  max-width: 270px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 18px 0 0;
  .logo {
    transform: scale(0.87);
    max-height: 100%;
  }
`;

export const textStyle = { '.MuiListItemText-primary': { fontSize: '14px' } };

export const headTextStyle = {
  pl: '15px',
  '.MuiListItemText-primary': { fontSize: '16px', fontWeight: 600, color: COLORS.MainBlue }
};
export const ImgBox = {
  backgroundColor: '#f8f7f7',
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px',
  borderRadius: '50%',
  overflow: 'hidden',
  marginRight: '1.2rem',
  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
};
