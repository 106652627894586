import { styled } from '@mui/material';
import { BasicBox } from '../styles';

export const DeliveryCON = styled(BasicBox)`
  .address-map {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
  .button button {
    max-width: 100%;
    width: 472px;
    margin: 100px auto 0;
    font-weight: 700;
  }
  @media (max-width: 1100px) {
    .address-map {
      grid-template-columns: 1fr;
    }
  }
`;
