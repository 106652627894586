import { badgePhoto } from 'components/molecules/blobs';

export interface Offer {
  title: string;
  img: string;
  href: string;
  description: string;
}

export const OFFERS: readonly Offer[] = [
  {
    title: 'BNPL',
    img: '/graphics/BNPL.png',
    href: '/account/bnpl',
    description: 'Elevate your shopping with our buy now pay later option'
  },
  {
    title: 'Refer a Friend',
    img: '/graphics/referfriend.png',
    href: '/account/subscriptions',
    description: 'Share your referral code and earn amazing rewards'
  },
  {
    title: 'Madina Plus',
    img: badgePhoto,
    href: '/account/refer',
    description: 'Earn points anytime you complete your shopping on madina'
  },
  {
    title: 'Shop & Earn',
    img: '/graphics/madinaplus.png',
    href: '/account/wallet',
    description: 'Earn points anytime you complete your shopping on madina'
  }
];
