import { Grid } from '@mui/material';
import { WalletIcon } from 'assets/Icons';
import { Divider, Spacer } from 'components/atoms';
import { isNaN } from 'lodash';
import { CheckoutProduct, PointModal } from 'pages/Checkout/component';
import { CheckoutItems, PriceSummary } from 'pages/Checkout/style';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cartListAtom } from 'store/atoms/cartAtom';
import { checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import checkoutVariablesAtom from 'store/atoms/checkoutVariables';
import { pointsAtom } from 'store/atoms/points';
import { cartTotalAmount } from 'store/selectors/cartSelector';
import { formatCurrency } from 'utils/currencyFormater';

const CheckoutSummary = () => {
  const [points, setPoints] = useRecoilState(pointsAtom);
  const [user, checkNewUser] = useState('');
  const cartList = useRecoilValue(cartListAtom);
  const subTotal = useRecoilValue(cartTotalAmount);
  const checkoutDetails = useRecoilValue(checkoutDetailsAtom);
  const checkoutVariables = useRecoilValue(checkoutVariablesAtom);

  useEffect(() => {
    return checkNewUser(sessionStorage.getItem('newuser') ?? 'false');
  }, []);  
  return (
    <>
      <Grid item xs={12} md={4.5} lg={3.75}>
        <CheckoutItems>
          <h3>Your Items</h3>
          <Spacer height={20} />
          <Divider />
          <Spacer height={35} />
          <div>
            {cartList.map((item, index) => {
              return (
                <div key={index}>
                  {index > 0 && <Spacer height={35} />}
                  <CheckoutProduct item={{ ...item, index }} />
                </div>
              );
            })}            
          </div>          
        </CheckoutItems>        
        <PriceSummary>
        {user === 'true' && (
            <div>
              <p className="font-bold text-sm">10% Discount On Your First Transaction 🥳</p>
            </div>
          )}
          <div className="subtotal">
            <p>Subtotal</p>
            <p>{formatCurrency(subTotal)}</p>
          </div>
          <div className="delivery">
            <p>Delivery</p>
            <p>{formatCurrency(checkoutDetails.shippingFee ?? 0)}</p>
          </div>
          <div className="total">
            <p>Total</p>
            <p>{formatCurrency(subTotal + (checkoutDetails.shippingFee ?? 0))}</p>
          </div>

          <div className="wallet">
            <p className="wallet-point" onClick={() => setPoints(c => ({ ...c, open: true }))}>
              <WalletIcon /> <span className="wallet-text">Point from Wallet</span>
            </p>
            <p className="flex items-center gap-2">
              <span>-</span>
              <span>
                {formatCurrency(
                  `${
                    isNaN(checkoutVariables.pointsUsed.pointNairaEquivalent)
                      ? 0
                      : checkoutVariables.pointsUsed.pointNairaEquivalent
                  }`
                )}
              </span>
            </p>
          </div>
          <div className="grandtotal">
            <p>Grand Total</p>
            <p>
              {formatCurrency(
                subTotal +
                  (checkoutDetails.shippingFee ?? 0) -
                  (isNaN(checkoutVariables.pointsUsed.pointNairaEquivalent)
                    ? 0
                    : checkoutVariables.pointsUsed.pointNairaEquivalent)
              )}
            </p>
          </div>
          
        </PriceSummary>
      </Grid>
      {points.open && <PointModal />}
    </>
  );
};

export default CheckoutSummary;
