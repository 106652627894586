import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';

export const verifyIdentityStepsAtom = atom<number>({
  key: STORE_KEYS.VERIFY_IDENTITY_STEPS,
  default: 0
});

export interface BvnLookup {
  bvn: string;
  session_id: string;
  methods: {
    method: string;
    hint: string;
  }[];
  phone?: string;
}

export const DEFAULT_BVN_LOOKUP = {
  bvn: '',
  session_id: '',
  methods: [{ method: '', hint: '' }]
};

export const bvnLookupAtom = atom<BvnLookup>({
  key: STORE_KEYS.BVN_LOOKUP,
  default: DEFAULT_BVN_LOOKUP
});
