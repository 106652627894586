import { InboxTruck } from 'assets/Icons';
import moment from 'moment';

type MessageListProp = {
  data: {
    subject?: string;
    content?: string;
    dateTime?: string;
    data: {
      type: string;
      title: string;
      description: string;
      time: string;
    }[];
  };
};

export const MessageList = ({ data }: MessageListProp) => {
  return (
    <div className="w-full mb-2 flex">
      <div className="flex gap-2 items-start w-[100%]">
        <InboxTruck className="lg:block hidden" />
        <div className="flex flex-col gap-1 w-full">
          {data.subject && <h3 className="font-semibold ">{data.subject}</h3>}
          {data?.content && <span className="text-black/60 whitespace-normal max-w-full overflow-x-hidden">{data?.content}</span>}
        </div>
      </div>
      <span className="hidden lg:block text-sm whitespace-nowrap">{moment(data?.dateTime).format('D MMMM YYYY')}</span>
    </div>
  );
};
