import { COMMONTYPES } from 'TYPES/event.types';
import { Button, InputField, Spacer } from 'components/atoms';
import { useMutation } from 'network/mutations/useMutationHook';
import { changePassword } from 'network/services/profile';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { checkFormData, validators } from 'utils/validator';

const INITIALSTATE = {
  oldPassword: '',
  password: '',
  confirmPassword: ''
};

export const ChangePassword = () => {
  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);

  const { oldPassword, password, confirmPassword } = formData;

  const handleChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    const err = validators[name](value);

    setFormData(oldCred => ({ ...oldCred, [name]: value }));
    setFormError(oldCred => ({ ...oldCred, [name]: err }));
  };

  const { mutateAsync, isLoading } = useMutation(changePassword, 'CHANGE_PASSWORD');

  const _submit = async (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();
    const isValid = checkFormData(formData, setFormError);
    if (!isValid) return;
    try {
      const { data } = await mutateAsync(formData);
      enqueueSnackbar({ message: data.description, variant: 'success' });
    } catch (error) {
      enqueueSnackbar({ message: 'Change password failed', variant: 'error' });
    }
  };

  return (
    <>
      <h2>Change Password</h2>
      <InputField
        label="Enter Old Password"
        name="oldPassword"
        value={oldPassword}
        onChange={handleChange}
        placeholder="Old Password"
        error={formError.oldPassword}
        required
      />
      <Spacer height="32px" />
      <InputField
        label="Enter New Password"
        name="password"
        value={password}
        onChange={handleChange}
        placeholder="New Password"
        error={formError.password}
        required
      />
      <Spacer height="32px" />
      <InputField
        label="Confirm Password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={handleChange}
        placeholder="Confirm Password"
        error={formError.confirmPassword}
        required
      />
      <Spacer height="49px" />
      <Button onClick={_submit} disabled={isLoading}>
        Change
      </Button>
    </>
  );
};
