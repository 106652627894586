import { Loader } from "@mantine/core";
import LOCALSTORAGE_KEYS from "constants/localStorage";
import QUERY_KEYS from "network/config/queryKeys";
import { clearCart } from "network/services/goods";
import { enqueueSnackbar } from "notistack";
import { BiTrash } from "react-icons/bi";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userIsLoggedIn } from "store/atoms/userAtom";
import { CartIcon } from "../../../assets/Icons";
import { CHECKOUT } from "../../../navigation/routes";
import { cartListAtom, isCartOpen } from "../../../store/atoms/cartAtom";
import { cartTotalAmount } from "../../../store/selectors/cartSelector";
import { formatCurrency } from "../../../utils/currencyFormater";
import { Button, Divider, EmptyCon, Spacer } from "../../atoms";
import { Modal } from "../Modal/Modal";
import { SideModalContentStyle, SideModalStyle } from "../Modal/style";
import { CartProduct } from "./CartProduct";
import { CartCon, CheckoutBottom, SubTotal } from "./style";

const initial = {
  x: "100%",
  height: "auto",
};
const animate = {
  x: "0",
};

export const Cart = () => {
  const setIsModalOpen = useSetRecoilState(isCartOpen);

  const isLogged = useRecoilValue(userIsLoggedIn);
  const [cartList, setCartList] = useRecoilState(cartListAtom);
  const total = useRecoilValue(cartTotalAmount);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: clearCart,
    onSuccess: () => {
      enqueueSnackbar("Cart Cleared Successfully", { variant: "success" });
      queryClient.invalidateQueries(QUERY_KEYS.CART);
    },
    onError: () => {
      enqueueSnackbar("An Error Occured", { variant: "error" });
    },
  });

  const gotoCheckout = () => {
    setIsModalOpen({ onCartOpen: false });
    navigate(CHECKOUT);
  };

  const handleDelete = () => {
    setCartList([]);
    localStorage.removeItem(LOCALSTORAGE_KEYS.CART);
  };

  return (
    <Modal
      onModalClose={() => {
        setIsModalOpen({ onCartOpen: false });
      }}
      extraModalStyle={SideModalStyle}
      extraModalContentStyle={SideModalContentStyle}
      initial={initial}
      animate={animate}
    >
      {cartList.length ? (
        <>
          <CartCon>
            <h3>My Cart</h3>
            <Spacer height={40} />
            {cartList.map((item, index) => (
              <CartProduct item={item} index={index} key={index} />
            ))}
          </CartCon>
          <Spacer height={30} />
          <CheckoutBottom>
            <Divider />
            <SubTotal>
              <h2>Subtotal</h2>
              <h4>{formatCurrency(total)}</h4>
            </SubTotal>
            <Divider />
            <Spacer height={50} />
            <div className="flex w-full items-center gap-2">
              <Button className="grow" onClick={gotoCheckout}>
                Checkout
              </Button>
              <button
                className="text-red-600 bg-red-100 h-full aspect-square p-5 shadow-sm rounded-md"
                onClick={() => {
                  if (!isLogged) {
                    handleDelete();
                  } else {
                    mutation.mutate();
                  }
                }}
              >
                {mutation.isLoading ? (
                  <Loader color="white" size="sm" />
                ) : (
                  <BiTrash />
                )}
              </button>
            </div>
          </CheckoutBottom>
        </>
      ) : (
        <EmptyCon>
          <CartIcon /> No Items in cart
        </EmptyCon>
      )}
    </Modal>
  );
};
