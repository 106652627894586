import api from '../config/setAuthHeader';
import { CHANGE_PASSWORD, DELETE_ACCOUNT, GET_CUSTOMER, UPDATE_CUSTOMER, UPDATE_PIC } from '../config/endpoints';
import { ChangePassword, CustomerResponseData, ResponseSuccess, UpdateCustomer } from 'TYPES/api.body';

export const getCustomer = async (): Promise<ResponseSuccess<CustomerResponseData>> => {
  const { data } = await api.get(GET_CUSTOMER);
  return data;
};

export const updateCustomer = async (formData: UpdateCustomer) => {
  return await api.post(UPDATE_CUSTOMER, formData);
};

export const changePassword = async (formData: ChangePassword) => {
  return await api.post(CHANGE_PASSWORD, formData);
};

export const updateProfilePic = async (formData: FormData) => {
  return await api.post(UPDATE_PIC, formData);
};

export const deleteCustomerAccount = async (password: string) => {
  return await api.delete(DELETE_ACCOUNT.concat(`?password=${password}`));
};
