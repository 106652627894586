import { useRecoilValue } from 'recoil';
import 'react-phone-input-2/lib/style.css';
import { enqueueSnackbar } from 'notistack';
import PhoneInput from 'react-phone-input-2';
import { ChangeEvent, useState } from 'react';
import { HidePassword, ShowPassword } from 'assets/Icons';
import { businessRegDetails } from 'store/atoms/businessKYB';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { LOGIN } from 'navigation/routes';
import { validators } from 'utils/validator';
import BusinessSignUp from './Business/SignUp';
import { TabPanel } from 'components/molecules';
import { register } from 'network/services/auth';
import BusinessKYB from './Business/BusinessKYB';
import { useMutation } from 'network/mutations/useMutationHook';
import { InputField } from 'components/atoms/InputField/InputField';
import { Button, Container, EmptyCon, Spacer } from 'components/atoms';
import {
  AltSignUp,
  AuthContainer,
  CustomAuthTab,
  CustomAuthTabs,
  extraStylesInput,
  extraStylesInputPhone,
  InputCon,
  TogglePassword
} from './styles';

const INITIALSTATE = {
  email: '',
  password: '',
  phoneNumber: '',
  lastname: '',
  firstname: ''
};

const SignUp = () => {
  const [searchParam] = useSearchParams();
  const referralCode = searchParam.get('referralCode');
  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);

  const businessFormData = useRecoilValue(businessRegDetails);

  const [agreement, setAgreement] = useState(false);

  const onToggleAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
  };

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutation(register, 'SIGNUP');

  const _register = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const completeData = referralCode !== null ? { ...formData, referralCode } : formData;
      await mutateAsync(completeData);
      enqueueSnackbar('An OTP has been sent to your email to verify your account', { variant: 'info' });
      navigate(`/otp/${formData.email}`);
    } catch (error) {
      console.log(error);
    }
  };

  const [value, setValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const disabled =
    !formData.email ||
    !formData.password ||
    !formData.phoneNumber ||
    !!validators.email(formData.email) ||
    !!validators.password(formData.password);

  return (
    <AuthContainer>
      {businessFormData.step === 1 ? (
        <div>
          <h1>Create an account</h1>
          <p className="option">
            Already have an account? <span onClick={() => navigate(LOGIN)}>Log in</span>
          </p>

          <Spacer height="2rem" />

          <Container
            extraStyles={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CustomAuthTabs value={value} onChange={handleChange} aria-label="Authentication Tab">
              <CustomAuthTab label="Individual" {...a11yProps(0)} />
              <CustomAuthTab label="Business" {...a11yProps(1)} />
            </CustomAuthTabs>
          </Container>

          <TabPanel index={0} value={value}>
            <Container>
              <form className="sign-up">
                <AltSignUp>
                  <InputCon>
                    <span>Firstname</span>
                    <InputField
                      name="firstname"
                      onChange={onUpdateFormData}
                      placeholder="Enter a name"
                      extraStyles={extraStylesInput}
                      required={true}
                      error={formError.firstname}
                    />
                  </InputCon>
                  <Spacer height="2rem" width="2rem" />
                  <InputCon>
                    <span>Lastname</span>
                    <InputField
                      name="lastname"
                      onChange={onUpdateFormData}
                      placeholder="Enter a name"
                      extraStyles={extraStylesInput}
                      required={true}
                      error={formError.lastname}
                    />
                  </InputCon>
                </AltSignUp>
                <Spacer height="1.5rem" />
                <InputCon>
                  <span>Email Address</span>
                  <InputField
                    type="email"
                    name="email"
                    onChange={onUpdateFormData}
                    placeholder="example@mail.com"
                    extraStyles={extraStylesInput}
                    required={true}
                    error={formError.email}
                    autoComplete="username"
                  />
                </InputCon>
                <Spacer height="1.5rem" />
                <InputCon>
                  <span>Phone Number</span>
                  <PhoneInput
                    country={'ng'}
                    value={formData.phoneNumber}
                    onChange={phone => {
                      setFormData({ ...formData, phoneNumber: phone });
                    }}
                    placeholder={'80XXXXXXXX'}
                    autoFormat={false}
                    inputStyle={extraStylesInputPhone}
                    // error={formError.phoneNumber}
                  />
                </InputCon>
                <Spacer height="1.5rem" />
                <InputCon>
                  <span>Password</span>
                  <InputField
                    type={showPassword}
                    name="password"
                    onChange={onUpdateFormData}
                    placeholder="enter your password"
                    extraStyles={extraStylesInput}
                    required={true}
                    error={formError.password}
                    action={
                      <TogglePassword onClick={onTogglePassword}>
                        {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                      </TogglePassword>
                    }
                    autoComplete="new-password"
                  />
                </InputCon>

                <div className="policy">
                  <input type="checkbox" name="policy" id="policy" onChange={onToggleAgreement} />
                  <p className="option">
                    I have read, understood and I agree to Madina’s
                    <Link className="text-primary font-bold" to="/privacy-policy">
                      {' '}
                      Privacy Policy
                    </Link>
                    , and{' '}
                    <Link className="text-primary font-bold" to="/terms-and-conditions">
                      Terms and conditions
                    </Link>
                    .
                  </p>
                </div>

                <div className="buttonContainer">
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    onClick={_register}
                    disabled={disabled || isLoading || !agreement}>
                    Create Account
                  </Button>
                </div>
              </form>
            </Container>
          </TabPanel>
          <TabPanel index={1} value={value}>
            <BusinessSignUp />
          </TabPanel>
          <TabPanel index={2} value={value}>
            <EmptyCon>
              <p>No content for seller yet</p>
            </EmptyCon>
          </TabPanel>
        </div>
      ) : (
        businessFormData.step === 2 && <BusinessKYB />
      )}
    </AuthContainer>
  );
};

export default SignUp;
