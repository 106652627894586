import { PaymentIcon, RIGHT_ICON } from '../../../assets/Icons';
import { PaymentMethod } from './style';

type IProp = {
  title: string;
  desc?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
};
export const PaymentButton = ({
  title,
  onClick,
  className,
  desc = 'Enjoy phenomenal transaction success rates'
}: IProp) => {
  return (
    <PaymentMethod className={className} onClick={onClick}>
      <div className="left">
        <PaymentIcon />
        <div className="text">
          <p>{title}</p>
          <p>{desc}</p>
        </div>
      </div>
      <RIGHT_ICON color="#000" />
    </PaymentMethod>
  );
};
