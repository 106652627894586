import { styled } from '@mui/material';

export const CategorySection = styled('div')({
  background: '#fff',
  padding: '2rem',
  h1: {
    paddingBottom: '1rem',
    textTransform: 'capitalize'
  }
});
