import { ApiSuccessResponse, BVNRESPONSE } from 'network/config/interface';
import { BNPLDetails, ICheckCreditScore, ICheckoutWithWallet, Paginated } from 'TYPES/api.body';
import {
  CHECK_BNPL_LOAN_STATUS,
  CHECK_CREDIT_SCORE,
  CHECK_LOAN_INSURANCE,
  CHECKOUT_WITH_BNPL,
  CHECKOUT_WITH_WALLET,
  POST_PURCHASE_PAYMENTS,
  CHECK_BVN_VERIFIED
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const v1GetVerifiedBVN = async (): Promise<ApiSuccessResponse<BVNRESPONSE>> => {
  return api.get(CHECK_BVN_VERIFIED);
};

export const checkCreditScore = async (formData: ICheckCreditScore) => {
  const loanStatusPromise = api.get(CHECK_BNPL_LOAN_STATUS);
  const veficationPromise = v1GetVerifiedBVN();
  const [loanRes, verifiedRes] = await Promise.all([loanStatusPromise, veficationPromise]);
  if (!(loanRes.data.status === 'Success')) {
    throw new Error('You still have a pending loan request', {
      cause: 'unpaid-loan'
    });
  }
  if (!(verifiedRes.data.data === true)) {
    throw new Error('BVN not verified', {
      cause: 'unvefied-bvn'
    });
  }
  return await api.post(CHECK_CREDIT_SCORE, formData);
};

export const checkoutWithBNPL = async (formData: BNPLDetails) => {
  return await api.post(CHECKOUT_WITH_BNPL, formData);
};

export const checkLoanInsurance = async () => {
  return await api.get(CHECK_LOAN_INSURANCE);
};

export const checkoutWithWallet = async (formData: ICheckoutWithWallet) => {
  return await api.post(CHECKOUT_WITH_WALLET, formData);
};

export const getPostPurchasePayments = async (opts: Paginated) => {
  return await api.get(POST_PURCHASE_PAYMENTS.concat(`?page=${opts.page ?? 0}&pageSize=${opts.size ?? 20}`));
};
