import { Icon } from '@iconify/react';
import { Modal } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import modalAtom from 'store/atoms/modals';

const TransactionInProgressModal = () => {
  const modalState = useRecoilValue(modalAtom);
  return (
    <Modal radius="lg" opened={modalState.transactionInProgressModalOpen} onClose={() => { }} centered size="lg">
      <div className="flex flex-col gap-4 items-center justify-center">
        <span className="text-8xl text-primary">
          <Icon icon="svg-spinners:ring-resize" />
        </span>
        <h2 className="font-bold text-xl my-8">Transaction In Progress</h2>
      </div>
    </Modal>
  );
};

export default TransactionInProgressModal;
