import { Theme, useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useSetRecoilState } from 'recoil';
import { ThumbsUpIcon } from '../../../../../assets/Icons';
import { Button, Spacer } from '../../../../../components/atoms';
import { Modal } from '../../../../../components/molecules';
import { CenterModalContentStyle, CenterModalStyle } from '../../../../../components/molecules/Modal/style';
import { useMutation } from '../../../../../network/mutations/useMutationHook';
import { checkLoanInsurance } from '../../../../../network/services/checkout';
import { getLoanSettings, getLoanTenures } from '../../../../../network/services/loanManagement';
import { loanInsuranceAtom, loanSettingsAtom, loanTenuresAtom } from '../../../../../store/atoms/checkoutAtom';
import { LoanApprovedCon, ThumbCon } from './style';

type LoanApprovedProp = {
  nextStep: (number: number) => void;
  setIsModalOpen: (number: number) => void;
};

export const LoanApproved = ({ setIsModalOpen, nextStep }: LoanApprovedProp) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { mutateAsync: mutateLoanInsuranceAsync, isLoading: isLoanInsuranceLoading } = useMutation(checkLoanInsurance);
  const { mutateAsync: mutateLoanTenuresAsync, isLoading: isLoanTenuresLoading } = useMutation(getLoanTenures);
  const { mutateAsync: mutateLoanSettingsAsync, isLoading: isLoanSettingsLoading } = useMutation(getLoanSettings);
  const setLoanInsurance = useSetRecoilState(loanInsuranceAtom);
  const setLoanTenures = useSetRecoilState(loanTenuresAtom);
  const setLoanSettings = useSetRecoilState(loanSettingsAtom);

  const setModalClose = () => {
    setIsModalOpen(0);
  };

  const handleContinue = async () => {
    try {
      const [loanInsuranceData, loanTenuresData, loanSettingsData] = await Promise.all([
        mutateLoanInsuranceAsync({}),
        mutateLoanTenuresAsync({}),
        mutateLoanSettingsAsync({})
      ]);

      setLoanInsurance(loanInsuranceData.data.data[0]);
      setLoanTenures(loanTenuresData.data);
      setLoanSettings(loanSettingsData.data);

      nextStep(4);
    } catch (error) {
      enqueueSnackbar(`Error Approving loan: ${(error as any).message}`, { variant: 'error' });
    }
  };

  return (
    <Modal
      onModalClose={setModalClose}
      maxWidth="500"
      extraModalContentStyle={{ ...CenterModalContentStyle, width: sm ? '550px' : '' }}
      extraModalStyle={CenterModalStyle}>
      <LoanApprovedCon>
        <ThumbCon>
          <ThumbsUpIcon />
        </ThumbCon>
        <h3>You qualify for the BNPL loan 🎉</h3>

        <Spacer height="40px" />

        <Button
          onClick={handleContinue}
          isLoading={isLoanInsuranceLoading && isLoanTenuresLoading && isLoanSettingsLoading ? true : false}
          disabled={isLoanInsuranceLoading && isLoanTenuresLoading && isLoanSettingsLoading ? true : false}>
          View Loan Details
        </Button>
      </LoanApprovedCon>
    </Modal>
  );
};
