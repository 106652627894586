import { CircularProgress, Tooltip } from "@mui/material";
import { DeleteCartProduct } from "assets/Icons";
import $ from "lodash";
import QUERY_KEYS from "network/config/queryKeys";
import {
  removeProductFromCart,
  updateCartItemQty,
} from "network/mutations/products";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { CartItem, cartListAtom } from "store/atoms/cartAtom";
import { profileAtom } from "store/atoms/profileAtom";
import { formatCurrency } from "utils/currencyFormater";
import { truncateCharCount } from "utils/truncate";
import { Logo, ProductQtyCounter } from "../../atoms";
import {
  localRemoveProductFromCart,
  localUpdateCartItemQuantity,
  performRemoveProductFromCart,
  performUpdateCartItemQty,
} from "../Goods/utils";
import { CartProductCon } from "./style";

type ICartProp = {
  item: CartItem;
  index: number;
};
export const CartProduct: React.FC<ICartProp> = ({ item, index }) => {
  const queryClient = useQueryClient();
  const profile = useRecoilValue(profileAtom);
  const [value, setValue] = useState(item.quantity);
  const maxquantiy = item.quantityAvailable;
  const { amount, name, photo } = item;
  const { mutateAsync: removeFn, isLoading: deleting } = useMutation({
    mutationFn: removeProductFromCart,
  });
  const { mutateAsync: updateMutate } = useMutation({
    mutationFn: updateCartItemQty,
  });

  const [cartList, setCartList] = useRecoilState(cartListAtom);

  const deleteItem = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    if (profile === undefined) {
      localRemoveProductFromCart(cartList, index, setCartList);
    } else {
      performRemoveProductFromCart(
        cartList,
        index,
        removeFn,
        setCartList,
        queryClient,
      );
    }
  };

  const debounceUpdateQty = $.debounce((qty: number) => {
    const item = cartList[index];
    if (profile === undefined) {
      if (qty < 1) {
        localRemoveProductFromCart(cartList, index, setCartList);
      } else {
        localUpdateCartItemQuantity(qty, cartList, item, setCartList);
      }
    } else {
      if (qty < 1) {
        performRemoveProductFromCart(
          cartList,
          index,
          removeFn,
          setCartList,
          queryClient,
        );
      } else {
        performUpdateCartItemQty(
          cartList,
          qty,
          item,
          updateMutate,
          removeFn,
          setCartList,
        )
          .then(() => {
            queryClient.invalidateQueries(QUERY_KEYS.CART);
          })
          .catch((err) => {
            enqueueSnackbar("Failed to update item quantity", {
              variant: "error",
            });
            console.log(err);
          });
      }
    }
  }, 1000);

  const onChangeQuantity = (value: number) => {
    setValue(value);
    debounceUpdateQty(value);
  };

  return (
    <CartProductCon>
      <div className="imageCon">
        {photo !== undefined ? (
          <img src={photo} alt="productImage" />
        ) : (
          <Logo />
        )}
      </div>

      <div className="productDetailsCon">
        <div className="top">
          <div>
            <Tooltip title={name}>
              <h1>{truncateCharCount(name, 12)}</h1>
            </Tooltip>
          </div>
          <h4>{formatCurrency(amount)}</h4>
        </div>

        <div className="bottom">
          <ProductQtyCounter
            value={value}
            setValue={onChangeQuantity}
            max={maxquantiy}
          />
          {deleting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <DeleteCartProduct onClick={deleteItem} />
          )}
        </div>
      </div>
    </CartProductCon>
  );
};
