import { useRecoilState } from 'recoil';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { ChangeEvent, useState } from 'react';
import { validators } from 'utils/validator';
import { HidePassword, ShowPassword } from 'assets/Icons';
import { businessRegDetails } from 'store/atoms/businessKYB';
import { Button, Container, Spacer } from 'components/atoms';
import { InputField } from 'components/atoms/InputField/InputField';
import { extraStylesInput, extraStylesInputPhone, InputCon, TogglePassword } from './styles';

const INITIALSTATE = {
  email: '',
  password: '',
  phoneNumber: '',
  lastname: 'Business',
  firstname: 'Business'
};

const SignUp = () => {
  const [formError, setFormError] = useState(INITIALSTATE);
  const [businessFormData, setBusinessFormData] = useRecoilState(businessRegDetails);

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setBusinessFormData(curr => ({
      ...curr,
      request: { ...curr.request, [name]: value }
    }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');
  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const _handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setBusinessFormData(curr => ({ ...curr, step: 2 }));
  };

  const disabled =
    !businessFormData.request.email ||
    !businessFormData.request.password ||
    !businessFormData.request.phoneNumber ||
    !!validators.email(businessFormData.request.email) ||
    !!validators.password(businessFormData.request.password);

  return (
    <Container>
      <form className="sign-up">
        <InputCon>
          <span>Email Address</span>
          <InputField
            type="email"
            name="email"
            onChange={onUpdateFormData}
            placeholder="example@mail.com"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.email}
            autoComplete="username"
          />
        </InputCon>
        <Spacer height="1.5rem" />
        <InputCon>
          <span>Phone Number</span>
          <PhoneInput
            country={'ng'}
            value={businessFormData.request.phoneNumber}
            onChange={phone => {
              setBusinessFormData(curr => ({
                ...curr,
                request: {
                  ...curr.request,
                  phoneNumber: phone
                }
              }));
            }}
            placeholder={'80XXXXXXXX'}
            autoFormat={false}
            inputStyle={extraStylesInputPhone}
          />
        </InputCon>
        <Spacer height="1.5rem" />
        <InputCon>
          <span>Password</span>
          <InputField
            type={showPassword}
            name="password"
            onChange={onUpdateFormData}
            placeholder="enter your password"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.password}
            action={
              <TogglePassword onClick={onTogglePassword}>
                {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
              </TogglePassword>
            }
            autoComplete="new-password"
          />
        </InputCon>

        <Spacer height="4rem" />

        <div className="buttonContainer">
          <Button type="submit" onClick={_handleSubmit} disabled={disabled}>
            Continue
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default SignUp;
