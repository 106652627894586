import { parseInt } from 'lodash';
import QUERY_KEYS from 'network/config/queryKeys';
import React from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getProductByCategory, getSubcategories, getVendorById } from '../../network/services/goods';

export default function useGoodsByCategoriesHook() {
  const [searchParams] = useSearchParams();
  const LIMIT = 20;

  const category = searchParams.get('category');

  const {
    isFetching: categoryProductsFetching,
    data: categoryProductsData,
    hasNextPage: categoryProductsHasNext,
    fetchNextPage: categoryProductsFetchNext
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.PRODUCT_BY_CATEGORIES, { categoryId: category }],
    enabled: category != null ? !!category : true,
    queryFn: ({ pageParam = 0 }) => getProductByCategory({ categoryId: category, page: pageParam, size: LIMIT }),
    getNextPageParam: (lastPage, allPages) => ((lastPage ?? []).length === LIMIT ? allPages.length : undefined)
  });

  const fetchMoreCategoryProducts = React.useCallback(() => {
    if (categoryProductsHasNext) {
      categoryProductsFetchNext();
    }
  }, [categoryProductsFetchNext, categoryProductsHasNext]);

  const { data: subCategories } = useQuery(
    ['subCategories', { categoryId: category != null ? category : 1 }],
    getSubcategories,
    {
      enabled: category != null ? !!category : true
    }
  );

  const superMarketSubCategories = subCategories || [];

  return {
    superMarketSubCategories,
    categoryProductsData,
    categoryProductsFetching,
    fetchMoreCategoryProducts
  };
}

export const useGoodsByVendor = () => {
  const [searchParams] = useSearchParams();

  const vendorId = searchParams.get('vendorId');
  return useQuery(['vendorDetails', vendorId], () => getVendorById(parseInt(vendorId, 10)));
};
