import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '../../../../../components/molecules';
import { CenterModalContentStyle, CenterModalStyle } from '../../../../../components/molecules/Modal/style';
import { ACCOUNT, VERIFY_IDENTITY } from '../../../../../navigation/routes';
import { VerifyIdentityContainer } from './style';

interface Props {
  setModalIsOpen: (step: number) => void;
  nextStep: (step: number) => void;
}
const VerifyIdentityModal: React.FC<Props> = ({ setModalIsOpen, nextStep }) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  function handleClose() {
    setModalIsOpen(0);
  }
  return (
    <Modal
      onModalClose={handleClose}
      maxWidth="500"
      extraModalStyle={CenterModalStyle}
      extraModalContentStyle={{ ...CenterModalContentStyle, width: sm ? '550px' : '' }}>
      <VerifyIdentityContainer>
        <p>You need to verify your identity to continue</p>
        <Link onClick={() => nextStep(0)} className="link" to={`${ACCOUNT}/${VERIFY_IDENTITY}`}>
          Verify
        </Link>
      </VerifyIdentityContainer>
    </Modal>
  );
};

export default VerifyIdentityModal;
