import { styled } from '@mui/material';
import { BasicBox } from '../styles';

export const BNPLDetailsCon = styled(BasicBox)`
  max-width: 100%;

  .header {
    display: flex;
    align-items: center;
  }

  .header .icon-wrap {
    padding-top: 5px;
    margin-right: 15px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;

export const ContentCon = styled('div')`
  margin: 0 20px;
  min-height: 200px;
`;

export const ContentHeader = styled('div')`
  padding: 12px 24px;
  background-color: #e0e4f6;

  .label {
    margin-bottom: 8px;
    color: #2f4dc4;
    font-size: 14px;
    font-weight: 500;
  }

  .value {
    color: #292d32;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const ContentBody = styled('div')`
  margin-top: 35px;

  .heading {
    color: #656769;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const ListItem = styled('div')`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .label {
    color: #00000073;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
  }

  .value {
    color: #180101;
    font-size: 14px;
    font-weight: 700;
  }
`;

export const BtnExtraStyles = {
  marginTop: '30px',
  // padding: '10px',
  width: '152px',
  height: '48px',
  fontSize: '16px',
  fontWeight: '700'
};
export const CancelBtnStyle = {
  marginTop: '30px',
  // padding: '10px',
  width: '152px',
  height: '48px',
  fontSize: '16px',
  fontWeight: '700',
  background: 'red'
}
