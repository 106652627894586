import React from "react";
import { logout as externLogout } from "network/services/auth";
import { useSetRecoilState } from "recoil";
import { userIsLoggedIn, userRoleAtom } from "store/atoms/userAtom";
import { buyNowPayLaterStepsAtom } from "store/atoms/buyNowPayLaterAtom";
import { profileAtom } from "store/atoms/profileAtom";
import { useQueryClient } from "react-query";
import { apiResponse } from "network/config/message.constants";

const useLogout = () => {
  const setUserStatus = useSetRecoilState(userIsLoggedIn);
  const setUserRole = useSetRecoilState(userRoleAtom);
  const setBNPLStatus = useSetRecoilState(buyNowPayLaterStepsAtom);
  const setProfile = useSetRecoilState(profileAtom);

  const queryClient = useQueryClient();

  const logout = React.useCallback(async () => {
    try {
      const data = await externLogout();
      apiResponse(data, "success", "LOGOUT");
    } catch (error) {
      console.log(error);
    }
    queryClient.clear();
    setUserRole("NORMAl");
    setBNPLStatus(0);
    setProfile(undefined);
    setUserStatus(false);
    sessionStorage.clear();
  }, [queryClient, setBNPLStatus, setProfile, setUserRole, setUserStatus]);

  return logout;
};

export default useLogout;
