import React, { useState } from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { HeaderCON } from '../styles';
import { ProfileIcon, SupportIcon } from 'assets/Icons';
import CustomerSupport from 'components/organisms/o-customer-support';
import { CustomTab, CustomTabs, Faqs, TabPanel, a11yProps } from 'components/molecules';

export const Support = () => {
  const [currentRoute, setCurrentRoute] = useState(0);
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: any) => {
    setCurrentRoute(value);
  };
  return (
    <>
      {!lg && <HeaderCON>Support</HeaderCON>}
      <Grid container gap={'35px'}>
        <Grid xs={12} lg={3.5} item>
          <CustomTabs
            value={currentRoute}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            orientation={lg ? 'horizontal' : 'vertical'}>
            <CustomTab label="FAQs" {...a11yProps(0)} disableRipple icon={<ProfileIcon />} iconPosition="start" />
            <CustomTab
              label="Customer Support"
              {...a11yProps(1)}
              disableRipple
              icon={<SupportIcon />}
              iconPosition="start"
            />
          </CustomTabs>
        </Grid>
        <Grid xs={12} md item>
          <TabPanel value={currentRoute} index={0}>
            <Faqs />
          </TabPanel>
          <TabPanel value={currentRoute} index={1}>
            <CustomerSupport />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};
