import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HidePassword, ShowPassword } from '../../assets/Icons';
import { Button, Spacer } from '../../components/atoms';
import { InputField } from '../../components/atoms/InputField/InputField';
import { LOGIN } from '../../navigation/routes';
import { useMutation } from '../../network/mutations/useMutationHook';
import { newPassword } from '../../network/services/auth';
import { checkFormData, validators } from '../../utils/validator';
import { AuthContainer, InputCon, TogglePassword, extraStylesInput } from './styles';

const INITIALSTATE = {
  password: '',
  confirmPassword: ''
};

const NewPassword = () => {
  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);
  const formValid = React.useMemo(() => checkFormData(formData, setFormError), [formData]);

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const [searchParams] = useSearchParams();
  const { mutateAsync, isLoading } = useMutation(newPassword);

  const navigate = useNavigate();

  const _resetPassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const otpParam = searchParams.get('otp');
    if (!(formData.password === formData.confirmPassword)) {
      enqueueSnackbar('Passwords do not match', { variant: 'error' });
      return;
    }

    if (!formValid) {
      enqueueSnackbar('Invalid Input', { variant: 'error' });
      return;
    }

    try {
      await mutateAsync({
        ...formData,
        otp: otpParam ? otpParam : undefined
      });
      navigate(LOGIN);
    } catch (error) {
      console.log(error);
    }
  };

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const disabled = !formData.password.length || !formData.confirmPassword.length;

  return (
    <AuthContainer>
      <h1>Please choose new password</h1>
      <p className="option">enter a secure password different from previously used password</p>
      <form>
        <InputCon>
          <span>New Password</span>
          <InputField
            type={showPassword}
            name="password"
            onChange={onUpdateFormData}
            placeholder="Enter your password"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.password}
            action={
              <TogglePassword onClick={onTogglePassword}>
                {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
              </TogglePassword>
            }
          />
        </InputCon>
        <Spacer height="2rem" />
        <InputCon>
          <span>Confirm New Password</span>
          <InputField
            type={showPassword}
            name="confirmPassword"
            onChange={onUpdateFormData}
            placeholder="Enter your password"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.confirmPassword}
            action={
              <TogglePassword onClick={onTogglePassword}>
                {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
              </TogglePassword>
            }
          />
        </InputCon>
      </form>
      <div className="buttonContainer">
        <Button
          // extraStyles={bottonStyle}
          isLoading={isLoading}
          type="submit"
          onClick={_resetPassword}
          disabled={disabled || isLoading || !formValid}>
          Reset
        </Button>
      </div>
      <Spacer height="3rem" />
    </AuthContainer>
  );
};

export default NewPassword;
