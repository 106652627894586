import RadioGroup from '@mui/material/RadioGroup';
import { Modal } from '../../../../components/molecules/Modal/Modal';
import { SideModalContentStyle, SideModalStyle } from '../../../../components/molecules/Modal/style';
import { PointsModalCon, StyledFormLabel } from './styles';
import { useCallback, useEffect, useState } from 'react';
import { COMMONTYPES } from '../../../../TYPES/event.types';
import { Divider, Radio } from '@mui/material';
import { Spacer } from '../../../../components/atoms';

const initial = {
  x: '100%',
  height: 'auto'
};
const animate = {
  x: '0'
};

type PointModalProp = {
  setIsPoint: React.Dispatch<React.SetStateAction<boolean>>;
  showPaymentModal: () => void;
};

export const UsePointsModal = ({ setIsPoint, showPaymentModal }: PointModalProp) => {
  const [toggle, setToggle] = useState<string>('Use cash from wallet');
  const setModalClose = useCallback(() => {
    setIsPoint(false);
  }, [setIsPoint]);

  const onSelectToggle = (e: COMMONTYPES['inputChange'], _: any) => {
    e.stopPropagation();
    setToggle(e.currentTarget.value);
  };

  useEffect(() => {
    if (toggle === 'Save for later') {
      setModalClose();
      showPaymentModal();
    }
  }, [setModalClose, showPaymentModal, toggle]);

  return (
    <Modal
      onModalClose={setModalClose}
      extraModalStyle={SideModalStyle}
      extraModalContentStyle={SideModalContentStyle}
      initial={initial}
      animate={animate}>
      <PointsModalCon>
        <div className="first">
          <h3>Madina Points</h3>

          <Spacer height="50px" />

          <RadioGroup value={toggle} onChange={onSelectToggle}>
            <div>
              <StyledFormLabel
                labelPlacement="start"
                value="Use cash from wallet"
                control={<Radio />}
                label="Use cash from wallet"
                checked={toggle === 'Use cash from wallet'}
              />
              <Spacer height="27px" />
              <Divider />
            </div>

            <Spacer height="27px" />

            <div>
              <StyledFormLabel
                labelPlacement="start"
                value="Save for later"
                control={<Radio />}
                label="Save for later"
                checked={toggle === 'Save for later'}
              />
              <Spacer height="27px" />
              <Divider />
            </div>
          </RadioGroup>
        </div>
      </PointsModalCon>
    </Modal>
  );
};
