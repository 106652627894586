const ADDRESS_CACHE_KEY = 'cache=address';

export const getCacheAddress = () => {
  return localStorage.getItem(ADDRESS_CACHE_KEY);
};

export const setCacheAddress = (address: string) => {
  localStorage.setItem(ADDRESS_CACHE_KEY, address);
};

export const removeCacheAddress = () => {
  localStorage.removeItem(ADDRESS_CACHE_KEY);
};
