import { Spacer } from 'components/atoms';
import { CATEGORIES, SUPERMARKET } from 'navigation/routes';
import QUERY_KEYS from 'network/config/queryKeys';
import { getProductsBySubCategory } from 'network/services/goods';
import { ProductsGrid } from 'pages/Products';
import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import replacer from 'utils/replacer';
import { GoodsGridGoodsGridCon } from './style';

type Props = {
  subCategory?: string;
  subCategoryId: number;
  disableSeeAll?: boolean;
  onEmptySubcategory?: (categoryId: number) => void;
};

export const SupermarketGoods: React.FC<Props> = ({
  subCategory,
  subCategoryId,
  disableSeeAll,
  onEmptySubcategory
}) => {
  const { category } = useParams();

  const new_sub = replacer(/\s+/g, '-', subCategory)?.toLowerCase();
  const route = `${CATEGORIES}${SUPERMARKET}/${category ? category : 'supermarket'}/${new_sub}-${subCategoryId}`;

  const { isFetching, data } = useQuery({
    queryKey: [QUERY_KEYS.PRODUCT_CATEGORIES],
    onSuccess: data => {
      if ((data.data ?? []).length === 0) {
        onEmptySubcategory && onEmptySubcategory(subCategoryId);
      }
    },
    queryFn: () => getProductsBySubCategory({ subCategoryId, size: 20, page: 0 })
  });

  return (
    <GoodsGridGoodsGridCon>
      {subCategory && (
        <>
          <div className="top">
            <h4>{subCategory}</h4>
            {!disableSeeAll && <Link to={route}>See all</Link>}
          </div>
          <Spacer height={'2rem'} />
        </>
      )}
      <ProductsGrid
        key={Math.random()}
        emptyMessage="Unfortunately, there are no items in this category."
        isLoading={isFetching}
        products={data?.data ?? []}
      />
    </GoodsGridGoodsGridCon>
  );
};
