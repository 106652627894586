import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';

export interface LiveChat {
  step: 0 | 1 | 2 | 3;
  issueType: 'orderRelated' | 'other';
  chatTopic?: string;
  orderId?: string;
  chatId?: string;
}

export const liveChatAtom = atom<LiveChat>({
  key: STORE_KEYS.LIVE_CHAT,
  default: {
    step: 0,
    issueType: 'orderRelated'
  }
});
